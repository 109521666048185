import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { ActivatedRoute } from '@angular/router';
import { TipoProducto } from 'src/app/moddelo/tipo-producto';
import { Categoria } from 'src/app/moddelo/categoria';

@Component({
  selector: 'app-tipoproducto',
  templateUrl: './tipoproducto.component.html',
  styleUrls: ['./tipoproducto.component.css']
})
export class TipoproductoComponent implements OnInit {
  public id: number = 0;
  public tipoProducto: TipoProducto = new TipoProducto();
  public categorias: Categoria [] = [];
  
  private productoFrm: FormGroup = new FormGroup({
          nomProducto: new FormControl('', Validators.required),
          fk_categoria: new FormControl('', Validators.required)
  });
  get nomProductoCtrl(){
    return this.productoFrm.get('nomProducto');
  }
  get fk_categoriaCtrl(){
    return this.productoFrm.get('fk_categoria');
  }
  
  constructor(private activated: ActivatedRoute,
              private location: Location, 
              private catalogosSrv: CatalogosService, 
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.id = Number(this.activated.snapshot.paramMap.get('id')); 
      
      if(this.id > 0) {
        this.catalogosSrv
        .getTipoProducto(this.id)
        .subscribe(data => {        
          this.tipoProducto = data;
          this.nomProductoCtrl.setValue(this.tipoProducto.nomProducto);
        }, err => { console.log(err); });
      }
      this.catalogosSrv
      .getCategorias()
      .subscribe(data => {
        this.categorias = data;
      }, error => { console.log()});
  }
  onSubmit(){
    if(this.id > 0){
      this.actualizar();
    }else{
      this.guardar();
    }
  }

  actualizar(): void{
    this.tipoProducto.nomProducto = this.nomProductoCtrl.value;
    this.tipoProducto.id = this.id;
    this.catalogosSrv
    .setTipoProductoUpdate(this.id, { nomProducto: this.tipoProducto.nomProducto})
    .subscribe(data => { 
      this.snackBar.open('Tipo producto actualizado', 'Producto', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
    }, err => { console.log(err) });
}
guardar(): void{
    this.tipoProducto.nomProducto = this.nomProductoCtrl.value;
    this.tipoProducto.fk_categoria = this.fk_categoriaCtrl.value;
    this.tipoProducto.id = this.id;

    this.catalogosSrv
    .setTipoProducto(this.tipoProducto)
    .subscribe(data => {
      this.id = data.id;
      this.tipoProducto = data;

      this.snackBar.open('Tipo producto registrado', 'Producto', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
    }, err => { console.log(err)});
}

  goBack(): void{
    this.location.back();
  }


}
