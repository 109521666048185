import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AuthService } from 'src/app/servicios/auth.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { Medico } from 'src/app/moddelo/medico';
import { Saldo } from 'src/app/moddelo/saldo';


@Component({
  selector: 'app-medico-saldo',
  templateUrl: './medico-saldo.component.html',
  styleUrls: ['./medico-saldo.component.css']
})
export class MedicoSaldoComponent implements OnInit {
  public saldos: Saldo[] = [];
  public medico: Medico;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100, 500];

  constructor(private medicosSrv: MedicosService, private aut: AuthService) { }

  ngOnInit() {
    this.medico = this.aut.getUsuario.medico;
    this.medicosSrv.card = 4;
    this.medicosSrv.getSaldos(this.medico.id)
    .subscribe(data => {
      this.saldos = data;
      for(let row in this.saldos){
         this.suma(parseInt(row));
      }
    }, err => {console.log(err)});
  }
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  suma(i: number){
    if( (this.saldos.length - (i + 1) ) == 0){
      this.saldos[i].saldo = this.saldos[i].puntos -  this.saldos[i].canje;
      return  this.saldos[i].saldo; 
    }else{
      this.saldos[i].saldo = this.saldos[i].puntos - this.saldos[i].canje + this.suma( i + 1);
      return this.saldos[i].saldo;
    }
  }
  
  edoCuenta(): void{
    const doc = new jsPDF('p', 'pt', 'letter');    
    // const DATA = document.createElement("div");    
    const options = {
      background: 'white',
      scale: 3
    };
    
    document.querySelector("input[name=filtro]").setAttribute("style", "visibility:hidden");
    doc.html(document.getElementById('edoCtaMedico'), {
      callback: function (doc) {        
        doc.save(`${new Date().toISOString()}_sdo.pdf`);

        document.querySelector("input[name=filtro]").setAttribute("style", "visibility:visible");
      },
      html2canvas: { scale: .5275}, 
      margin: [1, 1, 1, 1],
      x: 10,
      y: 10
   });
  
   /*
    html2canvas(DATA, options).then( (canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save();
    });
*/
   // doc.text("Estado de Cuenta", 10, 10);
    //doc.table()
    //doc.save("edoCuenta.pdf");
   
  } 
}
