import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material';

import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import { Enfermera } from 'src/app/moddelo/enfermera';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';
import S from '../../../datos/status';
 
@Component({
  selector: 'app-administrador-enfermera-r-canjes',
  templateUrl: './administrador-enfermera-r-canjes.component.html',
  styleUrls: ['./administrador-enfermera-r-canjes.component.css']
})
export class AdministradorEnfermeraRCanjesComponent implements OnInit {
  public canjes: RedimirEnfermera[] = [];

  @Input('enfermera') public enfermera: Enfermera;
  
  @Output('solicitudCanje') solicitudCanje: EventEmitter<RedimirEnfermera> = new EventEmitter<RedimirEnfermera>();
  @Output('deleteCanje') deleteCanje: EventEmitter<RedimirEnfermera> = new EventEmitter<RedimirEnfermera>();
  @Output('nuevoCanje') nuevoCanje: EventEmitter<any> = new EventEmitter<any>();
  @Output('referenciaCanje') referenciaCanje: EventEmitter<RedimirEnfermera> = new EventEmitter<RedimirEnfermera>();
  @Output('detalleCanje') detalleCanje: EventEmitter<RedimirEnfermera> = new EventEmitter<RedimirEnfermera>();
  
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  get saldo(){  
    let puntosAcumulado: number =  this.enfermera.casosclinicos.filter(cc => cc.status_canje == 0 ).reduce((valor, casoclinico) => casoclinico.e_puntos + valor, 0); //this.enfermera.productos.reduce((valor, producto) => producto.e_puntos + valor, 0);
    //let canjesAplicados: number = this.canjes.filter(canje => canje.status == 3 ).reduce((valor, canje ) => canje.redimir + valor, 0);
    //console.log('puntos acumulados: ', puntosAcumulado);
    //console.log('canjes aplicados: ', canjesAplicados);
    //return puntosAcumulado - canjesAplicados;
      return puntosAcumulado;
  }
  get usuario(){
    return this.auth.getUsuario;
  }
  get offCanjes(): boolean {
    return (this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3);
  }
  get borrar(): boolean {
    return (this.usuario.fk_nivel==3 || this.usuario.fk_nivel==0);
  }
   
  constructor(
    private auth: AuthService, 
    private adminSrv: AdministradorService,  
  ) { }

  ngOnInit() {

    this.canjes = this.enfermera.redimirenfermera.sort((a, b) => { let a_fecha = new Date(a.fechaInicio); 
                                                      let b_fecha = new Date(b.fechaInicio); 
                                                      return b_fecha.getTime() - a_fecha.getTime()
                                                    });
      
  }
    
  estatus(i: number){
    return S.status[i];
  }
  
  getProcesar(s: number){
    return s == 1;
  }

  getAceptado(s: number){
    return s == 3;
  }
  setSolicitudCanje(canje: RedimirEnfermera): void {
    this.solicitudCanje.emit(canje);
  }
  setDeleteCanje(canje: RedimirEnfermera): void {
    this.deleteCanje.emit(canje);
  }
  setNuevoCanje(): void {
    this.nuevoCanje.emit();
  }
  setReferenciaCanje(canje: RedimirEnfermera): void {
    this.referenciaCanje.emit(canje);
  }
  getDetalleCanje(canje: RedimirEnfermera): void{
    this.detalleCanje.emit(canje);
  }
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  generateData(): any[] {
    let result: any[] = this.canjes.map((c, i) => ({
                                                  No: (i+1).toString(),   
                                                  FechaSolicitud: c.fechaInicio ? moment(c.fechaInicio).format('L') : '', 
                                                  Cantidad: c.redimir,
                                                  FechaAplicacion: c.fechaFinal ? moment(c.fechaFinal).format('L') : '', 
                                                  Status: this.estatus(c.status),
                                                  Observacion: c.observacion
                                                  })  
                                        );
    return result;
  }
  exportTablaExcel(): void {
    let nombreEnfermera = [{campo: "Enfermera", nomEnfermera: this.enfermera.nombre + ' ' + this.enfermera.primerApellido + (this.enfermera.primerApellido ? ' ' + this.enfermera.primerApellido: '')}];
    let tredimir: any[] = this.canjes.map((c, i) => {
      return  { 
                              No: (i+1).toString(),   
                              FechaSolicitud: c.fechaInicio ? moment(c.fechaInicio).format('L') : '', 
                              Cantidad: c.redimir,
                              FechaAplicacion: c.fechaFinal ? moment(c.fechaFinal).format('L') : '', 
                              Status: this.estatus(c.status),
                              Observacion: c.observacion
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreEnfermera, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tredimir, { origin:"A4" });
        /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(wb, ws, 'Canjes1');
  
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_canjes.xlsx`);
  }
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
     let nombreEnfermera: string = this.enfermera.nombre + ' ' + this.enfermera.primerApellido + (this.enfermera.primerApellido ? ' ' + this.enfermera.primerApellido: '');
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     doc.setFontSize(18);
     doc.text("Registro de canje del enfermera",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombreEnfermera, 14, 15);
     doc.setFontSize(12);
     doc.text('Saldo: ' + this.saldo, 250, 15);
     doc.setTextColor(100);
     
    
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Fecha Solicitud', dataKey: 'FechaSolicitud' },
         { header: 'Cantidad', dataKey: 'Cantidad' },
         { header: 'Fecha Aplicacion', dataKey: 'FechaAplicacion' },
         { header: 'Status', dataKey: 'Status' },
         { header: 'Observacion', dataKey: 'Observacion' }
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
         doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_enf_c.pdf`);
  }
 
}
