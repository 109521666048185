import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Medico } from 'src/app/moddelo/medico';
import { MedicosService } from 'src/app/servicios/medicos.service';

interface IRemoveEnfermera{
    code: number;
    message: string;
    data: Enfermera;
}

@Component({
    selector: 'dialogo-medico-enfermera-remove',
    templateUrl: 'dialogo-medico-enfermera-remove.html'
})
export class DialogoMedicoEnfermeraRemove {
    public respuesta: IRemoveEnfermera;  
    constructor(
        public dialogo: MatDialogRef<DialogoMedicoEnfermeraRemove>,
        @Inject(MAT_DIALOG_DATA) public data: {enfermera: Enfermera, medico: Medico},
        private snackBar: MatSnackBar,
        private medicosSrv: MedicosService
    ){}

    
    remove(): void {
        if(confirm("¿Estas seguro?")){

                this.medicosSrv
                .setDesasociarEnfermera(this.data.medico.id, this.data.enfermera)
                .subscribe(data => {
                                    let i: number = 0;
                                    let e: Enfermera[] = null;
                                    this.respuesta = <IRemoveEnfermera> data;
                                    if(this.respuesta.code) {
                                    i = this.data.medico.enfermeras.findIndex(e => e.id == this.data.enfermera.id); 
                                    e = this.data.medico.enfermeras.splice(i, 1);
                                    this.snackBar.open(this.respuesta.message, 'Enfermera', 
                                        {
                                        duration: 3000,
                                        verticalPosition:'bottom',
                                        horizontalPosition:'center'
                                        });
                                        console.log("Removido: ", e[0].nombre, ' ' , e[0].primerApellido );
                                         this.onClose(); 
                                    }
                                    else{
                                            this.snackBar.open(this.respuesta.message, 'Enfermera', 
                                            {
                                                duration: 3000,
                                                verticalPosition:'bottom',
                                                horizontalPosition:'center'
                                            });
                                    }
                }, err => { console.log(err); });

       }
       
    }
    onClose(): void {
        this.dialogo.close();
    }
}