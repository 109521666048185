import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { ActivatedRoute } from '@angular/router';
import { Categoria } from 'src/app/moddelo/categoria';
@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})
export class CategoriaComponent implements OnInit {
 public id: number = 0;
 public categoria: Categoria = new Categoria();

 private categoriaFrm: FormGroup = new FormGroup({
    categoria: new FormControl('', Validators.required)
});

get categoriaCtrl(){
  return this.categoriaFrm.get('categoria');
}
  constructor(
    private activated: ActivatedRoute,
    private location: Location, 
    private catalogosSrv: CatalogosService, 
    private snackBar: MatSnackBar ) { }

  ngOnInit(): void {
    this.id = Number(this.activated.snapshot.paramMap.get('id')); 

    if(this.id > 0) {
      this.catalogosSrv
      .getCategoria(this.id)
      .subscribe(data => {        
        this.categoria = data;
        this.categoriaCtrl.setValue(this.categoria.categoria);
      }, err => { console.log(err); });
    }
  }

  onSubmit(): void{
    if(this.id > 0){
      this.actualizar();
    }else{
      this.guardar();
    }
  }
  
  actualizar(): void{
    this.categoria.categoria = this.categoriaCtrl.value;
    this.categoria.id = this.id;

    this.catalogosSrv
    .setCategoriaUpdate(this.id, {categoria: this.categoria.categoria } )
    .subscribe(data => { 
      this.snackBar.open('Categoria actualizada', 'Categoria', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
    }, err => { console.log(err) });
  }

  guardar(): void{
    this.categoria.categoria = this.categoriaCtrl.value;
    this.categoria.created_at = new Date();
    this.categoria.updated_at = new Date();   
    this.categoria.id = this.id;

    this.catalogosSrv
    .setCategoria(this.categoria)
    .subscribe(data => {
      this.id = data.id;
      this.categoria = data;

      this.snackBar.open('Categoria registrada', 'Categoria', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
    }, err => { console.log(err)});
  }
  
  goBack(): void{
    this.location.back();
  }

}
