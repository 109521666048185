import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent, MatDialog } from '@angular/material';
import { Usuario } from 'src/app/moddelo/usuario';
import { AuthService } from 'src/app/servicios/auth.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { DialogoCambiarPasswUsuario } from '../../dialogos/dialogo-cambiar-passw-usuario/dialogo-cambiar-passw-usuario';

@Component({
  selector: 'app-administrador-usuarios-adm-n3',
  templateUrl: './administrador-usuarios-adm-n3.component.html',
  styleUrls: ['./administrador-usuarios-adm-n3.component.css']
})
export class AdministradorUsuariosAdmN3Component implements OnInit {
  public usuarios: Usuario[] = [];
  public usuariosFiltrados: Usuario[] =[];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
    nomUsuario: new FormControl('')
  });
  get usuarioCtrl() {
    return this.filtroFrm.get('nomUsuario');
  }
  get Autorizacion() {
    return this.auth.getUsuario.fk_nivel == 0 || this.auth.getUsuario.fk_nivel == 3;
  }
  constructor(
    private auth: AuthService,
    private usuariosSrv: UsuariosService, 
    private dialogo: MatDialog) { }
 
  ngOnInit() {
    this.usuariosSrv.getUsuarios().subscribe(data => { 
      this.usuarios = data;     
      this.usuariosFiltrados = data.filter(u => u.fk_rol > 2 );        
    }, err => { console.log(err); } );
  }
  onSubmit()
  {
    this.usuariosFiltrados = this.usuarios.filter(u => this.filterUsuario(u.nombre.concat(u.primerApellido).concat(u.segundoApellido?u.segundoApellido:'')) && u.fk_rol > 2); 
    this.page_number = 1;
  }
  filterUsuario(nomusuario: string): boolean{
    let condicion: boolean = true;
    let usuario: string;
    let u: string;
    let nombre: string; 
    
    if(this.usuarioCtrl.value != ""){
      u = this.usuarioCtrl.value;
      usuario = u.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomusuario.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      condicion = nombre.replace(/ +/g,'').includes(usuario.replace(/ +/g,''));
    }  
   
    return condicion;
  }
  changePassw(usuario: Usuario){
    this.dialogo.open(DialogoCambiarPasswUsuario, {width:'600px', data: usuario});
  }
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }  
}
