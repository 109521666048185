import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';
import moment  from 'moment/moment';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CasosClinicosService } from '../../../servicios/casos-clinicos.service';
import { HospitalCasosClinicos } from 'src/app/moddelo/hospital-casos-clinicos';
import { InstitucionCasosClinicos } from 'src/app/moddelo/institucion-casos-clinicos';

@Component({
  selector: 'app-administrador-metrica-uno',
  templateUrl: './administrador-metrica-uno.component.html',
  styleUrls: ['./administrador-metrica-uno.component.css']
})
export class AdministradorMetricaUnoComponent implements OnInit {

  public hospitales: HospitalCasosClinicos[]=[];
  public hospitalesFilter: HospitalCasosClinicos[]=[];

  public instituciones: InstitucionCasosClinicos[]=[];
  public institucionesFilter: InstitucionCasosClinicos[] = [];
  public cargar: boolean = false;
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
                                                fechaInicial: new FormControl(''),
                                                fechaFinal: new FormControl('')
                                              });
  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }
  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  } 
  get url(): string{    
    return window.location.origin.replace(':4200', '');
  }
  constructor(private location: Location, private casosclinicossrv: CasosClinicosService ) { }

  ngOnInit() {
    moment.locale('es-MX');
  }

  onSubmit() {

    if(moment(this.fechaInicialCtrl.value).isValid()) {
        
      if(moment(this.fechaFinalCtrl.value).isValid()) {

        if(moment(this.fechaInicialCtrl.value) <= moment(this.fechaFinalCtrl.value)) {
            this.cargar = true;
            let fechaInicial = moment(this.fechaInicialCtrl.value).format('YYYY-MM-DD');
            let fechaFinal = moment(this.fechaFinalCtrl.value).format('YYYY-MM-DD');
            this.casosclinicossrv
            .getCasosClinicosxInstitucion(fechaInicial, fechaFinal)
            .pipe(finalize( () => this.cargar = false))
            .subscribe(data => {
                this.instituciones = data;
                this.institucionesFilter = this.instituciones;                
            }, err => { console.log(err); });

        }else {
          alert('El rango de fechas no esta bien definido')
        }            

      }else {
        alert('La fecha final no es valida')
      }

    }else {
      alert('La fecha inicial no es valida')
    }   
   
  }

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }


  exportTablaExcel(): void {  
    
    
    
    if(moment(this.fechaInicialCtrl.value).isValid()) {
        
      if(moment(this.fechaFinalCtrl.value).isValid()) {

        if(moment(this.fechaInicialCtrl.value) <= moment(this.fechaFinalCtrl.value)) {
            this.cargar = true;
            let fechaInicial = moment(this.fechaInicialCtrl.value).format('YYYY-MM-DD');
            let fechaFinal = moment(this.fechaFinalCtrl.value).format('YYYY-MM-DD');
            this.casosclinicossrv
            .getCasosClinicosxInstitucionRows(fechaInicial, fechaFinal)
            .pipe(finalize( () => this.cargar = false))
            .subscribe(data => {
              let rows: any[]  = data;
                
              const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);

              /* generate workbook and add the worksheet */
              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'Instituciones');

              /* save to file */  
              XLSX.writeFile(wb, `${new Date().toISOString()}_InstitucionCasosClinicos.xlsx`);


            }, err => { console.log(err); alert(err.error); });

        }else {
          alert('El rango de fechas no esta bien definido')
        }            

      }else {
        alert('La fecha final no es valida')
      }

    }else {
      alert('La fecha inicial no es valida')
    }
        
  }
  createHeaders(): any[] {
    let keys: string[] = [
                           "Institucion",
                           "NoCasosClinicos",
                           "NoProductos"
                         ]
     let result: any[]= []; 
     
     result = <any[]> keys.map(key => (<any> {
                                               id: key,
                                               name: key,
                                               prompt: key,
                                               width: "65",
                                               align: "center",
                                               padding: "0"
                                             }));
 
     
     return result;
  }
  generateData(): any[] {
    let result = [];
    let i: number = 0;

    this.institucionesFilter.forEach((h, i) => {
        
        result.push(Object.assign( {}, 
        {                
          Institucion: h.cvInstitucion,
          NoCasosClinicos: h.NoCasosClinicos,
          NoProductos: h.NoProductos.toString()
        }));

    });

  return result;
  }  
  exportTablaPDF(): void {  
    const doc = new jsPDF('landscape');  
    let periodo: string = 'Periodo: ' + moment(this.fechaInicialCtrl.value).format('L') + '  a  ' + moment(this.fechaFinalCtrl.value).format('L');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("Reporte por Institucion",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(periodo, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'Institucion', dataKey: 'Institucion' },         
         { header: 'No Casos Clinicos', dataKey: 'NoCasosClinicos' },
         { header: 'No Productos', dataKey: 'NoProductos' }        
       ],       
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
        // doc.text(moment(Date.now()).format('L'), data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }   
     
     doc.save(`${new Date().toISOString()}_CasosClinicosxInstitucion.pdf`);  
  }

  exportDetallesTablaPDF(): void
  {
    let fechaInicial = moment(this.fechaInicialCtrl.value).format('YYYY-MM-DD');
    let fechaFinal = moment(this.fechaFinalCtrl.value).format('YYYY-MM-DD');

    this.casosclinicossrv
    .getDestallescasosclinicosinstitucionPDF(fechaInicial, fechaFinal)
    .subscribe(data => {    
      window.open(this.url + data.filename, "_blank");
     }, err => { console.log(err) });
   
  }
  goBack() {
    this.location.back();
  }
}
