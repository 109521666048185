import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent, Sort, MatSnackBar } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Supervisor } from 'src/app/moddelo/supervisor';
import { Medico } from 'src/app/moddelo/medico';
import { Hospital } from 'src/app/moddelo/hospital';
import { Institucion } from 'src/app/moddelo/institucion';
import { AdministradorService } from 'src/app/servicios/administrador.service';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';

 
interface ItbCasosClinicos{
  No: string;
  Fecha: string;
	Folio: string;
	Paciente: string;
	Diagnostico: string;
  Status: string;
  Servicio: string;
	Hospital: string;
  Institucion: string;
	Estado: string;
  Medico: string;
  Enfermera: string;
  Productos: number;
}

@Component({
  selector: 'app-administrador-supervisor-r-casos-clinicos',
  templateUrl: './administrador-supervisor-r-casos-clinicos.component.html',
  styleUrls: ['./administrador-supervisor-r-casos-clinicos.component.css']
})
export class AdministradorSupervisorRCasosClinicosComponent implements OnInit, AfterViewInit {
  @Input('casosclinicos') casosclinicos: CasoClinico[];
  @Input('supervisor') supervisor: Supervisor;
  public filtroFrm: FormGroup;
  public filtroCasosClinicos: CasoClinico [] = [];
  
  public hospitales: Hospital[] = [];
  public instituciones: Institucion[] = [];
  public filtrosInstituciones: Institucion[] = []; 

  public filtrosHospitales: Hospital[]=[];
  public nombresHospitales: string[] = [];

  public filtroEnfermeras: Observable<String[]>;
  public nombresEnfermeras: String[] = [];

  public filtroMedicos: Observable<String[]>;
  public nombresMedicos: String[] = [];

  public canjecat: string[] = ["registrado", "solicitud", "en proceso", "Pagado", "cancelado"];
  public page_size: number = 5;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [0, 5, 10, 20, 50, 100];
 
  get url(): string {    
    return window.location.origin.replace(':4200', '');
  }
  
  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.filtroFrm.get('folio');
  }

  get pacienteCtrl(){
    return this.filtroFrm.get('paciente');
  }

  get fk_hospitalCtrl(){
    return this.filtroFrm.get('fk_hospital');
  }

  get fk_institucionCtrl(){
    return this.filtroFrm.get('fk_institucion');
  }

  get enfermeraCtrl() {
    return this.filtroFrm.get('enfermera');
  }

  get medicoCtrl() {
    return this.filtroFrm.get('medico');
  }
  constructor(
    private adminSrv: AdministradorService,
    private formBuilder: FormBuilder, 
    private matSnakBar: MatSnackBar) { 

    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial: [''],
                                            fechaFinal: [''],
                                            folio: [''],
                                            paciente: [''],
                                            fk_institucion: [''],
                                            fk_hospital: [''],
                                            enfermera: [''],
                                            medico: ['']                                            
                                          });
                                        
  }
  ngAfterViewInit(): void {
    this.filtroCasosClinicos = this.casosclinicos;
  }
  ngOnInit() {
      
    this.nombresEnfermeras = this.adminSrv.enfermeras.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) );  
    this.filtroEnfermeras = this.enfermeraCtrl.valueChanges.pipe( startWith(''), map(value => this._filterEnfermeras(value)) );   
   
    this.nombresMedicos = this.adminSrv.medicos.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) ); 
    this.filtroMedicos = this.medicoCtrl.valueChanges.pipe( startWith(''), map(value => this._filterMedicos(value)) ); 
    

    this.hospitales = this.adminSrv.hospitales;
   
    this.instituciones = this.adminSrv.instituciones;
    this.filtrosInstituciones = this.adminSrv.instituciones;
  }
  onSubmit() {
    this.filtroCasosClinicos =  this.casosclinicos.filter( cc => 
                                                                this.filterPaciente(cc.paciente) && 
                                                                this.filterHospital(cc.fk_hospital) && 
                                                                this.filterInstitucion(cc.fk_institucion) && 
                                                                this.filterFolio(cc.folio) && 
                                                                this.filterEnfermera(cc.fk_enfermera, cc.enfermera) && 
                                                                this.filterMedico(cc.fk_medico, cc.medico) && 
                                                                this.filterFecha(cc.fecha.toString())
                                                          );

    this.page_number=1;  
  }
  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  }
 

  filterInstitucion(fk_institucion: number): boolean {
    
    let condicion: boolean = true;  
    console.log("fk_institucion: ",this.fk_institucionCtrl.value);
    if( this.fk_institucionCtrl.value ){
      let id_inst: number = this.fk_institucionCtrl.value;       
      if(fk_institucion) {      
        condicion = id_inst == fk_institucion;      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterHospital(fk_hospital: number): boolean{
    let condicion: boolean = true;  

    if( this.fk_hospitalCtrl.value ){
      let id_hospital: number = this.fk_hospitalCtrl.value;       
      if(fk_hospital){      
        condicion = id_hospital == fk_hospital;      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
 /*
  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(nombre){
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterInstitucion(cveInstitucion: string): boolean{
    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let cve: string;

    if( this.institucionCtrl.value != 0 ){
        i = this.institucionCtrl.value;
        institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
        cve = cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(cve){
        condicion = cve.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  */
  filterEnfermera(fk_enfermera: number, enfermera: Enfermera): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: String = "";

    if(this.enfermeraCtrl.value != ""){
        nom = this._normalizeValue(this.enfermeraCtrl.value);
        if(fk_enfermera){
          nomEnfermera = enfermera ? (enfermera.nombre + ( enfermera.primerApellido ? ' ' + enfermera.primerApellido : '' ) + (enfermera.segundoApellido ? ' ' + enfermera.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );
        }
    }
    
    return condicion;
  }
  filterMedico(fk_medico: number, medico: Medico): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: String = "";

    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(fk_medico){
          nomMedico = medico ? (medico.nombre + ( medico.primerApellido ? ' ' + medico.primerApellido : '' ) + (medico.segundoApellido ? ' ' + medico.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );
        }
    }
    
    return condicion;
  }
  
  
  
  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
        condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }

  handlePage( e: PageEvent): void {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }
  sortCasosClinicos(sort: Sort): void {
    const datos: CasoClinico[] = this.filtroCasosClinicos.slice();
    if(!sort.active || sort.direction === ''){
      this.filtroCasosClinicos = datos;
      return;
    }
    this.filtroCasosClinicos =  datos.sort((a, b) => {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'fecha':
        let a1: number = new Date(a.fecha).getTime();
        let b1: number = new Date(b.fecha).getTime();
        return this.compare(a1, b1, isAsc);
      case 'serie':
        return this.compare(a.serie, b.serie, isAsc);
      case 'paciente':
        return this.compare(a.paciente, b.paciente, isAsc);
      case 'hospital':
        return this.compare(a.hospital, b.hospital, isAsc);
      case 'cveInstitucion':
        return this.compare(a.cveInstitucion, b.cveInstitucion, isAsc);
      case 'estado':
        return this.compare(a.fk_estado, b.fk_estado, isAsc);
      case 'medico':
        let medicoA: string = (a.fk_medico ? a.medico.nombre + ' ' +( a.medico.primerApellido ? a.medico.primerApellido: '') : '');
        let medicoB: string = (b.fk_medico ? b.medico.nombre + ' ' +( b.medico.primerApellido ? b.medico.primerApellido : ''): '');
        return this.compare(medicoA, medicoB, isAsc);
      case 'enfermera':
        let enfermeraA: string = (a.fk_enfermera ? a.enfermera.nombre + ' ' +( a.enfermera.primerApellido ? a.enfermera.primerApellido: '') : '');
        let enfermeraB: string = (b.fk_enfermera ? b.enfermera.nombre + ' ' +( b.enfermera.primerApellido ? b.enfermera.primerApellido : ''): '');
        return this.compare(enfermeraA, enfermeraB, isAsc);  
      case 'productos':
        let productosA: number = a.productos ? a.productos.length : 0;
        let productosB: number = b.productos ? b.productos.length : 0;
        return this.compare(productosA, productosB, isAsc);
      case 'diagnostico':
          let diagnosticoA: string = a.diagnostico;
          let diagnosticoB: string = b.diagnostico;
          return this.compare(diagnosticoA, diagnosticoB, isAsc);
      case 'servicio':
            let cuerpoa: string = a.seccioncuerpo ? a.seccioncuerpo.nomCuerpo: '';
            let cuerpob: string = b.seccioncuerpo ? b.seccioncuerpo.nomCuerpo: '';
            return this.compare(cuerpoa, cuerpob, isAsc);       
      default:
        return 0;
    }
  });
  } 
  compare(a: number | string, b: number | string, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  generateData(): any[] {
    let result: ItbCasosClinicos[] = [];
    let i: number = 0;

    this.filtroCasosClinicos.forEach((cc, i) => {
        
        result.push( {
                      No: (i+1).toString(),   
                      Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                      Folio: cc.folio,
                      Paciente: cc.paciente, 
                      Diagnostico: cc.diagnostico,
                      Servicio: cc.seccioncuerpo ? cc.seccioncuerpo.nomCuerpo : '',
                      Status: this.canjecat[cc.status_canje],
                      Hospital: cc.hospital,
                      Institucion: cc.cveInstitucion,
                      Estado: cc.fk_estado ? cc.estado.nomEstado : '',
                      Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido : '',                       
                      Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido : '',
                      Productos: cc.productos ? cc.productos.length : 0 
                    });
    });

  return result;
  }
  exportProductividad(): void {

    if(this.fechaFinalCtrl.value && this.fechaInicialCtrl.value){
     
      let f1 = moment(this.fechaInicialCtrl.value).format("YYYY-MM-DD");
      let f2 = moment(this.fechaFinalCtrl.value).format("YYYY-MM-DD");

      if(moment(this.fechaFinalCtrl.value) >= moment(this.fechaInicialCtrl.value)){
        
        const datos: any =  {fecha1: f1 , fecha2: f2};   
        this.adminSrv
       .getProductividadSupervisor(this.supervisor.id.toString(), datos)
       .subscribe(data => {
        //   console.log("productividad ", data.ruta);
           window.open(this.url + data.ruta, "_blank");
       }, err => { console.log(err) }); 


      } else {
        this.matSnakBar.open(`La Fecha Final debe ser mayor a la Fecha Inicial`, "Caso Clinico", {
          duration: 2000,
          verticalPosition:'bottom',
          horizontalPosition:'center'
        });
      }
      
    } else  {
      this.matSnakBar.open(`Debe de tener informacion de fecha que sean validas`, "Caso Clinico", {
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });
    }

  }
  exportTablaExcel(): void{
    let nombre = [{campo: "SUPERVISOR", nomSupervisor: this.supervisor.nombre + ' ' + this.supervisor.primerApellido + (this.supervisor.primerApellido ? ' ' + this.supervisor.primerApellido: '')}];
    let tCasosClinicos: ItbCasosClinicos[] = this.filtroCasosClinicos.map((cc, i, casos) => {
      return <ItbCasosClinicos> { 
                              No: (i+1).toString(),   
                              Fecha: cc.fecha ? moment(cc.fecha).format('DD/MM/YYYY') : '', 
                              Folio: cc.serie,
                              Paciente: cc.paciente,
                              Diagnostico: cc.diagnostico,
                              Servicio: cc.seccioncuerpo ? cc.seccioncuerpo.nomCuerpo : '',
                              Status: this.canjecat[cc.status_canje],
                              Hospital: cc.hospital,
                              Institucion: cc.cveInstitucion,
                              Estado: cc.fk_estado ? cc.estado.nomEstado : '',  
                              Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido: '',
                              Productos: cc.productos ? cc.productos.length : 0 
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombre, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tCasosClinicos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'supervisor_casos_clinicos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}supervisorcasosclinicos.xlsx`);
  }
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let nombreMedico: string = this.supervisor.nombre + ' ' + this.supervisor.primerApellido + (this.supervisor.primerApellido ? ' ' + this.supervisor.primerApellido: '');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("CASOS CLINICOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombreMedico, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'Folio', dataKey: 'Folio' },
         { header: 'Paciente', dataKey: 'Paciente' },
         { header: 'Diagnostico', dataKey: 'Diagnostico' },
         { header: 'Servicio', dataKey: 'Servicio'},
         { header: 'Status Canje', dataKey: 'Status'},
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Institucion', dataKey: 'Institucion' },
         { header: 'Estado', dataKey: 'Estado' },  
         { header: 'Medico', dataKey: 'Medico' },         
         { header: 'Enfermera', dataKey: 'Enfermera' },
         { header: 'Productos', dataKey: 'Productos' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_supervisor_cc.pdf`);
  }
  onChangeSelectInst(id_institucion: string) {  
   
    this.nombresHospitales = [];   
     
    this.nombresHospitales = [];
  
    this.fk_hospitalCtrl.setValue('');  
   
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0)); 
     
  }
  _filterEnfermeras(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresEnfermeras.filter(enfermera => this._normalizeValue(enfermera).includes(valorFiltro));
  }
  _filterMedicos(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresMedicos.filter(medico => this._normalizeValue(medico).includes(valorFiltro));

  } 
}
