import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { CasosClinicosRoutingModule } from './casos-clinicos-routing.module';
import { CasosClinicosComponent } from './casos-clinicos/casos-clinicos.component';
import { CasosClinicosFichaComponent } from './casos-clinicos-ficha/casos-clinicos-ficha.component';
import { CasosClinicosDocumentoUploadComponent } from './casos-clinicos-documento-upload/casos-clinicos-documento-upload.component';
import { CasosClinicosPostoperatoriosComponent } from './casos-clinicos-postoperatorios/casos-clinicos-postoperatorios.component';
import { CasosClinicosPostoperatorioComponent } from './casos-clinicos-postoperatorio/casos-clinicos-postoperatorio.component';
import { CasosClinicosCodigoComponent } from './casos-clinicos-codigo/casos-clinicos-codigo.component';
import { CasosClinicosDocumentosComponent } from './casos-clinicos-documentos/casos-clinicos-documentos.component';
import { CasosClinicosFichaVistaComponent } from './casos-clinicos-ficha-vista/casos-clinicos-ficha-vista.component';
import { CasoClinicoFichaNuevoComponent } from './caso-clinico-ficha-nuevo/caso-clinico-ficha-nuevo.component';





@NgModule({
  declarations: [CasosClinicosComponent, CasosClinicosFichaComponent, CasosClinicosDocumentoUploadComponent, CasosClinicosPostoperatoriosComponent, CasosClinicosPostoperatorioComponent, CasosClinicosCodigoComponent, CasosClinicosDocumentosComponent, CasosClinicosFichaVistaComponent, CasoClinicoFichaNuevoComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CasosClinicosRoutingModule,
    MaterialModule
  ]
})
export class CasosClinicosModule { }
