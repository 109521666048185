import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { Medico } from 'src/app/moddelo/medico';
import { Enfermera } from 'src/app/moddelo/enfermera';
@Component({
  selector: 'app-enfermera-medicos',
  templateUrl: './enfermera-medicos.component.html',
  styleUrls: ['./enfermera-medicos.component.css']
})
export class EnfermeraMedicosComponent implements OnInit {
  public medicos: Medico[] = [];
  public enfermera: Enfermera;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
    nomMedico: new FormControl(''),
    especialidad: new FormControl('')
  });

  get medicoCtrl(){
    return this.filtroFrm.get('nomMedico');
  }
  get especialidadCtrl(){
    return this.filtroFrm.get('especialidad');
  }

  constructor(private aut: AuthService, private enfermerasSrv: EnfermerasService) {
    this.enfermera = this.aut.getUsuario.enfermera;
  }
  
  ngOnInit() {
    this.enfermerasSrv.card = 2;
    this.medicos = this.enfermerasSrv.enfermera.medicos;    
  }
  
  onSubmit()
  {
    this.medicos = this.enfermerasSrv.enfermera.medicos.filter(m => this.filterMedico(m.nombre.concat(m.primerApellido).concat(m.segundoApellido?m.segundoApellido:'')) && this.filterEspecialidad(m.nomEspecialidad) ); 
    this.page_number=1;   
  }
  
  filterMedico(nommedico: string): boolean {
    let condicion: boolean = true;
    let medico: string;
    let m: string;
    let nombre: string; 
    
    if(this.medicoCtrl.value != ""){
      m = this.medicoCtrl.value;
      medico = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nommedico.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(medico.replace(/ +/g,''));
    }     
    return condicion;
  }

  filterEspecialidad(nomEspecialidad: string): boolean {
    let condicion: boolean = true;
    let especialidad: string;
    let e: string;
    let nombre: string; 
    
    if(this.especialidadCtrl.value != ""){
      e = this.especialidadCtrl.value;
      especialidad = e.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomEspecialidad.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(especialidad.replace(/ +/g,''));
    }     
    return condicion;
  }
  
  handlePage( e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

}
