import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { Usuario } from 'src/app/moddelo/usuario';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Medico } from 'src/app/moddelo/medico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Supervisor } from 'src/app/moddelo/supervisor';


interface Respuesta{
  code: number;
  message: String;
  data: Usuario;
}
@Component({
  selector: 'app-administrador-usuario-adm-n3',
  templateUrl: './administrador-usuario-adm-n3.component.html',
  styleUrls: ['./administrador-usuario-adm-n3.component.css']
})
export class AdministradorUsuarioAdmN3Component implements OnInit {
  
  public usuario: Usuario;


  public usuarioFrm: FormGroup = new FormGroup({   
                                                  alias: new FormControl('', Validators.required),
                                                  nombre: new FormControl('', Validators.required),
                                                  primerApellido: new FormControl('', Validators.required),
                                                  segundoApellido: new FormControl(''),
                                                  telefonoMovil: new FormControl('')    
                                                });
  get aliasCtrl(){
    return this.usuarioFrm.get('alias');
  }
  get nombreCtrl(){
    return this.usuarioFrm.get('nombre');
  }
  get primerApellidoCtrl(){
    return this.usuarioFrm.get('primerApellido');
  }
  get segundoApellidoCtrl(){
    return this.usuarioFrm.get('segundoApellido');
  }
  get telefonoMovilCtrl(){
    return this.usuarioFrm.get('telefonoMovil');
  }
 
  get getUsuarioActivo() {
    let r = false;
    if(this.usuario){
      r = this.usuario.activo == 1;
    }
    return r;
  }
  get isMedico() {
    let m = false;

    if(this.usuario){
      m = this.usuario.fk_rol == 3;
    }
    return m;
  }
  get isProductivo() {
    let respuesta: Boolean = false;
    let medico: Medico;
    let enfermera: Enfermera;
    let supervisor: Supervisor;

    if (this.usuario){
     if( this.usuario.fk_rol == 3){
       medico=this.adminSrv.medicos.find(m => m.fk_usuario == this.usuario.id);
       respuesta = ( medico.redimirmedico.length == 0 && medico.casosclinicos.length == 0 && medico.enfermeras.length == 0 && medico.productos.length==0 );
     }
     if(this.usuario.fk_rol == 5){
       enfermera = this.adminSrv.enfermeras.find(e => e.fk_usuario == this.usuario.id);
       respuesta = ( enfermera.redimirenfermera.length == 0 && enfermera.casosclinicos.length == 0  &&  enfermera.medicos.length==0  &&  enfermera.productos.length == 0 && enfermera.fk_supervisor==null)
     }
     if(this.usuario.fk_rol == 6){
       supervisor = this.adminSrv.supervisores.find(s => s.fk_usuario == this.usuario.id);
       respuesta = (supervisor.enfermeras.length == 0);
     }
    }

    return respuesta;
  }
  constructor(private location: Location, 
              private activated: ActivatedRoute, 
              private usuariosSRV: UsuariosService, 
              private router: Router,
              private adminSrv: AdministradorService) { }

  ngOnInit() {
    let id: number = Number( this.activated.snapshot.paramMap.get('id') ); 
  
    this.usuariosSRV.getUsuario(id).subscribe(data => { 
            this.usuario = data;   
            
            this.nombreCtrl.setValue(this.usuario.nombre);
            this.aliasCtrl.setValue(this.usuario.alias);
            this.primerApellidoCtrl.setValue(this.usuario.primerApellido);
            this.segundoApellidoCtrl.setValue(this.usuario.segundoApellido);
            this.telefonoMovilCtrl.setValue(this.usuario.telefonoMovil);

            if(this.usuario.fk_rol == 3){
              
            }
            

    }, err => { console.log(err); });
    
  }  
  onSubmit() {
      let usuarioO: Usuario;
      let medicoO: Medico;
      let enfermeraO: Enfermera;
      let supervisorO: Supervisor;

      let { alias, nombre, primerApellido, segundoApellido, telefonoMovil } = this.usuarioFrm.value;
      usuarioO = <Usuario>  { alias, nombre, primerApellido, segundoApellido, telefonoMovil };
    if(this.usuario.fk_rol == 3){
      medicoO = <Medico> { nombre, primerApellido, segundoApellido, telefonoMovil }; 
      this.usuariosSRV.setUsuarioMedicoUpdate(this.usuario.id, usuarioO, medicoO).subscribe(data => { if(data){ alert(" actualizado exitosamente"); this.router.navigate(['/administrador/usuarios/n3']); } }, err=> {console.log(err)});
    }else if(this.usuario.fk_rol == 5){
      enfermeraO = <Enfermera> { nombre, primerApellido, segundoApellido, telefonoMovil }; 
      this.usuariosSRV.setUsuarioEnfermeraUpdate(this.usuario.id, usuarioO, enfermeraO).subscribe(data => { if(data){ alert(" actualizado exitosamente"); this.router.navigate(['/administrador/usuarios/n3']);  } }, err=> {console.log(err)})
    }else if(this.usuario.fk_rol == 6){
      supervisorO = <Supervisor> { nombre, primerApellido, segundoApellido, telefonoMovil }; 
      this.usuariosSRV.setUsuarioSupervisorUpdate(this.usuario.id, usuarioO, supervisorO).subscribe(data => { if(data){ alert(" actualizado exitosamente"); this.router.navigate(['/administrador/usuarios/n3']);  } }, err=> {console.log(err)})
    }else{
      alert("usuario no definido");
    }
  
    
  }
  delete() {
     
    if(confirm("seguro de eliminar este usuario")){
      this.usuariosSRV.deleteUsuarioOperativo(this.usuario.id).subscribe(data => { 
        let usuario: Usuario;
        let respuesta: Respuesta;
        
        respuesta = <Respuesta> data;
        usuario = respuesta.data;
        
        alert(respuesta.message);

        if( respuesta.code == 1){
           this.refresh(usuario);
           this.goBack();         //this.router.navigate(['/administrador/usuarios/n3']); 
        }
       
      }, 
      err => { console.log(err) });
    }
    
  }
  desactivar(id: number) {

    if(confirm("seguro de desabilitar este usuario")){
      this.usuariosSRV.desactivarUSuario(id).subscribe(data => { if(data){ alert(`Usuario ${data.usuario} desactivado`); this.goBack();} }, err => { console.log(err); });
    }

  }
  activar(id: number) {
    
    if(confirm("seguro de habilitar este usuario")){
      this.usuariosSRV.activarUsuario(id).subscribe(data => { if(data){ alert(`Usuario ${data.usuario} activado`); this.goBack();} }, err => { console.log(err); });
    }
    
  }
  
  goBack() {
    this.location.back();
  }

  refresh(usuario: Usuario) {
    let i: number;
    let m: Medico[] = [];
    let e: Enfermera[] = [];
    let s: Supervisor[] = [];

    if(usuario.fk_rol == 3){
      i = this.adminSrv.medicos.findIndex(e => e.id == this.usuario.medico.id);
      m = this.adminSrv.medicos.splice(i, 1);
    }
    if(usuario.fk_rol == 5){
      i = this.adminSrv.enfermeras.findIndex(e => e.id == this.usuario.enfermera.id);
      e = this.adminSrv.enfermeras.splice(i, 1);
    }
    if(usuario.fk_rol == 6){
      i = this.adminSrv.supervisores.findIndex(e => e.id == (this.usuario.supervisor ? this.usuario.supervisor.id : 0));
      if(this.usuario.supervisor){
        s = this.adminSrv.supervisores.splice(i, 1);
      }
      
    }                   
     
  }

}
