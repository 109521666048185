import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { Diagnostico } from 'src/app/moddelo/diagnostico';
import { AuthService } from 'src/app/servicios/auth.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

@Component({
  selector: 'app-diagnosticos',
  templateUrl: './diagnosticos.component.html',
  styleUrls: ['./diagnosticos.component.css']
})
export class DiagnosticosComponent implements OnInit {
  public diagnosticos: Diagnostico[] = [];
  public pageEvent: PageEvent;

  get usuario(){
    return this.auth.getUsuario;
  }

  get offLink(){
    return this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }
  constructor(
    private auth: AuthService,
    public catalogosSrv: CatalogosService, 
    private location: Location) { }

  ngOnInit() {
    this.catalogosSrv
    .getDiagnosticos()
    .subscribe(data => {this.diagnosticos = data; }, err => { console.log(err); });
  }
  goBack(): void{
    this.location.back();
  }
  pagina(x: number, pageEvent: PageEvent): boolean{
    let sizePage: number = 10;
    let pageIndex: number = 0;
    
    if(pageEvent !== undefined){
      sizePage = pageEvent.pageSize;
      pageIndex = pageEvent.pageIndex;
    }

    return Math.ceil(( (x+1) /sizePage)) !== (pageIndex + 1);
  }

}
