import { Documento } from "./documento";
import { Enfermera } from "./enfermera";
import { Estado } from "./estado";
import { Hospital } from "./hospital";
import { Institucion } from "./institucion";
import { Medico } from "./medico";
import { Producto } from "./producto";
import { SeccionCuerpo } from "./seccion-cuerpo";
import { Accion } from "./accion";
import { SeguimientoPostoperatorio } from "./seguimiento-postoperatorio";
import { UsoProducto } from "./uso-producto";

export class CasoClinico {
        id: number;
        folio: string;
        serie: string;
        paciente: string;
        edad: number;
        sexo: string;        
        status: string;
        antecedente: string;
        fecha: Date;
        created_at: Date;
        updated_at: Date;
        fk_medico: number;
        fk_enfermera: number;
        fk_estado: number;
        fk_institucion: number;
        cveInstitucion: string;
        fk_hospital: number;        
        hospital: string;
        fk_diagnostico: number;
        diagnostico: string;        
        fk_antibiotico1: number;
        nomAntibiotico1: string;
        cantidad1: number;
        fk_antibiotico2: number;
        nomAntibiotico2: string;
        cantidad2: number;
        fk_antibiotico3: number;
        nomAntibiotico3: string;
        cantidad3: number;
        tratamiento: string;
        observacion: string;
        fechaAplicacionProducto: Date;
        activo: boolean;
        fk_seccion_cuerpo: number;
        nomCuerpo: string;
        e_puntos: number;
        status_canje: number;
        fk_redimir_enfermera: number;
        centromedico: Hospital;
        institucion: Institucion; 
        productos: Producto[];
        documentos: Documento[];
        seguimientos: SeguimientoPostoperatorio[];
        acciones: Accion[];
        usosproducto: UsoProducto[];
        seccioncuerpo: SeccionCuerpo;
        medico: Medico; 
        enfermera: Enfermera;
        estado: Estado;       
}
