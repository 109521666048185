import { Acumulado } from "./acumulado";
import { CasoClinico } from "./caso-clinico";
import { Enfermera } from "./enfermera";
import { Medico } from "./medico";
import { TipoProducto } from "./tipo-producto";
import { Venta } from "./venta";

export class Producto {
        id: number;
        noBatch: number;
        codigo: string;
        fk_tipoProducto: number; 
        nomProducto: string; 
        lote: string; 
        caducidad: Date; 
        created_at: Date;
        updated_at: Date;
        fk_casoClinico: number;
        fk_medico: number;
        fk_enfermera: number;
        m_puntos: number;
        e_puntos: number;
        fechaAplicacion: Date;
        fk_venta: number;
        status: number;
        tipo_mov: number;
        codigo_change: string;
        medico: Medico;
        casoclinico: CasoClinico;
        enfermera: Enfermera;
        acumulado: Acumulado;
        venta: Venta;
        tipo: TipoProducto;
}
