import { CasoClinico } from "./caso-clinico";
import { Hospital } from "./hospital";

export class Institucion {
        id: number;
        nomInstitucion: string;
        cvInstitucion: string;
        hospitales: Hospital[];
        casosclinicos: CasoClinico[];
}
