import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';

import descripcion from '../../../datos/status';

@Component({
  selector: 'app-enfermera-canje-detalle',
  templateUrl: './enfermera-canje-detalle.component.html',
  styleUrls: ['./enfermera-canje-detalle.component.css']
})
export class EnfermeraCanjeDetalleComponent implements OnInit {
  public id: string;
  public enfermera: Enfermera = null;

  public canje: RedimirEnfermera = null;
  
  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  constructor(private activatedRoute: ActivatedRoute, 
              private aut: AuthService,
              private enfermeraSrv: EnfermerasService,
              private location: Location) { 

              this.id = this.activatedRoute.snapshot.paramMap.get('id');

              this.enfermera = this.enfermeraSrv.enfermera;
               
              this.canje = this.enfermera.redimirenfermera.find(r => r.id == Number(this.id));
              }

  ngOnInit() {
  }
  getDescripcion(): string{
    return descripcion.status[this.canje.status];
  }
  goBack(){
    this.location.back();
  }

}
