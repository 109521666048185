import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AuthService } from 'src/app/servicios/auth.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { Medico } from 'src/app/moddelo/medico';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';
import descripcion from '../../../datos/status';

@Component({
  selector: 'app-medico-canje-detalle',
  templateUrl: './medico-canje-detalle.component.html',
  styleUrls: ['./medico-canje-detalle.component.css']
})
export class MedicoCanjeDetalleComponent implements OnInit {
  public id: string;
  public medico: Medico = null;

  public canje: RedimirMedico = null;
  
  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  constructor(  
                private activatedRoute: ActivatedRoute, 
                private aut: AuthService,
                private medicoSrv: MedicosService,
                private location: Location
              ) {

            this.id = this.activatedRoute.snapshot.paramMap.get('id');

            this.medico = this.medicoSrv.medico;
               
            this.canje = this.medico.redimirmedico.find(r => r.id == Number(this.id));
  }
  getDescripcion(): string{
    return descripcion.status[this.canje.status];
  }
  ngOnInit() {
  }
  goBack(){
    this.location.back();
  }

}
