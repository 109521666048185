import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Location } from '@angular/common';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { Usuario } from 'src/app/moddelo/usuario';
import { Medico } from 'src/app/moddelo/medico';
import { Especialidad } from 'src/app/moddelo/especialidad';
import { Residencia } from 'src/app/moddelo/residencia';
import { Puesto } from 'src/app/moddelo/puesto';
import { MatSnackBar } from '@angular/material';

import { Hospital } from 'src/app/moddelo/hospital';
import { Institucion } from 'src/app/moddelo/institucion';
import { MedicosService } from 'src/app/servicios/medicos.service';

@Component({
  selector: 'app-medico-update',
  templateUrl: './medico-update.component.html',
  styleUrls: ['./medico-update.component.css']
})
export class MedicoUpdateComponent implements OnInit {
  private usuario: Usuario = null;
  public hospitales: Hospital[] = [];
  public instituciones: Institucion[] = [];
  public filtrosInstituciones: Institucion[] = [];
  public filtrosHospitales: Hospital[]=[]; 
  public nombresHospitales: string[] = [];
  private especialidades: Especialidad[] = [];
  private residencias: Residencia[] = [];
  private puestos: Puesto[] = [];
  public usuarioMedicoFrm: FormGroup = new FormGroup({
                                          alias: new FormControl(''),
                                          nombre: new FormControl('', Validators.required),
                                          primerApellido: new FormControl('', Validators.required),
                                          segundoApellido: new FormControl(''),
                                          rfc: new FormControl(''),
                                          telefonoMovil: new FormControl(''),
                                          fk_especialidad: new FormControl(''),                                          
                                          nomEspecialidad: new FormControl(''),
                                          fk_institucion: new FormControl(''),
                                          fk_hospital: new FormControl(''),
                                          noConsultorio: new FormControl(''),
                                          telefonoConsultorio: new FormControl(''),
                                          hospitalConsultorioPrivado: new FormControl(''), 
                                          noConsultorioDireccionPrivado: new FormControl(''),
                                          telefonoConsultorioPrivado: new FormControl(''),
                                          fk_puesto: new FormControl(''),
                                          fk_residencia: new FormControl('')                                         
                                        }); 


  get aliasCtrl() { return this.usuarioMedicoFrm.get('alias'); }
  get nombreCtrl() { return this.usuarioMedicoFrm.get('nombre'); }
  get primerApellidoCtrl() { return this.usuarioMedicoFrm.get('primerApellido'); }
  get telefonoCtrl() { return this.usuarioMedicoFrm.get('telefonoMovil'); }
  get fk_puestoCtrl() { return this.usuarioMedicoFrm.get('fk_puesto'); }
  get fk_especialidadCtrl() { return this.usuarioMedicoFrm.get('fk_especialidad'); }
  get nomEspecialidadCtrl() { return this.usuarioMedicoFrm.get('nomEspecialidad'); }
  get fk_residenciaCtrl() { return this.usuarioMedicoFrm.get('fk_residencia'); }
  get fk_hospitalCtrl() { return this.usuarioMedicoFrm.get('fk_hospital'); }
  get fk_institucionCtrl() { return this.usuarioMedicoFrm.get('fk_institucion'); }
  get telefonoConsultorioCtrl() { return this.usuarioMedicoFrm.get('telefonoConsultorio'); }
  get noConsultorioCtrl() { return this.usuarioMedicoFrm.get('noConsultorio'); }
  get hospitalConsultorioPrivadoCtrl(){ return this.usuarioMedicoFrm.get('hospitalConsultorioPrivado')} 
  get noConsultorioDireccionPrivadoCtrl(){ return this.usuarioMedicoFrm.get('noConsultorioDireccionPrivado')}  
  get telefonoConsultorioPrivadoCtrl(){ return this.usuarioMedicoFrm.get('telefonoConsultorioPrivado')} 

  constructor(
    private location: Location, 
    private catalogosSrv: CatalogosService,
    private usuariosSrv: UsuariosService,
    private medicosSrv: MedicosService,
    private snackBar: MatSnackBar,
   ) { }

  ngOnInit() {
       this.medicosSrv.medico
     
      let { nombre, primerApellido, segundoApellido, alias, telefonoMovil } = this.medicosSrv.medico.usuario;
      let { rfc, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia, noConsultorio, fk_hospital, telefonoConsultorio, hospitalConsultorioPrivado, noConsultorioDireccionPrivado, telefonoConsultorioPrivado} = this.medicosSrv.medico;
      let fk_institucion = null;

      if(this.medicosSrv.medico.hospital){
        fk_institucion=this.medicosSrv.medico.hospital.fk_institucion;      
      }

      this.usuarioMedicoFrm.setValue({ alias, nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia, noConsultorio, fk_hospital, telefonoConsultorio, hospitalConsultorioPrivado, noConsultorioDireccionPrivado, telefonoConsultorioPrivado, fk_institucion });

      this.catalogosSrv.getHospitales().subscribe(data => {

        this.hospitales=data;
        
        if(this.medicosSrv.medico.hospital){
          this.filtrosHospitales = this.hospitales.filter(h => h.fk_institucion == this.medicosSrv.medico.hospital.fk_institucion);
        }
        
      }, error=> {console.log(error)});
      this.catalogosSrv.getEspecialidades().subscribe(data => {this.especialidades = data;}, err => { console.log(err); });
      this.catalogosSrv.getPuestos().subscribe(data => {this.puestos = data;}, err => { console.log(err) });
      this.catalogosSrv.getResidencias().subscribe(data => { this.residencias = data;}, err => { console.log(err) });
      this.catalogosSrv.getInstituciones().subscribe(data => {this.instituciones = data}, err=>{ console.log(err)});
      
      this.fk_puestoCtrl.valueChanges.subscribe(e => { this.fk_residenciaCtrl.setValue('');  });

      this.fk_especialidadCtrl.valueChanges.subscribe(e => { 
          let especialidad = this.especialidades.find(esp => esp.id == e);
          if(especialidad) {
            this.nomEspecialidadCtrl.setValue(especialidad.nomEspecialidad);
          }else{
            this.nomEspecialidadCtrl.setValue('');
          }
      }, err => { console.log(err) });
  }

  onSubmit() {

    let { alias, nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia, noConsultorio, fk_hospital, telefonoConsultorio, hospitalConsultorioPrivado, noConsultorioDireccionPrivado, telefonoConsultorioPrivado } = this.usuarioMedicoFrm.value;

    let usuarioU: Usuario = <Usuario>{ alias, nombre, primerApellido, segundoApellido, telefonoMovil }; 
    let medicoU: Medico = <Medico>{ nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia, noConsultorio, fk_hospital, telefonoConsultorio, hospitalConsultorioPrivado, noConsultorioDireccionPrivado, telefonoConsultorioPrivado  };
    let id =  this.medicosSrv.medico.fk_usuario;
    
    this.usuariosSrv
    .setUsuarioMedicoUpdate( id, usuarioU, medicoU)
    .subscribe(data => { 
      

      this.snackBar.open(`Registrado actualizado`, "Medico",{
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });     
      
      this.usuario.alias = usuarioU.alias;
      this.usuario.nombre = usuarioU.nombre;
      this.usuario.primerApellido = usuarioU.primerApellido;
      this.usuario.segundoApellido = usuarioU.segundoApellido;
      this.usuario.telefonoMovil = usuarioU.telefonoMovil;
      
      this.usuario.medico.nombre = medicoU.nombre;
      this.usuario.medico.primerApellido = medicoU.primerApellido;
      this.usuario.medico.segundoApellido = medicoU.segundoApellido;
      
      this.usuario.medico.rfc = medicoU.rfc;
      this.usuario.medico.telefonoMovil = medicoU.telefonoMovil;
      this.usuario.medico.fk_especialidad = medicoU.fk_especialidad;
      this.usuario.medico.nomEspecialidad = medicoU.nomEspecialidad; 
      this.usuario.medico.fk_puesto = medicoU.fk_puesto; 
      this.usuario.medico.fk_residencia = medicoU.fk_residencia;
      this.usuario.medico.fk_hospital = medicoU.fk_hospital;
      this.usuario.medico.noConsultorio = medicoU.noConsultorio;
      this.usuario.medico.noConsultorioDireccionPrivado = medicoU.noConsultorioDireccionPrivado;
      this.usuario.medico.hospitalConsultorioPrivado = medicoU.hospitalConsultorioPrivado;
      this.usuario.medico.telefonoConsultorio = medicoU.telefonoConsultorio;
      this.usuario.medico.telefonoConsultorioPrivado = medicoU.telefonoConsultorioPrivado;

      let medico: Medico = this.medicosSrv.medico;
      medico.nombre = medicoU.nombre;
      medico.primerApellido = medicoU.primerApellido;
      medico.segundoApellido = medicoU.segundoApellido;
      
      medico.rfc = medicoU.rfc;
      medico.telefonoMovil = medicoU.telefonoMovil;
      medico.fk_especialidad = medicoU.fk_especialidad;
      medico.nomEspecialidad = medicoU.nomEspecialidad; 
      medico.fk_puesto = medicoU.fk_puesto; 
      medico.fk_residencia = medicoU.fk_residencia;
      medico.fk_hospital = medicoU.fk_hospital;
      medico.noConsultorio = medicoU.noConsultorio;
      medico.noConsultorioDireccionPrivado = medicoU.noConsultorioDireccionPrivado;
      medico.hospitalConsultorioPrivado = medicoU.hospitalConsultorioPrivado;
      medico.telefonoConsultorio = medicoU.telefonoConsultorio;
      medico.telefonoConsultorioPrivado = medicoU.telefonoConsultorioPrivado;
    

      medico.usuario.alias = usuarioU.alias;
      medico.usuario.nombre = usuarioU.nombre;
      medico.usuario.primerApellido = usuarioU.primerApellido;
      medico.usuario.segundoApellido = usuarioU.segundoApellido;
      medico.usuario.telefonoMovil = usuarioU.telefonoMovil;  
       
     
    }, 
    err => { console.log(err); });
  
  }
  onChangeSelectInst(id_institucion: string) {  
   
    this.nombresHospitales = [];
        
    this.nombresHospitales = [];
  
    this.fk_hospitalCtrl.setValue('');  
   
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0)); 
     
  }
  goBack() {
    this.location.back();
  }

}
