import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { Antibiotico } from 'src/app/moddelo/antibiotico';
import { AuthService } from 'src/app/servicios/auth.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

@Component({
  selector: 'app-antibioticos',
  templateUrl: './antibioticos.component.html',
  styleUrls: ['./antibioticos.component.css']
})
export class AntibioticosComponent implements OnInit {
  public antibioticos: Antibiotico[] = [];
  public pageEvent: PageEvent;

  get usuario(){
    return this.auth.getUsuario;
  }

  get offLink(){
    return this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }

  constructor(
    private auth: AuthService,
    private location: Location, 
    private catalogosSrv: CatalogosService) { }

  ngOnInit() {
    this.catalogosSrv
    .getAntibioticos()
    .subscribe(data => { this.antibioticos = data; }, err => { console.log(err); });
  }

  pagina(x: number, pageEvent: PageEvent): boolean{
    
    let sizePage: number = 10;
    let pageIndex: number = 0;
    
    if(pageEvent !== undefined){
      sizePage = pageEvent.pageSize;
      pageIndex = pageEvent.pageIndex;
    }

    return Math.ceil(( (x+1) /sizePage)) !== (pageIndex + 1);
  }

  goBack(){
   this.location.back();
  }
}
