import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { Especialidad } from 'src/app/moddelo/especialidad';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-especialidad',
  templateUrl: './especialidad.component.html',
  styleUrls: ['./especialidad.component.css']
})
export class EspecialidadComponent implements OnInit {
  public id: number = 0;
  public especialidad: Especialidad = new Especialidad();
  
  private especialidadFrm: FormGroup = new FormGroup({
          nomEspecialidad: new FormControl('', Validators.required)
  });
  get nomEspecialidadCtrl(){
    return this.especialidadFrm.get('nomEspecialidad');
  }
  constructor(
              private activated: ActivatedRoute,
              private location: Location, 
              private catalogosSrv: CatalogosService, 
              private snackBar: MatSnackBar
              ) { }

  ngOnInit() {  
      this.id = Number(this.activated.snapshot.paramMap.get('id')); 
      
      if(this.id > 0){
        this.catalogosSrv
        .getEspecialidad(this.id)
        .subscribe(data => {        
          this.especialidad = data;
          this.nomEspecialidadCtrl.setValue(this.especialidad.nomEspecialidad);
        }, err => { console.log(err); });
      }
    
  }
  onSubmit(){
    if(this.id>0){
      this.actualizar();
    }else{
      this.guardar();
    }
  }

  actualizar(): void{
    this.especialidad.nomEspecialidad = this.nomEspecialidadCtrl.value;
    this.especialidad.id = this.id;
    this.catalogosSrv
    .setEspecialidadUpdate(this.id, this.especialidad)
    .subscribe(data => { 
      this.snackBar.open('Especialidad actualizado', 'especialidad', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
    }, err => { console.log(err) });
}
guardar(): void{
    this.especialidad.nomEspecialidad = this.nomEspecialidadCtrl.value;
    this.especialidad.id = this.id;

    this.catalogosSrv
    .setEspecialidad(this.especialidad)
    .subscribe(data => {
      this.id = data.id;
      this.especialidad.nomEspecialidad = data.nomEspecialidad;
      this.especialidad.id = data.id;

      this.snackBar.open('Especialidad registrado', 'especialidad', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
    }, err => { console.log(err)});
}

  goBack(): void{
    this.location.back();
  }

}
