import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/moddelo/usuario';

import { AuthService } from 'src/app/servicios/auth.service';


const ADMINISTRADOR = 2;
const MEDICO = 3;
const ENFERMERA = 5;
const SUPERVISOR = 6;

@Component({
  selector: 'app-usuario-login-signup',
  templateUrl: './usuario-login-signup.component.html',
  styleUrls: ['./usuario-login-signup.component.css']
})

export class UsuarioLoginSignupComponent implements OnInit {

  public login: FormGroup = new FormGroup({
                                  usuario: new FormControl(''),
                                  password: new FormControl('')
                                });

  constructor(private aut: AuthService, private router: Router) { }

  ngOnInit() { 
   
  }
  
  onSubmit(){
   
    this.aut
    .getAutorizacion( this.login.value.usuario, this.login.value.password)
    .subscribe( data => {                         

                       if(data.code == 1)
                       {
                        
                        
                          if(data.data.activo==1) {
                                this.aut.setLoggedIn(true);
                                this.aut.setUsuario(data.data);
                              
                                if(data.data.fk_rol == MEDICO) {
                                
                                    if(data.data.medico) {
                                        this.router.navigate(['/medico']);                                     
                                      } else {
                                            this.router.navigate(['/frmMedico']);                                      
                                    }

                                }else if(data.data.fk_rol == ENFERMERA) {
                                        this.router.navigate(['/enfermeras']);
                                  }else if(data.data.fk_rol == ADMINISTRADOR) {
                                          this.router.navigate(['/administrador']);
                                    }else if(data.data.fk_rol == SUPERVISOR) {
                                           if(data.data.fk_nivel == 3 ) {
                                              this.router.navigate(['/administrador']);
                                           } else if(data.data.fk_nivel == 4 || data.data.fk_nivel == 5) {
                                              this.router.navigate(['/supervisor']);
                                           }
                                           
                                        
                                }

                          } else {
                            alert(" Desactivado ");
                          }

                       } else if(data.code == 0){
                          alert(data.message)
                       }

                    }, 
                    er => {
                              alert(er.error.message);
                        });

  }
}
