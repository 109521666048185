import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-administrador-metricas',
  templateUrl: './administrador-metricas.component.html',
  styleUrls: ['./administrador-metricas.component.css']
})
export class AdministradorMetricasComponent implements OnInit {

  constructor(private location: Location, ) { }

  ngOnInit() {
  }
  goBack(){
    this.location.back();
  }
}
