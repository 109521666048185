import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators'; 

import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';

import { Medico } from 'src/app/moddelo/medico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { MedicosService } from 'src/app/servicios/medicos.service';

interface AddEnfermera{
    code: number;
    message: string;
    data: Enfermera;
  }
@Component({
    selector: 'dialogo-medico-enfermera-add',
    templateUrl: 'dialogo-medico-enfermera-add.html'
})
export class DialogoMedicoEnfermeraAdd {
    public enfermeras: Enfermera[] = [];
    
    public respuesta: AddEnfermera;
    private filteredEnfermeras: Observable<Enfermera[]>;
    public enfermeraFrm: FormGroup = new FormGroup({    
                                           enfermera: new FormControl('')   
                                        });
    get usuario(){
      return this.auth.getUsuario;
    }
    get offBoton(){
      return this.enfermeraFrm.valid && (this.usuario.fk_nivel==0 || this.usuario.fk_nivel==3);  
    } 
    constructor(
        public dialogo: MatDialogRef<DialogoMedicoEnfermeraAdd>,
        @Inject(MAT_DIALOG_DATA) public data: Medico,
        private auth: AuthService, 
        private adminSrv: AdministradorService, 
        private enfermerasSrv: EnfermerasService,
        private medicosSrv: MedicosService,
        private snackBar: MatSnackBar
        ){
            this.filteredEnfermeras = this.enfermeraFrm.controls["enfermera"].valueChanges.pipe(startWith(''), map(value => this._filterEnfermera(value)));
   
            this.enfermerasSrv.getEnfermeras().subscribe(data => { this.enfermeras = data; }, err => { console.log(err); }); 
        }
        onSubmit(event: any){
            let enfermera: Enfermera = <Enfermera> this.enfermeraFrm.value.enfermera;  
            
            this.medicosSrv
             .setAsociarEnfemera(this.data.id, enfermera)
             .subscribe(data => {
                                  this.respuesta = <AddEnfermera> data;
                                  if(this.respuesta.code == 1) {  

                                     this.data.enfermeras.push(enfermera);
                                  
                                     this.snackBar
                                     .open(
                                       this.respuesta.message, 
                                       'Enfermera', 
                                       {
                                         duration: 3000,
                                         verticalPosition:'bottom',
                                         horizontalPosition:'center'
                                       });
                                       this.onClose();
                                     
                                  }
                                   else {
                                     this.snackBar
                                     .open(
                                       this.respuesta.message, 
                                       'Enfermera', 
                                       {
                                         duration: 3000,
                                         verticalPosition:'bottom',
                                         horizontalPosition:'center'
                                       });
                                     console.log(this.respuesta.message);
                                   }
             }, err => { 
                           if(err.status == 500) {

                            let msj = '';
                        
                            if(err.error.code=="23000"){
                                msj='La enfermera ya esta asociada';
                            } else {
                                msj= err.error.message;
                            }
    
                            this.snackBar.open(msj , 'Enfermera', 
                            {
                                duration: 3000,
                                verticalPosition:'bottom',
                                horizontalPosition:'center'
                            });                           

                           }
                           console.log(err); 
                       } );  
           }    
        _normalizeValue(nombre: string): string 
        {
          let cadena = String(nombre).toLowerCase();
          return cadena ? cadena.replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u') : '';
        }
        _filterEnfermera(nombre: string): Enfermera[] 
        {
          const valorFiltro = this._normalizeValue(nombre);
          return this.enfermeras.filter(enfermera => this._normalizeValue(enfermera.nombre).includes(valorFiltro) || this._normalizeValue(enfermera.primerApellido).includes(valorFiltro) || this._normalizeValue(enfermera.segundoApellido).includes(valorFiltro));
        }
        displayFnEnfermera(enfermera: Enfermera): string 
        {
          return  enfermera  ? enfermera.nombre.concat(' ').concat(enfermera.primerApellido).concat(' ').concat(enfermera.segundoApellido) : '';
        }
        onClose(){
            this.dialogo.close();
        }    

}