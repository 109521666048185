import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Producto } from '../moddelo/producto';
import { Acumulado } from '../moddelo/acumulado';
import { Batch } from '../moddelo/batch';
import { Venta } from '../moddelo/venta';
import { CasoClinico } from '../moddelo/caso-clinico';
import { Data } from '@angular/router';
import { HospitalProductos } from '../moddelo/hospital-productos';
import { ProductosMedicoHospital } from '../moddelo/productos-medico-hospital';
import { ProductosTecnicoHospital } from '../moddelo/productos-tecnico-hospital';
import { ProductosServicioHospital } from '../moddelo/productos-servicio-hospital';
import { Exportpdf } from '../moddelo/exportpdf';
import { ProductosCategorizacion } from '../moddelo/productos-categorizacion';
import { CategoriaHopital } from '../moddelo/categoria-hospital';
import { CategoriaInstitucion } from '../moddelo/CategoriaInstitucion';
import { CategoriaParteCuerpo } from '../moddelo/categoria-parte-cuerpo';
import { PorProducto } from '../moddelo/por-producto';
import { DetallePorProducto } from '../moddelo/detalle-por-producto';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'   
  }),
};
@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private http: HttpClient) { }

  getProductos(){
    return this.http.get<Producto[]>('/apibioc/public/api/productos', httpOptions);
  }
  
  setProductoDelete(id: string) {
    return this.http.delete(`/apibioc/public/api/producto/${id}`, httpOptions);
  }

  setProductosCaso(productos: Producto[]) {
    return this.http.post<CasoClinico>('/apibioc/public/api/productos/caso', productos, httpOptions);
  }
  
  getProductosAsigandos() {
    return this.http.get<Acumulado[]>('/apibioc/public/api/productos/asignados', httpOptions);
  }
  
  setProductoEliminar(id: string){
    return this.http.delete(`/apibioc/public/api/producto/eliminar/${id}`, httpOptions);
  }

  setProductoCancelar(id: string, idm: string){
    return this.http.delete(`/apibioc/public/api/producto/cancelar/${id}/${idm}`, httpOptions);
  }

  setProductoCancelarCambio(id1: string, id2: string){
    return this.http.delete(`/apibioc/public/api/producto/cancelar/cambio/${id1}/${id2}`, httpOptions);
  }
  
  setSubirArchivo(formData: FormData) {
    return this.http.post('/apibioc/public/api/productos/uplote', formData, { reportProgress: true, observe: 'events' });
  }


  /* BATCH */
  setBatch(batch: Batch) {
    return this.http.post<Batch>('/apibioc/public/api/batch', batch, httpOptions);
  }

  getBatchs() {
    return this.http.get<Batch[]>('/apibioc/public/api/batchs', httpOptions);
  }

  getBatchAsignados() {
    return this.http.get<Batch[]>('/apibioc/public/api/batch/in/productos', httpOptions);
  }
  getBatchSinAsignados() {
    return this.http.get<Batch[]>('/apibioc/public/api/batch/notin/productos', httpOptions);
  }
  getBatchToBatch(ido: number, idn: number){
    return this.http.get(`/apibioc/public/api/batch/to/batch/${ido}/${idn}`);
  }

  /* VENTAS */
  getVentas() {
    return this.http.get<Venta[]>('/apibioc/public/api/ventas', httpOptions);
  }
  getVenta(id: string){
    return this.http.get<Venta>(`/apibioc/public/api/venta/${id}`, httpOptions);
  }
  setVentaDelete(id: string) {
    return this.http.delete<Venta>(`/apibioc/public/api/venta/${id}`, httpOptions);
  }
  setVentaCancelar(id: string) {
    return this.http.delete<Venta>(`/apibioc/public/api/venta/cancelar/${id}`, httpOptions);
  }
  getHospitalProductos(fechaInicial: Date, fechaFinal: Date){
    return this.http.post<HospitalProductos[]>('/apibioc/public/api/productosxhospital', {fechaInicial, fechaFinal}, httpOptions);
  }
  getProductosMedicoHospital(fechaInicio: Date, fechaFinal: Date){
    return this.http.post<ProductosMedicoHospital[]>('/apibioc/public/api/productosxhospitalymedico', {fechaInicio, fechaFinal}, httpOptions);
  }
  getProductosTecnicoHospital(fechaInicio: Date, fechaFinal: Date){
    return this.http.post<ProductosTecnicoHospital[]>('/apibioc/public/api/productosxhospitalytecnico', {fechaInicio, fechaFinal}, httpOptions);
  }
  getProductosServicioHospital(fechaInicio: Date, fechaFinal: Date){
    return this.http.post<ProductosServicioHospital[]>('/apibioc/public/api/productosxhospitalyservicio', {fechaInicio, fechaFinal}, httpOptions);
  }
  getPorProducto(fechaInicio: string, fechaFinal: string){
    return this.http.post<PorProducto[]>('/apibioc/public/api/porproducto', {fechaInicio, fechaFinal}, httpOptions);
  }
  getDetallesPorProducto(fechaInicio: string, fechaFinal: string){
    return this.http.post<DetallePorProducto[]>('/apibioc/public/api/detallesporproducto', {fechaInicio, fechaFinal}, httpOptions);
  }

  getDestallesPDF(fechaInicio: string, fechaFinal: string){
    return this.http.post<Exportpdf>('/apibioc/public/api/consultaproductosxinstitucion', {fechaInicio, fechaFinal});
  }  
  getProductosCategorizados(){
    return this.http.get<ProductosCategorizacion[]>('/apibioc/public/api/productoscategorizados', httpOptions);
  }
  getProductosCategorizadosHospital(){
    return this.http.get<CategoriaHopital[]>('/apibioc/public/api/catagoriaxhospital', httpOptions);
  }
  getProductosCategorizadosInstucion(){
    return this.http.get<CategoriaInstitucion[]>('/apibioc/public/api/catagoriaxinstitucion', httpOptions);
  }
  getProductosCategorizadosParteCuerpo(){
    return this.http.get<CategoriaParteCuerpo[]>('/apibioc/public/api/catagoriaxpartecuerpo', httpOptions);
  }
 
}
