import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../material.module';
import { DialogoMedicoFicha } from './dialogo-medico-ficha/dialogo-medico-ficha';
import { DialogoEnfermeraFicha } from './dialogo-enfermera-ficha/dialogo-enfermera-ficha';
import { DialogoCambiarPassw } from './dialogo-cambiar-passw/dialogo-cambiar-passw';
import { DialogoCambiarPasswUsuario } from './dialogo-cambiar-passw-usuario/dialogo-cambiar-passw-usuario';
import { DialogoAsignarEnfermeraCasoclinico } from './dialogo-asignar-enfermera-casoclinico/dialogo-asignar-enfermera-casoclinico';
import { DialogoAsignarMedicoProductoPuntos } from './dialogo-asignar-medico-producto-puntos/dialogo-asignar-medico-producto-puntos';
import { DialogoMedicoEnfermeraAdd } from './dialogo-medico-enfermera-add/dialogo-medico-enfermera-add';
import { DialogoMedicoEnfermeraRemove } from './dialogo-medico-enfermera-remove/dialgo-medico-enfermera-remove';
import { DialogoMedicoPuntosDefault } from './dialogo-medico-puntos-default/dialogo-medico-puntos-default';
import { DialogoEnfermeraPuntosDefault } from './dialogo-enfermera-puntos-default/dialogo-enfermera-puntos-default';
import { DialogoAsignarEnfermeraProductoPuntos } from './dialogo-asignar-enfermera-producto-puntos/dialogo-asignar-enfermera-producto-puntos';
import { DialogoEnfermeraMedicoAdd } from './dialogo-enfermera-medico-add/dialogo-enfermera-medico-add';
import { DialogoEnfermeraMedicoRemove } from './dialogo-enfermera-medico-remove/dialogo-enfermera-medico-remove';
import { DialogoSupervisorEnfermeraAdd } from './dialogo-supervisor-enfermera-add/dialogo-supervisor-enfermera-add';
import { DialogoSupervisorEnfermeraRemove } from './dialogo-supervisor-enfermera-remove/dialogo-supervisor-enfermera-remove';
import { DialogoEnfermeraMedicosAdd } from './dialogo-enfermera-medicos-add/dialogo-enfermera-medicos-add';
import { DialogoSupervisorEnfermerasAdd } from './dialogo-supervisor-enfermeras-add/dialogo-supervisor-enfermeras-add';
import { DialogoSupervisorEnfermerasRemove } from './dialogo-supervisor-enfermeras-remove/dialogo-supervisor-enfermeras-remove';
import { DialogoAsignarEnfermeraCasoclinicoPuntos } from './dialogo-asignar-enfermera-casoclinico-puntos/dialogo-asignar-enfermera-casosclinico-puntos';
import { DialogoCambiarUsuarioCorreo } from './dialogo-cambiar-usuario-correo/dialogo-cambiar-usuario-correo';

@NgModule({
  declarations: [
    DialogoMedicoFicha, 
    DialogoEnfermeraFicha, 
    DialogoCambiarPassw, 
    DialogoCambiarPasswUsuario,
    DialogoCambiarUsuarioCorreo, 
    DialogoAsignarEnfermeraCasoclinico, 
    DialogoAsignarMedicoProductoPuntos, 
    DialogoMedicoEnfermeraAdd, 
    DialogoMedicoEnfermeraRemove, 
    DialogoMedicoPuntosDefault, 
    DialogoEnfermeraPuntosDefault,
    DialogoAsignarEnfermeraProductoPuntos,
    DialogoAsignarEnfermeraCasoclinicoPuntos,
    DialogoEnfermeraMedicoAdd,
    DialogoEnfermeraMedicosAdd,
    DialogoEnfermeraMedicoRemove,
    DialogoSupervisorEnfermeraAdd,
    DialogoSupervisorEnfermerasAdd,
    DialogoSupervisorEnfermerasRemove,
    DialogoSupervisorEnfermeraRemove],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule    
  ],
  entryComponents:[
    DialogoMedicoFicha,
    DialogoEnfermeraFicha,
    DialogoCambiarPassw,
    DialogoCambiarPasswUsuario,
    DialogoCambiarUsuarioCorreo,
    DialogoAsignarEnfermeraCasoclinico,
    DialogoAsignarMedicoProductoPuntos,
    DialogoMedicoEnfermeraAdd,
    DialogoMedicoEnfermeraRemove,
    DialogoMedicoPuntosDefault,
    DialogoEnfermeraPuntosDefault,
    DialogoAsignarEnfermeraProductoPuntos,
    DialogoAsignarEnfermeraCasoclinicoPuntos,
    DialogoEnfermeraMedicoAdd,
    DialogoEnfermeraMedicosAdd,
    DialogoEnfermeraMedicoRemove,
    DialogoSupervisorEnfermeraAdd,
    DialogoSupervisorEnfermerasAdd,
    DialogoSupervisorEnfermerasRemove,
    DialogoSupervisorEnfermeraRemove
  ]
})
export class DialogosModule { }
