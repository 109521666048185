import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Institucion } from 'src/app/moddelo/institucion';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

@Component({
  selector: 'app-institucion-otro',
  templateUrl: './institucion-otro.component.html',
  styleUrls: ['./institucion-otro.component.css']
})
export class InstitucionOtroComponent implements OnInit {
  public id: number = 0;
  public casoClinico: CasoClinico = new CasoClinico();
  public institucion: Institucion = new Institucion();

  public institucionFrm: FormGroup = new FormGroup({
    nomInstitucion: new FormControl('', Validators.required),
    cvInstitucion: new FormControl('', Validators.required) 
  });
  get nomInstitucionCtrl(){ return this.institucionFrm.get('nomInstitucion');}
  get cvInstitucionCtrl(){ return this.institucionFrm.get('cvInstitucion');}

  constructor(
              private location: Location, 
              private activated: ActivatedRoute, 
              private catalogosSrv: CatalogosService,
              private casosClinicosSrv: CasosClinicosService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.id = Number(this.activated.snapshot.paramMap.get('id'));
    this.casosClinicosSrv
    .getCasoClinico(this.id)
    .subscribe(data => {
        let cvInstitucion = data.cveInstitucion;
        let nomInstitucion = "";        
        this.casoClinico = data;        
        this.institucionFrm.setValue({nomInstitucion, cvInstitucion});
    }, err => { console.log(err)});
  }
  onSubmit(){
    this.guardar();
  }
  guardar(): void{ 
     this.institucion.nomInstitucion = this.nomInstitucionCtrl.value;
     this.institucion.cvInstitucion =  this.cvInstitucionCtrl.value;
     this.catalogosSrv
     .setInstitucionOtro(this.institucion, this.casoClinico)
     .subscribe(data => {
       this.institucion.id = data.id;
       this.snackBar.open('Institucion registrada', 'Institucion', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
        this.goBack();
     }, err => { console.log(err); });
  }

  goBack(): void{
    this.location.back();
  }

}
