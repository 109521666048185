import { Component, OnInit } from '@angular/core';
import { AuthService} from '../../../servicios/auth.service';
import { AdministradorService } from '../../../servicios/administrador.service';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-administrador-main',
  templateUrl: './administrador-main.component.html',
  styleUrls: ['./administrador-main.component.css']
})
export class AdministradorMainComponent implements OnInit {
  public loadingMedicos: boolean = true;
  public loadingEnfermeras: boolean = true;
  public loadingSupervisores: boolean = true;
  public loadingProductos: boolean = true;
  constructor(
              private aut: AuthService, 
              private adminSrv: AdministradorService) { }
  
  get usuario(){
    return this.aut.getUsuario;
  }            
  get pgHome(){
    return this.adminSrv.card == 0;
  }
  get CardProductos(){
    return this.adminSrv.card == 1;
  }
  get CardMedicos(){
    return this.adminSrv.card == 2;
  }
  get CardEnfermeras(){
    return this.adminSrv.card == 3;
  }
  get CardSupervisores(){
    return this.adminSrv.card == 4;
  }


  get TotalProductos(){
    return this.adminSrv.productos.filter(p => p.status && p.fk_casoClinico != null).length;
  }
  get TotalMedicos(){
    return this.adminSrv.medicos.length;
  }
  get TotalEnfermeras(){
    return this.adminSrv.enfermeras.length;
  }
  get TotalSuperviores(){
    return this.adminSrv.supervisores.length;
  }

 


  ngOnInit() {

    this.adminSrv
    .getProductosFecha()
    .subscribe( data => { this.adminSrv.fechaActual = data; }, err => { console.log(err); } );  
    
    this.adminSrv
    .getProductos()
    .pipe(finalize( ()=> this.loadingProductos = false))
    .subscribe(data => {this.adminSrv.productos = data;}, err => {console.log(err);});

    this.adminSrv
    .getMedicos()
    .pipe(finalize( ()=> this.loadingMedicos = false))
    .subscribe(data => { this.adminSrv.medicos = data;}, err => { console.log(err);});

    this.adminSrv
    .getEnfermeras()
    .pipe(finalize( ()=> this.loadingEnfermeras = false))
    .subscribe(data => { this.adminSrv.enfermeras = data;}, err => {console.log(err);});

    this.adminSrv
    .getSupervisores()
    .pipe(finalize( ()=> this.loadingSupervisores = false))
    .subscribe(data => { this.adminSrv.supervisores = data;}, err => {console.log(err);});
  }

  NotificacionesMedicoSolicitud(): number{
    let notificaciones: number = 0;
    this.adminSrv.medicos.forEach(m => {
      notificaciones += m.redimirmedico.filter( m => m.status == 1 ).length;
    });
    return notificaciones;
  }

  NotificacionesMedicoAsociaciones(): number{
    let notificaciones: number = 0;
    this.adminSrv.medicos.forEach(m => {
      notificaciones += m.casosclinicos.filter( m => m.fk_enfermera == null ).length;
    });
    return notificaciones;
  }
  
  NotificacionesEnfermeraSolicitud(): number{
    let notificaciones: number = 0;
    this.adminSrv.enfermeras.forEach(m => {
      notificaciones += m.redimirenfermera.filter( m => m.status == 1 ).length;
    });
    return notificaciones;
  }
  
}
