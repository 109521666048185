import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Sort, PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { AdministradorService } from 'src/app/servicios/administrador.service';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';
import { Supervisor } from '../../../moddelo/supervisor';
import { DialogoAsignarEnfermeraCasoclinico } from '../../dialogos/dialogo-asignar-enfermera-casoclinico/dialogo-asignar-enfermera-casoclinico';

interface ItbCasosClinicos{
  No: string;
  Fecha: string;
	Folio: string;
	Paciente: string;
	Diagnostico: string;
	Hospital: string;
  Institucion: string;
	Estado: string;
  Medico: string;
  Enfermera: string;
  Productos: number;
}
@Component({
  selector: 'app-supervisor-casos-clinicos',
  templateUrl: './supervisor-casos-clinicos.component.html',
  styleUrls: ['./supervisor-casos-clinicos.component.css']
})
export class SupervisorCasosClinicosComponent implements OnInit {

  private tbCasosClinicos: CasoClinico[] = [];
  public filtroFrm: FormGroup;
  

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [10, 20, 50, 100];
  @Input("supervisor")supervisor: Supervisor;
  @Input("CasosClinicos")casosclinicos: CasoClinico[];
  @Output() updateCasoClinico: EventEmitter<CasoClinico> = new EventEmitter();

  get url(): string {    
    return window.location.origin.replace(':4200', '');
  }

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.filtroFrm.get('folio');
  }

  get pacienteCtrl(){
    return this.filtroFrm.get('paciente');
  }

  get hospitalCtrl(){
    return this.filtroFrm.get('hospital');
  }

  get institucionCtrl(){
    return this.filtroFrm.get('institucion');
  }

  get enfermeraCtrl() {
    return this.filtroFrm.get('enfermera');
  }

  get medicoCtrl() {
    return this.filtroFrm.get('medico');
  }
  constructor(
    private adminSrv: AdministradorService,
    private dialogo: MatDialog,  
    private formBuilder: FormBuilder, 
    private matSnakBar: MatSnackBar) { 

    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial: [''],
                                            fechaFinal: [''],
                                            folio: [''],
                                            paciente: [''],
                                            hospital: [''],
                                            enfermera: [''],
                                            medico: [''], 
                                            institucion: ['']
                                          });
                                        
  }

  ngOnInit() {
    this.tbCasosClinicos = (this.casosclinicos ? this.casosclinicos.sort( (a, b) => { let a_fecha = new Date(a.fecha); let b_fecha = new Date(b.fecha); return b_fecha.getTime() - a_fecha.getTime();} ) : []);
  }
  onSubmit() {
    this.tbCasosClinicos =  this.casosclinicos.filter( cc => 
                                                                this.filterPaciente(cc.paciente) && 
                                                                this.filterHospital(cc.hospital) && 
                                                                this.filterInstitucion(cc.cveInstitucion) && 
                                                                this.filterSerie(cc.serie) && 
                                                                this.filterEnfermera(cc.fk_enfermera, cc.enfermera) && 
                                                                this.filterFecha(cc.fecha.toString())
                                                          );

    this.page_number=1;  
  }
  setUpdateCasoClinico(casoclinico: CasoClinico): void {
    this.updateCasoClinico.emit(casoclinico);
  }
  
  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  }
 
  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(nombre){
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }

  filterEnfermera(fk_enfermera: number, enfermera: Enfermera): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: String = "";

    if(this.enfermeraCtrl.value != ""){
        nom = this._normalizeValue(this.enfermeraCtrl.value);
        if(fk_enfermera){
          nomEnfermera = enfermera ? (enfermera.nombre + ( enfermera.primerApellido ? ' ' + enfermera.primerApellido : '' ) + (enfermera.segundoApellido ? ' ' + enfermera.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );
        }
    }
    
    return condicion;
  }
    
  filterInstitucion(cveInstitucion: string): boolean{
    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let cve: string;

    if( this.institucionCtrl.value != 0 ){
        i = this.institucionCtrl.value;
        institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
        cve = cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(cve){
        condicion = cve.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  
  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
        condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }

  filterSerie(serie: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
        condicion = !( serie.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }
  sortCasosClinicos(sort: Sort): void {
    const datos: CasoClinico[] = this.tbCasosClinicos.slice();
    if(!sort.active || sort.direction === ''){
      this.tbCasosClinicos = datos;
      return;
    }
    this.tbCasosClinicos =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fecha':
          let a1: number = new Date(a.fecha).getTime();
          let b1: number = new Date(b.fecha).getTime();
          return this.compare(a1, b1, isAsc);
        case 'folio':
          return this.compare(a.folio, b.folio, isAsc);
        case 'serie':
          return this.compare(a.serie, b.serie, isAsc);
        case 'paciente':
          return this.compare(a.paciente, b.paciente, isAsc);
        case 'hospital':
          return this.compare(a.hospital, b.hospital, isAsc);
        case 'cveInstitucion':
          return this.compare(a.cveInstitucion, b.cveInstitucion, isAsc);
        case 'enfermera':
          let enfermeraA: string = a.fk_enfermera ? a.enfermera.nombre + ' ' +( a.enfermera.primerApellido ? a.enfermera.primerApellido: '') : '';
          let enfermeraB: string = b.fk_enfermera ? b.enfermera.nombre + ' ' +( b.enfermera.primerApellido ? b.enfermera.primerApellido: '') : '';
         return this.compare(enfermeraA, enfermeraB, isAsc);
        case 'productos':
          let productosA: number = a.productos ? a.productos.length : 0;
          let productosB: number = b.productos ? b.productos.length : 0;
          return this.compare(productosA, productosB, isAsc);
        default:
          return 0;
      }
    });
  }

  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  generateData(): any[] {
    let result: ItbCasosClinicos[] = [];
    let i: number = 0;

    this.tbCasosClinicos.forEach((cc, i) => {
        
        result.push( {
                      No: (i+1).toString(),   
                      Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                      Folio: cc.folio,
                      Paciente: cc.paciente, 
                      Diagnostico: cc.diagnostico,
                      Hospital: cc.hospital,
                      Institucion: cc.cveInstitucion,
                      Estado: cc.fk_estado ? cc.estado.nomEstado : '',
                      Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido : '',                       
                      Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido : '',
                      Productos: cc.productos ? cc.productos.length : 0 
                    });
    });

  return result;
  }
  exportProductividad(): void {

    if(this.fechaFinalCtrl.value && this.fechaInicialCtrl.value){
     
      let f1 = moment(this.fechaInicialCtrl.value).format("YYYY-MM-DD");
      let f2 = moment(this.fechaFinalCtrl.value).format("YYYY-MM-DD");

      if(moment(this.fechaFinalCtrl.value) >= moment(this.fechaInicialCtrl.value)){
        
        const datos: any =  {fecha1: f1 , fecha2: f2};   
        this.adminSrv
       .getProductividadSupervisor(this.supervisor.id.toString(), datos)
       .subscribe(data => {        
           window.open(this.url + data.ruta, "_blank");
       }, err => { console.log(err) }); 


      } else {
        this.matSnakBar.open(`La Fecha Final debe ser mayor a la Fecha Inicial`, "Caso Clinico", {
          duration: 2000,
          verticalPosition:'bottom',
          horizontalPosition:'center'
        });
      }
      
    } else  {
      this.matSnakBar.open(`Debe de tener informacion de fecha que sean validas`, "Caso Clinico", {
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });
    }

  }
  exportTablaExcel(): void{
    let nombre = [{campo: "SUPERVISOR", nomSupervisor: this.supervisor.nombre + ' ' + this.supervisor.primerApellido + (this.supervisor.primerApellido ? ' ' + this.supervisor.primerApellido: '')}];
    let tCasosClinicos: ItbCasosClinicos[] = this.tbCasosClinicos.map((cc, i, casos) => {
      return <ItbCasosClinicos> { 
                              No: (i+1).toString(),   
                              Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                              Folio: cc.serie,
                              Paciente: cc.paciente,
                              Diagnostico: cc.diagnostico,
                              Hospital: cc.hospital,
                              Institucion: cc.cveInstitucion,
                              Estado: cc.fk_estado ? cc.estado.nomEstado : '',  
                              Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido: '',
                              Productos: cc.productos ? cc.productos.length : 0 
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombre, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tCasosClinicos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'supervisor_casos_clinicos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}supervisorcasosclinicos.xlsx`);
  }
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let nombreMedico: string = this.supervisor.nombre + ' ' + this.supervisor.primerApellido + (this.supervisor.primerApellido ? ' ' + this.supervisor.primerApellido: '');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("CASOS CLINICOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombreMedico, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'Folio', dataKey: 'Folio' },
         { header: 'Paciente', dataKey: 'Paciente' },
         { header: 'Diagnostico', dataKey: 'Diagnostico' },
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Institucion', dataKey: 'Institucion' },
         { header: 'Estado', dataKey: 'Estado' },  
         { header: 'Medico', dataKey: 'Medico' },         
         { header: 'Enfermera', dataKey: 'Enfermera' },
         { header: 'Productos', dataKey: 'Productos' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_supervisor_cc.pdf`);
  }

  setAsignarEnfermera(cc: CasoClinico): void {  
    console.log(cc.medico) ; 
    this.dialogo.open(DialogoAsignarEnfermeraCasoclinico, { width: '800px', data: { casoclinico: cc, medico: cc.medico } });
  }

  handlePage( e: PageEvent): void {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

}
