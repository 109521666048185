import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog, PageEvent, Sort } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { DialogoAsignarEnfermeraCasoclinico } from '../../dialogos/dialogo-asignar-enfermera-casoclinico/dialogo-asignar-enfermera-casoclinico';
import { Medico } from 'src/app/moddelo/medico';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { Hospital } from 'src/app/moddelo/hospital';
import { Institucion } from '../../../moddelo/institucion';

interface ItbCasosClinicos {  
          Fecha: string;
          NoCX: string;
          Paciente: string;
          Diagnostico: string;
          Cuerpo: string;
          Hospital: string;
          Institucion: string;
          Estado: string;
          Enfermera: string;
          Productos: number;
}

@Component({
  selector: 'app-administrador-medico-r-casos-clinicos',
  templateUrl: './administrador-medico-r-casos-clinicos.component.html',
  styleUrls: ['./administrador-medico-r-casos-clinicos.component.css']
})
export class AdministradorMedicoRCasosClinicosComponent implements OnInit {
  public casosClinicos: CasoClinico[] = [];
  public hospitales: Hospital[] = [];
  public instituciones: Institucion[] = [];
  public filtrosInstituciones: Institucion[] = []; 

  public filtrosHospitales: Hospital[]=[];
  public nombresHospitales: string[] = [];

  public filtroFrm: FormGroup;
  public filtroEnfermeras: Observable<String[]>;
  public nombresEnfermeras: String[] = [];
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  @Input('medico') medico: Medico; 
  @Output() updateCasoClinico: EventEmitter<CasoClinico> = new EventEmitter();

  constructor(
    private aut: AuthService, 
    private adminSrv: AdministradorService,
    private casosClinicosSrv: CasosClinicosService, 
    private formBuilder: FormBuilder,
    private dialogo: MatDialog,   
    private location: Location
  ) { }
  
  get usuario(){
    return this.aut.getUsuario;
  }

  get offCasosClinicos(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.filtroFrm.get('folio');
  }
  get serieCtrl(){
    return this.filtroFrm.get('serie');
  }

  get pacienteCtrl(){
    return this.filtroFrm.get('paciente');
  }

  get fk_hospitalCtrl(){
    return this.filtroFrm.get('fk_hospital');
  }

  get fk_institucionCtrl(){
    return this.filtroFrm.get('fk_institucion');
  }

  get enfermeraCtrl() {
    return this.filtroFrm.get('enfermera');
  }
  ngOnInit() {
 
    
    this.filtroFrm = this.formBuilder.group({
                                              fechaInicial:[''],
                                              fechaFinal:[''],
                                              folio:[''],
                                              serie:[''],
                                              paciente:[''],
                                              fk_hospital: [''],
                                              enfermera:[''],                                               
                                              fk_institucion:['']
                                            });

    this.casosClinicos = (this.medico.casosclinicos ? this.medico.casosclinicos.sort( (a, b) => { let a_fecha = new Date(a.fecha); let b_fecha = new Date(b.fecha); return b_fecha.getTime() - a_fecha.getTime();} ) : []);
   
   
    this.nombresEnfermeras = this.adminSrv.enfermeras.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) );  
    this.filtroEnfermeras = this.enfermeraCtrl.valueChanges.pipe( startWith(''), map(value => this._filterEnfermeras(value)) );   
    
    //this.nombresEnfermeras = this.adminSrv.enfermeras.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) );  
    //this.filtroEnfermeras = this.enfermeraCtrl.valueChanges.pipe( startWith(''), map(value => this._filter(value)) ); 
    
    this.hospitales = this.adminSrv.hospitales;
   
    this.instituciones = this.adminSrv.instituciones;
    this.filtrosInstituciones = this.adminSrv.instituciones;
                                       
  }
 
  onSubmit() {
    this.casosClinicos =  this.medico.casosclinicos.filter( cc => 
                          this.filterPaciente(cc.paciente) && 
                          this.filterHospital(cc.fk_hospital) && 
                          this.filterInstitucion(cc.centromedico ? cc.centromedico.fk_institucion:null) && 
                          this.filterFolio(cc.folio) && 
                          this.filterSerie(cc.serie) && 
                          this.filterEnfermera(cc.fk_enfermera) && 
                          this.filterFecha(cc.fecha.toString()));

    this.page_number=1;  
  }

  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  }
 /*
  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(nombre){
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }
 */
  filterInstitucion(fk_institucion: number): boolean {
    
    let condicion: boolean = true;  
    console.log("fk_institucion: ",this.fk_institucionCtrl.value);
    if( this.fk_institucionCtrl.value ){
      let id_inst: number = this.fk_institucionCtrl.value;       
      if(fk_institucion) {      
        condicion = id_inst == fk_institucion;      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterHospital(fk_hospital: number): boolean{
    let condicion: boolean = true;  

    if( this.fk_hospitalCtrl.value ){
      let id_hospital: number = this.fk_hospitalCtrl.value;       
      if(fk_hospital){      
        condicion = id_hospital == fk_hospital;      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  /*
  filterInstitucion(cveInstitucion: string): boolean{
    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let cve: string;

    if( this.institucionCtrl.value != 0 ){
        i = this.institucionCtrl.value;
        institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
        cve = cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(cve){
        condicion = cve.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  */
  filterEnfermera(fk_enfermera: number): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: String = "";

    if(this.enfermeraCtrl.value != ""){
        nom = this._normalizeValue(this.enfermeraCtrl.value);
        if(fk_enfermera){
          nomEnfermera = this.getEnfermera(fk_enfermera.toString());
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );
        }
    }
    
    return condicion;
  }
  
  
  
  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
        condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }

  filterSerie(serie: string): boolean{
    let condicion: boolean = true;

      if(this.serieCtrl.value != ""){
        condicion = !( serie.indexOf(this.serieCtrl.value) == -1 );
      } 

    return condicion; 
  }

  _filter(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresEnfermeras.filter(enfermera => this._normalizeValue(enfermera).includes(valorFiltro));

  }
  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }

  getEnfermera(id: string): string {
    let enfermera: Enfermera;
    let nombre: string = "";
    if(id) {
      enfermera = this.adminSrv.enfermeras.find(e => e.id.toString() == id);
      nombre = enfermera ? (enfermera.nombre + ( enfermera.primerApellido ? ' ' + enfermera.primerApellido : '' ) + (enfermera.segundoApellido ? ' ' + enfermera.segundoApellido : '') ): '';
    }  
    return nombre;
  }
  setAsignarEnfermera(cc: CasoClinico): void {    
      this.dialogo.open(DialogoAsignarEnfermeraCasoclinico, { width: '800px', data: { casoclinico: cc, medico: this.medico } });
  }
  setUpdateCasoClinico(casoclinico: CasoClinico): void{
      this.updateCasoClinico.emit(casoclinico);
  }
  setInsertCasoClinico(): void {
      let casoClinico: CasoClinico = new CasoClinico();
      
      casoClinico.documentos = [];
      casoClinico.productos = [];
      casoClinico.seguimientos = [];
      
      this.updateCasoClinico.emit(casoClinico);
  }
  handlePage( e: PageEvent): void {
      this.page_size = e.pageSize;
      this.page_number  = e.pageIndex + 1;
  }
  sortMedicos(sort: Sort): void {
    const datos: CasoClinico[] = this.casosClinicos.slice();
    if(!sort.active || sort.direction === ''){
      this.casosClinicos = datos;
      return;
    }
    this.casosClinicos =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fecha':
          let a1: number = new Date(a.fecha).getTime();
          let b1: number = new Date(b.fecha).getTime();
          return this.compare(a1, b1, isAsc);
        case 'folio':
          return this.compare(a.folio, b.folio, isAsc);
        case 'serie':
          return this.compare(a.serie, b.serie, isAsc);
        case 'paciente':
          return this.compare(a.paciente, b.paciente, isAsc);
        case 'hospital':
          let hospitala: string = a.centromedico ? a.centromedico.nomHospital: '';
          let hospitalb: string = b.centromedico ? b.centromedico.nomHospital: '';
          return this.compare(hospitala, hospitalb, isAsc);
        case 'institucion':
          let instituciona: string = a.centromedico ? (a.centromedico.institucion?a.centromedico.institucion.cvInstitucion:''): '';
          let institucionb: string = b.centromedico ? (b.centromedico.institucion?b.centromedico.institucion.cvInstitucion:''): '';
          return this.compare(instituciona, institucionb, isAsc);
        case 'enfermera':
          let enfermeraA: string = a.fk_enfermera ? a.enfermera.nombre + ' ' +( a.enfermera.primerApellido ? a.enfermera.primerApellido: '') : '';
          let enfermeraB: string = b.fk_enfermera ? b.enfermera.nombre + ' ' +( b.enfermera.primerApellido ? b.enfermera.primerApellido: '') : '';
          return this.compare(enfermeraA, enfermeraB, isAsc);
        case 'productos':
          let productosA: number = a.productos ? a.productos.length : 0;
          let productosB: number = b.productos ? b.productos.length : 0;
          return this.compare(productosA, productosB, isAsc);
        case 'servicio':
            let cuerpoa: string = a.fk_seccion_cuerpo ? a.seccioncuerpo.nomCuerpo: '';
            let cuerpob: string = b.fk_seccion_cuerpo ? b.seccioncuerpo.nomCuerpo: '';
            return this.compare(cuerpoa, cuerpob, isAsc);    
        default:
          return 0;
      }
    });
  } 
  goBack(): void {
    this.location.back();
  }
  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  generateData(): any[] {
    let result: ItbCasosClinicos[] = [];
    let i: number = 0;

    this.casosClinicos.forEach((cc, i) => {
        
        result.push( { 
                      Institucion: cc.cveInstitucion,                     
                      Hospital: cc.hospital,
                      Estado: cc.fk_estado ? cc.estado.nomEstado : '',                       
                      Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                      NoCX: cc.folio,                     
                      Cuerpo: cc.seccioncuerpo ? cc.seccioncuerpo.nomCuerpo: '',
                      Paciente: cc.paciente, 
                      Diagnostico: cc.diagnostico,                                          
                      Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido : '',
                      Productos: cc.productos ? cc.productos.length : 0 
                    });
    });

  return result;
  }
  exportTablaExcel(): void{
    let nombreMedico = [{campo: "Medico", nomMedico: this.medico.nombre + ' ' + this.medico.primerApellido + (this.medico.primerApellido ? ' ' + this.medico.primerApellido: '')}];
    let tCasosClinicos: ItbCasosClinicos[] = this.casosClinicos.map((cc, i, casos) => {
      return <ItbCasosClinicos> { 
                              Institucion: cc.cveInstitucion,
                              Hospital: cc.hospital,
                              Estado: cc.fk_estado ? cc.estado.nomEstado : '',  
                              Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                              NoCX: cc.folio,
                              Cuerpo: cc.seccioncuerpo ? cc.seccioncuerpo.nomCuerpo: '',
                              Paciente: cc.paciente,
                              Diagnostico: cc.diagnostico,
                              Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido: '',
                              Productos: cc.productos ? cc.productos.length : 0 
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreMedico, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tCasosClinicos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'medico_casos_clinicos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}medicocasosclinicos.xlsx`);
  }
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let nombreMedico: string = this.medico.nombre + ' ' + this.medico.primerApellido + (this.medico.primerApellido ? ' ' + this.medico.primerApellido: '');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("CASOS CLINICOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombreMedico, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [ 
         { header: 'Institucion', dataKey: 'Institucion' }, 
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Estado', dataKey: 'Estado' },        
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'No CX', dataKey: 'NoCX' },
         { header: 'Cuerpo', dataKey: 'Cuerpo'},
         { header: 'Paciente', dataKey: 'Paciente' },
         { header: 'Diagnostico', dataKey: 'Diagnostico' },
         { header: 'Enfermera', dataKey: 'Enfermera' },
         { header: 'Productos', dataKey: 'Productos' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
         doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_medico_cc.pdf`);
  }
  _filterEnfermeras(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresEnfermeras.filter(enfermera => this._normalizeValue(enfermera).includes(valorFiltro));
  }  
  onChangeSelectInst(id_institucion: string) {  
   
    this.nombresHospitales = [];
   
    //let id_estado = this.fk_estadoCtrl.value;
   
    this.nombresHospitales = [];
  
    this.fk_hospitalCtrl.setValue('');  
   
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0)); 
     
  }
  deleteCaso(id: number): void {
    let conf: boolean = confirm("estas seguro de borrar el caso clinico");

    if(conf){
      this.casosClinicosSrv.getCasoClinicoDelete(id).subscribe(data => {
            let i: number = this.medico.casosclinicos.findIndex(c => c.id == id);
            this.medico.casosclinicos.splice(i, 1);

            this.casosClinicos = this.medico.casosclinicos.sort( 
                            (a, b) => { 
                              let a_fecha = new Date(a.fecha); 
                              let b_fecha = new Date(b.fecha); 
                              return b_fecha.getTime() - a_fecha.getTime();
                            } 
                          ).filter( cc => 
                                    this.filterPaciente(cc.paciente) && 
                                    this.filterHospital(cc.fk_hospital) && 
                                    this.filterInstitucion(cc.centromedico ? cc.centromedico.fk_institucion : null) && 
                                    this.filterFolio(cc.folio) && 
                                    this.filterEnfermera(cc.fk_enfermera) && 
                                    this.filterFecha(cc.fecha.toString())
                            );
                            
            this.page_number=1; 
      }, err => { console.log(err) });
      
      console.log("Documento eliminado" );
    }
    
  }
}
