import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';

import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import descripcion from '../../../datos/status';


@Component({
  selector: 'app-enfermera-canje',
  templateUrl: './enfermera-canje.component.html',
  styleUrls: ['./enfermera-canje.component.css']
})
export class EnfermeraCanjeComponent implements OnInit {
  public canjes: RedimirEnfermera[] = [];
  public enfermera: Enfermera;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  
  constructor(private aut: AuthService, private enfermerasSrv: EnfermerasService) { 
    this.enfermera = this.aut.getUsuario.enfermera;
  }
  getDescripcion(id: number){
    return descripcion.status[id];
  }
  ngOnInit() {
    this.enfermerasSrv.card = 4;
    this.canjes = this.enfermerasSrv.enfermera.redimirenfermera.sort((a, b) => {  
                                                                                  let a_fecha = new Date(a.fechaInicio); 
                                                                                  let b_fecha = new Date(b.fechaInicio); 
                                                                                  return b_fecha.getTime() - a_fecha.getTime()
                                                                                });


    /*
      this.enfermerasSrv
      .getCanjes(this.enfermera.id)
      .subscribe(data => {this.canjes = data;}, err => { console.log(err); });
    */
  }
  handlePage( e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
}
