import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Usuario } from 'src/app/moddelo/usuario'; 
import { DialogoCambiarPasswUsuario } from '../../dialogos/dialogo-cambiar-passw-usuario/dialogo-cambiar-passw-usuario';

interface Respuesta{
  code: number;
  message: String;
  data: Usuario;
}
@Component({
  selector: 'app-administrador-enfermera-perfil',
  templateUrl: './administrador-enfermera-perfil.component.html',
  styleUrls: ['./administrador-enfermera-perfil.component.css']
})
export class AdministradorEnfermeraPerfilComponent implements OnInit {
  private usuario: Usuario = null;

  public usuarioEnfermeraFrm = new FormGroup({                               
                                                alias: new FormControl('', Validators.required),
                                                nombre: new FormControl('', Validators.required),
                                                primerApellido: new FormControl('', Validators.required),
                                                segundoApellido: new FormControl(''),
                                                telefonoMovil: new FormControl('', Validators.required)                                
                                              });

  get aliasCtrl(){ return this.usuarioEnfermeraFrm.get('alias');}
  get nombreCtrl(){ return this.usuarioEnfermeraFrm.get('nombre');}
  get primerApellidoCtrl(){ return this.usuarioEnfermeraFrm.get('primerApellido');}
  get segundoApellidoCtrl(){ return this.usuarioEnfermeraFrm.get('segundoApellido');}
  get telefonoCtrl(){ return this.usuarioEnfermeraFrm.get('telefonoMovil');}
  
  get Authorizacion(){
    return this.auth.getUsuario;
  }
  get offBoton(){
    return (this.Authorizacion.fk_nivel == 3 || this.Authorizacion.fk_nivel == 2 || this.Authorizacion.fk_nivel == 0);
  }
  get passwHidden(){
    return (this.Authorizacion.fk_nivel == 3 || this.Authorizacion.fk_nivel == 0);
  }
  @Input('enfermera') public enfermera: Enfermera;

  constructor(
    private usuariosSrv: UsuariosService,
    private snackBar: MatSnackBar,
    private adminSrv: AdministradorService,
    private auth: AuthService,
    private router: Router,
    private dialogo: MatDialog) { }

  get url(): string{    
    return window.location.origin.replace(':4200', '');
  }
  get getUsuarioActivo(): boolean {
    let r = false;
    if(this.usuario){
      r = this.usuario.activo == 1;
    }
    return r;
  }
  get isProductivo(): boolean {
    let respuesta: boolean = false;
    let enfermera: Enfermera = this.enfermera;
    
    respuesta = ( enfermera.redimirenfermera.length == 0 && enfermera.casosclinicos.length == 0 && enfermera.medicos.length == 0 && enfermera.productos.length==0 );
   
    return respuesta;
  }
  ngOnInit() {
    this.usuario = this.enfermera.usuario; 
      
    let alias = this.usuario.alias;
    let { nombre, primerApellido, segundoApellido, telefonoMovil } = this.enfermera;
    
    this.usuarioEnfermeraFrm.setValue({ alias, nombre, primerApellido, segundoApellido, telefonoMovil });
  }
  onSubmit() {
    let { alias, nombre, primerApellido, segundoApellido, telefonoMovil } = this.usuarioEnfermeraFrm.value;
    let usuarioU: Usuario = <Usuario> { alias, nombre, primerApellido, segundoApellido, telefonoMovil };
    let enfermeraU: Enfermera = <Enfermera> { nombre, primerApellido, segundoApellido, telefonoMovil };
    let id = this.usuario.id;
    
    this.usuariosSrv
    .setUsuarioEnfermeraUpdate( id, usuarioU, enfermeraU)
    .subscribe(data => {
        
      this.snackBar.open(`Registrado actualizado`, "Enfermera", {
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
       });     
      
      this.usuario.alias = usuarioU.alias;
      this.usuario.nombre = usuarioU.nombre;
      this.usuario.primerApellido = usuarioU.primerApellido;
      this.usuario.segundoApellido = usuarioU.segundoApellido;
      this.usuario.telefonoMovil = usuarioU.telefonoMovil;
      
      this.enfermera.nombre = enfermeraU.nombre;
      this.enfermera.primerApellido = enfermeraU.primerApellido;
      this.enfermera.segundoApellido = enfermeraU.segundoApellido;
      this.enfermera.telefonoMovil = enfermeraU.telefonoMovil;

    }, err =>{ console.log(err); });
   
  }
  delete() {
     
    if(confirm("seguro de eliminar este usuario")){
      this.usuariosSrv.deleteUsuarioOperativo(this.usuario.id).subscribe(data => { 
        let usuario: Usuario;
        let respuesta: Respuesta;
        
        respuesta = <Respuesta> data;
        usuario = respuesta.data;
        
        alert(respuesta.message);

        if( respuesta.code == 1){
          this.router.navigate(['/administrador/enfermaras']); 
        }
       
      }, 
      err => { console.log(err) });
    }
    
  }
  desactivar(id: number) {

    if(confirm("seguro de desabilitar este usuario")){
      this.usuariosSrv.desactivarUSuario(id).subscribe(data => { if(data){ alert(`Usuario ${data.usuario} desactivado`); } }, err => { console.log(err); });
    }

  }
  activar(id: number) {
    
    if(confirm("seguro de habilitar este usuario")){
      this.usuariosSrv.activarUsuario(id).subscribe(data => { if(data){ alert(`Usuario ${data.usuario} activado`); } }, err => { console.log(err); });
    }
    
  }
  changePassw(): void {
    this.dialogo.open(DialogoCambiarPasswUsuario, {width:'600px', data: this.usuario});
  }
  
  

}
