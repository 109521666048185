import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsuariosRoutingModule } from './usuarios-routing.module';
import { UsuarioLoginSignupComponent } from './usuario-login-signup/usuario-login-signup.component';

import { MaterialModule } from '../../material.module';
import { UsuarioMedicoCreateComponent } from './usuario-medico-create/usuario-medico-create.component';
import { UsuarioEnfermeraCreateComponent } from './usuario-enfermera-create/usuario-enfermera-create.component';
import { UsuarioEleccionComponent } from './usuario-eleccion/usuario-eleccion.component';
import { UsuarioPerfilComponent } from './usuario-perfil/usuario-perfil.component';
import { UsuarioDocumentosUploadComponent } from './usuario-documentos-upload/usuario-documentos-upload.component';
import { UsuarioUpdateComponent } from './usuario-update/usuario-update.component';
import { UsuarioMedicoUpdateComponent } from './usuario-medico-update/usuario-medico-update.component';
import { UsuarioEnfermeraUpdateComponent } from './usuario-enfermera-update/usuario-enfermera-update.component';
import { UsuarioMedicoFichaComponent } from './usuario-medico-ficha/usuario-medico-ficha.component';
import { UsuarioDocumentosUpdateComponent } from './usuario-documentos-update/usuario-documentos-update.component';
import { UsuarioSupervisorCreateComponent } from './usuario-supervisor-create/usuario-supervisor-create.component';
import { UsuarioSupervisorUpdateComponent } from './usuario-supervisor-update/usuario-supervisor-update.component';
import { UsuarioRecoverPswComponent } from './usuario-recover-psw/usuario-recover-psw.component';

@NgModule({
  declarations: [UsuarioLoginSignupComponent, UsuarioMedicoCreateComponent, UsuarioEnfermeraCreateComponent, UsuarioEleccionComponent, UsuarioPerfilComponent, UsuarioDocumentosUploadComponent, UsuarioUpdateComponent, UsuarioMedicoUpdateComponent, UsuarioEnfermeraUpdateComponent, UsuarioMedicoFichaComponent, UsuarioDocumentosUpdateComponent, UsuarioSupervisorCreateComponent, UsuarioSupervisorUpdateComponent, UsuarioRecoverPswComponent],
  imports: [
    CommonModule,
    FormsModule,
    UsuariosRoutingModule,
    MaterialModule,
    ReactiveFormsModule 
  ]
})
export class UsuariosModule { }
