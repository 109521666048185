import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { AuthService } from 'src/app/servicios/auth.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
@Component({
  selector: 'app-medico-casos-clinicos',
  templateUrl: './medico-casos-clinicos.component.html',
  styleUrls: ['./medico-casos-clinicos.component.css']
})
export class MedicoCasosClinicosComponent implements OnInit {
  public casosClinicos: CasoClinico[] = [];
  public cc: CasoClinico[] = [];
  public filtroFrm: FormGroup;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100]; 
  
  constructor(
              private medicosSrv: MedicosService, 
              private aut: AuthService,
              private formBuilder: FormBuilder
              ) { }

  get medico(){
    return this.medicosSrv.medico;
  }

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.filtroFrm.get('folio');
  }

  get pacienteCtrl(){
    return this.filtroFrm.get('paciente');
  }

  get hospitalCtrl(){
    return this.filtroFrm.get('hospital');
  }
  ngOnInit() {
    this.medicosSrv.card = 1;
    this.filtroFrm = this.formBuilder.group({
                                              fechaInicial:[''],
                                              fechaFinal:[''],
                                              folio:[''],
                                              paciente:[''],
                                              hospital: ['']
                                            });

    this.cc = this.medico.casosclinicos.sort((a, b) =>{ 
      let a_fecha = new Date(a.fecha); 
      let b_fecha = new Date(b.fecha); 
      return b_fecha.getTime() - a_fecha.getTime()
    } );

    this.casosClinicos =  this.cc;
  }
  onSubmit(){         
    this.casosClinicos = this.cc.filter( cc => this.filterPaciente(cc.paciente) && this.filterHospital(cc.hospital) && this.filterFolio(cc.folio) && this.filterFecha(cc.fecha));
    this.page_number=1;
  }

  filterFecha(fechaCirugia: Date): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  }
 
  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(nombre){
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }

  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
       condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

}
