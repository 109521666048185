import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm} from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera'; 
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';

@Component({
  selector: 'app-administrador-enfermera-r-canje-add',
  templateUrl: './administrador-enfermera-r-canje-add.component.html',
  styleUrls: ['./administrador-enfermera-r-canje-add.component.css']
})
export class AdministradorEnfermeraRCanjeAddComponent implements OnInit {
  public casosclinicos: CasoClinico[] = [];
  @Input('enfermera') public enfermera: Enfermera;
  @Output() cancelarCanje: EventEmitter<any> = new EventEmitter<any>();

  private redimirFrm: FormGroup = new FormGroup({
    redimir: new FormControl(0),
    fechaInicio: new FormControl('', Validators.required), 
  });

  get fechaCtrl() { return this.redimirFrm.get('fechaInicio'); }
  get redimirCtrl() { return this.redimirFrm.get('redimir'); }

  get saldo() {
    //let canjesAplicados = this.enfermera.redimirenfermera.filter(canje => canje.status == 3 || canje.status == 1 ).reduce((valor, canje) => canje.redimir + valor, 0);
    let saldo = this.enfermera.casosclinicos.filter(cc => cc.status_canje == 0 ).reduce((valor, producto) => producto.e_puntos + valor, 0);
    return saldo;
  }
 
  constructor(
            private auth: AuthService,
            private adminSrv: AdministradorService, 
            private enfermerasSrv: EnfermerasService, 
            private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.validaciones();
    this.casosclinicos = this.enfermera.casosclinicos.filter(casoclinico => casoclinico.status_canje === 1 );
  }

  onSubmit(frm: NgForm){
 
    let canje: RedimirEnfermera = new RedimirEnfermera(); // { redimir, fechaInicio };
    canje.status = 1;
    canje.redimir = this.cantidad;
    canje.fk_enfermera = this.enfermera.id;
    canje.fechaInicio = this.fechaCtrl.value;
    
    this.enfermerasSrv
    .setRedimirCreate(canje, this.casosclinicos)
    .subscribe(data => { 
        console.log(data)
         this.enfermera.redimirenfermera.push(data); 
          
          frm.reset();          
          
          this.redimirCtrl.setValue(0);
          this.validaciones();

          this.enfermera.casosclinicos.map(cc => {
            if(cc.status_canje==1) {
              cc.fk_redimir_enfermera = data.id;
              cc.status_canje = 2;
            }
          })
          this.snackBar.open("Solicitud registrada", "Canje", {
            duration: 3000,
            verticalPosition:'bottom',
            horizontalPosition:'center'
          });
         this.cancelar();
    }, err => { console.log( err ); } );
  }
  
  get cantidad(): number{
    return this.casosclinicos.reduce((valor, casoclinico) => casoclinico.e_puntos+valor, 0);
  }

  validaciones(): void {
    this.redimirFrm.clearValidators();    
    this.fechaCtrl.setValidators(Validators.required)
  }

  cancelar(): void {
    this.cancelarCanje.emit();
  }

}
