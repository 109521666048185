import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators'; 
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService} from '../../../servicios/auth.service';
import { AdministradorService } from '../../../servicios/administrador.service';

import { Medico } from 'src/app/moddelo/medico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { MedicosService } from 'src/app/servicios/medicos.service';

interface AddMedico{
  code: number;
  message: string;
  data: Medico;
}
@Component({
  selector: 'app-administrador-enfermera-r-medico-add',
  templateUrl: './administrador-enfermera-r-medico-add.component.html',
  styleUrls: ['./administrador-enfermera-r-medico-add.component.css']
})
export class AdministradorEnfermeraRMedicoAddComponent implements OnInit {
  public enfermera: Enfermera;
  public medicos: Medico[] = [];
  public respuesta: AddMedico;
  private filteredMedicos: Observable<Medico[]>;
  public medicoFrm = new FormGroup({    
                                    medico: new FormControl('')   
                                  });
  get usuario(){
    return this.auth.getUsuario;
  }
  get offBoton(){
    return this.medicoFrm.valid && (this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3);  
  }                             
  constructor(private location: Location, 
    private route: ActivatedRoute, 
    private auth: AuthService, 
    private adminSrv: AdministradorService, 
    private enfermerasSrv: EnfermerasService,
    private medicosSrv: MedicosService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.enfermera = this.adminSrv.enfermeras.find( enfermera => enfermera.id == Number(id));
 
    this.filteredMedicos = this.medicoFrm.controls["medico"].valueChanges.pipe(startWith(''), map(value => this._filterMedico(value)));
   
    this.medicosSrv.getMedicos().subscribe(data => { this.medicos = data; }, err => { console.log(err)});
  }
  onSubmit(event: any){
    let medico: Medico = <Medico>this.medicoFrm.value.medico;  
     this.enfermerasSrv
     .setAsociarMedico(this.enfermera.id, medico)
     .subscribe(data => {
                            this.respuesta = <AddMedico> data;
                            if(this.respuesta.code == 1){          
                              this.enfermera.medicos.push(medico);
                              this.adminSrv.medicos.find( elemento => elemento.id == medico.id).enfermeras.push(this.enfermera);
                              this.snackBar
                              .open(
                                this.respuesta.message, 
                                'Enfermera', 
                                {
                                  duration: 3000,
                                  verticalPosition:'bottom',
                                  horizontalPosition:'center'
                                });
                              console.log(this.respuesta.message);
                              this.goBack();
                            }else{
                        
                            }
     }, err => { 
                if(err.status == 500){
                    if(err.error.includes('Duplicate')){            
                      this.snackBar
                      .open(
                        'El medico ya esta asociado', 
                        'Medico', 
                        {
                          duration: 3000,
                          verticalPosition:'bottom',
                          horizontalPosition:'center'
                        });
                    }
                  
                }
                console.log(err) 
      } );
   }

  _normalizeValue(nombre: string): string {
    let cadena = String(nombre).toLowerCase();
    return cadena ? cadena.replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u') : '';
  }
  _filterMedico(nombre: string): Medico[] {
    const valorFiltro = this._normalizeValue(nombre);
    return this.medicos.filter(medico => this._normalizeValue(medico.nombre).includes(valorFiltro) ||  this._normalizeValue(medico.primerApellido).includes(valorFiltro) || this._normalizeValue(medico.segundoApellido).includes(valorFiltro));
  }
  displayFnMedico(medico: Medico ): string {
    return  medico  ? medico.nombre.concat(' ').concat(medico.primerApellido).concat(' ').concat(medico.segundoApellido) : '';
  }
  goBack(): void {
    this.location.back();
  }

}
