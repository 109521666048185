import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { AntibioticosRoutingModule } from './antibioticos-routing.module';
import { AntibioticosComponent } from './antibioticos/antibioticos.component';
import { AntibioticoComponent } from './antibiotico/antibiotico.component';


@NgModule({
  declarations: [AntibioticosComponent, AntibioticoComponent],
  imports: [
    CommonModule,
    AntibioticosRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    MaterialModule
  ]
})
export class AntibioticosModule { }
