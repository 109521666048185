import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Enfermera } from 'src/app/moddelo/enfermera';

@Component({
    selector: 'dialogo-enfermera-ficha',
    templateUrl: 'dialogo-enfermera-ficha.html'    
})
export class DialogoEnfermeraFicha {
    get NombreCompleto(){
        return this.data.nombre.concat(' ').concat(this.data.primerApellido).concat(' ').concat(this.data.segundoApellido);
    }
  
  constructor(
            public dialogo: MatDialogRef<DialogoEnfermeraFicha>, 
            @Inject(MAT_DIALOG_DATA) public data: Enfermera
             ){}

    onClose(){
            this.dialogo.close(); 
    }

}