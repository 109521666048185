import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-administrador-supervisores-informes-saldos',
  templateUrl: './administrador-supervisores-informes-saldos.component.html',
  styleUrls: ['./administrador-supervisores-informes-saldos.component.css']
})
export class AdministradorSupervisoresInformesSaldosComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {
  }
  goBack(){
    this.location.back();
  }
}
