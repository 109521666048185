import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SeccionescuerpoRoutingModule } from './seccionescuerpo-routing.module';
import { MaterialModule } from '../../../material.module';
import { SeccioncuerpoComponent } from './seccioncuerpo/seccioncuerpo.component';
import { SeccionescuerpoComponent } from './seccionescuerpo/seccionescuerpo.component';

@NgModule({
  declarations: [SeccioncuerpoComponent, SeccionescuerpoComponent],
  imports: [
    CommonModule,
    FormsModule,
    SeccionescuerpoRoutingModule,
    ReactiveFormsModule,
    MaterialModule
  ]
})
export class SeccionescuerpoModule { }
