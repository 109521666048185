import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PageEvent} from '@angular/material';
import { finalize } from 'rxjs/operators';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { Categoria } from 'src/app/moddelo/categoria';
@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {
  public categorias: Categoria[]=[];
  public loadingCategorias: boolean = true;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  
  get usuario(){
    return this.auth.getUsuario;
  }

  get offLink(){
    return (this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 0);
  }

  constructor(
              private auth: AuthService, 
              private location: Location, 
              private catalogosSrv: CatalogosService) { }

  ngOnInit() {
    this.catalogosSrv
    .getCategorias()
    .pipe(finalize( ()=> this.loadingCategorias = false))
    .subscribe(data=>{ this.categorias = data}, err => {console.log(err)})
  }

  goBack(): void{
    this.location.back();
  }

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  
} 
