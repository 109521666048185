import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { PageEvent, Sort } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Producto } from 'src/app/moddelo/producto';
import { Supervisor } from 'src/app/moddelo/supervisor';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Medico } from 'src/app/moddelo/medico';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';


interface ItbProductos {
  No: number; 
  Fecha: string;
  NoCX: string;
  Folio: string;   
  Producto: string;
  Lote: string;
  Hospital: string;
  Institucion: string;
  Codigo: string; 
  Enfermera: string;
  Medico: string; 
}

interface IpdfProductos{
        No: number; 
        Fecha: string; 
        NoCX: string;
        Folio: string;        
        Producto: string;
        Lote: string;
        Hospital: string;
        Institucion: string;
        Codigo: string;
        Enfermera: string;
        Medico: string;       
}

@Component({
  selector: 'app-administrador-supervisor-r-productos',
  templateUrl: './administrador-supervisor-r-productos.component.html',
  styleUrls: ['./administrador-supervisor-r-productos.component.css']
})
export class AdministradorSupervisorRProductosComponent implements OnInit, AfterViewInit {
  public productosLocal: Producto[] = [];
  public productosFilter: Producto[] = [];
  public enfermeras: Enfermera[] = [];
  public filtroFrm: FormGroup; 
  @Input('productos') productos: Producto[];
  @Input('supervisor') supervisor: Supervisor;

  public page_size: number = 5;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(private formBuilder: FormBuilder  ) { }

  get fechaInicialCtrl() {
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl() {
    return this.filtroFrm.get('fechaFinal');
  }

  get codigoCtrl() {
    return this.filtroFrm.get('codigo');
  }

  get productoCtrl() {
    return this.filtroFrm.get('producto');
  }

  get enfermeraCtrl() {
    return this.filtroFrm.get('enfermera');
  }

  get medicoCtrl() {
    return this.filtroFrm.get('medico');
  } 

  get hospitalCtrl() {
    return this.filtroFrm.get('hospital');
  }

  get folioCtrl() {
    return this.filtroFrm.get('folio');
  }

  get serieCtrl() {
    return this.filtroFrm.get('serie');
  }
  ngAfterViewInit(): void {
    this.productosFilter = this.productos;
  }
  ngOnInit() {
    
    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial:[''],
                                            fechaFinal:[''],
                                            folio:[''],
                                            serie:[''],
                                            codigo:[''],
                                            producto:[''],
                                            medico:[''],
                                            enfermera:[''],
                                            hospital: ['']
                                            });
  }
  onSubmit(): void  {   
    this.productosFilter = this.productos.filter( producto => 
                                                            this.filterCodigo(producto.codigo) && 
                                                            this.filterProducto(producto.nomProducto) && 
                                                            this.filterEnfermera(producto.fk_enfermera, producto.enfermera) && 
                                                            this.filterMedico(producto.fk_enfermera, producto.medico) &&
                                                            this.filterHospital(producto.fk_casoClinico, producto.casoclinico) && 
                                                            this.filterFolio(producto.fk_casoClinico, producto.casoclinico) &&
                                                            this.filterSerie(producto.fk_casoClinico, producto.casoclinico) &&  
                                                            this.filterFecha(producto.fechaAplicacion.toString())
                                                );

    this.page_number = 1;
  }

  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterCodigo(codigo: string): boolean {
    let condicion: boolean = true;
    let code: string = "";

    if(this.codigoCtrl.value != ""){
      code = this.codigoCtrl.value;
     condicion = ( codigo.toUpperCase().includes(code.toUpperCase()) );
    }
    
    return condicion;
  }

  filterProducto(nomProductos: string): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    
    if(this.productoCtrl.value != ""){
        nom = this.productoCtrl.value;
        if(nomProductos)
        condicion = (nomProductos.toUpperCase().includes(nom.toUpperCase()) );
    }    
    return condicion;
  }

  filterEnfermera(fk_enfermera: number, enfermera: Enfermera): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: String = "";

    if(this.enfermeraCtrl.value != ""){
        nom = this._normalizeValue(this.enfermeraCtrl.value);
        if(fk_enfermera) {
          nomEnfermera = enfermera ? (enfermera.nombre + ( enfermera.primerApellido ? ' ' + enfermera.primerApellido : '' ) + (enfermera.segundoApellido ? ' ' + enfermera.segundoApellido : '') ) : '';
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );
        }
    }    
    return condicion;
  }

  filterMedico(fk_medico: number, medico: Medico): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: String = "";

    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(fk_medico) {
          nomMedico = medico ? (medico.nombre + ( medico.primerApellido ? ' ' + medico.primerApellido : '' ) + (medico.segundoApellido ? ' ' + medico.segundoApellido : '') ) : '';
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );
        }
    }    
    return condicion;
  }

  filterHospital(fk_casoclinico:  number, casoclinico: CasoClinico){
    let condicion: boolean = true;
    let nom: string = "";
    let nomHospital: String = "";

    if(this.hospitalCtrl.value != ""){
        nom = this._normalizeValue(this.hospitalCtrl.value);
        if(fk_casoclinico){
          nomHospital = casoclinico.hospital;
          condicion = ( this._normalizeValue(nomHospital).includes(nom) );
        }
    }
    
    return condicion;

  }
 
  filterFolio(fk_casoclinico: number, casoclinico: CasoClinico): boolean {
    let condicion: boolean = true;
    let f: String = "";
    let folio: String = "";

    if(this.folioCtrl.value != ""){
        f = this._normalizeValue(this.folioCtrl.value);
        if(fk_casoclinico){
          folio = casoclinico.folio;
          condicion = (folio.toLowerCase().includes(f.toLowerCase()) );
        }
    }
        
    return condicion;
  }

  filterSerie(fk_casoclinico: number, casoclinico: CasoClinico): boolean {
    let condicion: boolean = true;
    let f: String = "";
    let folio: String = "";

    if(this.serieCtrl.value != ""){
        f = this._normalizeValue(this.serieCtrl.value);
        if(fk_casoclinico){
          folio = casoclinico.serie;
          condicion = (folio.toLowerCase().includes(f.toLowerCase()) );
        }
    }
        
    return condicion;
  }

  _normalizeValue(nom: String): string{  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  
  sortProductos(sort: Sort): void {
    const datos: Producto[] = this.productosFilter.slice();
    if(!sort.active || sort.direction === ''){
      this.productos = datos;
      return;
    }
    this.productosFilter =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fecha':
          let a1: number = new Date(a.fechaAplicacion).getTime();
          let b1: number = new Date(b.fechaAplicacion).getTime();
          return this.compare(a1, b1, isAsc);
        case 'folio':
          return this.compare(a.casoclinico.folio, b.casoclinico.folio, isAsc);
        case 'serie':
            return this.compare(a.casoclinico.serie, b.casoclinico.serie, isAsc);
        case 'producto':
          return this.compare(a.nomProducto, b.nomProducto, isAsc);
        case 'enfermera':
          return this.compare(a.enfermera ? a.enfermera.nombre : '', b.enfermera ? b.enfermera.nombre : '', isAsc);
        case 'medico':
          return this.compare(a.medico ? a.medico.nombre : '', b.medico ? b.medico.nombre : '', isAsc);  
        case 'hospital':
            return this.compare(a.casoclinico.hospital, b.casoclinico.hospital, isAsc);  
        case 'codigo':
          return this.compare(a.codigo, b.codigo, isAsc);
        case 'puntos':
          return this.compare(a.m_puntos, b.m_puntos, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  generateData(): any[] {
    let result: IpdfProductos[] = [];
    let i: number = 0;

    this.productosFilter.forEach((p, i) => {
        result.push( { 
                      No: (i+1), 
                      Fecha: moment(p.fechaAplicacion).format('L'), 
                      NoCX: p.fk_casoClinico ? p.casoclinico.folio : '',
                      Folio: p.fk_casoClinico ? p.casoclinico.serie : '',
                      Producto: p.nomProducto,
                      Lote: p.lote,
                      Hospital: p.fk_casoClinico ? p.casoclinico.hospital: '',
                      Institucion: p.fk_casoClinico ? p.casoclinico.cveInstitucion: '',
                      Codigo: p.codigo,                      
                      Enfermera: p.fk_enfermera ? p.enfermera.nombre + ' ' + p.enfermera.primerApellido: '',
                      Medico: p.fk_medico ? p.medico.nombre + ' ' + p.medico.primerApellido: '',                      
                    });
    });

  return result;
  }

  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let nombreSupervisor: string = this.supervisor.nombre + ' ' + this.supervisor.primerApellido + (this.supervisor.primerApellido ? ' ' + this.supervisor.primerApellido: ''); 
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;

     doc.setFontSize(18); 
     doc.text("PRODUCTOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombreSupervisor, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();

     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       columnStyles: { Batch: { halign: 'center' } },
       body: rows,
       columns: [
         { header: 'No.', dataKey: 'No' },
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'No. CX', dataKey: 'NoCX' },
         { header: 'Folio', dataKey: 'Folio' },          
         { header: 'Producto', dataKey: 'Producto' },
         { header: 'Lote', dataKey: 'Lote' }, 
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Institucion', dataKey: 'Institucion' },        
         { header: 'Codigo', dataKey: 'Codigo' },
         { header: 'Medico', dataKey: 'Medico' },
         { header: 'Enfermera', dataKey: 'Enfermera' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
     
     
     doc.save(`${new Date().toISOString()}_sdo.pdf`);
  }

  exportTablaExcel(): void {
    let nombreSupervisor = [{campo: "Supervisor", nomSupervisor: this.supervisor.nombre + ' ' + this.supervisor.primerApellido + (this.supervisor.primerApellido ? ' ' + this.supervisor.primerApellido: '')}];
    let tProductos: ItbProductos[] = this.productosFilter.map((p, i) => {
      return <ItbProductos> { 
                              No:(i+1),
                              Fecha: moment(p.fechaAplicacion).format('L'),
                              NoCX: p.fk_casoClinico ? p.casoclinico.folio : '',
                              Folio: p.fk_casoClinico ? p.casoclinico.serie : '',  
                              Producto: p.nomProducto,
                              Lote: p.lote,
                              Hospital: p.fk_casoClinico ? p.casoclinico.hospital: '', 
                              Institucion: p.fk_casoClinico ? p.casoclinico.cveInstitucion: '',
                              Codigo: p.codigo,
                              Puntos: p.m_puntos,
                              Enfermera: p.fk_enfermera ? p.enfermera.nombre + ' ' + p.enfermera.primerApellido : '',
                              Medico: p.fk_medico ? p.medico.nombre + ' ' + p.medico.primerApellido: ''};
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreSupervisor, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tProductos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Supervisor_Productos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}s_productos.xlsx`);
  }

}
