import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Estado } from 'src/app/moddelo/estado';
import { Hospital } from 'src/app/moddelo/hospital';
import { Institucion } from 'src/app/moddelo/institucion';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';


@Component({
  selector: 'app-hospital-otro',
  templateUrl: './hospital-otro.component.html',
  styleUrls: ['./hospital-otro.component.css']
})
export class HospitalOtroComponent implements OnInit {
  public id: number = 0;
  public hospital: Hospital = new Hospital();
  private instituciones: Institucion[] = [];
  private estados: Estado[] = [];
  public casoClinico: CasoClinico = new CasoClinico();

  private hospitalFrm = new FormGroup({
                                      nomHospital: new FormControl('', Validators.required),
                                      direccion: new FormControl(''),
                                      telefono: new FormControl(''),
                                      fk_estado: new FormControl('', Validators.required),
                                      fk_institucion: new FormControl('', Validators.required)
                                    });

  get nomHospitalCtrl(){ return this.hospitalFrm.get('nomHospital');}
  get fk_institucionCtrl(){ return this.hospitalFrm.get('fk_institucion');} 
  get fk_estadoCtrl(){ return this.hospitalFrm.get('fk_estado');}
  get direccionCtrl(){ return this.hospitalFrm.get('direccion');}
  get telefonoCtrl(){ return this.hospitalFrm.get('telefono');}       

  constructor(
              private location: Location, 
              private activated: ActivatedRoute, 
              private casosClinicosSrv: CasosClinicosService,
              private catalogosSrv: CatalogosService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.id = Number(this.activated.snapshot.paramMap.get('id'));
    
    this.casosClinicosSrv
    .getCasoClinico(this.id)
    .subscribe(data => { 
                        this.casoClinico = data;

                        let nomHospital = this.casoClinico.hospital;
                        let fk_estado = this.casoClinico.fk_estado;
                        let fk_institucion = this.casoClinico.fk_institucion;
                        let telefono = "";
                        let direccion = "";
                        
                        this.hospitalFrm.setValue({ nomHospital, fk_institucion, fk_estado, telefono, direccion}); 
    
                      }, err => { console.log(err)});

    this.catalogosSrv
    .getInstituciones()
    .subscribe(data => {this.instituciones = data;}, err => { console.log(err);});

    this.catalogosSrv
    .getEstados()
    .subscribe(data => {this.estados = data;}, err => { console.log(err); });
  }
  onSubmit(){
    //console.log(this.hospitalFrm.value);
    this.guardar();
  }
  guardar(): void{ 

    this.hospital.nomHospital = this.nomHospitalCtrl.value;
    this.hospital.fk_institucion = this.fk_institucionCtrl.value;
    this.hospital.fk_estado = this.fk_estadoCtrl.value;
    this.hospital.direccion = this.direccionCtrl.value;
    this.hospital.telefono = this.telefonoCtrl.value;

    this.catalogosSrv
    .setHospitalOtro(this.hospital, this.casoClinico)
    .subscribe(data => {
                        this.hospital.id = data.id;

                        this.snackBar.open('Hospital registrado', 'Hospital', {
                        duration: 2000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                        });
       this.goBack();
    }, err => { console.log(err); });
  }
  goBack(): void{
    this.location.back();
  }

}
