import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort, PageEvent } from '@angular/material';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';
import moment  from 'moment/moment';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ProductosTecnicoHospital } from 'src/app/moddelo/productos-tecnico-hospital';
import { ProductosService } from 'src/app/servicios/productos.service';
import { ProductosCategorizacion } from 'src/app/moddelo/productos-categorizacion';
import { CategoriaHopital } from 'src/app/moddelo/categoria-hospital';
import { CategoriaInstitucion } from 'src/app/moddelo/CategoriaInstitucion';
import { CategoriaParteCuerpo } from 'src/app/moddelo/categoria-parte-cuerpo';
@Component({
  selector: 'app-administrador-metrica-dos-cuatro',
  templateUrl: './administrador-metrica-dos-cuatro.component.html',
  styleUrls: ['./administrador-metrica-dos-cuatro.component.css']
})
export class AdministradorMetricaDosCuatroComponent implements OnInit {
  public productoscategorizacion: ProductosCategorizacion[] = [];
  public productoscategorizacionFilter: ProductosCategorizacion[] = [];

  public categoriaxhospital: CategoriaHopital[]=[];
  public categoriaxinstitucion: CategoriaInstitucion[]=[];
  public categoriaxcuerpo: CategoriaParteCuerpo[]=[];

  public productosenfermerahospitales: ProductosTecnicoHospital[] = [];
  public productosenfermerahospitalesFilter: ProductosTecnicoHospital[] = [];

  public cargar: boolean = false;
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
                                                fechaInicial: new FormControl(''),
                                                fechaFinal: new FormControl('')
                                              });
  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }
  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }  
  constructor(private location: Location, private productossrv: ProductosService) { }

  ngOnInit() {
    moment.locale('es-MX');

    this.productossrv
    .getProductosCategorizados()
    .pipe(finalize( () => this.cargar = false))
    .subscribe(data => {
        this.productoscategorizacion = data;
        this.productoscategorizacionFilter = this.productoscategorizacion;        
    }, err => { console.log(err); });

    this.productossrv
    .getProductosCategorizadosHospital()
    .subscribe(data => {
        this.categoriaxhospital = data;      
    }, err => { console.log(err); });

    this.productossrv
    .getProductosCategorizadosParteCuerpo()
    .subscribe(data => {
        this.categoriaxcuerpo = data;      
    }, err => { console.log(err); });
  }
  onSubmit() {
    if(moment(this.fechaInicialCtrl.value).isValid()) {
        
      if(moment(this.fechaFinalCtrl.value).isValid()) {

        if(moment(this.fechaInicialCtrl.value) <= moment(this.fechaFinalCtrl.value)) {
           this.cargar = true;
        }else {
          alert('El rango de fechas no esta bien definido')
        }            

      }else {
        alert('La fecha final no es valida')
      }

    } else {
      alert('La fecha inicial no es valida')
    }   
   
  }
  handlePage( e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
  sortTecnicosHospitales(sort: Sort) {
    const datos: ProductosCategorizacion[] = this.productoscategorizacionFilter.slice();
    if(!sort.active || sort.direction === ''){
      this.productoscategorizacionFilter = datos;
      return;
    }
    this.productoscategorizacionFilter =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'categoria':
          return this.compare(a.categoria, b.categoria, isAsc);
        case 'noProducto':
          return this.compare(a.noProductos, b.noProductos, isAsc);               
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  exportTablaExcel(): void {
    let nombreMedico = [{campo: "Producto", productos: "Categorizados" }];
    let tProductos: ProductosCategorizacion[] = this.productoscategorizacionFilter; 
   

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreMedico, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tProductos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'categorias');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}categorias.xlsx`);
  }
  generateData(): any[] {
    let result = [];
    let i: number = 0;

    this.productoscategorizacionFilter.forEach((p, i) => {
        
        result.push(Object.assign( {}, 
        { 
          No: p.id, 
          Categoria: p.categoria,
          NoProductos: p.noProductos
        }));

    });

  return result;
  }
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let producto: string = "Categorizados";  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("Productos",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(producto, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [ 
                { header: 'NO.', dataKey: 'No' }, 
                { header: 'Categoria', dataKey: 'Categoria' },
                { header: 'No. Productos', dataKey: 'NoProductos' },        
               ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
         doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_productos_categorizados.pdf`);
  }
  exportCatagoriaInstitucionExcel(): void {
    let nombreMedico = [{campo: "Producto", productos: "Categorizados x Institucion" }];
    let tProductos: CategoriaInstitucion[] = this.categoriaxinstitucion; 
   

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreMedico, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tProductos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'categorias');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}categorias-institucion.xlsx`);
  }
  exportCatagoriaHospitalExcel(): void {
    let nombreMedico = [{campo: "Producto", productos: "Categorizados x Hospital" }];
    let tProductos: CategoriaHopital[] = this.categoriaxhospital; 
   

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreMedico, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tProductos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'categorias');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}categorias-hospital.xlsx`);
  }
  exportCatagoriaParteCuerpoExcel(): void {
    let nombreMedico = [{campo: "Producto", productos: "Categorizados x Parte de Cuerpo" }];
    let tProductos: CategoriaParteCuerpo[] = this.categoriaxcuerpo; 
   

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreMedico, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tProductos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'categorias');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}categorias-cuerpo.xlsx`);
  }
  goBack(): void{
    this.location.back();
  }
}
