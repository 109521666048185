import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { AuthService } from 'src/app/servicios/auth.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { Usuario } from 'src/app/moddelo/usuario';

@Component({
    selector: 'dialogo-cambiar-passw',
    templateUrl: 'dialogo-cambiar-passw.html',
    styles: ['.textRed { color: red; }']    
})
export class DialogoCambiarPassw{

    public passwordFrm: FormGroup = new FormGroup({
        password_nuevo: new FormControl('', Validators.required),
        password_confirmar: new FormControl('', Validators.required)
    });
    get password_nuevoCtrl(){
        return this.passwordFrm.get('password_nuevo');
    }
    get password_confirmarCtrl(){
        return this.passwordFrm.get('password_confirmar');
    }
    get comparacion(){
        let  mensaje: string = "";
        if(this.password_confirmarCtrl.value){
            if(this.password_nuevoCtrl.value !== this.password_confirmarCtrl.value){
                mensaje = "contraseña no coincide";
            };
        }
        return mensaje;
    }
    constructor(
        private usuariosSrv: UsuariosService,
        private auth: AuthService,
        private snackBar: MatSnackBar,
        public dialogo: MatDialogRef<DialogoCambiarPassw>, 
        @Inject(MAT_DIALOG_DATA) public data: String
         ){}

    onSubmit(){
        let usuario: Usuario = this.auth.getUsuario;
        usuario.password = this.password_nuevoCtrl.value;

        this.usuariosSrv
        .setUsuarioPasswordUpdate(usuario.id, usuario)
        .subscribe(data => {

            if(data){
                        this.snackBar.open("Contraseña actualizada", "Usuario", {
                            duration: 3000,
                            verticalPosition:'bottom',
                            horizontalPosition:'center'
                        });
            }
           
        }, err => { console.log(err)})
       
    }
    onClose(){
        this.dialogo.close(); 
    }    
}
