import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatTableDataSource, MatSort, Sort, MatPaginator } from '@angular/material';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Producto } from 'src/app/moddelo/producto';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';
import { DialogoMedicoFicha } from '../../dialogos/dialogo-medico-ficha/dialogo-medico-ficha';
import { finalize } from 'rxjs/operators';
import { Usuario } from 'src/app/moddelo/usuario';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';
import { Medico } from 'src/app/moddelo/medico';

interface IMedico{
  id: number;
  ingreso: Date;
  nomMedico: string;
  productos: number;
  casosclinicos: number;
  canjes: number;
  enfermeras: number;
  puntos: number;
  saldo: number;
  activo: number;
  solicitudes: number;
  asociaciones: number;   
}
interface ItbMedico{
  no: number;
  ingreso: string;
  nomMedico: string;
  productos: number;
  casosclinicos: number;
  canjes: number;
  enfermeras: number;
  puntos: number;
  saldo: number;  
}
interface ItbMedicosPerfil{
  id: number;
  ingreso: String;
  nomMedico: string;
  correo: string;
  movil: string;
  especialidad: string;   
}

@Component({
  selector: 'app-administrador-medicos',
  templateUrl: './administrador-medicos.component.html',
  styleUrls: ['./administrador-medicos.component.css']
})
export class AdministradorMedicosComponent implements OnInit, AfterViewInit{
  public medicos: Medico[] = [];
  public medicosAny: IMedico[];
  public medicosPerfil: ItbMedicosPerfil[];
  public dataSourceMedicos: MatTableDataSource<IMedico>  = new MatTableDataSource<IMedico>();
  public displayedColumns: String[] = ['nomMedico', 'casosclinicos', 'productos', 'canjes','enfermeras', 'puntos', 'saldo', 'solicitudes', 'asociaciones'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public cargar: boolean = true;
  
  public filtroFrm: FormGroup = new FormGroup({
    nomMedico: new FormControl('')
  })

  
  get usuario(){
    return this.aut.getUsuario;
  }

  get offLinkMedico(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }
  get offPuntos(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 3;
  }
  get offEnfermeras(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 3;
  }
  get offCanjes(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 3;
  }
  get offCasosClinicos(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }
  get offPws(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }

  constructor(
              private aut: AuthService,
              private _live: LiveAnnouncer, 
              private adminSrv: AdministradorService,
              private dialogo: MatDialog) { }
 
  ngAfterViewInit(): void{
    this.dataSourceMedicos.sort = this.sort;
    this.dataSourceMedicos.paginator = this.paginator;
  }  
  ngOnInit() {
          this.adminSrv.card = 2;
          
          this.adminSrv
          .getMedicos().pipe(finalize( () => this.cargar = false))
          .subscribe(data => { 
            this.adminSrv.medicos = data;
            this.medicos = this.adminSrv.medicos;
            this.medicosAny = this.adminSrv.medicos.map(medico => { 
              return <IMedico> { 
                  id: medico.id, 
                  ingreso: medico.created_at,
                  nomMedico: (medico.nombre.trim() + ' ' +  medico.primerApellido.trim() + ( medico.segundoApellido ? ' '+ medico.segundoApellido.trim():'')),  
                  productos: medico.productos.length, 
                  casosclinicos: medico.casosclinicos.length,
                  canjes: medico.redimirmedico.length, 
                  enfermeras: medico.enfermeras.length, 
                  puntos: this.puntos(medico.productos),
                  saldo: this.saldos(medico.productos, medico.redimirmedico),
                  activo: medico.usuario.activo,
                  solicitudes: this.solicitudes(medico.redimirmedico),
                  asociaciones: this.asociaciones(medico.casosclinicos)
                 }
              });

              this.medicosPerfil = this.adminSrv.medicos.map(medico => { 
                return <ItbMedicosPerfil> { 
                                            id: medico.id,   
                                            ingreso: medico.created_at ? moment( medico.created_at).format('L') : '',
                                            nomMedico: (medico.nombre.trim() + ' ' +  medico.primerApellido.trim() + ( medico.segundoApellido ? ' '+ medico.segundoApellido.trim():'')),  
                                            correo: medico.correo, 
                                            movil: medico.telefonoMovil,
                                            especialidad: medico.nomEspecialidad 
                                          }
                });  
            this.dataSourceMedicos.data = this.medicosAny.sort((a, b) => {
              return  (b.solicitudes + b.asociaciones) - (a.solicitudes + a.asociaciones) 
            }); 
            
            this.dataSourceMedicos.filterPredicate = (data: IMedico, filter: string): boolean => {
              const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
                return (currentTerm + (data as { [key: string]: any })[key] + '◬');
              }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        
              const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        
              return dataStr.indexOf(transformedFilter) != -1;
            }
         
          }, err => { console.log(err); });
                
  }
  announceSortChange(sortState: Sort){
    if(sortState.direction){
      this._live.announce(`Sorted ${sortState.direction}ending`);
    }else{
      this._live.announce('Shorting cleared');
    }
  }

  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceMedicos.filter = this.normalize(filterValue.toLowerCase());

    if(this.dataSourceMedicos.paginator){
      this.dataSourceMedicos.paginator.firstPage();
    }
  }
  normalize(caracteres: string): string{
    let datos: string = caracteres.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
    return datos;
  }
  onSubmit(){
  }
 
  solicitudes(canjes: RedimirMedico[]): number{
      return canjes.filter(canje => canje.status == 1).length;
  }
  asociaciones(casosClinicos: CasoClinico[]): number{     
      return casosClinicos.filter(cc => cc.fk_enfermera == null).length;
  }   
 
  puntos(productos: Producto[]): number{
      return productos.reduce((valor, producto) => producto.m_puntos + valor, 0);
  }
  saldos(productos: Producto[], canjes: RedimirMedico[]): number{
      return productos.reduce((valor, producto) => producto.m_puntos + valor, 0) - canjes.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
  }
  ficha(medico: Medico): void{
      this.dialogo.open(DialogoMedicoFicha, {data: medico, width: '400px' });
  }

  isInactivo(medico: Medico): boolean{
      return medico.productos.length == 0 && medico.casosclinicos.length == 0 && medico.enfermeras.length == 0 && medico.redimirmedico.length == 0;
  }
  generateData(): any[] {
    let result: ItbMedico[] = [];
    let i: number = 0;
    if(!this.dataSourceMedicos.filter){
      this.dataSourceMedicos._orderData(this.dataSourceMedicos.data).forEach((m, i) => {
        
        result.push( {
                      no: (i+1),
                      ingreso: m.ingreso ? moment( m.ingreso).format('L') : '',
                      nomMedico: m.nomMedico,
                      productos: m.productos,
                      casosclinicos: m.casosclinicos,
                      canjes: m.canjes,
                      enfermeras: m.enfermeras,
                      puntos: m.puntos,
                      saldo: m.saldo
                    });
    });
    } 
    else{
      this.dataSourceMedicos._filterData(this.dataSourceMedicos.data).forEach((m, i) => {
        
        result.push( {
                      no: (i+1),
                      ingreso: m.ingreso ? moment( m.ingreso).format('L') : '',
                      nomMedico: m.nomMedico,
                      productos: m.productos,
                      casosclinicos: m.casosclinicos,
                      canjes: m.canjes,
                      enfermeras: m.enfermeras,
                      puntos: m.puntos,
                      saldo: m.saldo
                    });
    });
    } 
    

  return result;
  }
  exportTablaPerfilExcel(){
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.medicosPerfil);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Medicos ' + moment(Date.now()).format('DD-MM-YYYY'));    
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_medicosPerfiles.xlsx`);
  }
  exportTablaExcel(){
    let tMedicos: ItbMedico[] = this.dataSourceMedicos.filteredData.map((m, i, medicos) => {
      return <ItbMedico> {    no: (i+1),
                              ingreso: m.ingreso ? moment( m.ingreso).format('L') : '',
                              nomMedico: m.nomMedico,
                              productos: m.productos,
                              casosclinicos: m.casosclinicos,
                              canjes: m.canjes,
                              enfermeras: m.enfermeras,
                              puntos: m.puntos,
                              saldo: m.saldo };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tMedicos);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Medicos ' + moment(Date.now()).format('DD-MM-YYYY'));    
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_medicosg.xlsx`);
  }
  exportTablaPDF():  void {
    const doc = new jsPDF('landscape');  
     
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     
     doc.text(" DATOS GENERALES DE MEDICOS ",  145, 10, { align: "center"});
    
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       columnStyles: { no: { halign: 'right' }, productos: { halign: 'right' },  casosclinicos: { halign: 'right'}, canjes:{ halign: 'right'}, enfermeras: {halign: 'right'},  puntos: {halign: 'right'}, saldo: {halign: 'right'} },
       body: rows,
       columns: [
         { header: 'No', dataKey: 'no' },
         { header: 'Fecha Ingreso', dataKey: 'ingreso' },
         { header: 'Medico', dataKey: 'nomMedico' },
         { header: 'No Productos', dataKey: 'productos' },
         { header: 'No. Casos Clinicos', dataKey: 'casosclinicos' },
         { header: 'No. Canjes', dataKey: 'canjes' },
         { header: 'No. Enfermeras', dataKey: 'enfermeras' },
         { header: 'Puntos', dataKey: 'puntos' },
         { header: 'Saldo', dataKey: 'saldo' }, 
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
         doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_medicosg.pdf`);
  }

}
