import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
@Component({
  selector: 'app-hospitales-otros',
  templateUrl: './hospitales-otros.component.html',
  styleUrls: ['./hospitales-otros.component.css']
})
export class HospitalesOtrosComponent implements OnInit {
  public casosClinicos: CasoClinico[] = [];

  get usuario(){
    return this.auth.getUsuario;
  }
  get offLink(){
    return this.usuario.fk_nivel == 2 || this.usuario.fk_nivel == 0;
  }
  
  constructor(
    private auth: AuthService,
    private location: Location, 
    private catalogosSrv: CatalogosService
    ) { }

  ngOnInit() {
    this.catalogosSrv
    .getHospitalesOtros()
    .subscribe(data => { this.casosClinicos = data; }, err => { console.log(err); });
  }
  goBack(): void{
    this.location.back();
  }

}
