import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material';
import { PaginarPipe } from 'src/app/pipes/paginar.pipe';
import { CustomMatPaginatorIntl } from 'src/app/pipes/paginar-es';

@NgModule({
    declarations: [ PaginarPipe],
    imports: [
      CommonModule
    ],
    exports:[ PaginarPipe],
    providers:[
      {
        provide: MatPaginatorIntl, 
        useClass: CustomMatPaginatorIntl
      }
    ]
  })
  export class PaginacionModule { }