import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent, Sort } from '@angular/material';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';
import moment  from 'moment/moment';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ProductosService } from 'src/app/servicios/productos.service';
import { PorProducto } from 'src/app/moddelo/por-producto';
import { DetallePorProducto } from 'src/app/moddelo/detalle-por-producto';

@Component({
  selector: 'app-administrador-metrica-cinco-dos',
  templateUrl: './administrador-metrica-cinco-dos.component.html',
  styleUrls: ['./administrador-metrica-cinco-dos.component.css']
})
export class AdministradorMetricaCincoDosComponent implements OnInit {
  public porProductos: PorProducto[] = [];
  public porProductosFilter: PorProducto[] = [];
  public cargar: boolean = false;
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  public filtroFrm: FormGroup = new FormGroup({
          fechaInicial: new FormControl(''),
          fechaFinal: new FormControl('')
  });

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }
  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }
    constructor(private location: Location, public productoservice: ProductosService) { }

  ngOnInit() {
    moment.locale('es-MX');
  }
  onSubmit() { 
    if(moment(this.fechaInicialCtrl.value).isValid()) {
        
      if(moment(this.fechaFinalCtrl.value).isValid()) {

        if(moment(this.fechaInicialCtrl.value) <= moment(this.fechaFinalCtrl.value)) {
           
            this.cargar = true;
            this.productoservice
            .getPorProducto(this.fechaInicialCtrl.value, this.fechaFinalCtrl.value)
            .pipe(finalize( () => this.cargar = false))
            .subscribe(data => {
                this.porProductos = data;
                this.porProductosFilter = this.porProductos;                
            }, err => { console.log(err); });

        } else {
          alert('El rango de fechas no esta bien definido')
        }            

      }else {
        alert('La fecha final no es valida')
      }

    }else {
      alert('La fecha inicial no es valida')
    }    
  }
  exportTablaExcel(): void {  
    let finicial=moment(this.fechaInicialCtrl.value).format('L');
    let ffinal=moment(this.fechaFinalCtrl.value).format('L');
    let Heading = [
      [`Cantidad de producto utilizado en el periodo ${finicial} al ${ffinal}`,""]
    ]
    let rows: PorProducto[] = this.porProductosFilter.map((p, i) =>({       
       nomProducto: p.nomProducto,
       cantidad: p.cantidad
    }));

    /* generate workbook and add the worksheet */

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: false });     
    XLSX.utils.book_append_sheet(wb, ws, 'PorProducto');

    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_PorProducto.xlsx`);
  }
  exportExcel(detallePorProducto: DetallePorProducto[], fechaInico: string, fechaFinal: string): void{
    
    let Heading = [
      [`Detalles de productos utilizados en el periodo ${fechaInico} al ${fechaFinal}`]
    ]
    

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, detallePorProducto, { origin: 'A2', skipHeader: false });     
    XLSX.utils.book_append_sheet(wb, ws, 'DetallesPorProducto');

    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_DetallesPorProducto.xlsx`);
  }
  detallesExcel(): void {
    let finicial=moment(this.fechaInicialCtrl.value).format('L');
    let ffinal=moment(this.fechaFinalCtrl.value).format('L');

    if(moment(this.fechaInicialCtrl.value).isValid()) {
        
      if(moment(this.fechaFinalCtrl.value).isValid()) {

        if(moment(this.fechaInicialCtrl.value) <= moment(this.fechaFinalCtrl.value)) {

            this.productoservice.getDetallesPorProducto(this.fechaInicialCtrl.value, this.fechaFinalCtrl.value)
            .subscribe(data => {
              this.exportExcel(data, finicial, ffinal);
            }, error => {console.log(error)});

          } else {
            alert('El rango de fechas no esta bien definido')
          }            
  
        }else {
          alert('La fecha final no es valida')
        }
  
      }else {
        alert('La fecha inicial no es valida')
      }     
  } 
  generateData(): any[] {
    let result = [];
    let i: number = 0;

    this.porProductosFilter.forEach((p, i) => {
        
        result.push(Object.assign( {}, 
        { 
          No: (i+1).toString(),             
          producto: p.nomProducto,
          cantidad: p.cantidad
        }));

    });

  return result;
  }
  exportTablaPDF(): void{  
    const doc = new jsPDF('landscape');  
    let periodo: string = 'Periodo: ' + moment(this.fechaInicialCtrl.value).format('L') + '  a  ' + moment(this.fechaFinalCtrl.value).format('L');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("Cantidad de producto utilizado en el periodo",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(periodo, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Producto', dataKey: 'producto' },
         { header: 'Cantidad', dataKey: 'cantidad' }     
       ],       
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_porProducto.pdf`);  
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
  goBack() {
    this.location.back();
  }
}
