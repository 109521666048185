import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { DialogoEnfermeraPuntosDefault } from '../../dialogos/dialogo-enfermera-puntos-default/dialogo-enfermera-puntos-default';

interface ICasoClinico {
  id: number;
  fecha: Date;
  noCx: string;
  paciente: string;
  fk_hostipital: number;
  nomHospital: string;
  fk_diagnostico: number;
  nomDiagnostico: string;
  fk_medico: number
  nomMedico: string;
  fk_estado: number;
  nomEstado:string;
  noProductos: string;  
}
@Component({
  selector: 'app-administrador-enfermera-main',
  templateUrl: './administrador-enfermera-main.component.html',
  styleUrls: ['./administrador-enfermera-main.component.css']
})
export class AdministradorEnfermeraMainComponent implements OnInit {
  public id: string;
  public AccesoCasoClinico: number = 0;
  public AccesoCanje: number = 0;
  public enfermera: Enfermera = new Enfermera();
  public canje: RedimirEnfermera = new RedimirEnfermera();
  public casoclinico: CasoClinico = new CasoClinico(); 

  @ViewChild(MatAccordion, {static: true}) accordion: MatAccordion;
  
  get puntos(): number{
    return this.enfermera ? this.enfermera.casosclinicos.reduce((valor, casoclinico) => casoclinico.e_puntos + valor, 0) : 0;
    //return this.enfermera ? this.enfermera.productos.reduce((valor, producto) => producto.e_puntos + valor, 0) : 0;
  }
  get Authorizacion(){
    return this.auth.getUsuario;
  }
  get offLink(){    
    return (this.Authorizacion.fk_nivel == 3 || this.Authorizacion.fk_nivel == 0);
  }
  get saldo (): number{
     //const puntos = this.enfermera ? this.enfermera.casosclinicos.reduce((valor, casoclinico) => casoclinico.e_puntos + valor, 0) : 0; 
     //this.enfermera ? this.enfermera.productos.reduce((valor, producto) => producto.e_puntos + valor, 0) : 0;    
     //const canjes = this.enfermera ? this.enfermera.redimirenfermera.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0): 0; 
     const saldo = this.enfermera ? this.enfermera.casosclinicos.filter(cc => cc.status_canje == 0).reduce((valor, cc ) => cc.e_puntos + valor, 0): 0; 
    return saldo;
  }
  constructor(
              private activatedRoute: ActivatedRoute, 
              private enfermeraServicio: EnfermerasService,
              private auth: AuthService, 
              private location: Location,
              private dialogo: MatDialog
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.enfermeraServicio
    .getEnfermera(this.id)
    .subscribe(data => { 
                this.enfermera = data; 
              }, 
              err => { 
                console.log(err); 
              }
              );
  }
  onCancelarFicha(e: any): void {
    this.AccesoCasoClinico = 0;
  }
  vistaCasoClinico(cc: CasoClinico): void {
    console.log("cc ", cc)
    this.AccesoCasoClinico = 1;
    this.casoclinico = cc;
   
  }
  onSolicitud(canje: RedimirEnfermera): void {
    this.canje = canje;
    this.AccesoCanje = 1;    
  }
  onDelete(canje: RedimirEnfermera): void{
    this.canje = canje;
    this.AccesoCanje = 2;    
  }
  onNuevo(): void{
    this.AccesoCanje = 3;
  }
  onReferencia(canje: RedimirEnfermera): void{
    this.canje = canje;
    this.AccesoCanje = 4;
  }
  onDetalle(canje: RedimirEnfermera): void{
    this.canje = canje;
    this.AccesoCanje = 5;
  }
  onCancelarCanje(e: any):void {
    this.AccesoCanje = 0;
  } 
  puntosDefault(): void{
    this.dialogo.open(DialogoEnfermeraPuntosDefault, {width: '800px', data: this.enfermera});
  }
  goBack(){
    this.location.back();
  }
}
