import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent, Sort, MatSnackBar, MatDialog } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { DialogoAsignarEnfermeraCasoclinicoPuntos } from '../../dialogos/dialogo-asignar-enfermera-casoclinico-puntos/dialogo-asignar-enfermera-casosclinico-puntos';

import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Medico } from 'src/app/moddelo/medico';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';
import { Institucion } from 'src/app/moddelo/institucion';
import { Hospital } from 'src/app/moddelo/hospital';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';


interface ItbCasosClinicos{
          Fecha: string;
          Folio: string;
          Paciente: string;
          Diagnostico: string;
          Cuerpo: string;
          Hospital: string;
          Institucion: string;
          Estado: string;
          Medico: string;
          Productos: number;
        }

@Component({
  selector: 'app-administrador-enfermera-r-casos-clinicos',
  templateUrl: './administrador-enfermera-r-casos-clinicos.component.html',
  styleUrls: ['./administrador-enfermera-r-casos-clinicos.component.css']
})
export class AdministradorEnfermeraRCasosClinicosComponent implements OnInit {
  @Input('enfermera') enfermera: Enfermera;
  @Output() vistaCasoClinico: EventEmitter<CasoClinico> = new EventEmitter();

  public canjecat: String[] = ["registrado", "solicitud", "en proceso", "pagado", "cancelado"];

  public casosClinicos: CasoClinico[] = [];
  public hospitales: Hospital[] = [];
  public filtroFrm: FormGroup;

  public instituciones: Institucion[] = [];
  public filtrosInstituciones: Institucion[] = []; 

  public filtrosHospitales: Hospital[]=[];
  public nombresHospitales: string[] = [];

  public filtroMedicos: Observable<String[]>;
  public nombresMedicos: String[] = [];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(    
    private aut: AuthService,
    private formBuilder: FormBuilder,
    private matSnakBar: MatSnackBar,
    private dialogo: MatDialog,
    private enfermerasSrv: EnfermerasService,    
    private adminSrv: AdministradorService) { }
  
  get url(): string {    
      return window.location.origin.replace(':4200', '');
  }

  get usuario(){
    return this.aut.getUsuario;
  }
  get offLink(){    
    return (this.usuario.fk_nivel == 3 || this.usuario.fk_nivel == 0);
  }
  get offCasosClinicos(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.filtroFrm.get('folio');
  }

  get pacienteCtrl(){
    return this.filtroFrm.get('paciente');
  }

  get fk_hospitalCtrl(){
    return this.filtroFrm.get('fk_hospital');
  }

  get fk_institucionCtrl(){
    return this.filtroFrm.get('fk_institucion');
  }  

  get medicoCtrl(){
    return this.filtroFrm.get('medico');
  }

  ngOnInit() {
   
    this.casosClinicos = this.enfermera.casosclinicos.sort( (a, b) => { let a_fecha = new Date(a.fecha); let b_fecha = new Date(b.fecha); return b_fecha.getTime() - a_fecha.getTime()} );
   
    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial:[''],
                                            fechaFinal:[''],
                                            folio:[''],
                                            paciente:[''],
                                            fk_hospital: [''],
                                            medico:[''],                                               
                                            fk_institucion:['']
                                          });

    this.nombresMedicos = this.adminSrv.medicos.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) ); 
    this.filtroMedicos = this.medicoCtrl.valueChanges.pipe( startWith(''), map(value => this._filter(value)) ); 
    
    this.hospitales = this.adminSrv.hospitales;
   
    this.instituciones = this.adminSrv.instituciones;
    this.filtrosInstituciones = this.adminSrv.instituciones;

  }
  onSubmit() {
    this.casosClinicos = this.enfermera
    .casosclinicos
    .filter( cc => 
            this.filterPaciente(cc.paciente) && 
            this.filterHospital(cc.fk_hospital) && 
            this.filterInstitucion(cc.fk_institucion) && 
                // this.filterFolio(cc.folio) && 
            this.filterSerie(cc.serie) && 
            this.filterMedico(cc.fk_medico) && 
            this.filterFecha(cc.fecha.toString())
            );
    this.page_number = 1;
  }
  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }
  filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  } 
  filterMedico(fk_medico: number): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: String = "";
 
    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(fk_medico){
          nomMedico = this.getMedico(fk_medico.toString());
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );
          console.log(" nomEnf:", this._normalizeValue(nomMedico), "Nom:", nom, " Bolean:", this._normalizeValue(nomMedico).includes(nom) );
        }
    }
    
    return condicion;
  }
   /*
  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(nombre){
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }   
    
    return condicion;
  }
  filterInstitucion(cveInstitucion: string): boolean{
    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let cve: string;

    if( this.institucionCtrl.value != 0 ){
        i = this.institucionCtrl.value;
        institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
        cve = cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(cve){
        condicion = cve.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  */
  filterInstitucion(fk_institucion: number): boolean {
    
    let condicion: boolean = true;  
    //console.log("fk_institucion: ",this.fk_institucionCtrl.value);
    if( this.fk_institucionCtrl.value ){
      let id_inst: number = this.fk_institucionCtrl.value;       
      if(fk_institucion) {      
        condicion = id_inst == fk_institucion;      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterHospital(fk_hospital: number): boolean{
    let condicion: boolean = true;  

    if( this.fk_hospitalCtrl.value ){
      let id_hospital: number = this.fk_hospitalCtrl.value;       
      if(fk_hospital){      
        condicion = id_hospital == fk_hospital;      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterFolio(folio: string): boolean{
    let condicion: boolean = true;
      
      if(this.folioCtrl.value != ""){
       condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 
      console.log("formfolio: ", this.folioCtrl.value, " len: ", this.folioCtrl.value.toString().length, " folio: ", folio, " len:", folio.length, " condicion: ", condicion)
    return condicion; 
  }
  filterSerie(serie: string): boolean{
    let condicion: boolean = true;
      
      if(this.folioCtrl.value != ""){
       condicion = !( serie.indexOf(this.folioCtrl.value) == -1 );
      } 
    //  console.log("formfolio: ", this.folioCtrl.value, " len: ", this.folioCtrl.value.toString().length, " folio: ", folio, " len:", folio.length, " condicion: ", condicion)
    return condicion; 
  }
  getMedico(id: string): string {
    let medico: Medico;
    let nombre: string = "";
    if(id) {
      medico = this.adminSrv.medicos.find(e => e.id.toString() == id);
      nombre = medico ? (medico.nombre + ( medico.primerApellido ? ' ' + medico.primerApellido : '' ) + (medico.segundoApellido ? ' ' + medico.segundoApellido : '') ): '';
    }  
    return nombre;
  }
  sortEnfermeras(sort: Sort) {
    const datos: CasoClinico[] = this.casosClinicos.slice();
    if(!sort.active || sort.direction === ''){
      this.casosClinicos = datos;
      return;
    }
    this.casosClinicos =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fecha':
          let a1: number = new Date(a.fecha).getTime();
          let b1: number = new Date(b.fecha).getTime();
          return this.compare(a1, b1, isAsc);
        case 'folio':
          return this.compare(a.serie, b.serie, isAsc);
        case 'paciente':
          return this.compare(a.paciente, b.paciente, isAsc);
        case 'hospital':
          let hospitala: string = a.centromedico ? a.centromedico.nomHospital: '';
          let hospitalb: string = b.centromedico ? b.centromedico.nomHospital: '';
          return this.compare(hospitala, hospitalb, isAsc);
        case 'institucion':
          let instituciona: string = a.centromedico ? (a.centromedico.institucion?a.centromedico.institucion.cvInstitucion:''): '';
          let institucionb: string = b.centromedico ? (b.centromedico.institucion?b.centromedico.institucion.cvInstitucion:''): '';
          return this.compare(instituciona, institucionb, isAsc);
        case 'medico':
          return this.compare((a.fk_medico ? a.medico.nombre + ' ' +( a.medico.primerApellido ? a.medico.primerApellido: '') : ''), (b.fk_medico ? b.medico.nombre + ' ' +( b.medico.primerApellido ? b.medico.primerApellido : ''): ''), isAsc);
        case 'productos':
          let productosA: number = a.productos ? a.productos.length : 0;
          let productosB: number = b.productos ? b.productos.length : 0;
          return this.compare(productosA, productosB, isAsc);
        case 'servicio':
            let cuerpoa: string = a.fk_seccion_cuerpo ? a.seccioncuerpo.nomCuerpo: '';
            let cuerpob: string = b.fk_seccion_cuerpo ? b.seccioncuerpo.nomCuerpo: '';
            return this.compare(cuerpoa, cuerpob, isAsc);      
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  } 
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  _filter(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresMedicos.filter(medico => this._normalizeValue(medico).includes(valorFiltro));

  }
  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }
  exportProductividad(): void {
    
    if(this.fechaFinalCtrl.value && this.fechaInicialCtrl.value){
      
      let f1 = moment(this.fechaInicialCtrl.value).format("YYYY-MM-DD");
      let f2 = moment(this.fechaFinalCtrl.value).format("YYYY-MM-DD");
      
      if(moment(this.fechaFinalCtrl.value) >= moment(this.fechaInicialCtrl.value)){
        
        const datos: any =  {fecha1: f1 , fecha2: f2};   
        this.adminSrv
        .getProductividadEnfermera(this.enfermera.id.toString(), datos)
        .subscribe(data => {
          //   console.log("productividad ", data.ruta);
          window.open(this.url + data.ruta, "_blank");
        }, err => { console.log(err) }); 
        
        
      } else {
        this.matSnakBar.open(`La Fecha Final debe ser mayor a la Fecha Inicial`, "Caso Clinico", {
          duration: 2000,
          verticalPosition:'bottom',
          horizontalPosition:'center'
        });
      }
      
    } else  {
      this.matSnakBar.open(`Debe de tener informacion de fecha que sean validas`, "Caso Clinico", {
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });
    }
    
  }    
  exportTablaExcel(): void {
    let nombreEnfermera = [{campo: "Enfermera", nomEnfermera: this.enfermera.nombre + ' ' + this.enfermera.primerApellido + (this.enfermera.primerApellido ? ' ' + this.enfermera.primerApellido: '')}];
    let tCasosClinicos: ItbCasosClinicos[] = this.casosClinicos.map((cc, i) => {
      return <ItbCasosClinicos> { 
        Hospital: cc.centromedico ? cc.centromedico.nomHospital : '',
        Institucion: cc.centromedico ? (cc.centromedico.institucion ? cc.centromedico.institucion.cvInstitucion : '')  : '',
        Estado: cc.fk_estado ? cc.estado.nomEstado : '',  
        Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
        Folio: cc.serie,
        Cuerpo: cc.seccioncuerpo ? cc.seccioncuerpo.nomCuerpo: '',
        Paciente: cc.paciente,
        Diagnostico: cc.diagnostico,
        Status: this.canjecat[cc.status_canje],
        Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido: '',
        Productos: cc.productos ? cc.productos.length : 0 
      };
    });
    
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreEnfermera, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tCasosClinicos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'casos_clinicos ' + moment(Date.now()).format('DD-MM-YYYY') );
    
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}enfermeracasosclinicos.xlsx`);
  }
  generateData(): any[] {
    let result: any[] = this.casosClinicos.map((cc, i) =>      
                 ( {                   
                    Institucion: cc.centromedico ? (cc.centromedico.institucion ? cc.centromedico.institucion.cvInstitucion : '')  : '',
                    Hospital: cc.centromedico ? cc.centromedico.nomHospital : '',
                    Estado: cc.fk_estado ? cc.estado.nomEstado : '',                      
                    Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                    Folio: cc.serie,
                    Cuerpo: cc.fk_seccion_cuerpo ? cc.seccioncuerpo.nomCuerpo: '',
                    Paciente: cc.paciente, 
                    Diagnostico: cc.diagnostico,
                    Status: this.canjecat[cc.status_canje],
                    Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido : '',
                    Productos: cc.productos ? cc.productos.length : 0 
                  })
  );

    return result;
  }
  exportTablaPDF(): void {
     const doc = new jsPDF('landscape');  
     let nombresEnfermera: string = this.enfermera.nombre + ' ' + this.enfermera.primerApellido + (this.enfermera.primerApellido ? ' ' + this.enfermera.primerApellido: '');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("CASOS CLINICOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombresEnfermera, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'Institucion', dataKey: 'Institucion' },
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Estado', dataKey: 'Estado' },           
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'Folio', dataKey: 'Folio' },
         { header: 'Cuerpo', dataKey: 'Cuerpo'},
         { header: 'Paciente', dataKey: 'Paciente' },
         { header: 'Diagnostico', dataKey: 'Diagnostico' },
         { header: 'Status Canje', dataKey: 'Status'},
         { header: 'Medico', dataKey: 'Medico' },
         { header: 'Productos', dataKey: 'Productos' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
         doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     } 
     
     doc.save(`${new Date().toISOString()}_tecnico_cc.pdf`);
  }
  onChangeSelectInst(id_institucion: string) {  
   
    this.nombresHospitales = [];
        
    this.nombresHospitales = [];
  
    this.fk_hospitalCtrl.setValue('');  
   
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0)); 
     
  }
  setVistaCasoClinico(casoclinico: CasoClinico): void{
    console.log(casoclinico)
    this.vistaCasoClinico.emit(casoclinico);
  }
  setAsignarPuntos(casoclinico: CasoClinico){
    let enfermera=this.enfermera;
    this.dialogo.open( DialogoAsignarEnfermeraCasoclinicoPuntos, { width: '800px', data: {casoclinico, enfermera } });
  }
  solicitud(casoClinico: CasoClinico): void {
 
    let status_canje  = this.casosClinicos.find((casoclinico) => casoclinico.id == casoClinico.id ).status_canje;
    
    if(status_canje == 1)
       status_canje = 0;
    else 
      status_canje = 1;   
   
      this.enfermerasSrv.getSolicitudCanjeCirugia(casoClinico.id, status_canje).subscribe(data => {
        if (data.status_canje == 1)
            {
            this.casosClinicos.find((casoclinico) => casoclinico.id == casoClinico.id ).status_canje = 1;
            
          }
        if (data.status_canje == 0)
            {
            this.casosClinicos.find((casoclinico) => casoclinico.id == casoClinico.id ).status_canje = 0;
            
          }   
  
      }, err => {console.log(err)})
        
      console.log(casoClinico);
    }
}
