import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { AuthService} from 'src/app/servicios/auth.service';

import { Estado } from 'src/app/moddelo/estado';
import { Hospital } from 'src/app/moddelo/hospital';
import { Institucion } from 'src/app/moddelo/institucion';
import { Fecha } from 'src/app/moddelo/fecha';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Antibiotico } from 'src/app/moddelo/antibiotico';
import { SeccionCuerpo } from 'src/app/moddelo/seccion-cuerpo';
import { UsoProducto } from 'src/app/moddelo/uso-producto';
import { Accion } from 'src/app/moddelo/accion';
import { Usuario } from 'src/app/moddelo/usuario';


@Component({
  selector: 'app-casos-clinicos-ficha-vista',
  templateUrl: './casos-clinicos-ficha-vista.component.html',
  styleUrls: ['./casos-clinicos-ficha-vista.component.css'],
  providers: [
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'es-MX'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, 
      useValue: MAT_MOMENT_DATE_FORMATS
    }]
})
export class CasosClinicosFichaVistaComponent implements OnInit {
  public id: string = '0';
  public identificacionFrm: FormGroup;
  public accionesFrm: FormGroup;
  public cirugiaFrm: FormGroup;
  public seguimientoFrm: FormGroup;

  public isEditable: boolean = true;
  public isCompletedBasico: boolean = false;
  public isCompletedCirugia: boolean = false;

  public estados: Estado[] = [];
  public instituciones: Institucion[] = [];
  public hospitales: Hospital[] = [];

  public antibioticos: Antibiotico [] = [];
  public seccionesCuerpo: SeccionCuerpo[] = [];
  public usosProducto: UsoProducto[] = [];
  public accionesList: Accion[] = [];
  
  public filtrosHospitales: Hospital[];
  public seccionCuerpo: SeccionCuerpo = null;
  public nombresHospitales: string[] = [];  

  public fecha: Fecha = null
  public maxFecha: Date = null;
  public minFecha: Date = null;
  public fechaCirugia: Date = null;
  public fechaFicha: Date = null;
  
  get usuario(): Usuario{
    return this.auth.getUsuario;
  }

  get folioCtrl(){
    return this.identificacionFrm.get('folio');
  }
  get serieCtrl(){
    return this.identificacionFrm.get('serie');
  }
  get sexoCtrl(){
    return this.identificacionFrm.get('sexo');
  }
  get edadCtrl(){
    return this.identificacionFrm.get('edad');
  }
  get fechaCtrl(){
    return this.identificacionFrm.get('fecha');
  }
  get fk_estadoCtrl(){
    return this.identificacionFrm.get('fk_estado');
  }
  get fk_institucionCtrl(){
    return this.identificacionFrm.get('fk_institucion');
  }
  get otracveInstitucionCtrl(){
    return this.identificacionFrm.get('otracveInstitucion');
  }
  get fk_hospitalCtrl(){
    return this.identificacionFrm.get('fk_hospital');
  }
  get otrohospitalCtrl(){
    return this.identificacionFrm.get('otrohospital');
  }
  get diagnosticoCtrl(){
    return this.identificacionFrm.get('diagnostico');
  }
  get otrodiagnosticoCtrl(){
    return this.identificacionFrm.get('otrodiagnostico');
  }

  
  get fechaAplicacionProductoCtrl(){
    return this.cirugiaFrm.get('fechaAplicacionProducto'); 
  }
  get fk_seccion_cuerpoCtrl() {
    return this.cirugiaFrm.get('fk_seccion_cuerpo');
  }   
  get fk_antibiotico1Ctrl(){
    return this.cirugiaFrm.get('fk_antibiotico1');
  }
  get cantidad1Ctrl(){
    return this.cirugiaFrm.get('cantidad1');
  }
  get fk_antibiotico2Ctrl(){
    return this.cirugiaFrm.get('fk_antibiotico2');
  }
  get cantidad2Ctrl(){
    return this.cirugiaFrm.get('cantidad2');
  }
  get fk_antibiotico3Ctrl(){
    return this.cirugiaFrm.get('fk_antibiotico3');
  }
  get cantidad3Ctrl(){
    return this.cirugiaFrm.get('cantidad3');
  }
  get accionesCtrls() {
    return this.cirugiaFrm.get("acciones") as FormArray; 
  }
  get usosProductoCtrls(){
    return this.cirugiaFrm.get("usosproducto");
  }
  get diffFecha(): number{

     let hoy: Date;
     let ficha: Date;
     let diffInTime: number = 0;
     let diffInDays: number = 0; 
     let oneDay: number = 0;

     if(this.fecha != null && this.fechaFicha != null){
       hoy =  new Date(this.fecha.fechaHoy);
       ficha = new Date(this.fechaFicha);
       // One day in milliseconds
      oneDay = (1000 * 60 * 60 * 24);

      // Calculating the time difference between two dates
      diffInTime = hoy.getTime() - ficha.getTime() ;

      // Calculating the no. of days between two dates
      diffInDays = Math.round(diffInTime / oneDay);
     }
   

    return diffInDays;
  }
  get getFechaMax(): Date{

    let fechaMx: Date;     
    
    if(this.fechaFicha == null) {

      fechaMx = this.fecha == null ? new Date() : new Date(this.fecha.fechaHoy);
    
    } else {
      let f = new Date(this.fechaFicha);
      fechaMx = new Date(f.getTime() + ((1000 * 60 * 60 * 24) * 3));
    }
      return fechaMx;    
  }
  get getFechaMin(): Date{
    let fechaMn: Date;

    if(this.fechaFicha == null) {
          fechaMn = this.fecha == null ? new Date() : new Date(this.fecha.fechaHoy);
    } else {
          fechaMn = this.fechaFicha;
    }
    return fechaMn;
  }
  get isCompletedCirugia_ctrl(){    
    return !(this.diffFecha < 4 && this.fechaCirugia==null);
  }
 
  get casoClinico(){
    return this.casosclinicosSrv.casoClinico == undefined ? null : this.casosclinicosSrv.casoClinico;
  }
  get productos(){
    if(this.casoClinico == null)
    return [];
    else 
    return  this.casoClinico.productos == undefined ? [] : this.casosclinicosSrv.casoClinico.productos;
  }

  get Osteosintesisdisabled ()
  {
   return this.desactivar(1);
  }
  get Lavadodisabled ()
  {
   return this.desactivar(2);
  }
  get Rellenodisabled ()
  {
   return this.desactivar(3);
  }
  get Intrumentaciondisabled ()
  {        
   return this.desactivar(4);
  }
  get Mediostintisdisabled ()
  {
   return this.desactivar(5);
  }
  get Injertosvascularesdisabled()
  {
   return this.desactivar(6);
  }
  get Protesisdisabled()
  {
   return this.desactivar(7);
  }
  get Clavocentromedulardisabled()
  {
   return this.desactivar(8);
  }
  get Mallasdisabled () 
  {
   return this.desactivar(9);
  }
  get Dehiscenciasdisabled () 
  {
   return this.desactivar(10);
  }
  get SxFournierdisabled()
  {
   return this.desactivar(11);
  }
  get Amputaciondisabled()
  {
   return this.desactivar(12);
  }
  get Viscosuplementaciondisabled()
  {
    return this.desactivar(13);
  }
  desactivar(id: number): boolean {
     if(!this.seccionCuerpo) return false;
     if(this.seccionCuerpo.acciones.find(cuerpo => cuerpo.id == id))
     return false;
     else 
     return true;
  }

  constructor(
              private formBuilder: FormBuilder, 
              private catalogosSrv: CatalogosService, 
              private activateRoute: ActivatedRoute,
              private location: Location,
              private casosclinicosSrv: CasosClinicosService, 
              private auth: AuthService, 
              private matSnakBar: MatSnackBar) { }

  ngOnInit(): void {
    this.id = this.activateRoute.snapshot.paramMap.get('id');    
   
    this.identificacionFrm = this.formBuilder.group({
                                                    folio:[''],
                                                    serie:[''],
                                                    paciente:[''],
                                                    sexo:['', Validators.required],
                                                    edad:['', Validators.required],
                                                    fecha:[''],
                                                    fk_estado:['', Validators.required],
                                                    fk_institucion:['', Validators.required],
                                                    otracveInstitucion:[''],
                                                    fk_hospital:['', Validators.required],
                                                    otrohospital:[''],
                                                    diagnostico:['', Validators.required],
                                                    antecedente:[''] 
                                                });

    this.cirugiaFrm = this.formBuilder.group({
                                              fk_seccion_cuerpo:[''],
                                              acciones: this.formBuilder.array([]),
                                              usosproducto: [''],
                                              fk_antibiotico1:[''],                                              
                                              cantidad1:[''],
                                              fk_antibiotico2:[''],                                              
                                              cantidad2:[''],
                                              fk_antibiotico3:[''],                                              
                                              cantidad3:[''],
                                              tratamiento:[''],
                                              fechaAplicacionProducto:['', Validators.required],
                                              observacion:['']
                                            });

    this.accionesFrm = this.formBuilder.group({
                                              osteosintesis: [false],
                                              lavado: [false],
                                              relleno: [false],
                                              instrumentacion: [false],
                                              mediostinitis: [false],
                                              injertosvasculares: [false],
                                              protesis: [false],
                                              clavocentromedular: [false],
                                              mallas: [false],
                                              dehiscencias: [false],
                                              sxfournier: [false],
                                              amputacion: [false],
                                              viscosuplementacion: [false],
                                            });      
    
    this.accionesCtrls.push(this.accionesFrm);
  
     
    this.casosclinicosSrv
    .getCasoClinico(this.id)
    .subscribe( data => 
              { 
                let idsAcciones = [];
                const { id, folio, serie,  paciente, sexo, edad, fecha, fk_estado, fk_hospital, hospital, fk_institucion, fk_diagnostico, antecedente, diagnostico, cveInstitucion } = data; 
                this.casosclinicosSrv.casoClinico = data; 
                this.fechaFicha = fecha;
                
               
                this.identificacionFrm.reset({ folio, serie,  paciente, sexo, edad, fecha, fk_estado, fk_institucion, fk_hospital, diagnostico, antecedente } );
                
                this.identificacionFrm.get('fk_hospital').setValue(fk_hospital);
                
                this.isCompletedBasico = true;

                const { fechaAplicacionProducto, tratamiento, fk_antibiotico1, cantidad1, fk_antibiotico2, cantidad2, fk_antibiotico3, cantidad3, observacion, fk_seccion_cuerpo, acciones, usosproducto } = data; 
                
                this.cirugiaFrm.reset({ fechaAplicacionProducto, tratamiento, fk_antibiotico1, cantidad1, fk_antibiotico2, cantidad2, fk_antibiotico3, cantidad3, observacion, fk_seccion_cuerpo });
                
                this.fechaCirugia = fechaAplicacionProducto;

               
                
                if(fechaAplicacionProducto){
                   this.isCompletedCirugia= true;
                }
                if(acciones)
                  this.setAccionesFrm(acciones);  
          
                if(acciones)
                  idsAcciones = acciones.map(accion => { return accion.id }); 
            
                if(usosproducto)
                  if(usosproducto[0] != undefined)
                    this.usosProductoCtrls.setValue(usosproducto[0].id);
                
              }, err => { console.log(err) });  
      


    this.casosclinicosSrv
    .getFechas(this.id)
    .subscribe(data => { 
                          this.fecha = data; 
                          this.maxFecha = this.fecha.fechaMax;
                          this.minFecha = this.fecha.fechaMin;
                          console.log("fecha", this.fecha.fechaHoy)    
                          console.log("fechaMax", this.fecha.fechaMax)
                          console.log("fechaMin", this.fecha.fechaMin)                        
                         // let dia: number = (1000 * 60 * 60 * 24);                         
                        }, err => { console.log(err) });

    this.catalogosSrv
    .getEstados()
    .subscribe(data => {
                          this.estados = data;
                        }, err => { console.log(err) });
    
    this.catalogosSrv
    .getInstituciones()
    .subscribe(data => {
                          this.instituciones = data;
                         }, err => { console.log(err) });

    this.catalogosSrv
    .getHospitales()
    .subscribe(data => {
                          this.hospitales = data;
                          this.filtrosHospitales = data;
                        }, err => { console.log(err) });
   
    this.catalogosSrv
    .getAntibioticos()
    .subscribe(data => { 
                        this.antibioticos = data; 
                      }, err => { console.log(err); });

    this.catalogosSrv
    .getUsosProducto()
    .subscribe(data => {
            this.usosProducto = data;
    });
                
    this.catalogosSrv
    .getSeccionesCuerpo()
    .subscribe(data => {
                          this.seccionesCuerpo = data;
                          if(this.fk_seccion_cuerpoCtrl.value) {                              
                            this.accionesList = data.find(seccio => seccio.id == this.fk_seccion_cuerpoCtrl.value).acciones;
                            this.seccionCuerpo = this.seccionesCuerpo.find(seccion => seccion.id == this.fk_seccion_cuerpoCtrl.value);
                          }

                        }, err => { console.log(err); });                    
  }
  
  onSubmitFicha(): void {
      this.actualizarFicha();
  }

  onSubmitCirugia(): void {
      this.actualizarCirugia();
  }

  setFormatFicha(): CasoClinico {

    const { folio, serie,  paciente, sexo, edad, fecha, fk_estado, antecedente } = this.identificacionFrm.value;

    let casoClinico: CasoClinico = <CasoClinico> { folio, serie, paciente, sexo, edad, fecha, fk_estado, antecedente };
    let institucion: Institucion = this.instituciones.find((i) => i.id.toString() == this.fk_institucionCtrl.value);
    let hospital: Hospital =  this.hospitales.find((h) => h.id.toString() == this.fk_hospitalCtrl.value);     

    casoClinico.fk_diagnostico = 0;
    casoClinico.diagnostico = this.diagnosticoCtrl.value;

    casoClinico.fk_institucion = institucion.id;
    casoClinico.cveInstitucion = institucion.cvInstitucion;

    casoClinico.fk_hospital = hospital.id;
    casoClinico.hospital = hospital.nomHospital;
       
    return casoClinico;
  }
 
  getFormatFichaCCSRV(casoClinico: CasoClinico, data: CasoClinico): CasoClinico {
    const { id, folio, serie,  paciente, sexo, edad, fecha, fk_estado, antecedente,fk_medico, fk_diagnostico, diagnostico, fk_institucion, cveInstitucion, fk_hospital, hospital, fk_enfermera } = data;
    
    casoClinico.id = id;
    casoClinico.folio = folio;
    casoClinico.serie = serie;
    casoClinico.paciente = paciente;
    casoClinico.sexo = sexo;
    casoClinico.edad = edad;
    casoClinico.fecha = fecha;
    casoClinico.fk_estado = fk_estado;
    casoClinico.antecedente = antecedente;
    casoClinico.fk_medico = fk_medico;
    casoClinico.fk_diagnostico = fk_diagnostico;
    casoClinico.diagnostico = diagnostico;
    casoClinico.fk_institucion = fk_institucion;
    casoClinico.cveInstitucion = cveInstitucion;
    casoClinico.fk_hospital = fk_hospital;
    casoClinico.hospital = hospital;
    casoClinico.fk_enfermera = fk_enfermera;
    
    if(data.enfermera)
      casoClinico.enfermera = data.enfermera;  
  
  return casoClinico;
  }

  setFormatCirugia(): any {

      const { tratamiento, fechaAplicacionProducto, observacion, acciones } = this.cirugiaFrm.value;
      let casoClinico: CasoClinico = <CasoClinico>  { tratamiento, fechaAplicacionProducto, observacion }
      const accionesCirugias: number[] = [];
      const usosProductoCirugia: number[] = [];

      if(this.fk_seccion_cuerpoCtrl.value){
        let seccionCuerpo: SeccionCuerpo = this.seccioncuerpo(this.fk_seccion_cuerpoCtrl.value);
        casoClinico.fk_seccion_cuerpo = seccionCuerpo.id;
        casoClinico.nomCuerpo = seccionCuerpo.nomCuerpo;
      }
      if(this.fk_antibiotico1Ctrl.value){
        let antibiotico: Antibiotico =  this.antibiotico(this.fk_antibiotico1Ctrl.value);
        casoClinico.fk_antibiotico1 = antibiotico.id;
        casoClinico.nomAntibiotico1 = antibiotico.nomAntibiotico;
        casoClinico.cantidad1 = this.cantidad1Ctrl.value;
      }
      if(this.fk_antibiotico2Ctrl.value){
        let antibiotico: Antibiotico =  this.antibiotico(this.fk_antibiotico2Ctrl.value);
        casoClinico.fk_antibiotico2 = antibiotico.id;
        casoClinico.nomAntibiotico2 = antibiotico.nomAntibiotico;
        casoClinico.cantidad2 = this.cantidad2Ctrl.value;
      }
      if(this.fk_antibiotico3Ctrl.value){
        let antibiotico: Antibiotico =  this.antibiotico(this.fk_antibiotico3Ctrl.value);
        casoClinico.fk_antibiotico3 = antibiotico.id;
        casoClinico.nomAntibiotico3 = antibiotico.nomAntibiotico;
        casoClinico.cantidad3 = this.cantidad3Ctrl.value;
      }
      if(this.usosProductoCtrls.value){
          usosProductoCirugia.push(this.usosProductoCtrls.value)
      }
       

      if(acciones[0].osteosintesis)
          accionesCirugias.push(1);
      if(acciones[0].lavado)
          accionesCirugias.push(2);
      if(acciones[0].relleno)    
          accionesCirugias.push(3);
      if(acciones[0].instrumentacion)
          accionesCirugias.push(4);    
      if (acciones[0].mediostinitis)
          accionesCirugias.push(5);
      if (acciones[0].injertosvasculares)
          accionesCirugias.push(6);
      if (acciones[0].protesis)
          accionesCirugias.push(7);
      if (acciones[0].clavocentromedular)
          accionesCirugias.push(8);
      if (acciones[0].mallas)
          accionesCirugias.push(9);
      if (acciones[0].dehiscencias)
          accionesCirugias.push(10);
      if (acciones[0].sxfournier)
          accionesCirugias.push(11);
      if (acciones[0].amputacion)
          accionesCirugias.push(12);

       
      return {casoClinico: casoClinico, accionesCirugia: accionesCirugias, usosProductoCirugia: usosProductoCirugia};
  }

  getFormatCirugiaCCSRV(casoClinico: CasoClinico, data: CasoClinico): CasoClinico {
    const { tratamiento, fechaAplicacionProducto, observacion, fk_antibiotico1, nomAntibiotico1, cantidad1, fk_antibiotico2, nomAntibiotico2, cantidad2, fk_antibiotico3, nomAntibiotico3, cantidad3 } = data;
    
    casoClinico.tratamiento=tratamiento; 
		casoClinico.fechaAplicacionProducto=fechaAplicacionProducto; 
		casoClinico.observacion = observacion; 
		casoClinico.fk_antibiotico1 = fk_antibiotico1; 
		casoClinico.nomAntibiotico1 = nomAntibiotico1;			 
		casoClinico.cantidad1=cantidad1;
			 
		casoClinico.fk_antibiotico2 = fk_antibiotico2; 
		casoClinico.nomAntibiotico2 = nomAntibiotico2;			 
		casoClinico.cantidad2=cantidad2;
			 
		casoClinico.fk_antibiotico3 = fk_antibiotico3; 
		casoClinico.nomAntibiotico3 = nomAntibiotico3;			 
		casoClinico.cantidad3=cantidad3;

    return casoClinico;
  }
 
  actualizarFicha(): void {
        
    
    this.casosclinicosSrv
    .setCasoClinicoFichaUpdate(this.id, this.setFormatFicha())
    .subscribe(data => {
                            if(data){
                              
                              this.matSnakBar.open(`Actualizado CC No. ${data.serie}`, "Caso Clinico", {
                                                                                                          duration: 2000,
                                                                                                          verticalPosition:'bottom',
                                                                                                          horizontalPosition:'center'
                                                                                                        });
                            }      

    }, err => { console.log(err) });

  } 
  
  actualizarCirugia(): void {
    
      this.casosclinicosSrv
      .setCasoClinicoCirugiaUpdate(this.id, this.setFormatCirugia())
      .subscribe(data => {

          if(data){
                        
            let cc: CasoClinico = this.getFormatCirugiaCCSRV(this.casosclinicosSrv.casoClinico, data);
            this.fechaCirugia = cc.fechaAplicacionProducto;

            if(this.fecha.fechaAplicacionProducto == null){
              this.fecha.fechaAplicacionProducto = data.fechaAplicacionProducto;
              this.fecha.fechaPrimerSeguimiento = this.primerSeguimiento(data.fechaAplicacionProducto);
              this.fecha.dias = this.enDias(this.fecha.fechaHoy, this.fecha.fechaPrimerSeguimiento);
              this.fecha.activo = this.activo(this.fecha.fechaHoy, this.fecha.fechaPrimerSeguimiento);
            }
                   
            this.isCompletedCirugia = true;

            this.matSnakBar.open(`Actualizado`, "Caso Clinico", {
              duration: 2000,
              verticalPosition:'bottom',
              horizontalPosition:'center'
            });

          }

      }, err => { console.log(err) });
  }
  
  activo(fechaHoy: Date, primerSeguimiento: Date): boolean {
    const hoy = new Date(fechaHoy);
    const seguimiento = new Date(primerSeguimiento);
    return seguimiento.getTime() > hoy.getTime();
  }

  enDias(fechaHoy: Date, primerSeguimiento: Date): number {
    const hoy = new Date(fechaHoy);
    const seguimiento = new Date(primerSeguimiento);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = seguimiento.getTime() - hoy.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }
  
  primerSeguimiento(aplicacionProducto: Date): Date {
    let fechaAplicacionProducto  = new Date(aplicacionProducto);
    return new Date(fechaAplicacionProducto.getTime() + (35 * ( (1000 * 60) * 60 * 24)) );
  }
  
  institucionformal(id: number, otraInstitucion: string = ""): Institucion {
      let institucion: Institucion;
      institucion = this.instituciones.find(institucion => institucion.id == id);
      if(id == 0){
        institucion.cvInstitucion = otraInstitucion;
      }
      return institucion;
  }
  
  hospitalformal(nomHospital: string, otroHospital: string = ""): Hospital {
      let hospital: Hospital;

      hospital = this.hospitales.find(hospital => hospital.nomHospital.trim().toLowerCase() === nomHospital.trim().toLowerCase());
      
      if(!hospital){        
          
          hospital = new Hospital();
          hospital.id = 0;

          if(nomHospital.trim().toLowerCase() === "otro") {
            hospital.nomHospital = otroHospital;
          }else{
            hospital.nomHospital = "";
          }
                  
      }
      
      return hospital;
  }

  antibiotico(id: number): Antibiotico {
    let antibiotico: Antibiotico;    
    antibiotico = this.antibioticos.find( antibiotico => antibiotico.id == id);    
   return  antibiotico;
  }

  seccioncuerpo(id: number): SeccionCuerpo {
    let seccioncuerpo: SeccionCuerpo;
    seccioncuerpo = this.seccionesCuerpo.find( seccion => seccion.id == id);
    return seccioncuerpo;
  }

  _filter(nombre: string): string[] {
    const valorFiltro = this._normalizeValue(nombre);      
    return this.nombresHospitales.filter(hospital => this._normalizeValue(hospital).includes(valorFiltro));

  }
  
  _normalizeValue(nomdelhospital: string): string {
   let nombre = String(nomdelhospital).toLowerCase();
    return nombre ? nombre.replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u') : '';
  }

  displayFn(hospital: Hospital): string {
    return hospital && hospital.nomHospital ? hospital.nomHospital : '';
  }

  onChangeSelectEdo(id_estado: string) {
    let id_institucion = this.fk_institucionCtrl.value;
    
    this.nombresHospitales = [];
    this.fk_hospitalCtrl.setValue('');
    this.otrohospitalCtrl.setValue('');   
       
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0) && hospital.fk_estado == (id_estado ? Number(id_estado): 0)); 
    
  }
  
  onChangeSelectInst(id_institucion: string) {
    let id_estado = this.fk_estadoCtrl.value;
   
    this.nombresHospitales = [];
    this.otracveInstitucionCtrl.setValue('');
    this.fk_hospitalCtrl.setValue('');
    this.otrohospitalCtrl.setValue('');
   
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0) && hospital.fk_estado == (id_estado ? Number(id_estado): 0)); 
      
  }

  setAccionesFrm(acciones: Accion[]): void 
  {
        let rowAccion = {
                          osteosintesis: false,
                          lavado: false,
                          relleno: false,
                          instrumentacion: false,
                          mediostinitis: false,
                          injertosvasculares: false,
                          protesis: false,
                          clavocentromedular: false,
                          mallas: false,
                          dehiscencias: false,
                          sxfournier: false,
                          amputacion: false,
                          viscosuplementacion: false,
        };

        if(acciones.findIndex(accion => accion.id==1) != -1){
          rowAccion = {...rowAccion, osteosintesis: true}   
        }
        if(acciones.findIndex(accion => accion.id==2) != -1){
          rowAccion = {...rowAccion, lavado: true}   
        }
        if(acciones.findIndex(accion => accion.id==3) != -1){
          rowAccion = {...rowAccion, relleno: true}   
        }
        if(acciones.findIndex(accion => accion.id==4) != -1){
          rowAccion = {...rowAccion, instrumentacion: true}   
        }
        if(acciones.findIndex(accion => accion.id==5) != -1){
          rowAccion = {...rowAccion, mediostinitis: true}   
        }
        if(acciones.findIndex(accion => accion.id==6) != -1){
          rowAccion = {...rowAccion, injertosvasculares: true}   
        }
        if(acciones.findIndex(accion => accion.id==7) != -1){
        rowAccion = {...rowAccion, protesis: true}   
        }
        if(acciones.findIndex(accion => accion.id==8) != -1){
          rowAccion = {...rowAccion, clavocentromedular: true}   
        }
        if(acciones.findIndex(accion => accion.id==9) != -1){
          rowAccion = {...rowAccion, mallas: true}   
        }
        if(acciones.findIndex(accion => accion.id==10) != -1){
          rowAccion = {...rowAccion, dehiscencias: true}   
        }
        if(acciones.findIndex(accion => accion.id==11) != -1){
          rowAccion = {...rowAccion, sxfournier: true}   
        }
        if(acciones.findIndex(accion => accion.id==12) != -1){
          rowAccion = {...rowAccion, amputacion: true}   
        }
        if(acciones.findIndex(accion => accion.id==13) != -1){
          rowAccion = {...rowAccion, viscosuplementacion: true}   
        }

        this.accionesFrm.reset(rowAccion);
  }

  onChangeSelectCuerpo(id_cuerpo: number): void {
      this.seccionCuerpo = this.seccionesCuerpo.find(seccion => seccion.id == id_cuerpo);

      if (this.seccionCuerpo) {

        if(this.casosclinicosSrv.casoClinico.fk_seccion_cuerpo == this.seccionCuerpo.id) {      
            this.setAccionesFrm(this.casosclinicosSrv.casoClinico.acciones);   
            //console.log("acciones 1", this.casoclinico.acciones)   
        } else {
              this.resetAcciones();
        }
      }
      
      console.log(this.seccionCuerpo);
  }

  resetAcciones(): void {
        let rowAccion = {
          osteosintesis: false,
          lavado: false,
          relleno: false,
          instrumentacion: false,
          mediostinitis: false,
          injertosvasculares: false,
          protesis: false,
          clavocentromedular: false,
          mallas: false,
          dehiscencias: false,
          sxfournier: false,
          amputacion: false,
          viscosuplementacion: false,
        };
        this.accionesFrm.reset(rowAccion);
  }

  goBack(): void {
    this.location.back();   
  }
  

}
