import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TiposproductoComponent } from './tiposproducto/tiposproducto.component';
import { TipoproductoComponent } from './tipoproducto/tipoproducto.component';
import { TiposproductosRoutingModule } from './tiposproductos-routing.module';
import { MaterialModule } from 'src/app/material.module';



@NgModule({
  declarations: [TiposproductoComponent, TipoproductoComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TiposproductosRoutingModule,
    MaterialModule
  ]
})
export class TiposproductosModule { }
