import { Component, OnInit } from '@angular/core';
import { AuthService} from '../../../servicios/auth.service';
import { AdministradorService } from '../../../servicios/administrador.service';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['./administrador.component.css']
})
export class AdministradorComponent implements OnInit {
  public loadingHospitales: boolean = true;
  public loadingInstituciones: boolean = true;
  get hospitales(){
    return this.adminSrv.hospitales;
  }
  get instituciones(){
    return this.adminSrv.instituciones;
  }
  get usuario(){
    return this.aut.getUsuario;
  }
  constructor(
    private aut: AuthService, 
    private adminSrv: AdministradorService) { }

  ngOnInit() {
    this.adminSrv.card = 0; 
    
    this.adminSrv.getInstituciones()
    .pipe(finalize( ()=> this.loadingInstituciones = false))
    .subscribe(data => {this.adminSrv.instituciones = data;}, err => { console.log(err); });
    
    this.adminSrv.getHospitales()
    .pipe(finalize( ()=> this.loadingHospitales = false))
    .subscribe(data => {this.adminSrv.hospitales = data;}, err => { console.log(err); });  
  }

}
