import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CasoClinico } from '../moddelo/caso-clinico';
import { Fecha } from '../moddelo/fecha';
import { SeguimientoPostoperatorio } from '../moddelo/seguimiento-postoperatorio';
import { HospitalCasosClinicos } from '../moddelo/hospital-casos-clinicos';
import { AplicacionCasosClinicos } from '../moddelo/aplicacion-casos-clinicos';
import { AplicacionesMedico } from '../moddelo/aplicaciones-medico';
import { CasosClinicosMedicoHospital } from '../moddelo/casos-clinicos-medico-hospital';
import { CasosClinicosEnfermeraHospital } from '../moddelo/casos-clinicos-enfermera-hospital';
import { CasosClinicosServicioHospital } from '../moddelo/casos-clinicos-servicio-hospital';
import { CasosClinicosServicioUso } from '../moddelo/casos-clinicos-servicio-uso';
import { CasosClinicosServicioUsoHospital } from '../moddelo/casos-clinicos-servicio-uso-hospital';
import { CasosClinicosServicioUsoMedico } from '../moddelo/casos-clinicos-servicio-uso-medico';
import { CasosClinicosEstadoUsoProducto } from '../moddelo/casos-clinicos-estado-uso';
import { CasosClinicosInstitucionUsoProducto } from '../moddelo/casos-clinicos-institucion-uso';
import { CasosClinicosInstitucionHospitalUsoProducto } from '../moddelo/casos-clinicos-institucion-hospital-uso-producto';
import { InstitucionParteCuerpo } from '../moddelo/institucion-parte-cuerpo';
import { CasosClinicosCuerpoUso } from '../moddelo/casos-clinicos-cuerpo-uso';
import { InstitucionCasosClinicos } from '../moddelo/institucion-casos-clinicos';
import { Exportpdf } from '../moddelo/exportpdf';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CasosClinicosService {  
  
  public casoClinico: CasoClinico; 

  public seguimientosPostoperatorios: SeguimientoPostoperatorio[] = [];
    
  constructor(private http: HttpClient) { }  
  
  setSubir(formData: FormData)
  {
    return this.http.post('/apibioc/public/api/casoclinico-upfile', formData, { reportProgress: true, observe: 'events' });
  }
  
  setBajar(id: number)
  {
    return this.http.delete(`/apibioc/public/api/casoclinico/documento/${id}`, httpOptions);
  }

  getFolio(id: number)
  {
    return this.http.get(`/apibioc/public/api/casoclinico/folio/${id}`, httpOptions);
  }

  getFechas(id: any)
  {
    return this.http.get<Fecha>(`/apibioc/public/api/casoclinico/fechas/${id}`, httpOptions);
  } 

  setCasoClinico(casoClinico: CasoClinico)
  {
    return this.http.post<CasoClinico>('/apibioc/public/api/casoclinico', casoClinico);
  }

  setCasoClinicoFichaUpdate(id: String, casoClinico: any)
  {
    return this.http.put<CasoClinico>(`/apibioc/public/api/casoclinico/ficha/${id}`, casoClinico);
  }

  setCasoClinicoCirugiaUpdate(id: String, casoClinico: any)
  {
    return this.http.put<CasoClinico>(`/apibioc/public/api/casoclinico/cirugia/${id}`, casoClinico);
  }

  getCasoClinico(id: any)
  {
    return this.http.get<CasoClinico>(`/apibioc/public/api/casoclinico/${id}`, httpOptions);
  }

  getCasosClinicos()
  {
    return this.http.get<CasoClinico[]>('/apibioc/public/api/casosclinicos', httpOptions);
  }
  
  getCasoClinicoDelete(id: Number) 
  {
    return this.http.delete<CasoClinico>(`/apibioc/public/api/casoclinico/${id}`);
  }
  
  setSeguimientoPostOperatorio(seguimiento: SeguimientoPostoperatorio)
  {
    return this.http.post<SeguimientoPostoperatorio>('/apibioc/public/api/seguimiento', seguimiento);
  }

  getSeguimientoPostOperatorio(id: any)
  {
    return this.http.get<SeguimientoPostoperatorio>(`/apibioc/public/api/seguimiento/${id}`);
  }
    
  setSeguimientoPostOperatorioUpdate(id: number, seguimiento: SeguimientoPostoperatorio)
  {
    return this.http.put(`/apibioc/public/api/seguimiento/${id}`, seguimiento);
  }
  
  getCasosClinicosQuery(query: any)
  {
    return this.http.post('/apibioc/public/api/casoclinico/consulta', query);
  }
  getCasosClinicosxInstitucion(fechaInicio: string, fechaFinal: string) 
  {
    return this.http.post<InstitucionCasosClinicos[]>('/apibioc/public/api/casosclinicosxinstitucion', {fechaInicio, fechaFinal});
  }
  getCasosClinicosxInstitucionRows(fechaInicio: string, fechaFinal: string) 
  {
    return this.http.post<any[]>('/apibioc/public/api/detallescasosclinicosxinstitucionrows', {fechaInicio, fechaFinal});
  }
  getCasosClinicosxHospital(fechaInicio:Date, fechaFinal:Date) 
  {
    return this.http.post<HospitalCasosClinicos[]>('/apibioc/public/api/casosclinicosxhospital', {fechaInicio, fechaFinal});
  }
  getCasosClinicosxMedico(fechaInicio:Date, fechaFinal:Date) 
  {
    return this.http.post<CasosClinicosMedicoHospital[]>('/apibioc/public/api/casosclinicosxmedico', {fechaInicio, fechaFinal});
  }
  getCasosClinicosxEnfermera(fechaInicio:Date, fechaFinal:Date) 
  {
    return this.http.post<CasosClinicosEnfermeraHospital[]>('/apibioc/public/api/casosclinicosxtecnico', {fechaInicio, fechaFinal});
  }
  getCasosClinicosxServicioyHospital(fechaInicio:Date, fechaFinal:Date) 
  {
    return this.http.post<CasosClinicosServicioHospital[]>('/apibioc/public/api/casosclinicosxservicioyhospital', {fechaInicio, fechaFinal});
  }
  getCasosClinicosxAplicacion(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<AplicacionCasosClinicos[]>('/apibioc/public/api/casosclinicosxaplicacion', {fechaInicio, fechaFinal});
  }
  getAplicacionesMedicos(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<AplicacionesMedico[]>('/apibioc/public/api/tipoaplicacionxmedico', {fechaInicio, fechaFinal});
  }

  getCasosClinicosxServicioyUso(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<CasosClinicosServicioUso[]>('/apibioc/public/api/casosclinicosxservicioyuso', {fechaInicio, fechaFinal})
  }
  getCasosClinicosxServicioyUsoHospital(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<CasosClinicosServicioUsoHospital[]>('/apibioc/public/api/casosclinicosxservicioyusoyhospital', {fechaInicio, fechaFinal})
  }
  getCasosClinicosxServicioyUsoMedico(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<CasosClinicosServicioUsoMedico[]>('/apibioc/public/api/casosclinicosxservicioyusoymedico', {fechaInicio, fechaFinal})
  }
  getCasosClinicosxEstadoyUsoProducto(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<CasosClinicosEstadoUsoProducto[]>('/apibioc/public/api/casosclinicosxestadoyusoproducto', {fechaInicio, fechaFinal})
  }
  getCasosClinicosxInstitucionyUsoProducto(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<CasosClinicosInstitucionUsoProducto[]>('/apibioc/public/api/casosclinicosxinstitucionyusoproducto', {fechaInicio, fechaFinal})
  }
  getCasosClinicosxInstitucionHospitalyUsoProducto(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<CasosClinicosInstitucionHospitalUsoProducto[]>('/apibioc/public/api/casosclinicosxinstitucionyhospitalyusoproducto', {fechaInicio, fechaFinal})
  }
  getCasosClinicosxParteCuerpoUsoProducto(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<CasosClinicosCuerpoUso[]>('/apibioc/public/api/casosclicnicosxcuerpoxusoproducto', {fechaInicio, fechaFinal})
  }
  getCasosClinicosxInstitucionParteCuerpo(fechaInicio: Date, fechaFinal:Date)
  {
    return this.http.post<InstitucionParteCuerpo[]>('/apibioc/public/api/casosclinicosxinstitucionycuerpo', {fechaInicio, fechaFinal})
  }
  getDestallescasosclinicosinstitucionPDF(fechaInicio: string, fechaFinal: string){
    return this.http.post<Exportpdf>('/apibioc/public/api/detallescasosclinicosxinstitucion', {fechaInicio, fechaFinal});
  }
  getDestallescasosclinicosmedicoPDF(fechaInicio: string, fechaFinal: string){
    return this.http.post<Exportpdf>('/apibioc/public/api/detallescasosclinicosxmedico', {fechaInicio, fechaFinal});
  }
  getCasosClinicosxMedicoRows(fechaInicio: string, fechaFinal: string) 
  {
    return this.http.post<any[]>('/apibioc/public/api/detallescasosclinicosxmedicorows', {fechaInicio, fechaFinal});
  }
  getDestallescasosclinicostecnicoPDF(fechaInicio: string, fechaFinal: string){
    return this.http.post<Exportpdf>('/apibioc/public/api/detallescasosclinicosxtecnico', {fechaInicio, fechaFinal});
  }
  getCasosClinicosxTecnicoRows(fechaInicio: string, fechaFinal: string) 
  {
    return this.http.post<any[]>('/apibioc/public/api/detallescasosclinicosxtecnicorows', {fechaInicio, fechaFinal});
  }
}
