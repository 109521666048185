import { AfterViewInit, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { Medico } from 'src/app/moddelo/medico';
import { Enfermera } from 'src/app/moddelo/enfermera'; 
import { DialogoEnfermeraMedicoAdd } from '../../dialogos/dialogo-enfermera-medico-add/dialogo-enfermera-medico-add';
import { DialogoEnfermeraMedicoRemove } from '../../dialogos/dialogo-enfermera-medico-remove/dialogo-enfermera-medico-remove';

@Component({
  selector: 'app-supervisor-enfermera-medicos',
  templateUrl: './supervisor-enfermera-medicos.component.html',
  styleUrls: ['./supervisor-enfermera-medicos.component.css']
})
export class SupervisorEnfermeraMedicosComponent implements OnInit {
  public displaydeColumns: string[] = ['nombre', 'correo', 'telefonoMovil', 'casosclinicos' ,'acciones'];
  public dataSourceMedicos: MatTableDataSource<Medico> = new MatTableDataSource<Medico>();

  @Input('Enfermera') public enfermera: Enfermera;
  @Output() cancelarEnfermera: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  public filtroFrm: FormGroup = new FormGroup({
    nomMedico: new FormControl('')
  });
  get medicoCtrl(){
    return this.filtroFrm.get('nomMedico');
  }
  constructor(
    private auth: AuthService, 
    private adminSrv: AdministradorService,  
    private enfermerasServ: EnfermerasService,
    private _live: LiveAnnouncer, 
    private dialogo: MatDialog) { }

  ngAfterViewInit(): void {
    this.dataSourceMedicos.paginator = this.paginator;
    this.dataSourceMedicos.sort = this.sort;
  }  
  ngOnInit() {
    this.dataSourceMedicos.data = this.enfermera.medicos;
    this.dataSourceMedicos.filterPredicate = (data: Medico, filter: string): boolean => {
          const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
            return (currentTerm + (data as { [key: string]: any })[key] + '◬');
          }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

          const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

          return dataStr.indexOf(transformedFilter) != -1;
    }
  }
  announceSortChange(sortState: Sort){
    if(sortState.direction){
      this._live.announce(`Sorted ${sortState.direction}ending`);
    }else{
      this._live.announce('Shorting cleared');
    }
  }

  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceMedicos.filter = this.normalize(filterValue.toLowerCase());

    if(this.dataSourceMedicos.paginator) {
      this.dataSourceMedicos.paginator.firstPage();
    }
  } 

  filterMedico(nommedico: string): boolean{
    let condicion: boolean = true;
    let medico: string;
    let m: string;
    let nombre: string; 
    
    if(this.medicoCtrl.value != ""){
      m = this.medicoCtrl.value;
      medico = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nommedico.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(medico.replace(/ +/g,''));
    }     
    return condicion;
  }
  
  addMedico(): void {
      let dialogRef = this.dialogo.open(DialogoEnfermeraMedicoAdd, {width: '800px', data: this.enfermera });
      dialogRef.afterClosed().subscribe(r => {
        this.dataSourceMedicos.data = this.enfermera.medicos;        
       })
  }

  removerMedico(medico: Medico): void {
      let dialogRef = this.dialogo.open(DialogoEnfermeraMedicoRemove, {width: '800px', data: {enfermera: this.enfermera, medico: medico} });
      dialogRef.afterClosed().subscribe(r => {
        this.dataSourceMedicos.data = this.enfermera.medicos;    
      });
  }

  normalize(caracteres: string): string{
    let datos: string = caracteres.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
    return datos;
  }
  onCancelar(): void {
    this.cancelarEnfermera.emit();
  }

}
