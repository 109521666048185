import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-footer-bar",
    templateUrl: "./footer-bar.html",
    styleUrls: ["./footer-bar.css"]
    })
export class FooterBarComponent implements OnInit {

    constructor(){
        
    }
    ngOnInit(): void {
        
    }
}    