import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { finalize } from 'rxjs/operators';

import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import { Producto } from 'src/app/moddelo/producto';

import { DialogoCambiarPasswUsuario } from '../../dialogos/dialogo-cambiar-passw-usuario/dialogo-cambiar-passw-usuario';
import { DialogoEnfermeraFicha } from '../../dialogos/dialogo-enfermera-ficha/dialogo-enfermera-ficha';
import { Usuario } from 'src/app/moddelo/usuario';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';

interface IEnfermera{
  id: number;
  ingreso: Date;
  nomEnfermera: string;
  productos: number;
  casosclinicos: number;
  canjes: number;
  medicos: number;
  puntos: number;
  saldo: number;
  activo: number;
  solicitudes: number;
}
interface ItbEnfermera{
  no: number;
  ingreso: string;
  nomEnfermera: string;
  productos: number;
  casosclinicos: number;
  canjes: number;
  medicos: number;
  puntos: number;
  saldo: number;  
}
@Component({
  selector: 'app-administrador-enfermeras',
  templateUrl: './administrador-enfermeras.component.html',
  styleUrls: ['./administrador-enfermeras.component.css']
})
export class AdministradorEnfermerasComponent implements OnInit, AfterViewInit {
  public enfermeras: Enfermera[] = [];
  public enfermerasAny: IEnfermera[] = [];
  public dataSourceEnfermeras: MatTableDataSource<IEnfermera> = new MatTableDataSource<IEnfermera>();
  public displayedColumns: String[] = ['nomEnfermera', 'casosclinicos', 'productos', 'canjes','medicos', 'puntos', 'saldo', 'solicitudes'];
  
  public cargar: boolean = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public filtroFrm: FormGroup = new FormGroup({
    nomEnfermera: new FormControl('')
  });

  get enfermeraCtrl(){
    return this.filtroFrm.get('nomEnfermera');
  }
  
  get usuario(){
    return this.aut.getUsuario;
  }

  get offLinkEnfemera(){
    return this.usuario.fk_nivel == 0 ||  this.usuario.fk_nivel == 3;
  }  
  get offPuntos(){
    return this.usuario.fk_nivel == 0 ||  this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 3;
  }
  get offMedicos(){
    return this.usuario.fk_nivel == 0 ||  this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 3;
  }
  get offCanjes(){
    return this.usuario.fk_nivel == 0 ||  this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 3;
  }
  get offCasosClinicos(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }
  get offPws(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }
  
  constructor(
              private _live: LiveAnnouncer,
              private aut: AuthService, 
              private adminSrv: AdministradorService,
              private dialogo: MatDialog) { }

  ngAfterViewInit(): void {
      this.dataSourceEnfermeras.paginator = this.paginator;
      this.dataSourceEnfermeras.sort = this.sort;
  }
  ngOnInit(): void {
          this.adminSrv.card = 3;
          //this.enfermeras = this.adminSrv.enfermeras;
        
          
          this.adminSrv
          .getEnfermeras().pipe(finalize( () => this.cargar = false))
          .subscribe(data => { 
            
            this.adminSrv.enfermeras = data;
            this.enfermeras = this.adminSrv.enfermeras;

            this.enfermerasAny = this.adminSrv.enfermeras.map( enfermera => {
              return <IEnfermera> {
                id: enfermera.id,
                ingreso: enfermera.created_at,
                nomEnfermera: (enfermera.nombre.trim() + ' ' +  enfermera.primerApellido.trim() + ( enfermera.segundoApellido ? ' '+ enfermera.segundoApellido.trim():'')),
                productos: enfermera.productos.length,
                casosclinicos: enfermera.casosclinicos.length,
                canjes: enfermera.redimirenfermera.length,
                medicos: enfermera.medicos.length,
                puntos: this.puntos(enfermera.casosclinicos),
                saldo: this.saldos(enfermera.casosclinicos, enfermera.redimirenfermera),
                activo: enfermera.usuario.activo,
                solicitudes: this.solicitudes(enfermera.redimirenfermera)
              }
            });
            
            this.dataSourceEnfermeras.data = this.enfermerasAny.sort((a, b) => (b.solicitudes - a.solicitudes));

            this.dataSourceEnfermeras.filterPredicate = (data: IEnfermera, filter: string): boolean => {
              const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
                return (currentTerm + (data as { [key: string]: any })[key] + '◬');
              }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        
              const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        
              return dataStr.indexOf(transformedFilter) != -1;
            }
            
          }, err => { console.log(err); });
    
  }
  announceSortChange(sortState: Sort): void {
    if(sortState.direction){
      this._live.announce(`Sorted ${sortState.direction}ending`);
    }else{
      this._live.announce('Shorting cleared');
    }
  }
  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceEnfermeras.filter = this.normalize(filterValue.toLowerCase());

    if(this.dataSourceEnfermeras.paginator){
      this.dataSourceEnfermeras.paginator.firstPage();
    }
  }
  normalize(caracteres: string): string{
    let datos: string = caracteres.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
    return datos;
  }

  filterEnfermera(nomEnfermera: string): boolean{
    let condicion: boolean = true;
    let enfermera: string;
    let e: string;
    let nombre: string; 

    if(this.enfermeraCtrl.value != ""){
      e = this.enfermeraCtrl.value;      
      enfermera = e.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomEnfermera.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      condicion = nombre.replace(/ +/g,'').includes(enfermera.replace(/ +/g,''));
    }  
   
    return condicion;
  }

  onSubmit()
  {
    this.enfermeras = this.adminSrv.enfermeras.filter(m => this.filterEnfermera(m.nombre.concat(m.primerApellido).concat(m.segundoApellido?m.segundoApellido:''))); 
    //this.page_number = 1;
  }


  solicitudes(canjes: RedimirEnfermera[]){
    return canjes.filter(canje => canje.status == 1).length;
  }

  saldos(acumulados: CasoClinico[], canjes: RedimirEnfermera[]){
   // return acumulados.reduce((valor, acumulado) => acumulado.e_puntos + valor, 0) - canjes.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
  return acumulados.filter(cc => cc.status_canje == 0).reduce((valor, acumulado) => acumulado.e_puntos + valor, 0);
  }

  puntos(acumulados: CasoClinico[]){
    return acumulados.reduce((valor, acumulado)=> acumulado.e_puntos + valor, 0);
  }

  ficha(enfermera: Enfermera){
    this.dialogo.open(DialogoEnfermeraFicha, {data: enfermera, width: '400px' });
  }
  
  changePassw(usuario: Usuario){
    this.dialogo.open(DialogoCambiarPasswUsuario, {width:'600px', data: usuario});
  }

  isInactivo(enfermera: Enfermera){
    return enfermera.productos.length == 0 && enfermera.casosclinicos.length == 0 && enfermera.medicos.length == 0 && enfermera.redimirenfermera.length == 0;
  }
  generateData(): any[] {
    let result: ItbEnfermera[] = [];
    let i: number = 0;
    if(!this.dataSourceEnfermeras.filter){
      this.dataSourceEnfermeras._orderData(this.dataSourceEnfermeras.data).forEach((e, i) => {
        
        result.push( {
                      no: (i+1),
                      ingreso: e.ingreso ? moment(e.ingreso).format('L') : '',
                      nomEnfermera: e.nomEnfermera,
                      productos: e.productos,
                      casosclinicos: e.casosclinicos,
                      canjes: e.canjes,
                      medicos: e.medicos,
                      puntos: e.puntos,
                      saldo: e.saldo
                    });
    });
    } 
    else{
      this.dataSourceEnfermeras._filterData(this.dataSourceEnfermeras.data).forEach((e, i) => {
        
        result.push( {
                      no: (i+1),
                      ingreso: e.ingreso ? moment(e.ingreso).format('L') : '',
                      nomEnfermera: e.nomEnfermera,
                      productos: e.productos,
                      casosclinicos: e.casosclinicos,
                      canjes: e.canjes,
                      medicos: e.medicos,
                      puntos: e.puntos,
                      saldo: e.saldo
                    });
    });
    } 
    

  return result;
  }

  exportTablaExcel(){
    let tEnfermeras: ItbEnfermera[] = this.dataSourceEnfermeras.filteredData.map((e, i) =>({  no: (i+1),
                                                                                          ingreso: e.ingreso? moment( e.ingreso).format('L') : '',
                                                                                          nomEnfermera: e.nomEnfermera,
                                                                                          productos: e.productos,
                                                                                          casosclinicos: e.casosclinicos,
                                                                                          canjes: e.canjes,
                                                                                          medicos: e.medicos,
                                                                                          puntos: e.puntos,
                                                                                          saldo: e.saldo }));

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tEnfermeras);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Enfermeras1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_enfermerasg.xlsx`);
  }
  exportTablaPDF():  void {
    const doc = new jsPDF('landscape');  
     
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     
     doc.text(" DATOS GENERALES DE ENFERMERAS ",  145, 10, { align: "center"});
    
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       columnStyles: { no: { halign: 'right' }, productos: { halign: 'right' },  casosclinicos: { halign: 'right'}, canjes:{ halign: 'right'}, medicos: {halign: 'right'},  puntos: {halign: 'right'}, saldo: {halign: 'right'} },
       body: rows,
       columns: [
         { header: 'No', dataKey: 'no' },
         { header: 'Fecha Ingreso', dataKey: 'ingreso' },
         { header: 'Enfermera', dataKey: 'nomEnfermera' },
         { header: 'No Productos', dataKey: 'productos' },
         { header: 'No. Casos Clinicos', dataKey: 'casosclinicos' },
         { header: 'No. Canjes', dataKey: 'canjes' },
         { header: 'No. Medicos', dataKey: 'medicos' },
         { header: 'Puntos', dataKey: 'puntos' },
         { header: 'Saldo', dataKey: 'saldo' }, 
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
         doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_enfermerasg.pdf`);
  } 
}
