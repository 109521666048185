import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { HospitalesRoutingModule } from './hospitales-routing.module';
import { HospitalComponent } from './hospital/hospital.component';
import { HospitalesComponent } from './hospitales/hospitales.component';
import { HospitalesOtrosComponent } from './hospitales-otros/hospitales-otros.component';
import { HospitalOtroComponent } from './hospital-otro/hospital-otro.component';
import { PaginacionModule } from 'src/app/pipes/paginacion.module';

@NgModule({
  declarations: [HospitalComponent, HospitalesComponent, HospitalesOtrosComponent, HospitalOtroComponent],
  imports: [
    CommonModule,
    HospitalesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PaginacionModule
  ]
})
export class HospitalesModule { }
