import { Component, OnInit } from '@angular/core';
import { AuthService} from 'src/app/servicios/auth.service';
import { MedicosService } from 'src/app/servicios/medicos.service';

@Component({
  selector: 'app-medicos-main',
  templateUrl: './medicos-main.component.html',
  styleUrls: ['./medicos-main.component.css']
})
export class MedicosMainComponent implements OnInit {
  public id: number = 0;

  constructor(private aut: AuthService, private medicosSrv: MedicosService) 
  {
      this.id = aut.getUsuario.medico.id;
      
      medicosSrv
      .getMedico(this.id.toString())
      .subscribe(data => {
                            this.medicosSrv.medico = data;
                            console.log(this.medicosSrv.medico);
                          }, 
                          err => { 
                                    console.log(err);  
                                  });

  }
  get pgHome(){
    return this.medicosSrv.card == 0;
  }
  get CardCasosClinicos (){
    return this.medicosSrv.card == 1;
  }
  get CardProductos(){
    return this.medicosSrv.card == 2;
  }
  get CardPuntos(){
    return this.medicosSrv.card == 3;
  }
  get CardSaldos(){
    return this.medicosSrv.card == 4;
  }
 
  get totalCasosclinicos(){       
    return  this.medicosSrv.medico === undefined ? 0 : this.medicosSrv.medico.casosclinicos.length;
  }
  get totalProductos(){
    return this.medicosSrv.medico === undefined ? 0 : this.medicosSrv.medico.productos.length;
  }
  get totalPuntos(){
    return  this.medicosSrv.medico === undefined ? 0 : this.medicosSrv.medico.productos.reduce((valor, producto) => producto.m_puntos + valor, 0);
  }
  get totalSaldo(){
    return this.medicosSrv.medico === undefined ? 0 : this.medicosSrv.medico.productos.reduce((valor, producto) => producto.m_puntos + valor, 0) - this.medicosSrv.medico.redimirmedico.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
  }
  
  ngOnInit() {

  }

}
