import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService} from 'src/app/servicios/auth.service';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Medico } from 'src/app/moddelo/medico';
import { Producto } from 'src/app/moddelo/producto';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { DialogoMedicoPuntosDefault } from '../../dialogos/dialogo-medico-puntos-default/dialogo-medico-puntos-default';
import { Usuario } from 'src/app/moddelo/usuario';

interface ICasoClinico {
          id: number;
          fecha: Date;
          noCx: string;
          paciente: string;
          fk_hostipital: number;
          nomHospital: string;
          fk_diagnostico: number;
          nomDiagnostico: string;
          fk_enfermera: number
          nomEnfermera: string;
          fk_estado: number;
          nomEstado:string;
          noProductos: string;  
}
@Component({
  selector: 'app-administrador-medico-main',
  templateUrl: './administrador-medico-main.component.html',
  styleUrls: ['./administrador-medico-main.component.css']
})
export class AdministradorMedicoMainComponent implements OnInit {
  public id: string;
  public AccesoCasoClinico: number = 0;
  public AccesoCanje: number = 0;
  public medico: Medico = new Medico();
  public canje: RedimirMedico = new RedimirMedico();
  public casoclinico: CasoClinico = new CasoClinico(); 

  @ViewChild(MatAccordion, {static: true}) accordion: MatAccordion;

  constructor(
              private activatedRoute: ActivatedRoute,
              private auth: AuthService, 
              private medicoServicio: MedicosService, 
              private location: Location,
              private dialogo: MatDialog) { }

  get usuario(): Usuario{
    return this.auth.getUsuario;
  }
  get offLink(): boolean {
    return (this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3);
  }  
  ngOnInit() {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
      this.medicoServicio.getMedico(this.id).subscribe(data => { this.medico = data; }, err => { console.log(err); });
  }
  selEnfermera(cc: CasoClinico) {
    console.log(cc);    
  }  
  updateCasoClinico(cc: CasoClinico): void {
    this.AccesoCasoClinico = 1;
    this.casoclinico = cc;   
  }  
  onCancelarFicha(e: any): void {
    this.AccesoCasoClinico = 0;
  }
  onSolicitud(canje: RedimirMedico): void {
    this.canje = canje;
    this.AccesoCanje = 1;    
  }
  onDelete(canje: RedimirMedico): void{
    this.canje = canje;
    this.AccesoCanje = 2;    
  }
  onNuevo(): void{
    this.AccesoCanje = 3;
  }
  onReferencia(canje: RedimirMedico): void{
    this.canje = canje;
    this.AccesoCanje = 4;
  }
  onDetalle(canje: RedimirMedico): void{
    this.canje = canje;
    this.AccesoCanje = 5;
  }
  onCancelarCanje(e: any):void {
    this.AccesoCanje = 0;
  } 
  puntos(productos: Producto[]){
    return productos.reduce((valor, producto) => producto.m_puntos + valor, 0);
  }
  saldos(productos: Producto[], canjes: RedimirMedico[]){
    return productos.reduce((valor, producto) => producto.m_puntos + valor, 0) - canjes.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
  }
  puntosDefault(){
    this.dialogo.open(DialogoMedicoPuntosDefault, {width: '800px', data: this.medico});
  }
  goBack(): void{
    this.location.back();
  }
}
