import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, PageEvent } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';

import { Supervisor } from 'src/app/moddelo/supervisor';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Usuario } from 'src/app/moddelo/usuario';


@Component({
  selector: 'app-administrador-supervisores',
  templateUrl: './administrador-supervisores.component.html',
  styleUrls: ['./administrador-supervisores.component.css']
})
export class AdministradorSupervisoresComponent implements OnInit {
  public cargar: boolean = true;

  public supervisores: Supervisor[] = [];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
        nomSupervisor: new FormControl('')
  });

  get supervisorCtrl(): AbstractControl{
    return this.filtroFrm.get('nomSupervisor');
  }
  get usuario(): Usuario{
    return this.aut.getUsuario;
  }
  get offLinkSupervisor(): boolean{
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  } 
  
  constructor(
    private aut: AuthService, 
    private adminSrv: AdministradorService) { }

  ngOnInit() {
    
    this.adminSrv.card = 4;
    this.adminSrv
    .getSupervisores()
    .pipe(finalize(() => this.cargar = false))
    .subscribe(data => { 
      this.adminSrv.supervisores = data;
      this.supervisores = this.adminSrv.supervisores;
    }, err => { console.log(err); });
    
  }

  filterSupervisor(nomsupervisor: string): boolean
  {
    let condicion: boolean = true;
    let supervisor: string;
    let s: string;
    let nombre: string; 

    if(this.supervisorCtrl.value != "")
    {
      s = this.supervisorCtrl.value;
      supervisor = s.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomsupervisor.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      condicion = nombre.replace(/ +/g,'').includes(supervisor.replace(/ +/g,''));
      
    }  
    
    return condicion;
  }

  onSubmit()
  {
    this.supervisores = this.adminSrv.supervisores.filter(m => this.filterSupervisor(m.nombre.concat(m.primerApellido).concat(m.segundoApellido?m.segundoApellido:'')));    
    this.page_number = 1;
  }
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }   

}
