import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Medico } from 'src/app/moddelo/medico';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
interface RemoveMedico{
  code: number;
  message: string;
  data: Medico;
}
@Component({
  selector: 'app-administrador-enfermera-r-medico-remove',
  templateUrl: './administrador-enfermera-r-medico-remove.component.html',
  styleUrls: ['./administrador-enfermera-r-medico-remove.component.css']
})
export class AdministradorEnfermeraRMedicoRemoveComponent implements OnInit {
  public enfermera: Enfermera = null;
  public medico: Medico = null;
  private respuesta: RemoveMedico;
  constructor(
              private location: Location, 
              private activatedRoute: ActivatedRoute,
              private adminSrv: AdministradorService,
              private enfermerasSrv: EnfermerasService,
              private snackBar: MatSnackBar,
              private aut: AuthService
              ) { }
              
  get usuario(){
      return this.aut.getUsuario;
  }            
  get offBoton(){
      return  (this.usuario.fk_nivel==0 || this.usuario.fk_nivel==3);
  }  
  ngOnInit() {
    let id_enfermera: number = Number(this.activatedRoute.snapshot.paramMap.get('id1'));
    let id_medico: number = Number(this.activatedRoute.snapshot.paramMap.get('id2'));

    this.enfermera = this.adminSrv.enfermeras.find(e => e.id == id_enfermera);
    this.medico = this.enfermera.medicos.find(m => m.id == id_medico);

  }
  remove(): void{
    if(confirm("¿Estas seguro?")){

     this.enfermerasSrv
     .setDesasociarMedico(this.enfermera.id, this.medico)
     .subscribe(data => {
                          let i: number = 0;
                          let m: Medico[] = null;
                          this.respuesta = <RemoveMedico> data;
                          if(this.respuesta.code){
                            i = this.enfermera.medicos.findIndex(m => m.id == this.medico.id); 
                            m = this.enfermera.medicos.splice(i, 1);
                            this.snackBar.open(this.respuesta.message, 
                              'Enfermera', 
                              {
                                duration: 3000,
                                verticalPosition:'bottom',
                                horizontalPosition:'center'
                              });
                              console.log("Removido: ", m[0].nombre, ' ' , m[0].primerApellido );
                              this.goBack(); 
                          }else{
                                  this.snackBar.open(this.respuesta.message, 
                                    'Medico', 
                                    {
                                      duration: 3000,
                                      verticalPosition:'bottom',
                                      horizontalPosition:'center'
                                    });
                          }
     }, err => { console.log(err); });
    }
  }
  goBack(): void{
      this.location.back();
  }

}
