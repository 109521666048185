import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import { PageEvent } from '@angular/material';
import { AuthService } from 'src/app/servicios/auth.service';
import { TipoProducto } from 'src/app/moddelo/tipo-producto';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

@Component({
  selector: 'app-tiposproducto',
  templateUrl: './tiposproducto.component.html',
  styleUrls: ['./tiposproducto.component.css']
})
export class TiposproductoComponent implements OnInit {
  public tiposProductos: TipoProducto [] = [];
  public loadingTipos: boolean = true;
  public pageEvent: PageEvent;

  get usuario(){
    return this.auth.getUsuario;
  }

  get offLink(){
    return (this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 0);
  }

  constructor(private auth: AuthService,
              private location: Location, 
              private catalogosSrv: CatalogosService) { }

  ngOnInit() {
    this.catalogosSrv.getTiposProductos().pipe(finalize( ()=> this.loadingTipos = false)).subscribe(data => {this.tiposProductos = data;}, error => {});
  
  }
  pagina(x: number, pageEvent: PageEvent): boolean{
    let sizePage: number = 10;
    let pageIndex: number = 0;
    
    if(pageEvent !== undefined){
      sizePage = pageEvent.pageSize;
      pageIndex = pageEvent.pageIndex;
    }

    return Math.ceil(( (x+1) /sizePage)) !== (pageIndex + 1);
  }
  goBack(): void{
    this.location.back();
  }
}
