import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';
import { Medico } from 'src/app/moddelo/medico';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { AuthService } from 'src/app/servicios/auth.service';

import S from 'src/app/datos/status';


interface Response{
  code: number; 
  message: string;
  data: RedimirMedico;
}

@Component({
  selector: 'app-administrador-medico-r-canje-delete',
  templateUrl: './administrador-medico-r-canje-delete.component.html',
  styleUrls: ['./administrador-medico-r-canje-delete.component.css']
})
export class AdministradorMedicoRCanjeDeleteComponent implements OnInit {
  @Input('medico') public medico: Medico;
  @Input('canje') public canje: RedimirMedico;
  @Output() cancelarCanje: EventEmitter<any> = new EventEmitter<any>();

  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  get usuario(){
    return this.auth.getUsuario;
  }
  get offButton(){
    return (this.usuario.fk_nivel==3 || this.usuario.fk_nivel==0);
  }
  constructor(
    private actived: ActivatedRoute, 
    private adminSrv: AdministradorService,
    private medicosSrv: MedicosService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
  ) { }

  ngOnInit() {
  }
  estatus(i: number): string{
    return S.status[i];
  }
  cancelar(){
    this.cancelarCanje.emit();
  }
  borrar(){
    let conf: boolean = confirm("estas seguro de borrar los datos");

    if(conf){
      this.medicosSrv
      .setRedimirDelete(this.medico.id, this.canje.id)
      .subscribe(
        res => {
          let r: Response = <Response>res;
          if(r.code) {
           // this.medico.redimirmedico.find( rd => rd.id == r.data.id) 
           // let i: number = this.enfermera.canjesenfermera.find(canje => canje.id ==  this.canje.id);
            this.canje.status = 1;
            this.canje.fechaFinal = null;
            this.canje.observacion = null;
            this.canje.descripcion = null;
            
            this.canje.ruta = null;
            this.canje.media = null;
            this.canje.extension = null; 
            this.canje.filename = null; 
            this.canje.size = null;  
            
            this.canje.referencias = [];
            this.cancelar();      
          }
          this.snackBar.open(r.message, 'Documento', {
            duration: 3000,
            verticalPosition:'bottom',
            horizontalPosition:'center'
          });
          
        }, err => { console.log(err); }
      );

    }
    
  }

}
