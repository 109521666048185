import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Medico } from 'src/app/moddelo/medico';
import { Producto } from 'src/app/moddelo/producto';
import { ProductosService } from 'src/app/servicios/productos.service';

interface ICasoProductos{
  code: number;
  message: string;
  data: Producto[];
}
@Component({
  selector: 'app-caso-clinico-codigo-adm',
  templateUrl: './caso-clinico-codigo-adm.component.html',
  styleUrls: ['./caso-clinico-codigo-adm.component.css']
})
export class CasoClinicoCodigoAdmComponent implements OnInit {
  private catalogoProductos: Producto[] = [];
  public productosCache: Producto[] = [];
  
  @Input('casoclinico') casoclinico: CasoClinico;
  @Input('medico') medico: Medico;

  public productoFrm: FormGroup = new FormGroup({
          codigo: new FormControl('', Validators.required)
  });

  get codigoCtrl(){
    return this.productoFrm.get('codigo');
  }
  
  get productos(){
    return this.casoclinico.productos;
  }  
  
  constructor(  
                private productosSrv: ProductosService, 
                private matSnakBar: MatSnackBar) { }

  ngOnInit(): void {
              this.productosSrv.getProductos().subscribe(data => { this.catalogoProductos = data; }, err => { console.log(err); });    
  }
  onSubmit(frm: NgForm): void {
    let codigo: string = this.codigoCtrl.value;    
    let productodel: Producto = this.catalogoProductos.find( producto => producto.codigo.trim().toUpperCase() === codigo.trim().toUpperCase());
    if(productodel)
    {
         
          if(productodel.fk_casoClinico != null)
          {

              this.matSnakBar.open(`Codigo asignado anteriormente`, "Codigo", {
                duration: 2000,
                verticalPosition:'bottom',
                horizontalPosition:'center'
              });
              
          }
          else if(productodel.status === 0)
          {

              this.matSnakBar.open(`Codigo eliminado`, "Codigo", {
                duration: 2000,
                verticalPosition:'bottom',
                horizontalPosition:'center'
              });
              
          }
          else
          {
              productodel.fk_casoClinico = this.casoclinico.id;
              productodel.fk_medico = this.medico.id; 
              productodel.m_puntos = this.medico.puntos;
              productodel.fechaAplicacion = this.casoclinico.fechaAplicacionProducto;
              productodel.casoclinico = this.casoclinico;
              

              if(this.casoclinico.enfermera)
              {
                productodel.e_puntos = this.casoclinico.enfermera.puntos;
                productodel.fk_enfermera = this.casoclinico.enfermera.id;
                productodel.enfermera = this.casoclinico.enfermera;
              }                                     
             
              this.productosCache.push(productodel);
              
              this.codigoCtrl.clearValidators();

              frm.reset();
              
              this.codigoCtrl.setValidators( Validators.required );
             
          }
          
    }
    else 
    {      
      this.matSnakBar.open(`Codigo no registrado en el sistema`, "Codigo", {
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });
    }
  }

  onGuardar(): void {

  this.productosSrv
  .setProductosCaso(this.productosCache)
  .subscribe((data: CasoClinico) => {
                       
                        
                        if(data) {
                            
                          this.medico.productos.push(...this.productosCache); // data.productos
                          this.casoclinico.productos.push(...this.productosCache);

                          this.matSnakBar.open("Codigo Registrados con exito", "Codigo", {
                            duration: 2000,
                            verticalPosition:'bottom',
                            horizontalPosition:'center'
                          });

                          this.productosCache = [];
                        }
                      
                            
                      }, 
                      err => { console.log(err); } );
  
  }
  delete( i: number): void {
    this.productosCache[i].fk_casoClinico = null;
    this.productosCache[i].fk_medico = null; 
    this.productosCache[i].m_puntos = null;
    this.productosCache[i].fechaAplicacion = null;
    this.productosCache[i].casoclinico = null;
    this.productosCache[i].fk_casoClinico = null;

    this.productosCache.splice(i, 1);
    
  }

}
