import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent, Sort } from '@angular/material';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';

import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';

import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Hospital } from 'src/app/moddelo/hospital';
import { SeccionCuerpo } from 'src/app/moddelo/seccion-cuerpo';
import { Institucion } from 'src/app/moddelo/institucion';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { UsoProducto } from 'src/app/moddelo/uso-producto';

interface ItbCasosClinicos{
  No: string;
  Fecha: string;
  Institucion: string;
  Hospital: string;
	Folio: string;
  ParteCuerpo: string;
  UsoProducto: string;
  Sexo: string;
  Edad: number; 
	Estado: string;
	Medico: string;
  Enfermera: string;
  Productos: number;
  Canje: string;
}
@Component({
  selector: 'app-administrador-casos-clinicos-informes',
  templateUrl: './administrador-casos-clinicos-informes.component.html',
  styleUrls: ['./administrador-casos-clinicos-informes.component.css']
})
export class AdministradorCasosClinicosInformesComponent implements OnInit {
  public canje: string[] = ["registrado", "solicitud", "en proceso", "pagado", "cancelado"];
  public consultaFrm: FormGroup;
  public casosclinicos: CasoClinico [] = [];
  public casosclinicosFilter: CasoClinico [] = [];

  public hospitales: Hospital[] = [];
  public instituciones: Institucion[] = [];

  public filtroMedicos: Observable<String[]>;
  public nombresMedicos: String[] = [];

  public filtroEnfermeras: Observable<String[]>;
  public nombresEnfermeras: String[] = [];

  public filtrosHospitales: Hospital[]=[];
  public nombresHospitales: string[] = [];

  public filtrosInstituciones: Institucion[];
  public nombresInstituciones: string[] = [];
  
  public usosProducto: UsoProducto[] = [];
  public seccionesCuerpo: SeccionCuerpo[] = [];
  public seccionCuerpo: SeccionCuerpo = null; 

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public cargar: boolean = true;
  
  constructor(
    private formBuilder: FormBuilder, 
    private location: Location,
    private adminSrv: AdministradorService,
    private catalogoSrv: CatalogosService, 
    private casosClinicosSrv: CasosClinicosService ) { }
 

  get fechaInicialCtrl(){
    return this.consultaFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.consultaFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.consultaFrm.get('serie');
  }

  get fk_institucionCtrl(){
    return this.consultaFrm.get('fk_institucion');
  }
  
  get fk_hospitalCtrl(){
    return this.consultaFrm.get('fk_hospital');
  }
  get hospitalCtrl(){
    return this.consultaFrm.get('hospital');
  }

 
  get estadoCtrl(){
    return this.consultaFrm.get('estado');
  }

  get enfermeraCtrl() {
    return this.consultaFrm.get('enfermera');
  }

  get medicoCtrl(){
    return this.consultaFrm.get('medico');
  }

  get fk_uso_productoCtrl(){
    return this.consultaFrm.get('fk_uso_producto');
  }

  get fk_seccion_cuerpoCtrl(){
    return this.consultaFrm.get('fk_seccion_cuerpo');
  }

  ngOnInit() {
     
    this.casosClinicosSrv
    .getCasosClinicos()
    .pipe(finalize( () => this.cargar = false))
    .subscribe((data: CasoClinico[]) => {
                      this.casosclinicos = data.sort((a, b) => {
                        let fechaA: number = new Date(a.fecha).getTime();
                        let fechaB: number = new Date(b.fecha).getTime();
                        return fechaB - fechaA;
                      });
                      this.casosclinicosFilter = this.casosclinicos;
                }, err => { console.log(err); });

    this.consultaFrm = this.formBuilder.group({
                                                fechaInicial: [''],
                                                fechaFinal: [''],
                                                serie: [''],
                                                fk_institucion: [''],
                                                fk_hospital: [''],
                                                hospital: [''],
                                                medico: [''],
                                                enfermera: [''],                                               
                                                fk_uso_producto: [''],
                                                fk_seccion_cuerpo: [''],
                                                estado: ['']
                                              });
    this.catalogoSrv
    .getSeccionesCuerpo()
    .subscribe(data => { 
      this.seccionesCuerpo = data;
    }, error => { console.log(error)});

    this.catalogoSrv
    .getUsosProducto()
    .subscribe(data => {
      this.usosProducto = data;
    }, err => {console.log(err)})

    this.nombresMedicos = this.adminSrv.medicos.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) ); 
    this.filtroMedicos = this.medicoCtrl.valueChanges.pipe( startWith(''), map(value => this._filterMedicos(value)) );

    this.nombresEnfermeras = this.adminSrv.enfermeras.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) );  
    this.filtroEnfermeras = this.enfermeraCtrl.valueChanges.pipe( startWith(''), map(value => this._filterEnfermeras(value)) );   
    
    this.hospitales = this.adminSrv.hospitales;
   
    this.instituciones = this.adminSrv.instituciones;
    this.filtrosInstituciones = this.adminSrv.instituciones;
  }
  onSubmit(): void {
   
    this.casosclinicosFilter =  this.casosclinicos.filter(cc => 
                                              this.filterInstitucion((cc.centromedico?cc.centromedico.fk_institucion:0)) &&
                                              this.filterHospital(cc.fk_hospital) &&
                                              this.filterUsoProducto(cc.usosproducto) &&                             
                                              this.filterSeccionCuerpo(cc.fk_seccion_cuerpo) &&
                                              this.filterMedico(cc) &&
                                              this.filterEnfermera(cc) &&
                                              this.filterEstado(cc) &&
                                              this.filterFolio(cc.serie) &&
                                              this.filterFecha(cc.fecha.toString())
                                );
     this.page_number = 1;                         
  }
  /* filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  } */
  filterMedico(casoclinico: CasoClinico): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: string = "";
 
    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(casoclinico.fk_medico){
          nomMedico = casoclinico.medico ? (casoclinico.medico.nombre + ( casoclinico.medico.primerApellido ? ' ' + casoclinico.medico.primerApellido : '' ) + (casoclinico.medico.segundoApellido ? ' ' + casoclinico.medico.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );
          
        }
    }
    
    return condicion;
  }
  filterEnfermera(casoclinico: CasoClinico): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: string = ""; 

    if(this.enfermeraCtrl.value != ""){
          nom = this._normalizeValue(this.enfermeraCtrl.value);       
          nomEnfermera = casoclinico.enfermera ? (casoclinico.enfermera.nombre + ( casoclinico.enfermera.primerApellido ? ' ' + casoclinico.enfermera.primerApellido : '' ) + (casoclinico.enfermera.segundoApellido ? ' ' + casoclinico.enfermera.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );
        
    }    
    return condicion;
  } 
  filterInstitucion(fk_institucion: number): boolean {
    
    let condicion: boolean = true;  
    console.log("fk_institucion: ",this.fk_institucionCtrl.value);
    if( this.fk_institucionCtrl.value ){
      let id_inst: number = this.fk_institucionCtrl.value;       
      if(fk_institucion) {      
        condicion = id_inst == fk_institucion;      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterHospital(fk_hospital: number): boolean{
    let condicion: boolean = true;  

    if( this.fk_hospitalCtrl.value ){
      let id_hospital: number = this.fk_hospitalCtrl.value;       
      if(fk_hospital){      
        condicion = id_hospital == fk_hospital;      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterSeccionCuerpo(fk_seccion_cuerpo: number): boolean{
    let condicion: boolean = true;  
 //   console.log("fk_institucion: ",this.fk_seccion_cuerpoCtrl.value);
    if( this.fk_seccion_cuerpoCtrl.value ){
      let id_seccion: number = this.fk_seccion_cuerpoCtrl.value;       
      if(fk_seccion_cuerpo) {      
        condicion = id_seccion == fk_seccion_cuerpo;      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterUsoProducto(usos_producto: UsoProducto[]): boolean{
    let condicion: boolean = true;  
    // console.log("usos: ", usos_producto )
    if( this.fk_uso_productoCtrl.value ){
      let id_seccion: number = this.fk_uso_productoCtrl.value;       
      if(usos_producto) {      
       condicion = usos_producto.findIndex( u => u.id == id_seccion) != -1;   
      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  /*
  filterDiagnostico(nomDiagnostico: string): boolean{
    let condicion: boolean = true;
    let diagnostico: string;
    let d: string;
    let nombre: string;

    if( this.diagnosticoCtrl.value ){
      d = this.diagnosticoCtrl.value;
      diagnostico = d.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(nomDiagnostico){
        nombre = nomDiagnostico.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
        condicion = nombre.replace(/ +/g,'').includes(diagnostico.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  */
  filterEstado(casoclinico: CasoClinico): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEstado: string = ""; 
    if(this.estadoCtrl.value != ""){
        nom = this._normalizeValue(this.estadoCtrl.value);
        if(casoclinico.fk_estado){
          nomEstado = casoclinico.estado ? casoclinico.estado.nomEstado : '';
          condicion = ( this._normalizeValue(nomEstado).includes(nom) );
        }
    }
    
    return condicion;
  }
  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
         
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }
  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
       condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }
   
  _filterEnfermeras(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresEnfermeras.filter(enfermera => this._normalizeValue(enfermera).includes(valorFiltro));
  }  
  onChangeSelectInst(id_institucion: string) {  
   
    this.nombresHospitales = [];
   
    //let id_estado = this.fk_estadoCtrl.value;
   
    this.nombresHospitales = [];
  
    this.fk_hospitalCtrl.setValue('');  
   
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0)); 
     
  }
  
  //funcion sin definir
  refresh(){

    let envio = this.consultaFrm.value;

    console.log('FORMULARIO: ', envio);
    this.casosClinicosSrv
    .getCasosClinicosQuery(envio)
    .subscribe((data: CasoClinico[]) => { 
        this.casosclinicos = data;      
        console.log('REQUEST => ', data); 
    }, err => { console.log(err); });
  }
  handlePage( e: PageEvent): void {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  sortCasosClinicos(sort: Sort): void {
    const datos: CasoClinico[] = this.casosclinicosFilter.slice();
    if(!sort.active || sort.direction === ''){
      this.casosclinicosFilter = datos;
      return;
    }
    this.casosclinicosFilter =  datos.sort((a, b) => {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'fecha':
        let a1: number = new Date(a.fecha).getTime();
        let b1: number = new Date(b.fecha).getTime();
        return this.compare(a1, b1, isAsc);
      case 'serie':
        return this.compare(a.serie, b.serie, isAsc);
      case 'usoproducto':
        let usoa: string = a.usosproducto.length > 0 ? a.usosproducto[0].descripcion : '';
        let usob: string = b.usosproducto.length > 0 ? b.usosproducto[0].descripcion : '';
        return this.compare(usoa, usob, isAsc);     
      case 'hospital':
        let hospitala: string = a.centromedico ? a.centromedico.nomHospital: '';
        let hospitalb: string = b.centromedico ? b.centromedico.nomHospital: '';
        return this.compare(hospitala, hospitalb, isAsc);
      case 'institucion':
        let instituciona: string = a.centromedico ? (a.centromedico.institucion?a.centromedico.institucion.cvInstitucion:''): '';
        let institucionb: string = b.centromedico ? (b.centromedico.institucion?b.centromedico.institucion.cvInstitucion:''): '';
        return this.compare(instituciona, institucionb, isAsc);
      case 'estado':
        return this.compare(a.fk_estado, b.fk_estado, isAsc);
      case 'medico':
        let medicoA: string = (a.fk_medico ? a.medico.nombre + ' ' +( a.medico.primerApellido ? a.medico.primerApellido: '') : '');
        let medicoB: string = (b.fk_medico ? b.medico.nombre + ' ' +( b.medico.primerApellido ? b.medico.primerApellido : ''): '');
        return this.compare(medicoA, medicoB, isAsc);
      case 'productos':
        let productosA: number = a.productos ? a.productos.length : 0;
        let productosB: number = b.productos ? b.productos.length : 0;
        return this.compare(productosA, productosB, isAsc);
      case 'enfermera':
        let enfermeraA: string = (a.fk_enfermera ? a.enfermera.nombre + ' ' +( a.enfermera.primerApellido ? a.enfermera.primerApellido: '') : '');
        let enfermeraB: string = (b.fk_enfermera ? b.enfermera.nombre + ' ' +( b.enfermera.primerApellido ? b.enfermera.primerApellido : ''): '');
        return this.compare(enfermeraA, enfermeraB, isAsc);
      case 'seccioncuerpo':
        let cuerpoa: string = a.fk_seccion_cuerpo ? a.seccioncuerpo.nomCuerpo: '';
        let cuerpob: string = b.fk_seccion_cuerpo ? b.seccioncuerpo.nomCuerpo: '';
        return this.compare(cuerpoa, cuerpob, isAsc);       
      default:
        return 0;
    }
  });
  } 
  compare(a: number | string, b: number | string, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  generateData(): any[] {
    let result: ItbCasosClinicos[] = [];
    let i: number = 0;

    this.casosclinicosFilter.forEach((cc, i) => {
        
        result.push( {
                      No: (i+1).toString(),   
                      Fecha: cc.fecha ? moment(cc.fecha).format('L') : '',                     
                      Institucion: cc.centromedico ? ( cc.centromedico.institucion ? cc.centromedico.institucion.cvInstitucion : '') : '',
                      Hospital: cc.centromedico ? cc.centromedico.nomHospital : '', 
                      Folio: cc.serie,
                      ParteCuerpo: cc.fk_seccion_cuerpo ? cc.seccioncuerpo.nomCuerpo: '',
                      UsoProducto: cc.usosproducto.length>0 ? cc.usosproducto[0].descripcion: '', 
                      Sexo: cc.sexo,
                      Edad: cc.edad,                                         
                      Estado: cc.fk_estado ? cc.estado.nomEstado : '',                      
                      Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido : '',
                      Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido : '',                      
                      Productos: cc.productos ? cc.productos.length : 0, 
                      Canje: this.canje[cc.status_canje] 
                    });
    });

  return result;
  }
  exportTablaExcel(): void {
    let tCasosClinicos: ItbCasosClinicos[] = this.casosclinicosFilter.map((cc, i, casos) => {
      return <ItbCasosClinicos> { 
                              No: (i+1).toString(),   
                              Fecha: cc.fecha ? moment(cc.fecha).format('L') : '',
                              Institucion: cc.centromedico ? ( cc.centromedico.institucion ? cc.centromedico.institucion.cvInstitucion : '') : '',
                              Hospital: cc.centromedico ? cc.centromedico.nomHospital : '',  
                              Folio: cc.serie,                                                                                    
                              ParteCuerpo: cc.fk_seccion_cuerpo ? cc.seccioncuerpo.nomCuerpo: '',
                              UsoProducto: cc.usosproducto.length>0?cc.usosproducto[0].descripcion : '',
                              Sexo: cc.sexo,
                              Edad: cc.edad,
                              Estado: cc.fk_estado ? cc.estado.nomEstado : '', 
                              Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido: '', 
                              Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido: '',
                              Productos: cc.productos ? cc.productos.length : 0,
                              Canje: this.canje[cc.status_canje] 
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tCasosClinicos);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Casos Clinicos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_InfCasosClinicos.xlsx`);
  }
  exportPDF():  void {
    const doc = new jsPDF('landscape');  
     
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
          
     doc.text("CASOS CLINICOS",  145, 10, { align: "center"});
    
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'Institucion', dataKey: 'Institucion' },
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Folio', dataKey: 'Folio' },        
         { header: 'Parte de Cuerpo', dataKey: 'ParteCuerpo' },
         { header: 'UsoProducto', dataKey: 'UsoProducto' },
         { header: 'Estado', dataKey: 'Estado' },
         { header: 'Medico', dataKey: 'Medico' },       
         { header: 'Enfermera', dataKey: 'Enfermera' },
         { header: 'Productos', dataKey: 'Productos' },
         { header: 'Canje', dataKey: 'Canje' }
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_ccg.pdf`);
  }
  _filterMedicos(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresMedicos.filter(medico => this._normalizeValue(medico).includes(valorFiltro));
  }
  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }
  goBack(): void {
    this.location.back();
  }
}
