import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth.guard';
import { SupervisoresMainComponent } from './supervisores-main/supervisores-main.component';


const routes: Routes = [ {path: 'supervisor', component: SupervisoresMainComponent, canActivate: [AuthGuard]}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupervisoresRoutingModule { }
