import { RedimirEnfermeraReferencia } from "./redimir-enfermera-referencia";
import { CasoClinico } from './caso-clinico';

export class RedimirEnfermera 
{
    id: number;
    fk_enfermera: number;
    redimir: number;
    concepto: number;
    descripcion: string;    
    created_at: Date;
    updated_at: Date;
    status: number;
    fechaInicio: Date;
    fechaFinal: Date;
    observacion: string;
    
    ruta: string;
    media: string;
    extension: string;
    filename: string;
    size: number; 
    casosclinicos: CasoClinico[];
    referencias: RedimirEnfermeraReferencia[];
}
