import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PuntosPorProducto } from 'src/app/moddelo/puntos-por-producto';
import { AuthService } from 'src/app/servicios/auth.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

@Component({
  selector: 'app-puntos-productos',
  templateUrl: './puntos-productos.component.html',
  styleUrls: ['./puntos-productos.component.css']
})
export class PuntosProductosComponent implements OnInit {
  public puntosProductos: PuntosPorProducto[] = [];
  get usuario(){
    return this.auth.getUsuario;
  }

  get offLink(){
    return this.usuario.fk_nivel == 3 || this.usuario.fk_nivel == 0;
  }
  constructor(
              private auth: AuthService,
              private location: Location, 
              private catalogosSrv: CatalogosService
              ) { }

  ngOnInit() {
    this.catalogosSrv
    .getPuntosPorProductos()
    .subscribe(data => { this.puntosProductos = data; }, err => { console.log(err); });
  }
  goBack(): void{
    this.location.back();
  }

}
