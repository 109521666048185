import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { FormGroup, FormControl } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

import { Enfermera } from 'src/app/moddelo/enfermera';
import { Producto } from 'src/app/moddelo/producto';
import { PuntosPorProducto } from 'src/app/moddelo/puntos-por-producto';

interface ActualizarPuntosEnfermera{
    code: number;
    message: string;
    data: Producto;
  }

@Component({
    selector: 'dialogo-asignar-enfermera-producto-puntos',
    templateUrl : 'dialogo-asignar-enfermera-producto-puntos.html'
})
export class DialogoAsignarEnfermeraProductoPuntos {


    public respuesta: ActualizarPuntosEnfermera;
  
    public puntosProducto: PuntosPorProducto[] = [];  
    public productoFrm: FormGroup = new FormGroup({
                                                  codigo: new FormControl(''),
                                                  nomProducto: new FormControl(''),
                                                  e_puntos: new FormControl('')
                                                });
    get codigoCtrl(){
      return this.productoFrm.get('codigo');
    }
    get nomProductoCtrl(){
      return this.productoFrm.get('nomProducto');
    }                                            
    get e_puntosCtrl(){
      return this.productoFrm.get('e_puntos');
    }
    get usuario(){
      return this.auth.getUsuario;
    }
    get offBoton(){
      return this.productoFrm.valid && (this.usuario.fk_nivel==3 || this.usuario.fk_nivel==0)
    }

    constructor(
        private dialogo: MatDialogRef<DialogoAsignarEnfermeraProductoPuntos>,
        @Inject(MAT_DIALOG_DATA) public data: { producto: Producto, enfermera: Enfermera },
        private adminSrv: AdministradorService,  
        private catalogoSrv: CatalogosService,
        private snackBar: MatSnackBar,
        private auth: AuthService
    ){
          
    this.codigoCtrl.setValue(data.producto.codigo);
    this.nomProductoCtrl.setValue(data.producto.nomProducto);
    this.e_puntosCtrl.setValue(data.producto.e_puntos);
    this.catalogoSrv.getPuntosPorProductos().subscribe(puntos => this.puntosProducto = puntos, err => console.log(err) );    
    }

    onSubmit(){
   
        let id_producto =   this.data.producto.id;
        let puntos = this.e_puntosCtrl.value;
    
        this.adminSrv
        .setActualizarPuntosEnfermera(id_producto, puntos)
        .subscribe( data => { 
                                    this.respuesta = <ActualizarPuntosEnfermera> data;
                                    if(this.respuesta.code == 1) {
                                        this.data.producto.e_puntos = this.e_puntosCtrl.value;
                                        this.snackBar.open( this.respuesta.message, "Enfermera", 
                                                                                                {
                                                                                                  duration: 3000,
                                                                                                  verticalPosition:'bottom',
                                                                                                  horizontalPosition:'center'
                                                                                                });
                                        this.onClose();                                                        
                                    }
                                    else {
                                          this.snackBar.open( this.respuesta.message, "Enfermera", 
                                            {
                                              duration: 3000,
                                              verticalPosition:'bottom',
                                              horizontalPosition:'center'
                                            });
                                    }
                                   
                            }, err => { console.log(err); }); 
        
      }
      onClose(): void {
        this.dialogo.close();
    }
}