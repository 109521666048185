import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';
import moment  from 'moment/moment';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { ProductividadEnfermera } from 'src/app/moddelo/productividad-enfemera';
import { img } from 'src/app/datos/content-img';

@Component({
  selector: 'app-administrador-enfermeras-informes',
  templateUrl: './administrador-enfermeras-informes.component.html',
  styleUrls: ['./administrador-enfermeras-informes.component.css']
})
export class AdministradorEnfermerasInformesComponent implements OnInit {
  public enfermeras: ProductividadEnfermera[] = [];
  public enfermerasFilter: ProductividadEnfermera[] = [];
  public cargar: boolean = false;
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
                                                fechaInicial: new FormControl(''),
                                                fechaFinal: new FormControl('')
                                              });
  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }
  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }
  get url(): string{    
    return window.location.origin.replace(':4200', '');
  }  
  constructor(  
              private location: Location, 
              private enfermerasSrv: EnfermerasService
              ) { }

  ngOnInit() {
    moment.locale('es-MX');
  }


  onSubmit() {
    if(moment(this.fechaInicialCtrl.value).isValid()) {
        
      if(moment(this.fechaFinalCtrl.value).isValid()) {

        if(moment(this.fechaInicialCtrl.value) <= moment(this.fechaFinalCtrl.value)) {
            this.cargar = true;
            let finicial = moment(this.fechaInicialCtrl.value).format('YYYY-MM-DD');
            let ffinal = moment(this.fechaFinalCtrl.value).format('YYYY-MM-DD');
            this.enfermerasSrv
            .getProductividadEnfermeras(finicial, ffinal)
            .pipe(finalize( () => this.cargar = false))
            .subscribe(data => {
                this.enfermeras = data;
                this.enfermerasFilter = this.enfermeras;
                
            }, err => { console.log(err); });

        }else {
          alert('El rango de fechas no esta bien definido')
        }            

      }else {
        alert('La fecha final no es valida')
      }

    }else {
      alert('La fecha inicial no es valida')
    }
    
    
   
  }
  
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

  generateData() {
    let result = [];
    let i: number = 0;

    this.enfermerasFilter.forEach((m, i) => {
        
        result.push(Object.assign( {}, 
        { 
          No: (i+1).toString(),             
          Enfermera: m.enfermera,
          TotalCasos: m.totalCasos.toString(),
          TotalPuntos: m.totalPuntos.toString()
        }));

    });

  return result;
  }
  createHeaders(): any[] {
   let keys: string[] = [
                          "No",
                          "Enfermera",
                          "TotalCasos",
                          "TotalPuntos"
                        ]
    let result: any[]= []; 
    
    result = <any[]> keys.map(key => (<any> {
                                              id: key,
                                              name: key,
                                              prompt: key,
                                              width: "65",
                                              align: "center",
                                              padding: "0"
                                            }));

    
    return result;
  }
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let periodo: string = 'Periodo: ' + moment(this.fechaInicialCtrl.value).format('L') + '  a  ' + moment(this.fechaFinalCtrl.value).format('L');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("PRODUCTIVIDAD GENERAL DE TECNICOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(periodo, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'Enfermera', dataKey: 'Enfermera' },
         { header: 'Total de Casos Clinicos', dataKey: 'TotalCasos' },
         { header: 'Total de Puntos Acumulados', dataKey: 'TotalPuntos' },        
       ],       
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
         doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_productividad_Enfermera.pdf`);
  }
  exportTablaExcel(): void {
    let tEnfermeras: ProductividadEnfermera[] = this.enfermerasFilter.map((e, i) =>({  
                                                                                      id: e.id,
                                                                                      enfermera: e.enfermera,
                                                                                      totalCasos: e.totalCasos,
                                                                                      totalPuntos: e.totalPuntos
                                                                                    }));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tEnfermeras);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Enfermeras');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_ProductividadEnfermerasGeneral.xlsx`);
  }
  exportDetallesTablaPDF(): void
  {
    let fechaInicial = moment(this.fechaInicialCtrl.value).format('YYYY-MM-DD');
    let fechaFinal = moment(this.fechaFinalCtrl.value).format('YYYY-MM-DD');
    this.enfermerasSrv.getDestallesPDF(fechaInicial, fechaFinal).subscribe(data => {    
      window.open(this.url + data.filename, "_blank");
    }, err => { console.log(err)});
  }
  goBack(){
    this.location.back();
  }

}
