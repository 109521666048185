import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-usuario-eleccion',
  templateUrl: './usuario-eleccion.component.html',
  styleUrls: ['./usuario-eleccion.component.css']
})
export class UsuarioEleccionComponent implements OnInit {

  constructor( private location: Location) { }

  ngOnInit() {
  }
  goBack(): void {
    this.location.back();
  }

}
