import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Producto } from 'src/app/moddelo/producto';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { ProductosService } from 'src/app/servicios/productos.service';

interface Mensaje {
              code: number,
              message: string,
              producto: Producto
              }

@Component({
  selector: 'app-administrador-producto',
  templateUrl: './administrador-producto.component.html',
  styleUrls: ['./administrador-producto.component.css']
})
export class AdministradorProductoComponent implements OnInit {
 
  public producto: Producto = null;
  public productoFrm: FormGroup = new FormGroup({ 
                                                  id: new FormControl(''),
                                                  batch: new FormControl(''),
                                                  fecha: new FormControl(''),
                                                  nofactura: new FormControl(''),
                                                  fk_institucion: new FormControl(''),       
                                                  codigo: new FormControl('', Validators.required), 
                                                  nomProducto: new FormControl(''), 
                                                  lote: new FormControl(''),
                                                  caducidad: new FormControl(''),
                                                  fechaRegistro: new FormControl(''),
                                                  institucion: new FormControl(''),
                                                  selectFormControl: new FormControl('', Validators.required),
                                                  codigo_change: new FormControl(''), 
                                                });
  get idCtrl() {
    return this.productoFrm.get('id');
  }                                              
  get codigoCtrl(){
    return  this.productoFrm.get('codigo');
  }
  get nomProductoCtrl(){
    return  this.productoFrm.get('nomProducto');
  }
  get loteCtrl(){
    return  this.productoFrm.get('lote');
  }
  get caducidadCtrl(){
    return  this.productoFrm.get('caducidad');
  }

  get Seleccion(){
    return this.productoFrm.get('selectFormControl');
  }
  get codigo_changeCtrl(){
    return this.productoFrm.get('codigo_change');
  }
  constructor(
              public activate: ActivatedRoute, 
              public adminSrv: AdministradorService,
              private snackBar: MatSnackBar,
              public productosSrv: ProductosService,
              public location: Location) { }

  ngOnInit() {
    
    let id = Number(this.activate.snapshot.paramMap.get('id')); 
    this.producto = this.adminSrv.productos.find(p => p.id == id);
    
    this.idCtrl.setValue(this.producto.id);
    this.codigoCtrl.setValue(this.producto.codigo);
    this.nomProductoCtrl.setValue(this.producto.nomProducto);
    this.loteCtrl.setValue(this.producto.lote);
    this.caducidadCtrl.setValue(this.producto.caducidad);
    
    // let { batch, fecha, nofactura, fk_institucion, codigo, nomProducto, lote, caducidad, created_at, institucion} = this.producto;
    // this.productoFrm.setValue({id, batch, fecha, nofactura, fk_institucion, codigo, nomProducto, lote, caducidad, created_at, institucion});
  
  }
  onSubmit(){
    let id = this.idCtrl.value;
   
    this.productosSrv.setProductoDelete(id).subscribe(data => {
                let msj: Mensaje = <Mensaje> data;
                alert(msj.message);
                if(msj.code){
                    console.log(msj.producto);
                    this.adminSrv.productos.find(a => a.id == id).status = 0;                   
                }  
                this.goBack();
    }, err => { console.log(err)});
    
  }

  cancelar(){
    let id = this.idCtrl.value;
    let codigo_cambio = this.codigo_changeCtrl.value;
    let seleccion_cancelacion = this.Seleccion.value;
    let producto_cambio: Producto; 
    let producto_validado: Producto;
    if(seleccion_cancelacion == "2"){
      
      console.log("Cambio de Producto")
      
      if(codigo_cambio){
        
          producto_cambio = this.adminSrv.productos.find(a => a.codigo == codigo_cambio);
          if(producto_cambio === undefined){
            alert(`EL codigo ${codigo_cambio} no es valido`);
          } else {
                  producto_validado = this.adminSrv.productos.find(a => a.codigo_change == codigo_cambio);
                  if(producto_validado == undefined){
                    this.cancelacioncambio(id, producto_cambio.id.toString())                  
                  } else {
                    alert("se encuentra asignado " + producto_cambio.codigo );
                  }  
          }          
      } else {
        alert(" Se requiere codigo de cambio");
      }     
      
    }      
    if(seleccion_cancelacion == "3"){
      this.cancelacion(id, seleccion_cancelacion)
      console.log("Cancelacion por Caducidad")
    }
     
    if(seleccion_cancelacion == "4"){
      this.cancelacion(id, seleccion_cancelacion)
      console.log("Cancelacion por Exteporabilidad") // producto_cambio = this.adminSrv.productos.find(a => a.codigo == codigo_cambio);)
    }
    
    if(seleccion_cancelacion == "5"){
      this.cancelacion(id, seleccion_cancelacion)
      console.log("Cancelacio por Desabilitar")
    }
   
  }

  cancelacion(id: string, tipomov: string) {
      
    this.productosSrv.setProductoCancelar(id, tipomov).subscribe(data => {
                let msj: Mensaje = <Mensaje> data;
                
                alert(msj.message);

                if(msj.code) {
                    console.log(msj.producto);
                    
                    this.adminSrv.productos.find(a => a.id.toString() == id).status = 0;
                    this.adminSrv.productos.find(a => a.id.toString() == id).tipo_mov = Number(tipomov);                    
                                     
                }  
                this.goBack();
    }, err => { console.log(err) } );
    
  }
  cancelacioncambio(id1: string, id2: string){

    this.productosSrv.setProductoCancelarCambio(id1, id2).subscribe( data => {
      let msj: Mensaje = <Mensaje> data;
      let productodecambio: Producto;          
      alert(msj.message);

      if(msj.code) {
          console.log(msj.producto);
          productodecambio = this.adminSrv.productos.find(a => a.id.toString() == id2);

          this.adminSrv.productos.find(a => a.id.toString() == id1).status = 0;
          this.adminSrv.productos.find(a => a.id.toString() == id1).tipo_mov = 2;
          this.adminSrv.productos.find(a => a.id.toString() == id1).codigo_change = productodecambio.codigo;          
      }  
      this.goBack();
    }, err => { console.log(err); })
  }
  goBack(): void {
    this.location.back();
  }

}
