import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Producto } from 'src/app/moddelo/producto';
import { AuthService } from 'src/app/servicios/auth.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Medico } from 'src/app/moddelo/medico'; 
import { PuntosPorProducto } from 'src/app/moddelo/puntos-por-producto';

interface ActualizarPuntosMedico {      code: number;
                                        message: string;
                                        data: Producto;
                                 }
@Component({
    selector: 'dialogo-asignar-medico-producto-puntos',
    templateUrl: 'dialogo-asignar-medico-producto-puntos.html'
})
export class DialogoAsignarMedicoProductoPuntos {
      public respuesta: ActualizarPuntosMedico;
  
    public puntosProducto: PuntosPorProducto[] = [];  
    public productoFrm: FormGroup = new FormGroup({
                                                    codigo: new FormControl(''),
                                                    nomProducto: new FormControl(''),
                                                    puntos: new FormControl('', Validators.required)
                                                    });
    get codigoCtrl(){
        return this.productoFrm.get('codigo');
        }
    get nomProductoCtrl(){
        return this.productoFrm.get('nomProducto');
        }                                            
    get puntosCtrl(){
        return this.productoFrm.get('puntos');
        }
    get usuario(){
        return this.auth.getUsuario;    
        }
    get offBoton(){
        return this.productoFrm.valid && ( this.usuario.fk_nivel==0  || this.usuario.fk_nivel==3 );
        }
    get offSelect(){
        return ( this.usuario.fk_nivel == 0 ||  this.usuario.fk_nivel == 3);
        }                                                
    constructor(
                public dialogo: MatDialogRef<DialogoAsignarMedicoProductoPuntos>, 
                @Inject(MAT_DIALOG_DATA) public data: {producto: Producto, medico: Medico},
                private adminSrv: AdministradorService, 
                private catalogoSrv: CatalogosService,
                private snackBar: MatSnackBar,
                private auth: AuthService){
                                            this.codigoCtrl.setValue(this.data.producto.codigo);
                                            this.nomProductoCtrl.setValue(this.data.producto.nomProducto);
                                            this.puntosCtrl.setValue(this.data.producto.m_puntos);
                                        
                                            this.catalogoSrv.getPuntosPorProductos().subscribe(data => this.puntosProducto = data, err => console.log(err));
                }

    onSubmit(){

            let id_producto = this.data.producto.id;
            let puntos = this.puntosCtrl.value;

            this.adminSrv
            .setActualizarPuntosMedico(id_producto, puntos)
            .subscribe( data => { this.respuesta = <ActualizarPuntosMedico> data;
                                        if(this.respuesta.code == 1){
                                            this.data.producto.m_puntos = this.puntosCtrl.value;
                                            this.snackBar.open( this.respuesta.message, 'Medico', 
                                            {
                                                duration: 3000,
                                                verticalPosition:'bottom',
                                                horizontalPosition:'center'
                                            });
                                            this.onClose();
                                        }
                                        else {
                                                this.snackBar
                                                .open( this.respuesta.message, 'Medico', 
                                                {
                                                    duration: 3000,
                                                    verticalPosition:'bottom',
                                                    horizontalPosition:'center'
                                                });
                                            }
                                        
                                }, err => { console.log(err); }); 

    }            
    onClose(): void {
        this.dialogo.close();
    }            
}