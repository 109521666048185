import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Producto } from 'src/app/moddelo/producto';
import { Supervisor } from 'src/app/moddelo/supervisor';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Enfermera } from '../../../moddelo/enfermera';

@Component({
  selector: 'app-administrador-supervisor-main',
  templateUrl: './administrador-supervisor-main.component.html',
  styleUrls: ['./administrador-supervisor-main.component.css']
})
export class AdministradorSupervisorMainComponent implements OnInit, AfterViewInit {
  id: string = "";
  supervisor: Supervisor = null;
  casosclinicos: CasoClinico[] = [];
  productos: Producto[] = [];
 

  constructor(
    private activatedRoute: ActivatedRoute, 
    private location: Location, 
    private adminSrv: AdministradorService) { 
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }
 ngAfterViewInit(): void {
  this.adminSrv.getSupervisor(this.id)
  .subscribe( data => {
                        this.supervisor = data;
                        this.supervisor.enfermeras.map((enfermera) => this.casosclinicos.push(...enfermera.casosclinicos));  
                        this.supervisor.enfermeras.map((enfermera) => this.productos.push(...enfermera.productos));

                        
                    });
 }
  ngOnInit() {
    
    
  }
  goBack(): void{
    this.location.back();
  }

}
