import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriaComponent } from './categoria/categoria.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { PaginacionModule } from 'src/app/pipes/paginacion.module';
import { CategoriasRoutingModule } from './categorias-routing.module';


@NgModule({
  declarations: [CategoriaComponent, CategoriasComponent],
  imports: [
    CommonModule,
    FormsModule,
    CategoriasRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    PaginacionModule
  ]
})
export class CategoriasModule { }
