import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
//import { Router } from '@angular/router';
import { DocumentoIdentificacion } from 'src/app/moddelo/documento-identificacion';
import { Usuario } from 'src/app/moddelo/usuario';
import { AuthService } from 'src/app/servicios/auth.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
const ADMINISTRADOR = 2;
const MEDICO = 3;
const ENFERMERA = 5;
@Component({
  selector: 'app-usuario-perfil',
  templateUrl: './usuario-perfil.component.html',
  styleUrls: ['./usuario-perfil.component.css']
})
export class UsuarioPerfilComponent implements OnInit {
  public documentosId: DocumentoIdentificacion[] = [];
  public isChecked: boolean = true;  
  
  get usuario(): Usuario{
    return this.auth.getUsuario;
  }  
  get nombre(): string{
    return this.auth.getUsuario.nombre.concat(' ').concat(this.auth.getUsuario.primerApellido).concat(' ').concat(this.auth.getUsuario.segundoApellido);
  }
  get url(): string{    
    return window.location.origin.replace(':4200', '');
  }
  
  constructor(private auth: AuthService, 
            //  private router: Router,
              private usuariosSrv : UsuariosService,
              private catalogosSrv: CatalogosService,
              private location: Location) { }

  ngOnInit() {
        if(this.usuario.fk_rol == 6 && this.usuario.fk_nivel==3 ) this.isChecked = true; 
           
        if(this.usuario.fk_rol == 6 && this.usuario.fk_nivel==5 ) this.isChecked = false; 

        this.catalogosSrv.getDocumentosIdentificacion().subscribe(data => { this.documentosId = data;}, err => {console.log(err)});
  }

  DocIdentificacion(id: number): string{
    let msj: string = "";
    if(id && this.documentosId.length>0){
      msj = this.documentosId.find(doc => doc.id == id).nomDocumentoId;
    }
    return msj;
  }
  goBack(){
    this.location.back();
  }
  eCambio(e: any){
    let rol = "";
    let nivel = 0;

    if ( e.checked ) //rol="Administrador";
        nivel = 3;
    else  // rol="Supervisor";
        nivel = 5;
    this.usuariosSrv.setNivelUpdate(this.usuario.id, nivel).subscribe(data => {console.log(data); this.usuario.fk_nivel = nivel;}, err => {console.log(err)});
    //console.log(nivel, e.checked);
  }

}
