import { DocumentoUsuario } from "./documento-usuario";
import { Enfermera } from "./enfermera";
import { Medico } from "./medico";
import { Rol } from "./rol";
import { Supervisor } from "./supervisor";

export class Usuario {
        id: number;
        usuario: string;
        password: string;
        alias: string;
        nombre: string;        
        primerApellido: string;
        segundoApellido: string;        
        telefonoMovil: string;
        fk_rol: number;
        rol: Rol;
        fk_nivel: number;
        activo: number;
        enfermera: Enfermera;        
        medico: Medico;
        supervisor: Supervisor;
        documentos: DocumentoUsuario[];        
    }

