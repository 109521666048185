import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { SeccionCuerpo } from 'src/app/moddelo/seccion-cuerpo';

@Component({
  selector: 'app-seccioncuerpo',
  templateUrl: './seccioncuerpo.component.html',
  styleUrls: ['./seccioncuerpo.component.css']
})
export class SeccioncuerpoComponent implements OnInit {
  public id: number = 0;
  public seccionCuerpo: SeccionCuerpo = new SeccionCuerpo();

  private seccionCuerpoFrm: FormGroup = new FormGroup({
        nomCuerpo: new FormControl('', Validators.required)
  });

  get nomCuerpoCtrl() {
    return this.seccionCuerpoFrm.get('nomCuerpo');
  }

  constructor(private activated: ActivatedRoute,
    private location: Location, 
    private catalogosSrv: CatalogosService, 
    private snackBar: MatSnackBar) {   
  }

  ngOnInit() {
    this.id = Number(this.activated.snapshot.paramMap.get('id')); 
      
    if(this.id > 0){
      this.catalogosSrv
      .getSeccionCuerpo(this.id)
      .subscribe(data => {        
        this.seccionCuerpo = data;
        this.nomCuerpoCtrl.setValue(this.seccionCuerpo.nomCuerpo);
      }, err => { console.log(err); });
    }
  }
  actualizar(): void {
    this.seccionCuerpo.nomCuerpo = this.nomCuerpoCtrl.value;
    this.seccionCuerpo.id = this.id;
    this.catalogosSrv
    .setSeccionCuerpoUpdate(this.id, this.seccionCuerpo)
    .subscribe(data => { 
      this.snackBar.open('Actualizado', 'Servicio', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
    }, err => { console.log(err) });
  }
  onSubmit(){
    if(this.id>0){
      this.actualizar();
    }else{
      this.guardar();
    }
  }

  guardar(): void{
    this.seccionCuerpo.nomCuerpo = this.nomCuerpoCtrl.value;
    this.seccionCuerpo.id = this.id;

    this.catalogosSrv
    .setSeccionCuerpo(this.seccionCuerpo)
    .subscribe(data => {
      this.id = data.id;
      this.seccionCuerpo.nomCuerpo = data.nomCuerpo;
      this.seccionCuerpo.id = data.id;

      this.snackBar.open(' Registrado', 'Servicio', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
    }, err => { console.log(err)});
  }
  eliminar(id: number): void{    

    if(confirm("Estas seguro de eliminar")){
          this.catalogosSrv
          .setSeccionCuerpoDelete(id)
          .subscribe(data => {
                                if(data) {
                                    this.snackBar.open(' Eliminado ', 'Parte de Cuerpo', {
                                    duration: 2000,
                                    verticalPosition: 'bottom',
                                    horizontalPosition: 'center'
                                    });

                                  this.goBack();
                                } else {

                                  this.snackBar.open(' No Eliminado', 'Parte de Cuerpo', {
                                    duration: 2000,
                                    verticalPosition: 'bottom',
                                    horizontalPosition: 'center'
                                    });
                                } 
          }, err => { console.log(err)});
    }      
  }
  goBack(): void{
    this.location.back();
  }
}
