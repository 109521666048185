import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { PaginacionModule } from 'src/app/pipes/paginacion.module';

import { SupervisoresRoutingModule } from './supervisores-routing.module';
import { SupervisoresMainComponent } from './supervisores-main/supervisores-main.component';
import { SupervisorEnfermerasComponent } from './supervisor-enfermeras/supervisor-enfermeras.component';
import { SupervisorProductosComponent } from './supervisor-productos/supervisor-productos.component';
import { SupervisorCasosClinicosComponent } from './supervisor-casos-clinicos/supervisor-casos-clinicos.component';
import { CasoClinicoFichaSupervisorComponent } from '../casos-clinicos/caso-clinico-ficha-supervisor/caso-clinico-ficha-supervisor.component';
import { CasoClinicoDocumentoUploadSupervisorComponent } from '../casos-clinicos/caso-clinico-documento-upload-supervisor/caso-clinico-documento-upload-supervisor.component';
import { CasoClinicoDocumentosSupervisorComponent } from '../casos-clinicos/caso-clinico-documentos-supervisor/caso-clinico-documentos-supervisor.component';
import { SupervisorEnfermeraMedicosComponent } from './supervisor-enfermera-medicos/supervisor-enfermera-medicos.component';




@NgModule({
  declarations: [SupervisoresMainComponent, SupervisorEnfermerasComponent, SupervisorProductosComponent, SupervisorCasosClinicosComponent, CasoClinicoFichaSupervisorComponent, CasoClinicoDocumentoUploadSupervisorComponent, CasoClinicoDocumentosSupervisorComponent, SupervisorEnfermeraMedicosComponent], 
  imports: [
    CommonModule,
    SupervisoresRoutingModule,
    MaterialModule,
    PaginacionModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SupervisoresModule { }
