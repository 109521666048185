import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Usuario } from 'src/app/moddelo/usuario';
import { UsuariosService } from 'src/app/servicios/usuarios.service';

@Component({
  selector: 'app-administrador-usuario-adm-n1',
  templateUrl: './administrador-usuario-adm-n1.component.html',
  styleUrls: ['./administrador-usuario-adm-n1.component.css']
})
export class AdministradorUsuarioAdmN1Component implements OnInit {
  public usuario: Usuario = new Usuario();
  public usuarioFrm: FormGroup = new FormGroup({   
                                                  alias: new FormControl('', Validators.required),
                                                  nombre: new FormControl('', Validators.required),
                                                  primerApellido: new FormControl('', Validators.required),
                                                  segundoApellido: new FormControl(''),
                                                  telefonoMovil: new FormControl(''),
                                                  fk_nivel: new FormControl('', Validators.required),
                                                });
                                                get aliasCtrl(){
                                                  return this.usuarioFrm.get('alias');
                                                }
                                                get nombreCtrl(){
                                                  return this.usuarioFrm.get('nombre');
                                                }
                                                get primerApellidoCtrl(){
                                                  return this.usuarioFrm.get('primerApellido');
                                                }
                                                get segundoApellidoCtrl(){
                                                  return this.usuarioFrm.get('segundoApellido');
                                                }
                                                get telefonoMovilCtrl(){
                                                  return this.usuarioFrm.get('telefonoMovil');
                                                }
                                                get nivelCtrl(){
                                                  return this.usuarioFrm.get('fk_nivel');
                                                }
                                              
                                                constructor( private location: Location, private activated: ActivatedRoute, private usuariosSrv: UsuariosService) { }

  ngOnInit() {
    let id: number = Number( this.activated.snapshot.paramMap.get('id') ); 
    this.usuariosSrv.getUsuario(id).subscribe(data => { 
                                                        this.usuario = data; 
                                                        this.aliasCtrl.setValue(this.usuario.alias);
                                                        this.nombreCtrl.setValue(this.usuario.nombre);
                                                        this.primerApellidoCtrl.setValue(this.usuario.primerApellido);
                                                        this.segundoApellidoCtrl.setValue(this.usuario.segundoApellido);
                                                        this.telefonoMovilCtrl.setValue(this.usuario.telefonoMovil);
                                                        this.nivelCtrl.setValue(this.usuario.fk_nivel.toString());
                                                      }, err => { console.log(err) });
  }
  onSubmit(){
    let {alias, nombre, primerApellido, segundoApellido, telefonoMovil, fk_nivel}  = <Usuario> this.usuarioFrm.value;

    this.update(this.usuario.id, <Usuario>{alias, nombre, primerApellido, segundoApellido, telefonoMovil, fk_nivel});
    
  }
  delete(id: number){
    
    if(confirm("Seguro de eliminar este usuario")){
      this.usuariosSrv.deleteUsuario(id).subscribe(data => { if(data){ alert(`Usuario ${data.usuario} eliminado`); this.goBack();} }, err => { console.log(err) });
    }
    
  }
  desactivar(id: number){

    if(confirm("seguro de desabilitar este usuario")){
      this.usuariosSrv.desactivarUSuario(id).subscribe(data => { if(data){ alert(`Usuario ${data.usuario} desactivado`); this.goBack();} }, err => { console.log(err); });
    }

  }
  activar(id: number){
    
    if(confirm("seguro de habilitar este usuario")){
      this.usuariosSrv.activarUsuario(id).subscribe(data => { if(data){ alert(`Usuario ${data.usuario} activado`); this.goBack();} }, err => { console.log(err); });
    }
    
  }
  update(id: number, usuario: Usuario){
    this.usuariosSrv.updateUsuario(id, usuario).subscribe(data => { if(data){ alert("Registro Actualizado"); this.goBack();} }, err => { console.log(err) });
  }

  goBack(){
    this.location.back();
  }

}
