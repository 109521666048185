import { Component, Input, OnInit } from '@angular/core';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';

@Component({
  selector: 'app-caso-clinico-postoperatorios-adm',
  templateUrl: './caso-clinico-postoperatorios-adm.component.html',
  styleUrls: ['./caso-clinico-postoperatorios-adm.component.css']
})
export class CasoClinicoPostoperatoriosAdmComponent implements OnInit {
  public panelOpenState = false;
  public valoracion: String[] = ["", "Malo", "Regular", "Bueno", "Excelente"];
  
 @Input('casoclinico') casoclinico: CasoClinico;

  get seguimientos() {
    return this.casoclinico ? this.casoclinico.seguimientos : []; 
  }
  constructor() { }
  
  ngOnInit() {}

  valorar(i: number): String{
    return this.valoracion[i];
  }

}
