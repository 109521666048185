import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/servicios/auth.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { Medico } from 'src/app/moddelo/medico';
import { Producto } from 'src/app/moddelo/producto';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';

@Component({
  selector: 'app-medico-puntos',
  templateUrl: './medico-puntos.component.html',
  styleUrls: ['./medico-puntos.component.css']
})
export class MedicoPuntosComponent implements OnInit {
  public productos: Producto[] = [];
  public p: Producto[] = [];
  public medico: Medico;
  public filtroFrm: FormGroup;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(private medicosSrv: MedicosService, private aut: AuthService, private formBuilder: FormBuilder) { 
    this.medico = this.aut.getUsuario.medico;
  }

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get codigoCtrl() {
    return this.filtroFrm.get('codigo');
  }

  get productoCtrl() {
    return this.filtroFrm.get('producto');
  }
 
  get folioCtrl() {
    return this.filtroFrm.get('folio');
  }
  
  ngOnInit() {
    this.medicosSrv.card = 3;

    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial:[''],
                                            fechaFinal:[''],
                                            folio:[''],
                                            codigo:[''],
                                            producto:['']                                            
                                            });

    this.p = this.medicosSrv.medico.productos.sort((a, b) => {  let a_fecha = new Date(a.fechaAplicacion); 
                                                                        let b_fecha = new Date(b.fechaAplicacion); 
                                                                        return b_fecha.getTime() - a_fecha.getTime()
                                                                      });
    
    this.productos = this.p;                                                                  

  }

  onSubmit() {  
    this.productos = this.p.filter(producto => 
      this.filterCodigo(producto.codigo) && 
      this.filterProducto(producto.nomProducto) && 
      this.filterFolio(producto.casoclinico.folio) && 
      this.filterFecha(producto.fechaAplicacion));
    this.page_number = 1;
  }
  
  filterFecha(fechaCirugia: Date): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterCodigo(codigo: string): boolean {
    let condicion: boolean = true;
    let code: string = "";

    if(this.codigoCtrl.value != "") {
      code = this.codigoCtrl.value;
      if(codigo) {
        condicion = codigo.toUpperCase().includes(code.toUpperCase());
      }      
    }    
    return condicion;
  }

  filterProducto(nomProductos: string): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    
    if(this.productoCtrl.value != "") {
        nom = this.productoCtrl.value;
        if(nomProductos) {
          condicion = nomProductos.toUpperCase().includes(nom.toUpperCase());
        }        
    }    
    return condicion;
  }
 
  filterFolio(folio: string): boolean {
    let condicion: boolean = true;
    let fol: String = "";    

    if(this.folioCtrl.value != ""){
        fol = this._normalizeValue(this.folioCtrl.value);
        if(folio) {       
          condicion = folio.toLowerCase().includes(fol.toLowerCase());
        }
    }        
    return condicion;
  }

  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }

  handlePage( e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

}
