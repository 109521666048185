import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import moment, { MomentInput } from 'moment';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';

@Component({
  selector: 'app-caso-clinico-ficha-vista-admin',
  templateUrl: './caso-clinico-ficha-vista-admin.component.html',
  styleUrls: ['./caso-clinico-ficha-vista-admin.component.css']
})
export class CasoClinicoFichaVistaAdminComponent implements OnInit {
  
  public valoracion: String[] = ["", "Malo", "Regular", "Bueno", "Excelente"];
  
  @Input('enfermera') enfermera: Enfermera;
  @Input('casoclinico') casoclinico: CasoClinico;
  @Output() cancelarFicha: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }
    
  get seguimientos(){
    return  this.casoclinico.seguimientos;
  }
  get productos(){
    return  this.casoclinico.productos;
  }
  get fecha(){
    return  moment(this.casoclinico.fecha).format("L");
  }
  get fechaAplicacion(){
    let fechaApp = "";

    if(this.casoclinico.fechaAplicacionProducto)
        fechaApp = moment(this.casoclinico.fechaAplicacionProducto).format("L");

    return  fechaApp;
  }
  get sexo(){
    let sx = "";
    if(this.casoclinico.sexo == 'H')
        sx = "Hombre";
    if(this.casoclinico.sexo == 'M')
        sx = "Mujer";
    return sx;
  }   
  ngOnInit() {
    moment.locale('es');
  }
  onCancelar(){
    this.cancelarFicha.emit();
  }
  valorar(i: number): String{
    return this.valoracion[i];
  }
}
