import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { PuntosPorProducto } from "src/app/moddelo/puntos-por-producto";
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { Enfermera } from "src/app/moddelo/enfermera";

import { AuthService } from 'src/app/servicios/auth.service'
@Component({
    selector: 'dialogo-enfermera-puntos-default',
    templateUrl: 'dialogo-enfermera-puntos-default.html'
})
export class DialogoEnfermeraPuntosDefault {
    public enfermera: Enfermera = null;
    public puntos: PuntosPorProducto[] = [];
    public enfermeraFrm: FormGroup = new FormGroup({
        id: new FormControl(''),
        nombre: new FormControl(''),
        primerApellido: new FormControl(''),
        fk_puntos: new FormControl(''),
      });
    get idCtrl(){
        return this.enfermeraFrm.get("id");    
        }
    get nombreCtrl(){
        return this.enfermeraFrm.get("nombre");
        }
    get primerApellidoCtrl(){
        return this.enfermeraFrm.get("primerApellido");
        }
    get fk_puntosCtrl(){
        return this.enfermeraFrm.get("fk_puntos");
        }
    
    get usuario(){
        return this.aut.getUsuario;
        }
    get offSelect() {
        return (this.usuario.fk_nivel==0 || this.usuario.fk_nivel==3);
        }
    get offBoton(){
        return  this.enfermeraFrm.valid && (this.usuario.fk_nivel==0 || this.usuario.fk_nivel==3);
        }     
    constructor(
                public dialogo: MatDialogRef<DialogoEnfermeraPuntosDefault>,
                @Inject(MAT_DIALOG_DATA) public data: Enfermera,
                private adminSrv: AdministradorService,
                private catalogoSrv: CatalogosService,
                private snackBar: MatSnackBar,
                private aut: AuthService
                ){

                    this.enfermera = data;
                
                    this.idCtrl.setValue(this.enfermera.id);
                    this.nombreCtrl.setValue(this.enfermera.nombre);
                    this.primerApellidoCtrl.setValue(this.enfermera.primerApellido);
                    this.fk_puntosCtrl.setValue(this.enfermera.fk_puntos);
                    this.fk_puntosCtrl.setValidators(Validators.required);
                
                    this.catalogoSrv.getPuntosPorProductos().subscribe(puntos => {this.puntos = puntos;}, err => { console.log(err); });

                }
    onSubmit(): void{
            let pp: PuntosPorProducto = this.puntos.find(p => p.id == this.fk_puntosCtrl.value);
            let e: Enfermera = new Enfermera();
    
            e.id = this.idCtrl.value;
            e.fk_puntos = pp.id;
            e.puntos = pp.puntos;
    
            this.adminSrv.setEnfermeraUpdate(e.id, e)
            .subscribe(data => {
                                if(data){
                                    this.enfermera.fk_puntos = data.fk_puntos;
                                    this.enfermera.puntos = data.puntos;
                                    this.snackBar
                                        .open(
                                            'Se actualizo con exito', 
                                            'Enfermera', 
                                            {
                                            duration: 3000,
                                            verticalPosition:'bottom',
                                            horizontalPosition:'center'
                                            });
                                    this.onClose();
                                }
                                }, err => { console.log(err); });
    }            
    onClose(){
        this.dialogo.close();
    }        
}