import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { TipoDocumento } from 'src/app/moddelo/tipo-documento';
import { DocumentoIdentificacion } from 'src/app/moddelo/documento-identificacion';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { DocumentoUsuario } from 'src/app/moddelo/documento-usuario';
import { AuthService } from 'src/app/servicios/auth.service';
import { Usuario } from 'src/app/moddelo/usuario';

interface uploadFile{
  code: number;
  message: number;
  data: DocumentoUsuario;
}

@Component({
  selector: 'app-usuario-documentos-upload',
  templateUrl: './usuario-documentos-upload.component.html',
  styleUrls: ['./usuario-documentos-upload.component.css']
})
export class UsuarioDocumentosUploadComponent implements OnInit {
  public labelText: string = "Seleccione un archivo";
  public fileUploadProgress: string = "0%";
  public progress: number = 0;  
  public fileData: File;

  public documentosIds: DocumentoIdentificacion[] = [];
  public tiposDocumentos: TipoDocumento[] = [];
  public documentoUsuarioFrm: FormGroup = new FormGroup({
                                      fk_documentoId: new FormControl('', Validators.required),                                   
                                      fechaDocumento: new FormControl('', Validators.required),
                                      archivo: new FormControl('', Validators.required)
  });

  get fk_documentoIdCtrl(){
    return this.documentoUsuarioFrm.get('fk_documentoId');
  }
  get fechaDocumentoCtrl(){
    return this.documentoUsuarioFrm.get('fechaDocumento');
  }
  get archivoCtrl(){
    return this.documentoUsuarioFrm.get('archivo');
  }
  
  @Input() usuario: Usuario;  

  constructor(private catalogosSrv: CatalogosService, 
              private matSnackBar: MatSnackBar, 
              private usuariosSrv: UsuariosService,) { }

  ngOnInit() {

    this.catalogosSrv
    .getDocumentosIdentificacion()
    .subscribe(data => { this.documentosIds = data; }, err => { console.log(); });
    
  }  
  onSubmit() {
   
    const formData = new FormData();         

    formData.append('fk_usuario', this.usuario.id.toString());
          
    formData.append('fk_documentoId', this.fk_documentoIdCtrl.value);

    formData.append('fechaDocumento', formatDate(this.fechaDocumentoCtrl.value, "yyyy-MM-dd", "es-MX")); 

    formData.append('file', this.fileData);

    this.usuariosSrv
    .setSubir(formData)
    .subscribe( events => {
                                let r: uploadFile;
                                if(events.type === HttpEventType.UploadProgress){

                                  this.fileUploadProgress = Math.round( events.loaded / events.total * 100) + "%";
                                  this.progress = Math.round( events.loaded / events.total * 100);                                   
                                  
                                } else if( events.type === HttpEventType.Response){
                                    r = <uploadFile> events.body;
                                      
                                      if(r.data){                                    
                                            
                                            this.matSnackBar.open("Solicitud Procesada", "Documentos", {
                                              duration: 3000,
                                              verticalPosition:'bottom',
                                              horizontalPosition:'center'
                                            });
                                            this.usuario.documentos.push(r.data);
                                            
                                            //let documento: DocumentoUsuario = new DocumentoUsuario(); 
                                            //let { id, fk_usuario, fk_documentoId, fechaDocumento, nomDocumento, ruta, media, extension, tamano } = r.data;
                                            //documento = <DocumentoUsuario> { id, fk_usuario, fk_documentoId, fechaDocumento, nomDocumento, ruta, media, extension, tamano};
                                            //this.documentos.push(documento);
                                            
                                            this.documentoUsuarioFrm.clearValidators();
                                            this.documentoUsuarioFrm.reset();
                                            
                                            //this.fechaDocumentoCtrl.setValidators(Validators.required);
                                            //this.fk_tipoDocumentoCtrl.setValidators(Validators.required);
                                            //this.descDocumentoCtrl.setValidators(Validators.required);
                                            //this.archivoCtrl.setValidators(Validators.required);                                                                                      

                                      }else {
                                            this.matSnackBar.open("Verifique la informacion", "Documentos", {
                                                                                                                duration: 3000,
                                                                                                                verticalPosition:'bottom',
                                                                                                                horizontalPosition:'center'
                                                                                                              });
                                      }
                                            
                                  
                                } else if(events.type === HttpEventType.ResponseHeader){
                                    console.log( events.ok ); 
                                }
                              }, err => { console.log(err); }); 
 
}
  fileSelect(inputfile: any) {
    this.fileData = <File> inputfile.target.files[0];
    this.labelText = this.fileData.name;
  }
}
