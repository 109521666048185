import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';

import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import { CasoClinico } from '../../../moddelo/caso-clinico';

@Component({
  selector: 'app-enfermera-canje-add',
  templateUrl: './enfermera-canje-add.component.html',
  styleUrls: ['./enfermera-canje-add.component.css']
})
export class EnfermeraCanjeAddComponent implements OnInit {
  public casosclinicos: CasoClinico[] = [];
  private redimirEFrm: FormGroup = new FormGroup({
                                                  redimir: new FormControl(0),
                                                  fechaInicio: new FormControl('')
                                                });
  
  get fechaCtrl(){ return this.redimirEFrm.get('fechaInicio');}
  get redimirCtrl(){ return this.redimirEFrm.get('redimir');}
  
  get enfermera(){
    return this.enfermerasSrv.enfermera;
  }

  get saldo(){    
   // let canje = this.enfermera.redimirenfermera.filter(canje => canje.status == 3 || canje.status ==1 ).reduce((valor, canje) => canje.redimir + valor, 0);
    //let puntos = this.enfermera.casosclinicos.reduce((valor, casoclinico) => casoclinico.e_puntos + valor, 0);    
    //return puntos - canje;   
    let saldo = this.enfermera.casosclinicos.filter(cc => cc.status_canje == 0 ).reduce((valor, casoclinico) => casoclinico.e_puntos + valor, 0);    
    return saldo;
  }
  get cantidad(): number{
    return this.casosclinicos.reduce((valor, casoclinico) => casoclinico.e_puntos+valor, 0);
  }
  constructor(
              private location: Location, 
              private auth: AuthService, 
              private enfermerasSrv: EnfermerasService, 
              private snackBar: MatSnackBar ) {   }

  ngOnInit() {       
    this.validaciones();
    this.casosclinicos = this.enfermera.casosclinicos.filter(casoclinico => casoclinico.status_canje === 1 );
  }
  
  onSubmit(frm: NgForm){
   
    let canje: RedimirEnfermera = new RedimirEnfermera(); // { redimir, fechaInicio };
    canje.status = 1;
    canje.redimir = this.cantidad;
    canje.fk_enfermera = this.enfermera.id;
    canje.fechaInicio = this.fechaCtrl.value;
    
    this.enfermerasSrv
    .setRedimirCreate(canje, this.casosclinicos)
    .subscribe(data => { 
          console.log(data)
         this.enfermera.redimirenfermera.push(data); 
          
          frm.reset();          
          this.redimirCtrl.setValue(0);
          this.validaciones();
          this.enfermera.casosclinicos.map(cc => {
            if(cc.status_canje==1) {
              cc.fk_redimir_enfermera = data.id;
              cc.status_canje = 2;
            }
          })
          this.snackBar.open("Solicitud registrada", "Canje", {
            duration: 3000,
            verticalPosition:'bottom',
            horizontalPosition:'center'
          });

    }, err => { console.log( err ); } );
  }
  
  validaciones(): void{
   
    this.redimirEFrm.clearValidators();
    //Validar proceso de saldo evitar que tenga numeros negativos
    /*
    if(this.saldo > 0 ) 
    {
      this.redimirCtrl.setValidators( [Validators.min(100),Validators.required, Validators.max(this.saldo)]  );      
    } 
    */   
    this.fechaCtrl.setValidators(Validators.required)
  }

  goBack(): void {
    this.location.back();
  }

}
