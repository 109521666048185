import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { 
        MAT_MOMENT_DATE_FORMATS, 
        MomentDateAdapter, 
        MAT_MOMENT_DATE_ADAPTER_OPTIONS 
        } from '@angular/material-moment-adapter';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';

interface ActualizarCanje{
  code: number;
  message: string;
  data: RedimirEnfermera;
}
@Component({
  selector: 'app-administrador-enfermera-r-canje-update',
  templateUrl: './administrador-enfermera-r-canje-update.component.html',
  styleUrls: ['./administrador-enfermera-r-canje-update.component.css'],
  providers: [
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'es-MX'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, 
      useValue: MAT_MOMENT_DATE_FORMATS
    }]
})
export class AdministradorEnfermeraRCanjeUpdateComponent implements OnInit {
  
  @Input('enfermera') public enfermera: Enfermera;
  @Input('canje') public canje: RedimirEnfermera;
  @Output() private cancelarCanje: EventEmitter<any> = new EventEmitter<any>();
 
  public labelText: string = "Seleccione un archivo";
  public fileUploadProgress: string = "0%";
  public progress: number = 0;
  public fileData: File;
  public canjeFrm = new FormGroup({ 
                                  redimir: new FormControl(''),
                                  fechaFinal: new FormControl('', Validators.required),
                                  observacion: new FormControl(''),
                                  archivo: new FormControl('', Validators.required)                                  
                                  });
  get redimirCtrl() {
    return this.canjeFrm.get('redimir');
  } 
  get fechaFinalCtrl() {
    return this.canjeFrm.get('fechaFinal');
  }
  get observacionCtrl() {
    return this.canjeFrm.get('observacion');
  }
  get saldo() {
    return this.enfermera.productos.reduce((valor, producto) => producto.e_puntos + valor, 0) - this.enfermera.redimirenfermera.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
  }
  get puntos() {
    return this.enfermera.productos.reduce((valor, producto) => producto.e_puntos + valor, 0);
  } 
  get redimir() {
    return this.enfermera.redimirenfermera.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
  }
  get entransito() {
    let transito: number = this.enfermera.redimirenfermera.filter(canje => canje.status == 1).reduce((valor, canje ) => canje.redimir + valor, 0);
    return transito; //( transito - this.redimirCtrl.value) >= 0 ? (transito - this.redimirCtrl.value) : 0;
  }
  get pendientes() { 
      return this.redimirCtrl.value;
  }  
  get usuario() {
    return this.auth.getUsuario;
  } 
  get offBoton() {
    return this.canjeFrm.valid && ( this.usuario.fk_nivel==3 || this.usuario.fk_nivel==0)
  }
  get onBoton() {
   return (this.fechaFinalCtrl.errors || !this.observacionCtrl.value) && !( this.usuario.fk_nivel==3 || this.usuario.fk_nivel==2 || this.usuario.fk_nivel==0)
  }                                                                   
  constructor(
    private location: Location,
    private auth: AuthService,
    private enfermerasSrv: EnfermerasService,
    private adminSrv: AdministradorService,    
    private matSnackBar: MatSnackBar) { }

  ngOnInit() {
    /*
    if(this.saldo > 0)
    {
       this.redimirCtrl.setValidators( [Validators.max(this.saldo), Validators.min(500), Validators.required]);
    }
     this.redimirCtrl.setValue(this.canje.redimir); 
     */
  }
  onSubmit() {
   
    const formData = new FormData();
    
    formData.append('id', this.canje.id.toString());
    formData.append('redimir', this.canje.redimir.toString());
    formData.append('fk_enfermera', this.canje.fk_enfermera.toString());
    formData.append('fechaFinal', formatDate(this.fechaFinalCtrl.value, "yyyy-MM-dd", "es-MX") );
    formData.append('observacion', this.observacionCtrl.value); 
    formData.append('status', '3');  
    formData.append('file', this.fileData); 
   
    if(this.saldo - this.pendientes >= 0 )
    {
        this.enfermerasSrv
        .setRedimirUpdate(formData)
        .subscribe( events => {
                                  let r: ActualizarCanje;
                                  if(events.type === HttpEventType.UploadProgress){
  
                                    this.fileUploadProgress = Math.round( events.loaded / events.total * 100) + "%";
                                    this.progress = Math.round( events.loaded / events.total * 100);                                   
                                    
                                  } 
                                  else if( events.type === HttpEventType.Response){
                                        //console.log(events.body);
                                        let updateCanje: RedimirEnfermera = <RedimirEnfermera> events.body;
                                        this.canje.redimir = Number(updateCanje.redimir);
                                        this.canje.fechaFinal = updateCanje.fechaFinal;
                                        this.canje.status = updateCanje.status;
                                        this.canje.observacion = updateCanje.observacion;
                                        this.canje.referencias.push(updateCanje.referencias[0]); 
                                        this.canje.casosclinicos = updateCanje.casosclinicos; 
                                        this.canje.casosclinicos.forEach((value: CasoClinico, index: number, arr: CasoClinico[])=>{
                                            this.enfermera.casosclinicos.find(cc=>cc.id == value.id).status_canje=value.status_canje;
                                        });
                                        this.matSnackBar.open("Solicitud Procesada", "Canje", {
                                                                                      duration: 3000,
                                                                                      verticalPosition:'bottom',
                                                                                      horizontalPosition:'center'
                                                                                    }); 
                                        this.cancelar(); 
                                  } 
                                  else if(events.type === HttpEventType.ResponseHeader)
                                  {
                                      console.log( events.ok ); 
                                  }
                                }, err => {console.log(err);});
                                 
                          }
                          else
                          {      
                              this.matSnackBar.open("Puntos insuficientes", "Canje", {
                                duration: 3000,
                                verticalPosition:'bottom',
                                horizontalPosition:'center'
                              });
                          }

    
   
  }
  fileSelect(inputfile: any): void{
    this.fileData = <File> inputfile.target.files[0];
    this.labelText = this.fileData.name;
  }

  rechazado(): void{
    let canje: RedimirEnfermera = new RedimirEnfermera();
    canje.id =  this.canje.id;
    canje.redimir = this.redimirCtrl.value;
    canje.observacion = this.observacionCtrl.value;
    canje.fechaFinal = this.fechaFinalCtrl.value;
    canje.status = 2;

    this.enfermerasSrv
    .setRedimirUpdateRechazado(canje)
    .subscribe(data => {
                          if(data){
                            this.matSnackBar.open("Rechazada con exito", "Canje", {
                              duration: 3000,
                              verticalPosition:'bottom',
                              horizontalPosition:'center'
                            });
                            this.canje.redimir = Number(data.redimir);
                            this.canje.status = data.status;
                            this.canje.fechaFinal = data.fechaFinal;
                            this.canje.observacion = data.observacion;
                            this.canje.casosclinicos = data.casosclinicos;
                            this.canje.casosclinicos.forEach((value: CasoClinico, index: number, arr: CasoClinico[])=>{
                                    this.enfermera.casosclinicos.find(cc=>cc.id == value.id).status_canje=value.status_canje;
                            });
                          }else{
                            this.matSnackBar.open("No Rechazada", "Canje", {
                              duration: 3000,
                              verticalPosition:'bottom',
                              horizontalPosition:'center'
                            });
                          }
              }, err => {
                this.matSnackBar.open("No se puede rechazar", "Canje", {
                  duration: 3000,
                  verticalPosition:'bottom',
                  horizontalPosition:'center'
                });
              });    

  }

  cancelar(): void {
    this.cancelarCanje.emit();
  }


  delete(id: number): void{
    let caso = this.canje.casosclinicos.find((cc)=>cc.id == id );

    if(confirm("Estas seguro de remover de la lista el caso clinico " + caso.serie)){
      this.enfermerasSrv.setRedimirRemoveCasoClinico(id).subscribe(data=>{
        if(data) {
                  this.canje.redimir = data.redimir;
                  let x = this.canje.casosclinicos.filter(
                        (value: CasoClinico, index: number, arr: CasoClinico[]) => {
                        if(value.id == id){
                          arr.splice(index, 1);
                          return true;
                        }
                        return false;
                      }
                  )
                  this.enfermera.casosclinicos.find(cc=> cc.id == caso.id).status_canje = 0;
                //  alert(x[0].serie);
        }
      })
      
    }
  }

}
