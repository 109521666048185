import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { FormGroup, FormControl } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

import { Enfermera } from 'src/app/moddelo/enfermera';
import { PuntosPorProducto } from 'src/app/moddelo/puntos-por-producto';
import { CasoClinico } from "src/app/moddelo/caso-clinico";

interface ActualizarPuntosEnfermera{
    code: number;
    message: string;
    data: CasoClinico;
  }

@Component({
    selector: 'dialogo-asignar-enfermera-casoclinico-puntos',
    templateUrl : 'dialogo-asignar-enfermera-casoclinico-puntos.html'
})
export class DialogoAsignarEnfermeraCasoclinicoPuntos {


    public respuesta: ActualizarPuntosEnfermera;
  
    public puntosProducto: PuntosPorProducto[] = [];  
    public productoFrm: FormGroup = new FormGroup({
                                                  serie: new FormControl(''),
                                                  paciente: new FormControl(''),
                                                  e_puntos: new FormControl('')
                                                });
    get serieCtrl(){
        return this.productoFrm.get('serie');
    }
    get pacienteCtrl(){
      return this.productoFrm.get('paciente');
    }                                               
    get e_puntosCtrl(){
      return this.productoFrm.get('e_puntos');
    }
    get usuario(){
      return this.auth.getUsuario;
    }
    get offBoton(){
      return this.productoFrm.valid && (this.usuario.fk_nivel==3 || this.usuario.fk_nivel==0)
    }

    constructor(
        private dialogo: MatDialogRef<DialogoAsignarEnfermeraCasoclinicoPuntos>,
        @Inject(MAT_DIALOG_DATA) public data: { casoclinico: CasoClinico, enfermera: Enfermera },
        private adminSrv: AdministradorService,  
        private catalogoSrv: CatalogosService,
        private snackBar: MatSnackBar,
        private auth: AuthService
    ){
          
    this.serieCtrl.setValue(data.casoclinico.serie);
    this.pacienteCtrl.setValue(data.casoclinico.paciente);
    this.e_puntosCtrl.setValue(data.casoclinico.e_puntos);
    this.catalogoSrv.getPuntosPorProductos().subscribe(puntos => this.puntosProducto = puntos, err => console.log(err) );    
    }

    onSubmit(){
   
        let id_casoclinico =   this.data.casoclinico.id;
        let puntos = this.e_puntosCtrl.value;
    
        this.adminSrv
        .setActualizarPuntosCasoClinicoEnfermera(id_casoclinico, puntos)
        .subscribe( data => { 
                                    this.respuesta = <ActualizarPuntosEnfermera> data;
                                    if(this.respuesta.code == 1) {
                                        this.data.casoclinico.e_puntos = this.e_puntosCtrl.value;
                                        this.snackBar.open( this.respuesta.message, "Enfermera", 
                                                                                                {
                                                                                                  duration: 3000,
                                                                                                  verticalPosition:'bottom',
                                                                                                  horizontalPosition:'center'
                                                                                                });
                                        this.onClose();                                                        
                                    }
                                    else {
                                          this.snackBar.open( this.respuesta.message, "Enfermera", 
                                            {
                                              duration: 3000,
                                              verticalPosition:'bottom',
                                              horizontalPosition:'center'
                                            });
                                    }
                                   
                            }, err => { console.log(err); }); 
        
      }
      onClose(): void {
        this.dialogo.close();
    }
}