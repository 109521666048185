import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
//import { Observable } from 'rxjs';
//import { startWith, map } from 'rxjs/operators';

import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { Diagnostico } from 'src/app/moddelo/diagnostico';
import { Estado } from 'src/app/moddelo/estado';
import { Hospital } from 'src/app/moddelo/hospital';
import { Institucion } from 'src/app/moddelo/institucion';
import { Fecha } from 'src/app/moddelo/fecha';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Antibiotico } from 'src/app/moddelo/antibiotico';
import { Medico } from 'src/app/moddelo/medico';
//import { Documento } from 'src/app/moddelo/documento';
import { Usuario } from 'src/app/moddelo/usuario';
import { SeccionCuerpo } from 'src/app/moddelo/seccion-cuerpo';
import { UsoProducto } from 'src/app/moddelo/uso-producto';
import { Accion } from 'src/app/moddelo/accion';

interface IIdentificadores{
  folio: string;
  serie: string;
}
@Component({
  selector: 'app-caso-clinico-ficha-adm',
  templateUrl: './caso-clinico-ficha-adm.component.html',
  styleUrls: ['./caso-clinico-ficha-adm.component.css'],
  providers: [
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'es-MX'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, 
      useValue: MAT_MOMENT_DATE_FORMATS
    }]
})
export class CasoClinicoFichaAdmComponent implements OnInit {
  public idm: string = '0';
  public id: string = '0';
  public identificacionFrm: FormGroup;
  public cirugiaFrm: FormGroup;
  public seguimientoFrm: FormGroup;
  public accionesFrm: FormGroup;

  public isEditable: boolean = true;
  public isCompletedBasico: boolean = false;
  public isCompletedCirugia: boolean = false;

  public estados: Estado[] = [];
  public instituciones: Institucion[] = [];
  public hospitales: Hospital[] = [];
  public diagnosticos: Diagnostico[] = [];
  public antibioticos: Antibiotico [] = [];
  public seccionesCuerpo: SeccionCuerpo[] = [];
  public usosProducto: UsoProducto[] = [];
  public accionesList: Accion[] = [];

  public nombresHospitales: string[] = [];
  public filtrosHospitales: Hospital[] = [];
  public seccionCuerpo: SeccionCuerpo = null;
  public fecha: Fecha = null;
  public maxFecha: Date;
  public minFecha: Date;

  @Input('medico') medico: Medico;
  @Input('casoclinico') casoclinico: CasoClinico;
  @Output() cancelarFicha: EventEmitter<any> = new EventEmitter<any>();
  
  get usuario(): Usuario{
    return this.auth.getUsuario;
  }
  get offLink(): boolean {
    return (this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3);
  }
  get offAssiCodigo(): boolean {
    return (this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3);
  }

  get folioCtrl() {
    return this.identificacionFrm.get('folio');
  }
  get serieCtrl() {
    return this.identificacionFrm.get('serie');
  }
  get sexoCtrl() {
    return this.identificacionFrm.get('sexo');
  }
  get edadCtrl() {
    return this.identificacionFrm.get('edad');
  }
  get fechaCtrl() {
    return this.identificacionFrm.get('fecha');
  }
  get fk_estadoCtrl() {
    return this.identificacionFrm.get('fk_estado');
  }
  get fk_institucionCtrl() {
    return this.identificacionFrm.get('fk_institucion');
  }
  get otracveInstitucionCtrl() {
    return this.identificacionFrm.get('otracveInstitucion');
  }
  get fk_hospitalCtrl() {
    return this.identificacionFrm.get('fk_hospital');
  }
  get otrohospitalCtrl() {
    return this.identificacionFrm.get('otrohospital');
  }
  get diagnosticoCtrl() {
    return this.identificacionFrm.get('diagnostico');
  }
  get otrodiagnosticoCtrl() {
    return this.identificacionFrm.get('otrodiagnostico');
  }  

  get fechaAplicacionProductoCtrl() {
    return this.cirugiaFrm.get('fechaAplicacionProducto'); 
  }
  get fk_seccion_cuerpoCtrl() {
    return this.cirugiaFrm.get('fk_seccion_cuerpo');
  }  
  get fk_antibiotico1Ctrl() {
    return this.cirugiaFrm.get('fk_antibiotico1');
  }
  get cantidad1Ctrl() {
    return this.cirugiaFrm.get('cantidad1');
  }
  get fk_antibiotico2Ctrl() {
    return this.cirugiaFrm.get('fk_antibiotico2');
  }
  get cantidad2Ctrl() {
    return this.cirugiaFrm.get('cantidad2');
  }
  get fk_antibiotico3Ctrl() {
    return this.cirugiaFrm.get('fk_antibiotico3');
  }
  get cantidad3Ctrl() {
    return this.cirugiaFrm.get('cantidad3');
  }
  get accionesCtrls() {
    return this.cirugiaFrm.get("acciones") as FormArray; 
  }
  get usosProductoCtrls(){
    return this.cirugiaFrm.get("usosproducto");
  }
   
  get productos() {
    return this.casoclinico.productos ? this.casoclinico.productos : [];
  }

  get Osteosintesisdisabled ()
  {
   return this.desactivar(1);
  }
  get Lavadodisabled ()
  {
   return this.desactivar(2);
  }
  get Rellenodisabled ()
  {
   return this.desactivar(3);
  }
  get Intrumentaciondisabled ()
  {        
   return this.desactivar(4);
  }
  get Mediostintisdisabled ()
  {
   return this.desactivar(5);
  }
  get Injertosvascularesdisabled()
  {
   return this.desactivar(6);
  }
  get Protesisdisabled()
  {
   return this.desactivar(7);
  }
  get Clavocentromedulardisabled()
  {
   return this.desactivar(8);
  }
  get Mallasdisabled () 
  {
   return this.desactivar(9);
  }
  get Dehiscenciasdisabled () 
  {
   return this.desactivar(10);
  }
  get SxFournierdisabled()
  {
   return this.desactivar(11);
  }
  get Amputaciondisabled()
  {
   return this.desactivar(12);
  }
  get Viscosuplementaciondisabled()
  {
    return this.desactivar(13);
  }
  desactivar(id: number): boolean {
     if(!this.seccionCuerpo) return false;
     if(this.seccionCuerpo.acciones.find(cuerpo => cuerpo.id == id))
     return false;
     else 
     return true;
  }
 
 
  constructor(
              private formBuilder: FormBuilder, 
              private catalogosSrv: CatalogosService, 
              private auth: AuthService, 
              private casosclinicosSrv: CasosClinicosService,
              private adminSrv: AdministradorService,
              private matSnakBar: MatSnackBar) { }

  ngOnInit() {
    let idsAcciones = [];
    if(this.casoclinico.id != undefined)
       this.id = this.casoclinico.id.toString(); 
   
    
    this.identificacionFrm = this.formBuilder.group({
                                                    folio:[''],
                                                    serie:[''],
                                                    paciente:[''],
                                                    sexo:['', Validators.required],
                                                    edad:['', Validators.required],
                                                    fecha:['', Validators.required],
                                                    fk_estado:['', Validators.required],
                                                    fk_institucion:['', Validators.required],
                                                    otracveInstitucion:[''],
                                                    fk_hospital:['', Validators.required],
                                                    otrohospital:[''],
                                                    diagnostico:['', Validators.required],
                                                    otrodiagnostico:[''],
                                                    antecedente:[''] 
                                                });

    this.cirugiaFrm = this.formBuilder.group({
                                              fk_seccion_cuerpo:[''],
                                              acciones: this.formBuilder.array([]),
                                              usosproducto: [''],
                                              fk_antibiotico1:[''],                                              
                                              cantidad1:[''],
                                              fk_antibiotico2:[''],                                              
                                              cantidad2:[''],
                                              fk_antibiotico3:[''],                                              
                                              cantidad3:[''],
                                              tratamiento:[''],
                                              fechaAplicacionProducto:['', Validators.required],
                                              observacion:['']
                                            });

    this.accionesFrm = this.formBuilder.group({
                                              osteosintesis: [false],
                                              lavado: [false],
                                              relleno: [false],
                                              instrumentacion: [false],
                                              mediostinitis: [false],
                                              injertosvasculares: [false],
                                              protesis: [false],
                                              clavocentromedular: [false],
                                              mallas: [false],
                                              dehiscencias: [false],
                                              sxfournier: [false],
                                              amputacion: [false],
                                              viscosuplementacion: [false],
                                            });      
    
    this.accionesCtrls.push(this.accionesFrm);                                         
     
    if(this.id != '0') {

        const { id, folio, serie,  paciente, sexo, edad, fecha, fk_estado, fk_hospital, hospital, fk_institucion, fk_diagnostico, antecedente, diagnostico, cveInstitucion }  = this.casoclinico; 
                  
        this.identificacionFrm.reset({ folio, serie,  paciente, sexo, edad, fecha, fk_estado, fk_institucion, antecedente, diagnostico });
        this.identificacionFrm.get('fk_hospital').setValue(fk_hospital);
         
        if(fk_institucion!=null)
            if(fk_institucion==0)
                this.identificacionFrm.get('otracveInstitucion').setValue(cveInstitucion);
           
        this.isCompletedBasico = true;

        const { fechaAplicacionProducto, tratamiento, fk_antibiotico1, cantidad1, fk_antibiotico2, cantidad2, fk_antibiotico3, cantidad3, observacion, fk_seccion_cuerpo, acciones, usosproducto } = this.casoclinico; 
        this.cirugiaFrm.reset({ fechaAplicacionProducto, tratamiento, fk_antibiotico1, cantidad1, fk_antibiotico2, cantidad2, fk_antibiotico3, cantidad3, observacion, fk_seccion_cuerpo});
        
        if(fechaAplicacionProducto) {
          this.isCompletedCirugia= true;
        }
        if(acciones)
           this.setAccionesFrm(acciones);  
          
        if(acciones)
            idsAcciones = acciones.map(accion => { return accion.id }); 
            
        if(usosproducto)
            if(usosproducto[0] != undefined)
                this.usosProductoCtrls.setValue(usosproducto[0].id);           
    } 
    else {    
     
      this.casosclinicosSrv
      .getFolio(this.medico.id)
      .subscribe((data: IIdentificadores) => {
        this.serieCtrl.setValue(data.serie);
        this.folioCtrl.setValue(data.folio);
      }, err => { console.log(err); });
               
    }

    this.casosclinicosSrv
    .getFechas(this.id)
    .subscribe(data => { 
                          this.fecha = data; 
                          this.maxFecha = this.fecha.fechaHoy;
                          this.minFecha = this.fecha.fechaMin;
                         //  dia: number = (1000 * 60 * 60 * 24);                         
                        }, err => { console.log(err) });

    this.catalogosSrv
    .getEstados()
    .subscribe(data => {
                          this.estados = data;
                        }, err => { console.log(err) });
    
    this.catalogosSrv
    .getInstituciones()
    .subscribe(data => {
                          this.instituciones = data;
                          this.instituciones.push({id: 0, nomInstitucion: "OTRA", cvInstitucion: "OTRA", hospitales: [], casosclinicos: []});
                        }, err => { console.log(err) });

    this.catalogosSrv
    .getHospitales()
    .subscribe(data => {
                          this.hospitales = data;
                          this.filtrosHospitales = data;
                        }, err => { console.log(err) });

    this.catalogosSrv
    .getDiagnosticos()
    .subscribe(data => {
                          this.diagnosticos = data;
                          this.diagnosticos.push({id: 0, nomDiagnostico: "OTRO"});
                        }, err => { console.log(err) });
    
    this.catalogosSrv
    .getAntibioticos()
    .subscribe(data => { 
                          this.antibioticos = data; 
                        }, err => { console.log(err); });
    this.catalogosSrv
    .getUsosProducto()
    .subscribe(data => {
            this.usosProducto = data;
    });
                  
    this.catalogosSrv
    .getSeccionesCuerpo()
    .subscribe(data => {
                          this.seccionesCuerpo = data;
                          if(this.fk_seccion_cuerpoCtrl.value) {                              
                            this.accionesList = data.find(seccio => seccio.id == this.fk_seccion_cuerpoCtrl.value).acciones;
                            this.seccionCuerpo = this.seccionesCuerpo.find(seccion => seccion.id == this.fk_seccion_cuerpoCtrl.value);
                          }

                        }, err => { console.log(err); });                         
    
  }

  onSubmitFicha() {

    if(this.id == '0') {
      this.saveFicha();
    }
    else {
      this.updateFicha();
    }

  }
  
  onSubmitCirugia() {
    this.updateCirugia();
  } 

  setFormatFicha(propiedad: boolean=false): CasoClinico {

    const { folio, serie, paciente, sexo, edad, fecha, fk_estado, antecedente } = this.identificacionFrm.value;
    let casoClinico: CasoClinico = <CasoClinico> { folio, serie, paciente, sexo, edad, fecha, fk_estado, antecedente };
    let institucion: Institucion = this.institucionformal(this.fk_institucionCtrl.value, this.otracveInstitucionCtrl.value);
    let hospital: Hospital =  this.hospitales.find((h) => h.id.toString() == this.fk_hospitalCtrl.value); 
    
    if(propiedad) {
      casoClinico.fk_medico = this.medico.id;
      casoClinico.fk_enfermera = this.medico.enfermeras.length == 1 ? this.medico.enfermeras[0].id : null;
    }
    

    casoClinico.fk_diagnostico = 0;
    casoClinico.diagnostico = this.diagnosticoCtrl.value;

    casoClinico.fk_institucion = institucion.id;
    casoClinico.cveInstitucion = institucion.cvInstitucion;

    casoClinico.fk_hospital = hospital.id;
    casoClinico.hospital = hospital.nomHospital;
   
       
    return casoClinico;
  }
 
  getFormatFichaCCSRV(casoClinico: CasoClinico, data: CasoClinico): CasoClinico {
    const { id, folio, serie,  paciente, sexo, edad, fecha, fk_estado, antecedente, fk_medico, fk_diagnostico, diagnostico, fk_institucion, cveInstitucion, fk_hospital, hospital, fk_enfermera } = data;
    
    casoClinico.id = id;
    casoClinico.folio = folio;
    casoClinico.serie = serie;
    casoClinico.paciente = paciente;
    casoClinico.sexo = sexo;
    casoClinico.edad = edad;
    casoClinico.fecha = fecha;
    casoClinico.fk_estado = fk_estado;
    casoClinico.antecedente = antecedente;
    casoClinico.fk_medico = fk_medico;
    casoClinico.fk_diagnostico = fk_diagnostico;
    casoClinico.diagnostico = diagnostico;
    casoClinico.fk_institucion = fk_institucion;
    casoClinico.cveInstitucion = cveInstitucion;
    casoClinico.fk_hospital = fk_hospital;
    casoClinico.hospital = hospital;
    casoClinico.fk_enfermera = fk_enfermera;
    
    if(data.enfermera)
      casoClinico.enfermera = data.enfermera;  
  
   return casoClinico;
  }

  setFormatCirugia(): any {

    const { tratamiento, fechaAplicacionProducto, observacion, acciones } =  this.cirugiaFrm.value;
    const casoClinico: CasoClinico = <CasoClinico>  { tratamiento, fechaAplicacionProducto, observacion }
    const accionesCirugias: number[] = [];
    const usosProductoCirugia: number[] = [];


    if(this.fk_seccion_cuerpoCtrl.value){
      let seccionCuerpo: SeccionCuerpo = this.seccioncuerpo(this.fk_seccion_cuerpoCtrl.value);
      casoClinico.fk_seccion_cuerpo = seccionCuerpo.id;
      casoClinico.nomCuerpo = seccionCuerpo.nomCuerpo;
    }
    if(this.fk_antibiotico1Ctrl.value){
      let antibiotico: Antibiotico =  this.antibiotico(this.fk_antibiotico1Ctrl.value);
      casoClinico.fk_antibiotico1 = antibiotico.id;
      casoClinico.nomAntibiotico1 = antibiotico.nomAntibiotico;
      casoClinico.cantidad1 = this.cantidad1Ctrl.value;
    }
    if(this.fk_antibiotico2Ctrl.value){
      let antibiotico: Antibiotico =  this.antibiotico(this.fk_antibiotico2Ctrl.value);
      casoClinico.fk_antibiotico2 = antibiotico.id;
      casoClinico.nomAntibiotico2 = antibiotico.nomAntibiotico;
      casoClinico.cantidad2 = this.cantidad2Ctrl.value;
    }
    if(this.fk_antibiotico3Ctrl.value){
      let antibiotico: Antibiotico =  this.antibiotico(this.fk_antibiotico3Ctrl.value);
      casoClinico.fk_antibiotico3 = antibiotico.id;
      casoClinico.nomAntibiotico3 = antibiotico.nomAntibiotico;
      casoClinico.cantidad3 = this.cantidad3Ctrl.value;
    }
    if(this.usosProductoCtrls.value){
      usosProductoCirugia.push(this.usosProductoCtrls.value)
    }
   

  if(acciones[0].osteosintesis)
      accionesCirugias.push(1);
  if(acciones[0].lavado)
      accionesCirugias.push(2);
  if(acciones[0].relleno)    
      accionesCirugias.push(3);
  if(acciones[0].instrumentacion)
      accionesCirugias.push(4);    
  if (acciones[0].mediostinitis)
      accionesCirugias.push(5);
  if (acciones[0].injertosvasculares)
      accionesCirugias.push(6);
  if (acciones[0].protesis)
      accionesCirugias.push(7);
  if (acciones[0].clavocentromedular)
      accionesCirugias.push(8);
  if (acciones[0].mallas)
      accionesCirugias.push(9);
  if (acciones[0].dehiscencias)
      accionesCirugias.push(10);
  if (acciones[0].sxfournier)
      accionesCirugias.push(11);
  if (acciones[0].amputacion)
      accionesCirugias.push(12);

   
  return {casoClinico: casoClinico, accionesCirugia: accionesCirugias, usosProductoCirugia: usosProductoCirugia};
}

  getFormatCirugiaCCSRV(casoClinico: CasoClinico, data: CasoClinico): CasoClinico {
    let { tratamiento, fechaAplicacionProducto, observacion, fk_antibiotico1, nomAntibiotico1, cantidad1, fk_antibiotico2, nomAntibiotico2, cantidad2, fk_antibiotico3, nomAntibiotico3, cantidad3 } = data;
    
    casoClinico.tratamiento=tratamiento; 
		casoClinico.fechaAplicacionProducto=fechaAplicacionProducto; 
		casoClinico.observacion = observacion; 
		casoClinico.fk_antibiotico1 = fk_antibiotico1; 
		casoClinico.nomAntibiotico1 = nomAntibiotico1;			 
		casoClinico.cantidad1=cantidad1;
			 
		casoClinico.fk_antibiotico2 = fk_antibiotico2; 
		casoClinico.nomAntibiotico2 = nomAntibiotico2;			 
		casoClinico.cantidad2=cantidad2;
			 
		casoClinico.fk_antibiotico3 = fk_antibiotico3; 
		casoClinico.nomAntibiotico3 = nomAntibiotico3;			 
		casoClinico.cantidad3=cantidad3;
 

    return casoClinico;
  }

  saveFicha(): void {

     this.casosclinicosSrv
    .setCasoClinico(this.setFormatFicha(true))
    .subscribe(data => { 
      
      if(data){

        let { id, folio, serie, paciente, sexo, edad, fecha, fk_estado, antecedente,fk_medico, fk_diagnostico, diagnostico, fk_institucion, cveInstitucion, fk_hospital, hospital, fk_enfermera } = data;
                              this.id = id.toString();
                              this.casoclinico.id = id;
                              this.casoclinico.folio = folio;
                              this.casoclinico.serie = serie;                          
                              this.casoclinico.paciente = paciente;
                              this.casoclinico.sexo = sexo;
                              this.casoclinico.edad = edad;
                              this.casoclinico.fecha = fecha;
                              this.casoclinico.fk_estado = fk_estado;
                              this.casoclinico.antecedente = antecedente;
                              this.casoclinico.fk_medico = fk_medico;
                              this.casoclinico.fk_diagnostico = fk_diagnostico;
                              this.casoclinico.diagnostico = diagnostico;
                              this.casoclinico.fk_institucion = fk_institucion;
                              this.casoclinico.cveInstitucion = cveInstitucion;
                              this.casoclinico.fk_hospital = fk_hospital;
                              this.casoclinico.hospital = hospital;
                               this.casoclinico.fk_enfermera = fk_enfermera;
                              
                              if(data.enfermera)
                                this.casoclinico.enfermera = data.enfermera; 
                              else   
                                this.casoclinico.enfermera = null; 

                              if(data.centromedico) 
                                this.casoclinico.centromedico = data.centromedico; 
                              else
                                this.casoclinico.centromedico = null; 

                              if(data.documentos) 
                                this.casoclinico.documentos = data.documentos;
                              else   
                                this.casoclinico.documentos = [];

                              if(data.estado) 
                                this.casoclinico.estado = data.estado; 
                              else
                                this.casoclinico.estado = null; 

                              if(data.institucion) 
                                this.casoclinico.institucion = data.institucion;
                              else
                                this.casoclinico.institucion = null;  
                              
                              if(data.productos)
                                this.casoclinico.productos = data.productos;
                              else
                                this.casoclinico.productos = null;                                     
                              
                              if(data.seguimientos)
                                this.casoclinico.seguimientos = data.seguimientos;  
                              else 
                                this.casoclinico.seguimientos = null; 

                              this.medico.casosclinicos.push(this.casoclinico);
        
      

                      this.isCompletedBasico = true;
      
                this.matSnakBar.open(`Registrado con el No. ${data.serie}`, "Caso Clinico",{
                  duration: 2000,
                  verticalPosition:'bottom',
                  horizontalPosition:'center'
                });
      }          

    }, err => { console.log(err) });

  }
    
  updateFicha(): void {
            
    this.casosclinicosSrv
    .setCasoClinicoFichaUpdate(this.id, this.setFormatFicha())
    .subscribe(data => {  
                            if(data) {
                              let { paciente, sexo, edad, fecha, fk_estado, antecedente,fk_medico, fk_diagnostico, diagnostico, fk_institucion, cveInstitucion, fk_hospital, hospital, fk_enfermera } = data;
    
                                                        
                              this.casoclinico.paciente = paciente;
                              this.casoclinico.sexo = sexo;
                              this.casoclinico.edad = edad;
                              this.casoclinico.fecha = fecha;
                              this.casoclinico.fk_estado = fk_estado;
                              this.casoclinico.antecedente = antecedente;
                              this.casoclinico.fk_medico = fk_medico;
                              this.casoclinico.fk_diagnostico = fk_diagnostico;
                              this.casoclinico.diagnostico = diagnostico;
                              this.casoclinico.fk_institucion = fk_institucion;
                              this.casoclinico.cveInstitucion = cveInstitucion;
                              this.casoclinico.fk_hospital = fk_hospital;
                              this.casoclinico.hospital = hospital;
                              this.casoclinico.fk_enfermera = fk_enfermera;
                              
                              if(data.enfermera)
                                this.casoclinico.enfermera = data.enfermera; 
                              else   
                                this.casoclinico.enfermera = null; 

                              if(data.centromedico) 
                                this.casoclinico.centromedico = data.centromedico; 
                              else
                                this.casoclinico.centromedico = null; 

                              if(data.documentos) 
                                this.casoclinico.documentos = data.documentos;
                              else   
                                this.casoclinico.documentos = [];

                              if(data.estado) 
                                this.casoclinico.estado = data.estado; 
                              else
                                this.casoclinico.estado = null; 

                              if(data.institucion) 
                                this.casoclinico.institucion = data.institucion;
                              else
                                this.casoclinico.institucion = null;  
                              
                              if(data.productos)
                                this.casoclinico.productos = data.productos;
                              else
                                this.casoclinico.productos = null;                                     
                              
                              if(data.seguimientos)
                                this.casoclinico.seguimientos = data.seguimientos;  
                              else 
                                this.casoclinico.seguimientos = null; 

                              
                              this.matSnakBar.open(`Actualizado CC No. ${data.serie}`, "Caso Clinico", {
                                                                                                          duration: 2000,
                                                                                                          verticalPosition:'bottom',
                                                                                                          horizontalPosition:'center'
                                                                                                        });

                            }      

    }, err => { console.log(err) });

  } 
  
  updateCirugia() {
    

      this.casosclinicosSrv
      .setCasoClinicoCirugiaUpdate(this.id, this.setFormatCirugia())
      .subscribe(data => {

          if(data){
          
            let { tratamiento, fechaAplicacionProducto, observacion, fk_antibiotico1, nomAntibiotico1, cantidad1, fk_antibiotico2, nomAntibiotico2, cantidad2, fk_antibiotico3, nomAntibiotico3, cantidad3 } = data;
    
            this.casoclinico.tratamiento = tratamiento; 
            this.casoclinico.fechaAplicacionProducto = fechaAplicacionProducto; 
            this.casoclinico.observacion = observacion; 
            this.casoclinico.fk_antibiotico1 = fk_antibiotico1; 
            this.casoclinico.nomAntibiotico1 = nomAntibiotico1;			 
            this.casoclinico.cantidad1 = cantidad1;
               
            this.casoclinico.fk_antibiotico2 = fk_antibiotico2; 
            this.casoclinico.nomAntibiotico2 = nomAntibiotico2;			 
            this.casoclinico.cantidad2 = cantidad2;
               
            this.casoclinico.fk_antibiotico3 = fk_antibiotico3; 
            this.casoclinico.nomAntibiotico3 = nomAntibiotico3;			 
            this.casoclinico.cantidad3 = cantidad3;

            if(this.fecha.fechaAplicacionProducto == null) {
              this.fecha.fechaAplicacionProducto = data.fechaAplicacionProducto;
              this.fecha.fechaPrimerSeguimiento = this.primerSeguimiento(data.fechaAplicacionProducto);
              this.fecha.dias = this.enDias(this.fecha.fechaHoy, this.fecha.fechaPrimerSeguimiento);
              this.fecha.activo = this.activo(this.fecha.fechaHoy, this.fecha.fechaPrimerSeguimiento);
            }
            
            this.isCompletedCirugia = true;

            this.matSnakBar.open(`Actualizado`, "Caso Clinico", {
              duration: 2000,
              verticalPosition:'bottom',
              horizontalPosition:'center'
            });

          }

      }, err => { console.log(err) });
  }

  activo(fechaHoy: Date, primerSeguimiento: Date): boolean{
    const hoy = new Date(fechaHoy);
    const seguimiento = new Date(primerSeguimiento);
    return seguimiento.getTime() > hoy.getTime();
  }

  enDias(fechaHoy: Date, primerSeguimiento: Date) {
    const hoy = new Date(fechaHoy);
    const seguimiento = new Date(primerSeguimiento);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = seguimiento.getTime() - hoy.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }
  
  primerSeguimiento(aplicacionProducto: Date) {
    let fechaAplicacionProducto  = new Date(aplicacionProducto);
    return new Date(fechaAplicacionProducto.getTime() + (35 * ( (1000 * 60) * 60 * 24)) );
  }
  
  diagnosticoformal(id: number, otroDiagnostico: string = ""): Diagnostico {
      let diagnostico: Diagnostico;    
      diagnostico = this.diagnosticos.find( diagnostico => diagnostico.id == id);
     
      if(id == 0){
          diagnostico.nomDiagnostico = otroDiagnostico;
      }
      return  diagnostico;
  }
  
  institucionformal(id: number, otraInstitucion: string = ""): Institucion {
      let institucion: Institucion;
      institucion = this.instituciones.find(institucion => institucion.id == id);
      if(id == 0){
        institucion.cvInstitucion = otraInstitucion;
      }
      return institucion;
  }
  
  hospitalformal(nomHospital: string, otroHospital: string = ""): Hospital {
      let hospital: Hospital;

      hospital = this.hospitales.find(hospital => hospital.nomHospital.trim().toLowerCase() === nomHospital.trim().toLowerCase());
      
      if(!hospital){        
          
          hospital = new Hospital();
          hospital.id = 0;

          if(nomHospital.trim().toLowerCase() === "otro"){
            hospital.nomHospital = otroHospital;
          }else{
            hospital.nomHospital = "";
          }
                  
      }
      
      return hospital;
  }
  
  antibiotico(id: number): Antibiotico {
    let antibiotico: Antibiotico;    
    antibiotico = this.antibioticos.find( antibiotico => antibiotico.id == id);    
   return  antibiotico;
  }

  seccioncuerpo(id: number): SeccionCuerpo {
    let seccioncuerpo: SeccionCuerpo;
    seccioncuerpo = this.seccionesCuerpo.find( seccion => seccion.id == id);
    return seccioncuerpo;
  }

  _filter(nombre: string): string[] {
    const valorFiltro = this._normalizeValue(nombre);      
    return this.nombresHospitales.filter(hospital => this._normalizeValue(hospital).includes(valorFiltro));

  }
  
  _normalizeValue(nomdelhospital: string): string {
   let nombre = String(nomdelhospital).toLowerCase();
    return nombre ? nombre.replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u') : '';
  }

  displayFn(hospital: Hospital): string {
    return hospital && hospital.nomHospital ? hospital.nomHospital : '';
  }
  
  onChangeSelectEdo(id_estado: string): void {
    let id_institucion = this.fk_institucionCtrl.value;
    
    this.nombresHospitales = [];
    this.fk_hospitalCtrl.setValue('');
    this.otrohospitalCtrl.setValue('');   
       
    this.nombresHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0) && hospital.fk_estado == (id_estado ? Number(id_estado): 0)).map((h) => h.nomHospital); 
    
    if(id_institucion && id_estado){
      this.nombresHospitales.push("OTRO");  
    }
   
  }
  
  onChangeSelectInst(id_institucion: string): void {
    let id_estado = this.fk_estadoCtrl.value;
   
    this.nombresHospitales = [];
    this.otracveInstitucionCtrl.setValue('');
    this.fk_hospitalCtrl.setValue('');
    this.otrohospitalCtrl.setValue('');
   
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0) && hospital.fk_estado == (id_estado ? Number(id_estado): 0)); 
   
   
  }

  setAccionesFrm(acciones: Accion[]): void 
  {
      let rowAccion = {
                        osteosintesis: false,
                        lavado: false,
                        relleno: false,
                        instrumentacion: false,
                        mediostinitis: false,
                        injertosvasculares: false,
                        protesis: false,
                        clavocentromedular: false,
                        mallas: false,
                        dehiscencias: false,
                        sxfournier: false,
                        amputacion: false,
                        viscosuplementacion: false,
      };

      if(acciones.findIndex(accion => accion.id==1) != -1){
        rowAccion = {...rowAccion, osteosintesis: true}   
      }
      if(acciones.findIndex(accion => accion.id==2) != -1){
        rowAccion = {...rowAccion, lavado: true}   
      }
      if(acciones.findIndex(accion => accion.id==3) != -1){
        rowAccion = {...rowAccion, relleno: true}   
      }
      if(acciones.findIndex(accion => accion.id==4) != -1){
        rowAccion = {...rowAccion, instrumentacion: true}   
      }
      if(acciones.findIndex(accion => accion.id==5) != -1){
        rowAccion = {...rowAccion, mediostinitis: true}   
      }
      if(acciones.findIndex(accion => accion.id==6) != -1){
        rowAccion = {...rowAccion, injertosvasculares: true}   
      }
      if(acciones.findIndex(accion => accion.id==7) != -1){
      rowAccion = {...rowAccion, protesis: true}   
      }
      if(acciones.findIndex(accion => accion.id==8) != -1){
        rowAccion = {...rowAccion, clavocentromedular: true}   
      }
      if(acciones.findIndex(accion => accion.id==9) != -1){
        rowAccion = {...rowAccion, mallas: true}   
      }
      if(acciones.findIndex(accion => accion.id==10) != -1){
        rowAccion = {...rowAccion, dehiscencias: true}   
      }
      if(acciones.findIndex(accion => accion.id==11) != -1){
        rowAccion = {...rowAccion, sxfournier: true}   
      }
      if(acciones.findIndex(accion => accion.id==12) != -1){
        rowAccion = {...rowAccion, amputacion: true}   
      }
      if(acciones.findIndex(accion => accion.id==13) != -1){
        rowAccion = {...rowAccion, viscosuplementacion: true}   
      }

      this.accionesFrm.reset(rowAccion);
  }

  onChangeSelectCuerpo(id_cuerpo: number): void {
    this.seccionCuerpo = this.seccionesCuerpo.find(seccion => seccion.id == id_cuerpo);

    if (this.seccionCuerpo) {

      if(this.casoclinico.fk_seccion_cuerpo == this.seccionCuerpo.id) {      
          this.setAccionesFrm(this.casoclinico.acciones);   
          console.log("acciones 1", this.casoclinico.acciones)   
      }else {
         
        this.resetAcciones();
      }
    }
    
    console.log(this.seccionCuerpo);
  }

  resetAcciones(): void {
      let rowAccion = {
        osteosintesis: false,
        lavado: false,
        relleno: false,
        instrumentacion: false,
        mediostinitis: false,
        injertosvasculares: false,
        protesis: false,
        clavocentromedular: false,
        mallas: false,
        dehiscencias: false,
        sxfournier: false,
        amputacion: false,
        viscosuplementacion: false,
      };
      this.accionesFrm.reset(rowAccion);
  }
   
  borrarProductodelCaso(id: number){
    console.log(id)
  }

  onCancelar(){
    this.cancelarFicha.emit();
  }

}