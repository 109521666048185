import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Usuario } from 'src/app/moddelo/usuario';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { DialogoCambiarPasswUsuario } from '../../dialogos/dialogo-cambiar-passw-usuario/dialogo-cambiar-passw-usuario';

@Component({
  selector: 'app-administrador-usuarios-adm-n1',
  templateUrl: './administrador-usuarios-adm-n1.component.html',
  styleUrls: ['./administrador-usuarios-adm-n1.component.css']
})
export class AdministradorUsuariosAdmN1Component implements OnInit {
  public usuarios: Usuario[] = [];
  public usuariosFiltrados: Usuario[] =[];

  constructor(private usuariosSrv: UsuariosService, private dialogo: MatDialog) { }

  ngOnInit() {
    this.usuariosSrv.getUsuarios().subscribe(data => { 
      this.usuarios = data;     
      this.usuariosFiltrados = data.filter(u => u.fk_rol == 2 && u.fk_nivel > 1);        
  }, err => { console.log(err); } );
  }

  changePassw(usuario: Usuario){
    this.dialogo.open(DialogoCambiarPasswUsuario, {width:'600px', data: usuario});
  }

}
