import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth.guard';
import { CasosClinicosFichaComponent } from '../casos-clinicos/casos-clinicos-ficha/casos-clinicos-ficha.component';
import { MedicoCanjeAddComponent } from './medico-canje-add/medico-canje-add.component';
import { MedicoCanjeDetalleComponent } from './medico-canje-detalle/medico-canje-detalle.component';
import { MedicoCanjeComponent } from './medico-canje/medico-canje.component';
import { MedicoCasosClinicosComponent } from './medico-casos-clinicos/medico-casos-clinicos.component';
import { MedicoProductosComponent } from './medico-productos/medico-productos.component';
import { MedicoPuntosComponent } from './medico-puntos/medico-puntos.component';
import { MedicoSaldoComponent } from './medico-saldo/medico-saldo.component';
import { MedicosMainComponent } from './medicos-main/medicos-main.component';
import { MedicoUpdateComponent } from './medico-update/medico-update.component';



const routes: Routes = [
  { path:'medico', component: MedicosMainComponent, 
  children: [
    {path:'casosclinicos/:id', component: MedicoCasosClinicosComponent},
    {path:'casoclinico/:id', component: CasosClinicosFichaComponent},
    {path:'productos/:id', component: MedicoProductosComponent},
    {path:'puntos/:id', component: MedicoPuntosComponent},
    {path:'saldo/:id', component: MedicoSaldoComponent},
    {path:'canjes/:id', component: MedicoCanjeComponent},
    {path:'canje/detalle/:id', component: MedicoCanjeDetalleComponent},
    {path:'canje/add/:id', component: MedicoCanjeAddComponent}, 
    {path:'update', component: MedicoUpdateComponent}    
  ], canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MedicosRoutingModule { }
