import { Component, OnInit } from '@angular/core';
import { AuthService} from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';

@Component({
  selector: 'app-enfermeras-main',
  templateUrl: './enfermeras-main.component.html',
  styleUrls: ['./enfermeras-main.component.css']
})
export class EnfermerasMainComponent implements OnInit {
  public id: number = 0;
  
  constructor(private aut: AuthService, private enfermerasSrv: EnfermerasService) 
  { 
      this.id = this.aut.getUsuario.enfermera.id;

      enfermerasSrv
      .getEnfermera(this.id.toString())
      .subscribe(data => { 
                            this.enfermerasSrv.enfermera = data; 
                          }, err => { console.log(err); });
  }

  get pgHome(){
    return this.enfermerasSrv.card == 0;
  }

  get CardCasosClinicos(){
    return this.enfermerasSrv.card == 1;
  }
  get CardMedicos(){
    return this.enfermerasSrv.card == 2;
  }
  get CardProductos(){
    return this.enfermerasSrv.card == 3;
  }
  get CardPuntos(){
    return this.enfermerasSrv.card == 4;
  }
  get CardSaldos(){
    return this.enfermerasSrv.card == 5;
  }
  
  get totalCasosclinicos(){       
    return  this.enfermerasSrv.enfermera === undefined ? 0 : this.enfermerasSrv.enfermera.casosclinicos.length;
  }
  get totalProductos(){
    return this.enfermerasSrv.enfermera === undefined ? 0 : this.enfermerasSrv.enfermera.casosclinicos.length;
  }
  get totalPuntos(){
    return this.enfermerasSrv.enfermera === undefined ? 0 : this.enfermerasSrv.enfermera.casosclinicos.reduce((valor, casoclinico) => casoclinico.e_puntos + valor, 0);
  }
  get totalSaldo(){
    return this.enfermerasSrv.enfermera === undefined ? 0 : this.enfermerasSrv.enfermera.casosclinicos.filter(cc => cc.status_canje == 0).reduce((valor, casoclinico) => casoclinico.e_puntos + valor, 0);
  }
  get totalMedicos(){
    return this.enfermerasSrv.enfermera === undefined ? 0 : this.enfermerasSrv.enfermera.medicos.length;
  }

  ngOnInit() { 
    this.enfermerasSrv.card = 0;
  }

}
