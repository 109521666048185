import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Location } from '@angular/common';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { Usuario } from 'src/app/moddelo/usuario';
import { Medico } from 'src/app/moddelo/medico';
import { Especialidad } from 'src/app/moddelo/especialidad';
import { Residencia } from 'src/app/moddelo/residencia';
import { Puesto } from 'src/app/moddelo/puesto';
import { MatSnackBar } from '@angular/material';
import { Institucion } from 'src/app/moddelo/institucion';
import { Hospital } from 'src/app/moddelo/hospital';

@Component({
  selector: 'app-usuario-medico-create',
  templateUrl: './usuario-medico-create.component.html',
  styleUrls: ['./usuario-medico-create.component.css']
})
export class UsuarioMedicoCreateComponent implements OnInit {
  private especialidades: Especialidad[] = [];
  public instituciones: Institucion[] = [];
  public filtrosInstituciones: Institucion[] = []; 

  public hospitales: Hospital[]=[];
  public filtrosHospitales: Hospital[]=[];
  public nombresHospitales: string[] = [];
  private residencias: Residencia[] = [];
  private puestos: Puesto[] = [];
  public usuarioMedicoFrm: FormGroup = new FormGroup({
                                                      usuario: new FormControl('', Validators.required),
                                                      password: new FormControl('', Validators.required),
                                                      alias: new FormControl(''),
                                                      nombre: new FormControl('', Validators.required),
                                                      primerApellido: new FormControl('', Validators.required),
                                                      segundoApellido: new FormControl(''),
                                                      rfc: new FormControl(''),
                                                      telefonoMovil: new FormControl(''),
                                                      fk_especialidad: new FormControl(''),
                                                      nomEspecialidad: new FormControl(''),
                                                      fk_hospital: new FormControl(''),
                                                      fk_puesto: new FormControl(''),
                                                      fk_residencia: new FormControl(''),
                                                      noConsultorio: new FormControl(''),
                                                      telefonoConsultorio: new FormControl(''),
                                                      fk_puntos: new FormControl(1, Validators.required),
                                                      puntos: new FormControl(500),
                                                      fk_puntos_seg: new FormControl(1, Validators.required),
                                                      puntos_seg: new FormControl(500),
                                                      fk_rol: new FormControl(3)
                                        });
get usuarioCtrl(){ return this.usuarioMedicoFrm.get('usuario');}
get passwordCtrl(){ return this.usuarioMedicoFrm.get('password');}
get aliasCtrl(){ return this.usuarioMedicoFrm.get('alias');}
get nombreCtrl(){ return this.usuarioMedicoFrm.get('nombre');}
get primerApellidoCtrl() { return this.usuarioMedicoFrm.get('primerApellido');}
get telefonoCtrl(){return this.usuarioMedicoFrm.get('telefonoMovil');}
get fk_puestoCtrl(){return this.usuarioMedicoFrm.get('fk_puesto');}
get fk_especialidadCtrl(){return this.usuarioMedicoFrm.get('fk_especialidad');}
get nomEspecialidadCtrl(){return this.usuarioMedicoFrm.get('nomEspecialidad');}
get fk_hospitalCtrl(){return this.usuarioMedicoFrm.get('fk_hospital');}
get fk_residenciaCtrl(){return this.usuarioMedicoFrm.get('fk_residencia');}
get noConsultorioCtrl(){return this.usuarioMedicoFrm.get('noConsultorio');}
get telefonoConsultorioCtrl(){return this.usuarioMedicoFrm.get('telefonoConsultorio');}
get puntosCtrl(){ return this.usuarioMedicoFrm.get('fk_puntos');}
get puntos_segCtrl(){ return this.usuarioMedicoFrm.get('fk_puntos_seg');}

  constructor(
            private location: Location, 
            private catalogosSrv: CatalogosService,
            private usuariosSrv: UsuariosService,          
            private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.catalogosSrv.getEspecialidades().subscribe(data => {this.especialidades = data;}, err => { console.log(err); });
    this.catalogosSrv.getPuestos().subscribe(data => {this.puestos = data;}, err => { console.log(err) });
    this.catalogosSrv.getResidencias().subscribe(data => { this.residencias = data;}, err => { console.log(err) });
    
    this.catalogosSrv.getInstituciones().subscribe(data =>{ this.instituciones = data;}, err => {console.log(err)} );
    this.catalogosSrv.getHospitales().subscribe(data => { this.hospitales = data;}, err => { console.log(err)});

    this.fk_puestoCtrl.valueChanges.subscribe(e => { 
           this.fk_residenciaCtrl.setValue('');
    });
   
    this.fk_especialidadCtrl.valueChanges.subscribe(e => { 
       let especialidad = this.especialidades.find(esp => esp.id == e);
       if(especialidad){
         this.nomEspecialidadCtrl.setValue(especialidad.nomEspecialidad);
       }else{
        this.nomEspecialidadCtrl.setValue('');
       }       
     }, err => { 
        console.log(err) 
      });


  }
  
  onSubmit(){
    let { 
          usuario, 
          password, 
          alias, 
          nombre, 
          primerApellido, 
          segundoApellido, 
          rfc, 
          telefonoMovil, 
          fk_especialidad, 
          nomEspecialidad,
          fk_hospital, 
          fk_puesto, 
          fk_residencia,
          noConsultorio,
          telefonoConsultorio, 
          fk_puntos, puntos, 
          fk_puntos_seg,
          puntos_seg, 
          fk_rol 
        } = this.usuarioMedicoFrm.value;

    let usuarioN: Usuario = <Usuario>{ usuario, password, alias, nombre, primerApellido, segundoApellido, telefonoMovil, fk_rol };
    let medicoN: Medico = <Medico>{ nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_hospital, fk_puesto, fk_residencia, noConsultorio, telefonoConsultorio, fk_puntos, puntos, fk_puntos_seg, puntos_seg};
    
    medicoN.correo = usuario;
    this.usuariosSrv.setUsuarioMedicoNuevo(usuarioN, medicoN)
    .subscribe(data => { alert("Registrado Medico!!!"); console.log(data); this.goBack() }, 
               err => { 
                        if(err){
                          alert(err.error.message);
                        }
                        console.log(err); 
                      });
    
  }
  onChangeSelectInst(id_institucion: string) {  
   
    this.nombresHospitales = [];
   
    //let id_estado = this.fk_estadoCtrl.value;
   
    this.nombresHospitales = [];
  
    this.fk_hospitalCtrl.setValue('');  
   
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0)); 
     
  }
  goBack(): void {
    this.location.back();
  }
}
