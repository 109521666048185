import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth.guard';
import { AntibioticoComponent } from '../catalogos/antibioticos/antibiotico/antibiotico.component';
import { AntibioticosComponent } from '../catalogos/antibioticos/antibioticos/antibioticos.component';
import { DiagnosticoComponent } from '../catalogos/diagnosticos/diagnostico/diagnostico.component';
import { DiagnosticosComponent } from '../catalogos/diagnosticos/diagnosticos/diagnosticos.component';
import { EspecialidadComponent } from '../catalogos/especialidades/especialidad/especialidad.component';
import { EspecialidadesComponent } from '../catalogos/especialidades/especialidades/especialidades.component';
import { HospitalOtroComponent } from '../catalogos/hospitales/hospital-otro/hospital-otro.component';
import { HospitalComponent } from '../catalogos/hospitales/hospital/hospital.component';
import { HospitalesOtrosComponent } from '../catalogos/hospitales/hospitales-otros/hospitales-otros.component';
import { HospitalesComponent } from '../catalogos/hospitales/hospitales/hospitales.component';
import { InstitucionOtroComponent } from '../catalogos/instituciones/institucion-otro/institucion-otro.component';
import { InstitucionComponent } from '../catalogos/instituciones/institucion/institucion.component';
import { InstitucionesOtrosComponent } from '../catalogos/instituciones/instituciones-otros/instituciones-otros.component';
import { InstitucionesComponent } from '../catalogos/instituciones/instituciones/instituciones.component';
import { PuntosProductoComponent } from '../catalogos/puntos/puntos-producto/puntos-producto.component';
import { PuntosProductosComponent } from '../catalogos/puntos/puntos-productos/puntos-productos.component';
import { UsuarioEnfermeraCreateComponent } from '../usuarios/usuario-enfermera-create/usuario-enfermera-create.component';
import { UsuarioEnfermeraUpdateComponent } from '../usuarios/usuario-enfermera-update/usuario-enfermera-update.component';
import { UsuarioMedicoCreateComponent } from '../usuarios/usuario-medico-create/usuario-medico-create.component';
import { UsuarioMedicoUpdateComponent } from '../usuarios/usuario-medico-update/usuario-medico-update.component';
import { CasosClinicosFichaVistaComponent } from '../casos-clinicos/casos-clinicos-ficha-vista/casos-clinicos-ficha-vista.component';
import { AdministradorEnfermeraPerfilPuntosComponent } from './administrador-enfermera-perfil-puntos/administrador-enfermera-perfil-puntos.component';
import { AdministradorEnfermeraPerfilComponent } from './administrador-enfermera-perfil/administrador-enfermera-perfil.component';
import { AdministradorEnfermeraRCanjeDeleteComponent } from './administrador-enfermera-r-canje-delete/administrador-enfermera-r-canje-delete.component';
import { AdministradorEnfermeraRCanjeUpdateComponent } from './administrador-enfermera-r-canje-update/administrador-enfermera-r-canje-update.component';
import { AdministradorEnfermeraRCanjesComponent } from './administrador-enfermera-r-canjes/administrador-enfermera-r-canjes.component';
import { AdministradorEnfermeraRCasosClinicosComponent } from './administrador-enfermera-r-casos-clinicos/administrador-enfermera-r-casos-clinicos.component';
import { AdministradorEnfermeraRMedicoAddComponent } from './administrador-enfermera-r-medico-add/administrador-enfermera-r-medico-add.component';
import { AdministradorEnfermeraRMedicoRemoveComponent } from './administrador-enfermera-r-medico-remove/administrador-enfermera-r-medico-remove.component';
import { AdministradorEnfermeraRMedicosComponent } from './administrador-enfermera-r-medicos/administrador-enfermera-r-medicos.component';
import { AdministradorEnfermeraRPuntoUpdateComponent } from './administrador-enfermera-r-punto-update/administrador-enfermera-r-punto-update.component';
import { AdministradorEnfermeraRPuntosComponent } from './administrador-enfermera-r-puntos/administrador-enfermera-r-puntos.component';
import { AdministradorEnfermerasInformesComponent } from './administrador-enfermeras-informes/administrador-enfermeras-informes.component';
import { AdministradorEnfermerasComponent } from './administrador-enfermeras/administrador-enfermeras.component';
import { AdministradorMainComponent } from './administrador-main/administrador-main.component';
import { AdministradorMedicoPerfilPuntosComponent } from './administrador-medico-perfil-puntos/administrador-medico-perfil-puntos.component';
import { AdministradorMedicoPerfilComponent } from './administrador-medico-perfil/administrador-medico-perfil.component';
import { AdministradorMedicoRCanjeDeleteComponent } from './administrador-medico-r-canje-delete/administrador-medico-r-canje-delete.component';
import { AdministradorMedicoRCanjeUpdateComponent } from './administrador-medico-r-canje-update/administrador-medico-r-canje-update.component';
import { AdministradorMedicoRCanjesComponent } from './administrador-medico-r-canjes/administrador-medico-r-canjes.component';
import { AdministradorMedicoRCasoClinicoEnfermeraComponent } from './administrador-medico-r-caso-clinico-enfermera/administrador-medico-r-caso-clinico-enfermera.component';
import { AdministradorMedicoRCasosClinicosComponent } from './administrador-medico-r-casos-clinicos/administrador-medico-r-casos-clinicos.component';
import { AdministradorMedicoREnfermeraAddComponent } from './administrador-medico-r-enfermera-add/administrador-medico-r-enfermera-add.component';
import { AdministradorMedicoREnfermeraRemoveComponent } from './administrador-medico-r-enfermera-remove/administrador-medico-r-enfermera-remove.component';
import { AdministradorMedicoREnfermerasComponent } from './administrador-medico-r-enfermeras/administrador-medico-r-enfermeras.component';
import { AdministradorMedicoRPuntoUpdateComponent } from './administrador-medico-r-punto-update/administrador-medico-r-punto-update.component';
import { AdministradorMedicoRPuntosComponent } from './administrador-medico-r-puntos/administrador-medico-r-puntos.component';
import { AdministradorMedicosInformesComponent } from './administrador-medicos-informes/administrador-medicos-informes.component';
import { AdministradorMedicosInformesSaldosGeneralesComponent } from './administrador-medicos-informes-saldos-generales/administrador-medicos-informes-saldos-generales.component';
import { AdministradorMedicosComponent } from './administrador-medicos/administrador-medicos.component';
import { AdministradorProductoComponent } from './administrador-producto/administrador-producto.component';
import { AdministradorProductosAsignadosNoComponent } from './administrador-productos-asignados-no/administrador-productos-asignados-no.component';
import { AdministradorProductosAsignadosSinCambioFacturaComponent } from './administrador-productos-asignados-sin-cambio-factura/administrador-productos-asignados-sin-cambio-factura.component';
import { AdministradorProductosAsignadosComponent } from './administrador-productos-asignados/administrador-productos-asignados.component';
import { AdministradorProductosLoteComponent } from './administrador-productos-lote/administrador-productos-lote.component';
import { AdministradorProductosOnlyBatchComponent } from './administrador-productos-only-batch/administrador-productos-only-batch.component';
import { AdministradorProductosComponent } from './administrador-productos/administrador-productos.component';
import { AdministradorComponent } from './administrador/administrador.component';
import { AdministradorVentasComponent } from './administrador-ventas/administrador-ventas.component';
import { AdministradorVentaComponent } from './administrador-venta/administrador-venta.component';
import { InstitucionHospitalesComponent } from '../catalogos/instituciones/institucion-hospitales/institucion-hospitales.component';
import { AdministradorUsuariosComponent } from './administrador-usuarios/administrador-usuarios.component';
import { AdministradorUsuarioComponent } from './administrador-usuario/administrador-usuario.component';
import { AdministradorUsuariosAdmN1Component } from './administrador-usuarios-adm-n1/administrador-usuarios-adm-n1.component';
import { AdministradorUsuariosAdmN3Component } from './administrador-usuarios-adm-n3/administrador-usuarios-adm-n3.component';
import { AdministradorSupervisoresComponent } from './administrador-supervisores/administrador-supervisores.component';
import { AdministradorUsuarioAddComponent } from './administrador-usuario-add/administrador-usuario-add.component';
import { UsuarioSupervisorCreateComponent } from '../usuarios/usuario-supervisor-create/usuario-supervisor-create.component';
import { AdministradorSupervisorREnfermerasComponent } from './administrador-supervisor-r-enfermeras/administrador-supervisor-r-enfermeras.component';
import { AdministradorSupervisorREnfermeraAddComponent } from './administrador-supervisor-r-enfermera-add/administrador-supervisor-r-enfermera-add.component';
import { AdministradorSupervisorREnfermeraRemoveComponent } from './administrador-supervisor-r-enfermera-remove/administrador-supervisor-r-enfermera-remove.component';
import { AdministradorUsuarioAdmN1Component } from './administrador-usuario-adm-n1/administrador-usuario-adm-n1.component';
import { AdministradorUsuarioAdmN3Component } from './administrador-usuario-adm-n3/administrador-usuario-adm-n3.component';
import { AdministradorUsuarioAdmN1AddComponent } from './administrador-usuario-adm-n1-add/administrador-usuario-adm-n1-add.component';
import { AdministradorMedicoReferenciaPagoRemplazoComponent } from './administrador-medico-referencia-pago-remplazo/administrador-medico-referencia-pago-remplazo.component';
import { AdministradorEnfermeraReferenciaPagoRemplazoComponent } from './administrador-enfermera-referencia-pago-remplazo/administrador-enfermera-referencia-pago-remplazo.component';
import { CasoClinicoFichaAdmComponent } from '../casos-clinicos/caso-clinico-ficha-adm/caso-clinico-ficha-adm.component';
import { AdministradorMedicoRCanjeAddComponent } from './administrador-medico-r-canje-add/administrador-medico-r-canje-add.component';
import { AdministradorEnfermeraRCanjeAddComponent } from './administrador-enfermera-r-canje-add/administrador-enfermera-r-canje-add.component';
import { AdministradorMedicoMainComponent } from './administrador-medico-main/administrador-medico-main.component';
import { AdministradorEnfermeraMainComponent } from './administrador-enfermera-main/administrador-enfermera-main.component';
import { AdministradorCasosClinicosInformesComponent } from './administrador-casos-clinicos-informes/administrador-casos-clinicos-informes.component';
import { AdministradorSupervisorMainComponent } from './administrador-supervisor-main/administrador-supervisor-main.component';
import { AdministradorMetricasComponent } from './administrador-metricas/administrador-metricas.component';
import { AdministradorMetricaUnoComponent } from './administrador-metrica-uno/administrador-metrica-uno.component';
import { AdministradorMetricaDosComponent } from './administrador-metrica-dos/administrador-metrica-dos.component';
import { AdministradorMetricaTresComponent } from './administrador-metrica-tres/administrador-metrica-tres.component';
import { AdministradorMetricaCuatroComponent } from './administrador-metrica-cuatro/administrador-metrica-cuatro.component';
import { SeccionescuerpoComponent } from '../catalogos/seccionescuerpo/seccionescuerpo/seccionescuerpo.component';
import { SeccioncuerpoComponent } from '../catalogos/seccionescuerpo/seccioncuerpo/seccioncuerpo.component';
import { AdministradorMetricaUnoUnoComponent } from './administrador-metrica-uno-uno/administrador-metrica-uno-uno.component';
import { AdministradorMetricaUnoDosComponent } from './administrador-metrica-uno-dos/administrador-metrica-uno-dos.component';
import { AdministradorMetricaUnoTresComponent } from './administrador-metrica-uno-tres/administrador-metrica-uno-tres.component';
import { AdministradorMetricaDosUnoComponent } from './administrador-metrica-dos-uno/administrador-metrica-dos-uno.component';
import { AdministradorMetricaDosDosComponent } from './administrador-metrica-dos-dos/administrador-metrica-dos-dos.component';
import { AdministradorMetricaDosTresComponent } from './administrador-metrica-dos-tres/administrador-metrica-dos-tres.component';
import { AdministradorMetricaTresDosComponent } from './administrador-metrica-tres-dos/administrador-metrica-tres-dos.component';
import { AdministradorMetricaTresUnoComponent } from './administrador-metrica-tres-uno/administrador-metrica-tres-uno.component';
import { AdministradorMetricaTresTresComponent } from './administrador-metrica-tres-tres/administrador-metrica-tres-tres.component';
import { AdministradorSupervisoresInformesComponent } from './administrador-supervisores-informes/administrador-supervisores-informes.component';
import { AdministradorMetricaCuatroUnoComponent } from './administrador-metrica-cuatro-uno/administrador-metrica-cuatro-uno.component';
import { AdministradorMetricaCuatroDosComponent } from './administrador-metrica-cuatro-dos/administrador-metrica-cuatro-dos.component';
import { AdministradorMetricaCuatroTresComponent } from './administrador-metrica-cuatro-tres/administrador-metrica-cuatro-tres.component';
import { AdministradorMetricaCincoComponent } from './administrador-metrica-cinco/administrador-metrica-cinco.component';
import { AdministradorMetricaCincoUnoComponent } from './administrador-metrica-cinco-uno/administrador-metrica-cinco-uno.component';
import { TiposproductoComponent } from '../catalogos/tiposproductos/tiposproducto/tiposproducto.component';
import { TipoproductoComponent } from '../catalogos/tiposproductos/tipoproducto/tipoproducto.component';
import { CategoriasComponent } from '../catalogos/categorias/categorias/categorias.component';
import { CategoriaComponent } from '../catalogos/categorias/categoria/categoria.component';
import { AdministradorEnfermerasInformesSaldosComponent } from './administrador-enfermeras-informes-saldos/administrador-enfermeras-informes-saldos.component';
import { AdministradorSupervisoresInformesSaldosComponent } from './administrador-supervisores-informes-saldos/administrador-supervisores-informes-saldos.component';
import { AdministradorMetricaDosCuatroComponent } from './administrador-metrica-dos-cuatro/administrador-metrica-dos-cuatro.component';
import { AdministradorMetricaCincoDosComponent } from './administrador-metrica-cinco-dos/administrador-metrica-cinco-dos.component';

const routes: Routes = [{ path:'administrador', component: AdministradorMainComponent, 
                          children: [
                                      {path:'', component: AdministradorComponent},
                                      {path:'productos', component: AdministradorProductosComponent},
                                      {path:'medicos', component: AdministradorMedicosComponent},  
                                      {path:'enfermeras', component: AdministradorEnfermerasComponent},
                                      {path:'supervisores', component: AdministradorSupervisoresComponent},                                      
                                      {path:'usuarios', component: AdministradorUsuariosComponent},
                                      {path:'medico/:id', component: AdministradorMedicoMainComponent},
                                      {path:'supervisor/:id', component: AdministradorSupervisorMainComponent},
                                      {path:'enfermera/:id', component: AdministradorEnfermeraMainComponent},
                                      {path:'usuarios/n1', component: AdministradorUsuariosAdmN1Component},
                                      {path:'usuarios/n3', component: AdministradorUsuariosAdmN3Component},
                                      {path:'usuario/create', component: AdministradorUsuarioAddComponent},
                                      {path:'usuario/n1/create', component: AdministradorUsuarioAdmN1AddComponent},
                                      {path:'supervisores/add', component: UsuarioSupervisorCreateComponent},
                                      {path:'medico/perfil/puntos/:id', component: AdministradorMedicoPerfilPuntosComponent},
                                      {path:'medico/perfil/:id', component: AdministradorMedicoPerfilComponent},
                                      {path:'medicos/add', component: UsuarioMedicoCreateComponent},
                                      {path:'medico/update/:id', component: UsuarioMedicoUpdateComponent},
                                      {path:'medico/casosclinicos/:id', component: AdministradorMedicoRCasosClinicosComponent},
                                      {path:'medico/casoclinico/enfermera/:idm/:idc', component: AdministradorMedicoRCasoClinicoEnfermeraComponent},
                                      {path:'medico/enfermeras/:id', component: AdministradorMedicoREnfermerasComponent},
                                      {path:'medico/enfermera/add/:id', component: AdministradorMedicoREnfermeraAddComponent},
                                      {path:'medico/enfermera/remove/:id1/:id2', component: AdministradorMedicoREnfermeraRemoveComponent},
                                      {path:'medico/puntos/:id', component: AdministradorMedicoRPuntosComponent},
                                      {path:'medico/punto/:id1/:id2', component: AdministradorMedicoRPuntoUpdateComponent},
                                      {path:'medico/canje/add/:id', component: AdministradorMedicoRCanjeAddComponent},
                                      {path:'medico/canjes/:id', component: AdministradorMedicoRCanjesComponent},
                                      {path:'medico/canje/:id1/:id2', component: AdministradorMedicoRCanjeUpdateComponent},
                                      {path:'medico/canje/delete/:id1/:id2', component: AdministradorMedicoRCanjeDeleteComponent},
                                      {path:'medico/referencia/remplazo/:id1/:id2', component: AdministradorMedicoReferenciaPagoRemplazoComponent},
                                      {path:'medicos/informes', component: AdministradorMedicosInformesComponent},
                                      {path:'medicos/informes/saldo', component: AdministradorMedicosInformesSaldosGeneralesComponent},
                                      {path:'supervisor/enfermeras/:id', component: AdministradorSupervisorREnfermerasComponent},
                                      {path:'supervisor/enfermera/add/:id', component: AdministradorSupervisorREnfermeraAddComponent},
                                      {path:'supervisor/enfermera/remove/:id1/:id2', component: AdministradorSupervisorREnfermeraRemoveComponent},
                                      {path:'supervisores/informes', component: AdministradorSupervisoresInformesComponent},
                                      {path:'supervisores/informes/saldos', component: AdministradorSupervisoresInformesSaldosComponent},
                                      {path:'enfermera/perfil/puntos/:id', component: AdministradorEnfermeraPerfilPuntosComponent},
                                      {path:'enfermera/perfil/:id', component: AdministradorEnfermeraPerfilComponent},
                                      {path:'enfermeras/add', component: UsuarioEnfermeraCreateComponent},
                                      {path:'enfermera/update/:id', component: UsuarioEnfermeraUpdateComponent},
                                      {path:'enfermera/medicos/:id', component: AdministradorEnfermeraRMedicosComponent},
                                      {path:'enfermera/medico/add/:id', component: AdministradorEnfermeraRMedicoAddComponent},
                                      {path:'enfermera/medico/remove/:id1/:id2', component: AdministradorEnfermeraRMedicoRemoveComponent},
                                      {path:'enfermera/puntos/:id', component: AdministradorEnfermeraRPuntosComponent},
                                      {path:'enfermera/punto/:id1/:id2', component: AdministradorEnfermeraRPuntoUpdateComponent},
                                      {path:'enfermera/casosclinicos/:id', component: AdministradorEnfermeraRCasosClinicosComponent},
                                      {path:'enfermera/canje/add/:id', component: AdministradorEnfermeraRCanjeAddComponent},
                                      {path:'enfermera/canjes/:id', component: AdministradorEnfermeraRCanjesComponent},
                                      {path:'enfermera/canje/:id1/:id2', component: AdministradorEnfermeraRCanjeUpdateComponent},
                                      {path:'enfermera/canje/delete/:id1/:id2', component: AdministradorEnfermeraRCanjeDeleteComponent},
                                      {path:'enfermera/referencia/remplazo/:id1/:id2', component: AdministradorEnfermeraReferenciaPagoRemplazoComponent},
                                      {path:'enfermeras/informes', component: AdministradorEnfermerasInformesComponent},
                                      {path:'enfermeras/informes/saldos', component: AdministradorEnfermerasInformesSaldosComponent},
                                      {path:'usuario/:id', component: AdministradorUsuarioComponent},
                                      {path:'usuario/n1/:id', component: AdministradorUsuarioAdmN1Component},
                                      {path:'usuario/n3/:id', component: AdministradorUsuarioAdmN3Component},
                                      {path:'productos/asignados', component: AdministradorProductosAsignadosComponent},
                                      {path:'productos/asignados/no', component: AdministradorProductosAsignadosNoComponent},
                                      {path:'productos/cambio/factura', component: AdministradorProductosAsignadosSinCambioFacturaComponent},
                                      {path:'productos/lote', component: AdministradorProductosLoteComponent},
                                      {path:'productos/only/batch', component: AdministradorProductosOnlyBatchComponent},
                                      {path:'producto/:id', component: AdministradorProductoComponent},
                                      {path:'casoclinico/:id', component: CasosClinicosFichaVistaComponent},
                                      {path:'casoclinico/adm/:idm/:idcc', component: CasoClinicoFichaAdmComponent},
                                      {path:'casosclinicos/informes', component: AdministradorCasosClinicosInformesComponent},
                                      {path:'instituciones', component: InstitucionesComponent},
                                      {path:'institucion/:id', component: InstitucionComponent},
                                      {path:'instituciones/otros', component: InstitucionesOtrosComponent},
                                      {path:'institucion/otro/:id', component: InstitucionOtroComponent},
                                      {path:'institucion/hospitales/:id', component: InstitucionHospitalesComponent},
                                      {path:'hospitales', component: HospitalesComponent},
                                      {path:'hospital/:id', component: HospitalComponent},
                                      {path:'hospitales/otros', component: HospitalesOtrosComponent},
                                      {path:'hospital/otro/:id', component: HospitalOtroComponent},
                                      {path:'diagnosticos', component: DiagnosticosComponent},
                                      {path:'diagnostico/:id', component: DiagnosticoComponent},
                                      {path:'especialidades', component: EspecialidadesComponent},
                                      {path:'especialidad/:id', component: EspecialidadComponent},
                                      {path:'antibioticos', component: AntibioticosComponent},
                                      {path:'antibiotico/:id', component: AntibioticoComponent},
                                      {path:'seccionesdecuerpo', component: SeccionescuerpoComponent},
                                      {path:'secciondecuerpo/:id', component: SeccioncuerpoComponent},
                                      {path:'tiposproductos', component: TiposproductoComponent},
                                      {path:'tipoproducto/:id', component: TipoproductoComponent},
                                      {path:'categorias', component: CategoriasComponent},
                                      {path:'categoria/:id', component: CategoriaComponent},
                                      {path:'puntosproductos', component: PuntosProductosComponent},
                                      {path:'puntosproducto/:id', component: PuntosProductoComponent},
                                      {path:'ventas', component: AdministradorVentasComponent},
                                      {path:'venta/:id', component: AdministradorVentaComponent},
                                      {path:'metricas', component: AdministradorMetricasComponent},
                                      {path:'metricas/uno', component: AdministradorMetricaUnoComponent},
                                      {path:'metricas/unouno', component: AdministradorMetricaUnoUnoComponent},
                                      {path:'metricas/unodos', component: AdministradorMetricaUnoDosComponent},
                                      {path:'metricas/unotres', component: AdministradorMetricaUnoTresComponent},
                                      {path:'metricas/dos', component: AdministradorMetricaDosComponent},
                                      {path:'metricas/dosuno', component: AdministradorMetricaDosUnoComponent},
                                      {path:'metricas/dosdos', component: AdministradorMetricaDosDosComponent},
                                      {path:'metricas/dostres', component: AdministradorMetricaDosTresComponent},
                                      {path:'metricas/doscuatro', component: AdministradorMetricaDosCuatroComponent},
                                      {path:'metricas/tres', component: AdministradorMetricaTresComponent},
                                      {path:'metricas/tresuno', component: AdministradorMetricaTresUnoComponent},
                                      {path:'metricas/tresdos', component: AdministradorMetricaTresDosComponent},
                                      {path:'metricas/trestres', component: AdministradorMetricaTresTresComponent},
                                      {path:'metricas/cuatro', component: AdministradorMetricaCuatroComponent},
                                      {path:'metricas/cuatrouno', component: AdministradorMetricaCuatroUnoComponent},
                                      {path:'metricas/cuatrodos', component: AdministradorMetricaCuatroDosComponent},
                                      {path:'metricas/cuatrotres', component: AdministradorMetricaCuatroTresComponent},
                                      {path:'metricas/cinco', component: AdministradorMetricaCincoComponent},
                                      {path:'metricas/cincouno', component: AdministradorMetricaCincoUnoComponent},
                                      {path:'metricas/cincodos', component: AdministradorMetricaCincoDosComponent}

                          ], canActivate: [AuthGuard] }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministradorRoutingModule { }
