import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort,PageEvent } from '@angular/material';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';
import moment  from 'moment/moment';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CasosClinicosService } from '../../../servicios/casos-clinicos.service';
import { CasosClinicosServicioHospital } from 'src/app/moddelo/casos-clinicos-servicio-hospital';
@Component({
  selector: 'app-administrador-metrica-uno-tres',
  templateUrl: './administrador-metrica-uno-tres.component.html',
  styleUrls: ['./administrador-metrica-uno-tres.component.css']
})
export class AdministradorMetricaUnoTresComponent implements OnInit {
  public casosclinicosserviciohospital: CasosClinicosServicioHospital[] = [];
  public casosclinicosserviciohospitalFilter: CasosClinicosServicioHospital[] = [];
  public cargar: boolean = false;
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
    fechaInicial: new FormControl(''),
    fechaFinal: new FormControl('')
  });
  
  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }
  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  } 
  constructor(private location: Location, private casosclinicossrv: CasosClinicosService) { }

  ngOnInit() {
    moment.locale('es-MX');
  }
  onSubmit() {
    if(moment(this.fechaInicialCtrl.value).isValid()) {
        
      if(moment(this.fechaFinalCtrl.value).isValid()) {

        if(moment(this.fechaInicialCtrl.value) <= moment(this.fechaFinalCtrl.value)) {
           this.cargar = true;
            this.casosclinicossrv
            .getCasosClinicosxServicioyHospital(this.fechaInicialCtrl.value, this.fechaFinalCtrl.value)
           .pipe(finalize( () => this.cargar = false))
            .subscribe(data => {
                this.casosclinicosserviciohospital = data;
                this.casosclinicosserviciohospitalFilter = this.casosclinicosserviciohospital;
                
            }, err => { console.log(err); });

        }else {
          alert('El rango de fechas no esta bien definido')
        }            

      }else {
        alert('La fecha final no es valida')
      }

    }else {
      alert('La fecha inicial no es valida')
    }   
   
  }
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
  exportTablaExcel(): void {  
    
    let rows: CasosClinicosServicioHospital[] = this.casosclinicosserviciohospitalFilter.map((h, i) =>({  
      fk_seccion_cuerpo: h.fk_seccion_cuerpo,
      nomCuerpo: h.nomCuerpo,
      fk_hospital: h.fk_hospital,
      nomHospital: h.nomHospital,
      NoCasosClinicos: h.NoCasosClinicos
    }));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Servicio-Hospitales');

    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_CasosClinicosServicioHospital.xlsx`);
  }
  generateData(): any[] {
    let result = [];
    let i: number = 0;

    this.casosclinicosserviciohospitalFilter.forEach((h, i) => {
        
        result.push(Object.assign( {}, 
        { 
          No: (i+1).toString(),             
          Servicio: h.nomCuerpo,          
          Hospital: h.nomHospital,
          NoCasosClinicos: h.NoCasosClinicos.toLocaleString()
        }));

    });

  return result;
  }
  createHeaders(): any[] {
   let keys: string[] = [
                          "No",
                          "Servicio",
                          "Hospital",
                          "NoCasosClinicos"
                        ]
    let result: any[]= []; 
    
    result = <any[]> keys.map(key => (<any> {
                                              id: key,
                                              name: key,
                                              prompt: key,
                                              width: "65",
                                              align: "center",
                                              padding: "0"
                                            }));

    
    return result;
  }
  exportTablaPDF(): void{  
    const doc = new jsPDF('landscape');  
    let periodo: string = 'Periodo: ' + moment(this.fechaInicialCtrl.value).format('L') + '  a  ' + moment(this.fechaFinalCtrl.value).format('L');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("Casos Clinicos Por Parte de Cuerpo y Hospital",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(periodo, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Parte de Cuerpo', dataKey: 'Servicio' },
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'No Casos Clinicos', dataKey: 'NoCasosClinicos' }        
       ],       
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
        
     doc.save(`${new Date().toISOString()}_CasosClinicosxServicioyHospital.pdf`);  
  }
  sortServicios(sort: Sort) {
    const datos: CasosClinicosServicioHospital[] = this.casosclinicosserviciohospitalFilter.slice();
    if(!sort.active || sort.direction === ''){
      this.casosclinicosserviciohospitalFilter = datos;
      return;
    }
    this.casosclinicosserviciohospitalFilter =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'servicio':
          return this.compare(a.nomCuerpo, b.nomCuerpo, isAsc);
        case 'hospital':
          return this.compare(a.nomHospital, b.nomHospital, isAsc);
        case 'nocasosclinicos':
          return this.compare(a.NoCasosClinicos, b.NoCasosClinicos, isAsc);        
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  } 
  goBack(){
    this.location.back();
  }

}
