import { Component,  OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PageEvent, Sort } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { img } from 'src/app/datos/content-img';
import moment  from 'moment/moment';
 
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

import { Producto } from 'src/app/moddelo/producto';
import { Institucion } from 'src/app/moddelo/institucion';
import { Medico } from 'src/app/moddelo/medico';
import { Hospital } from 'src/app/moddelo/hospital';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { finalize } from 'rxjs/operators';
import { resolve } from 'url';

//import 'moment/locale/pt-br';
interface CHeaders{
      id: string,
      name: string,
      prompt: string,
      width: string,
      align: string,
      padding: string
}
interface ItbProductos {
          Hospital: string;
          Institucion: string;
          Fecha: string;
          Batch: number;
          Codigo: string;
          Producto: string;
          Lote: string;
          Caducidad: string;
          Medico: string;
          Status: string;
}
interface IpdfProductos{
        CvInstitucion: string;
        NomHospital: string;
        Fecha: string; 
        Codigo: string;
        Producto: string;
        Lote: string;
        Caducidad: string;        
        Medico: string;
        Status: string;
}
@Component({
  selector: 'app-administrador-productos',
  templateUrl: './administrador-productos.component.html',
  styleUrls: ['./administrador-productos.component.css'],
  providers: [
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'es-MX'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, 
      useValue: MAT_MOMENT_DATE_FORMATS
    }]
})
export class AdministradorProductosComponent implements OnInit {
  public medicos: Medico[] = [];
  public productos: Producto[] = [];

  public estatus: String[] = ['','Utilizado', 'Utilizado en la Institucion asignada', ' Utilizado fuera de la Institucion asignada','No utilizado', 'No utilizado vigencia activa ', ' No utilizado caducado', 'No utilizado ingresado', 'No utilizado ingresado vigencia activa', 'No utilizado ingresado caducado' ]
  
  public hospitales: Hospital[] = [];
  public instituciones: Institucion[] = [];

  public filtroMedicos: Observable<String[]>;
  public nombresMedicos: String[] = [];

  public filtroEnfermeras: Observable<String[]>;
  public nombresEnfermeras: String[] = [];

  public filtrosHospitales: Hospital[]=[];
  public nombresHospitales: string[] = [];

  public filtrosInstituciones: Institucion[];
  public nombresInstituciones: string[] = [];

  public cargar: boolean = true;
  
  @ViewChild('tbProductos', {static:true})  tbProductos: HTMLTableElement;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  
  public filtroFrm: FormGroup = new FormGroup({
                                                fechaInicial: new FormControl(''),
                                                fechaFinal: new FormControl(''),
                                                codigo: new FormControl(''),
                                                producto: new FormControl(''),
                                                factura: new FormControl(''),
                                                st: new FormControl(''),
                                                medico: new FormControl(''),
                                                fk_hospital: new FormControl(''),
                                                fk_institucion: new FormControl(''),
                                              });
  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }
  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }
  get codigoCtrl(){
    return this.filtroFrm.get('codigo');
  }
  get productoCtrl(){
    return this.filtroFrm.get('producto');
  }
  get facturaCtrl(){
    return this.filtroFrm.get('factura');
  }
  get statusCtrl(){
    return this.filtroFrm.get('st');
  }
  get medicoCtrl(){
    return this.filtroFrm.get('medico');
  }
  get fk_hospitalCtrl(){
    return this.filtroFrm.get('fk_hospital');
  }
  get fk_institucionCtrl(){
    return this.filtroFrm.get('fk_institucion');
  }
  get usuario(){
    return this.aut.getUsuario;
  }
  get offProductos(){
    return (this.aut.getUsuario.fk_nivel==2 || this.aut.getUsuario.fk_nivel==0);
  }
  constructor(
              private aut: AuthService, 
              private adminSrv: AdministradorService,
              private catalogosSrv: CatalogosService,
              private medicosSrv: MedicosService
              ) { }

  ngOnInit() {
    this.adminSrv.card = 1;

    this.adminSrv
    .getProductos().pipe(finalize( () => this.cargar = false))
    .subscribe(data => {
      
      this.adminSrv.productos = data;      
      this.productos = this.adminSrv.productos.filter(producto => producto.status).sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

    }, err => {console.log(err);});
  
    moment.locale('es');
 
    this.nombresMedicos = this.adminSrv.medicos.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) ); 
    this.filtroMedicos = this.medicoCtrl.valueChanges.pipe( startWith(''), map(value => this._filterMedicos(value)) );
  
    this.nombresEnfermeras = this.adminSrv.enfermeras.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) );  
    //this.filtroEnfermeras = this.enfermeraCtrl.valueChanges.pipe( startWith(''), map(value => this._filterEnfermeras(value)) );   
      
    this.hospitales = this.adminSrv.hospitales;     
    this.instituciones = this.adminSrv.instituciones;
    this.filtrosInstituciones = this.adminSrv.instituciones; 
  }

  descripcionHospital( producto: Producto ): string{
    let msj: string = "";
    if(producto.casoclinico !== null){       
        msj=producto.casoclinico.hospital;      
    }
    return msj;
  }

  descripcionInstitucion( producto: Producto ): string{
    let msj: string = "";
    if(producto.casoclinico !== null){  
        msj = producto.casoclinico.cveInstitucion;     
    }
    return msj;
  }

  estatu(producto: Producto): number{
    let edo: number = 0;
    if(producto.casoclinico)
    {       
          edo = 1; // 'Utilizado ';
          if(producto.venta.fk_institucion == (producto.casoclinico.centromedico ? producto.casoclinico.centromedico.fk_institucion : null) )
            edo = 2; // 'En la Institucion asignada ';
          if(producto.venta.fk_institucion !== (producto.casoclinico.centromedico ? producto.casoclinico.centromedico.fk_institucion : null) ) 
            edo = 3; // 'Fuera de la Institucion asignada ';  
    }
    else if(producto.fk_casoClinico == null){
                edo = 4; //  'No utilizado ';
                if(new Date(producto.caducidad).getTime() >= new Date(this.adminSrv.fechaActual.hoy).getTime())
                  edo = 5; // 'Vigencia activa ';
                else  
                  edo = 6; // 'Caducado ';
    }              
    return edo;
  } 
  handlePage( e: PageEvent): void {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  applyFilter(filtro: string): void {
    this.productos = this.adminSrv.productos.filter( producto =>  producto.codigo.toLowerCase().includes(filtro.trim().toLowerCase()) || producto.nomProducto.toLowerCase().includes(filtro.trim().toLowerCase()) ).sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
  }
  onSubmit(): void  {
    if(this.page_number > 1)
    {
      this.page_number = 1;
    }
    this.productos = this.adminSrv.productos.filter(producto =>
          this.filterHospital(producto.casoclinico)  && 
          this.filterMedico(producto.medico) && 
          this.filterStatus(producto) && 
          this.filterCaducidad(producto.caducidad.toString()) && 
          this.filterCodigo(producto.codigo) && 
          this.filterProducto(producto.nomProducto) &&
          this.filterInstitucion(producto.casoclinico)
       ).sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());    
  
  }
  filterCaducidad(caducidad: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(caducidad + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(caducidad + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(caducidad + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(caducidad + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }
  filterCodigo(codigo: string): boolean{
      let condicion: boolean = true;
      if(this.codigoCtrl.value != ""){
       condicion = !(codigo.indexOf(this.codigoCtrl.value) == -1);
      }
      
    return condicion;
  }
  filterProducto(producto: string): boolean{
    let condicion: boolean = true;
    if(this.productoCtrl.value != ""){
      condicion = !(producto.indexOf(this.productoCtrl.value)==-1);
    }    
    return condicion;
  }
  filterFactura(factura: string): boolean{
    let condicion: boolean = true;
    if(this.facturaCtrl.value != ""){
      condicion = factura.includes(this.facturaCtrl.value);
    }    
    return condicion;
  }
  filterStatus(producto: Producto): boolean{
    let condicion: boolean = true;
    if(this.statusCtrl.value != 0){
      if(this.statusCtrl.value == 1){
        condicion = this.estatu(producto) == 2 || this.estatu(producto) == 3;
      }else if(this.statusCtrl.value == 4) {
        condicion = this.estatu(producto) == 5 || this.estatu(producto) == 6;
      } else {
        condicion = this.estatu(producto) == this.statusCtrl.value;
      }
      
    }    
    return condicion;
  }
  filterMedico(medico: Medico): boolean{
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: string = "";
 
    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(medico){
          nomMedico = medico ? (medico.nombre + ( medico.primerApellido ? ' ' + medico.primerApellido : '' ) + (medico.segundoApellido ? ' ' + medico.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );          
        }
    }    
    return condicion;
  }
  filterInstitucion(casoclinico: CasoClinico): boolean {
    
    let condicion: boolean = true;  
    console.log("fk_institucion: ",this.fk_institucionCtrl.value);

    if( this.fk_institucionCtrl.value ){
      let id_inst: number = this.fk_institucionCtrl.value;       
      if(casoclinico) {    
         if(casoclinico.centromedico){
            condicion = id_inst == casoclinico.centromedico.fk_institucion;
         }  else {
            condicion = false
         }
              
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterHospital(casoclinico: CasoClinico): boolean{
    let condicion: boolean = true;  

    if( this.fk_hospitalCtrl.value ){
      let id_hospital: number = this.fk_hospitalCtrl.value;       
      if(casoclinico) {      
         if(casoclinico.centromedico) condicion = id_hospital == casoclinico.fk_hospital; 
         else condicion = false;            
      }else{
        condicion = false;
      }      
    }   
    return condicion;
  }
  
  filterHospitalCadena(casoclinico: CasoClinico): boolean{
    
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.fk_hospitalCtrl.value != 0 ){
      h = this.fk_hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(casoclinico){
        nombre = casoclinico.hospital ? casoclinico.hospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n') : '';
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } 
      else {
        condicion = false;
      }      
    }    
    
    return condicion;   
  }
  filterInstitucionCadena(casoclinico: CasoClinico): boolean {    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let nombre: string;
    if( this.fk_institucionCtrl.value != 0 ) {
      i = this.fk_institucionCtrl.value;
      institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(casoclinico){
        nombre = casoclinico.cveInstitucion ? casoclinico.cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n') : '';
        condicion = nombre.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }        
    return condicion;   
  }


  sortProductos(sort: Sort): void {
    const datos: Producto[] =  this.productos.slice();
    if(!sort.active || sort.direction === '') {
      this.productos = datos;
      return;
    }
    this.productos =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'caducidad':
          let caducidadA: number = new Date(a.caducidad).getTime();
          let caducidadB: number = new Date(b.caducidad).getTime();
          return this.compare(caducidadA, caducidadB, isAsc);
        case 'nomproducto':
          return this.compare(a.nomProducto, b.nomProducto, isAsc);
        case 'lote':
          return this.compare(a.lote, b.lote, isAsc);
        case 'codigo':
          return this.compare(a.codigo, b.codigo, isAsc);
        case 'batch':
          return this.compare(a.noBatch, b.noBatch, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        case 'hospital':
          let hospitalA: string = a.fk_casoClinico ? this.descripcionHospital(a) : '';//a.fk_enfermera ? a.enfermera.nombre + ' ' +( a.enfermera.primerApellido ? a.enfermera.primerApellido: '') : '';
          let hospitalB: string = b.fk_casoClinico ? this.descripcionHospital(b) : '';//b.fk_enfermera ? b.enfermera.nombre + ' ' +( b.enfermera.primerApellido ? b.enfermera.primerApellido: '') : '';
          return this.compare(hospitalA, hospitalB, isAsc);
        case 'institucion':
          let institucionA: string = a.fk_casoClinico ? this.descripcionInstitucion(a) : ''; //a.productos ? a.productos.length : 0;
          let institucionB: string = b.fk_casoClinico ? this.descripcionInstitucion(b) : ''; // .productos ? b.productos.length : 0;
          return this.compare(institucionA, institucionB, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }
  generateData(): any[] {
    let result: IpdfProductos[] = [];
    let i: number = 0;
    
    this.productos.forEach( (p, i) => {
        let ix = this.estatu(p);
        let institucion: string;
        let hospital: string = "";

        institucion = this.CVInstitucion(p);
        hospital = this.NomHospital(p);
       
      
        result.push( { 
                      CvInstitucion: institucion,
                      NomHospital: hospital,
                      Fecha: p.fechaAplicacion ? moment(p.fechaAplicacion).format('L') : 'S-N',
                      Codigo: p.codigo,
                      Producto: p.nomProducto,
                      Lote: p.lote.toString(),
                      Caducidad: moment(p.caducidad).format('L'),                      
                      Medico: p.fk_medico ? p.medico.nombre + ' ' + p.medico.primerApellido : '',
                      Status: this.estatus[ix].toString(), 
                    });
    });

  return result;
  }  
  exportTablaPDF(): void {
    
    const doc = new jsPDF('landscape');  
   // let image = `data:image/png;base64,${img}`;
    
    let PdfWidth = doc.internal.pageSize.width;
    let PdfHeight = doc.internal.pageSize.height;
    
    //doc.addImage(image, 'PNG', 1, 1, 45, 45);
    //doc.setFontSize(24);    
    //doc.text('PRODUCTOS', 145, 20, {align: "center"});
    
    doc.text("Informe PRODUCTOS",  145, 10, { align: "center"});
    //let headers = this.createHeaders();
    let rows = this.generateData();
    //doc.setFontSize(12); 
    //doc.table(0, 25, rows, headers, { autoSize: true});
    var totalPagesExp = '{total_pages_count_string}';
    autoTable(doc, ({
      startY: 10 + 10,
      tableWidth: 'wrap',
      columnStyles: { Batch: { halign: 'center' } },
      styles: { cellPadding: 0.5, fontSize: 8 },
      body: rows,
      columns: [
        { header: 'Institucion', dataKey: 'CvInstitucion' },
        { header: 'Hospital', dataKey: 'NomHospital' },
        { header: 'Fecha', dataKey: 'Fecha' },
        { header: 'Codigo', dataKey: 'Codigo' },
        { header: 'Producto', dataKey: 'Producto' },
        { header: 'Caducidad', dataKey: 'Caducidad' },       
        { header: 'Status', dataKey: 'Status' },
      ],
      didDrawPage: function (data) {
        // Footer
        var str = 'Pagina ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' de ' + totalPagesExp
        }
        doc.setFontSize(10)
  
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.text(str, data.settings.margin.left, pageHeight - 10)
        doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
      }
    }))
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }
    /*
    styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
    // Override the default above for the text column
    
    let pages = doc.getNumberOfPages();
    */
    
    doc.save(`${new Date().toISOString()}_Productos.pdf`);
  }
  exportTablaExcel(): void{
    let tProductos: ItbProductos[] = this.productos.map(p => {
      return <ItbProductos> { 
                              Institucion: this.CVInstitucion(p),
                              Hospital: this.NomHospital(p), 
                              Medico: p.fk_medico ? p.medico.nombre + ' ' + p.medico.primerApellido : '',                             
                              Fecha: p.fechaAplicacion ? moment(p.fechaAplicacion).format('L') : 'S-N',
                              Batch: p.noBatch, 
                              Codigo: p.codigo,
                              Producto: p.nomProducto,
                              Caducidad: moment(p.caducidad).format('L'),
                              Lote: p.lote,
                              Status: this.estatus[this.estatu( p )], 
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tProductos);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Productos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_productos.xlsx`);
  }
  _filterEnfermeras(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresEnfermeras.filter(enfermera => this._normalizeValue(enfermera).includes(valorFiltro));
  }
  _filterMedicos(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresMedicos.filter(medico => this._normalizeValue(medico).includes(valorFiltro));
  }  
  onChangeSelectInst(id_institucion: string) {  
   
    this.nombresHospitales = [];
   
    //let id_estado = this.fk_estadoCtrl.value;
   
    this.nombresHospitales = [];
  
    this.fk_hospitalCtrl.setValue('');  
   
    this.filtrosHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0)); 
     
  }
 
  //PROMESAS
  productoAinstitucion(producto: Producto): string {
    
    let institucion: string = ""; 
    this.obtenerCasoClinico(producto)
    .then((res: CasoClinico) => this.obternerHospital(res))
    .then((res: Hospital) => this.obtenerInstitucion(res))
    .then((res: Institucion) => {
      institucion = res.cvInstitucion; 
    }       
    ).catch(err => err);

    return institucion;
  }
  productoAHospital(producto: Producto): string {

    let rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;   
    let hospital: string = ""; 
   
    this.obtenerCasoClinico(producto)
    .then((res: CasoClinico) => this.obternerHospital(res))
    .then((res: Hospital) => {
            let nombre: string = res.nomHospital;
            hospital = nombre.replace(rtrim, "");
      })
    .catch(err => err);
     return hospital;
  } 

  obtenerCasoClinico(producto: Producto): Promise<CasoClinico> {
    
    return  new Promise((resolve, reject ) => {
      if(producto.casoclinico) resolve(producto.casoclinico);
      else reject('');      
    })
  }
  obternerHospital(casoclinico: CasoClinico): Promise<Hospital>{
    return new Promise((resolve, reject ) => {
      if (casoclinico.centromedico) resolve( casoclinico.centromedico);
      else reject('');
    })
  }
  obtenerInstitucion(centromedico: Hospital): Promise<Institucion> {
    return new Promise((resolve, reject) => {
      if (centromedico.institucion) resolve(centromedico.institucion);
      else reject('');
    })
  }


  CVInstitucion( producto: Producto): string
  {
    return producto.casoclinico ? (producto.casoclinico.centromedico ? (producto.casoclinico.centromedico.institucion ? producto.casoclinico.centromedico.institucion.cvInstitucion : ''):'') :'';    
  }
  NomHospital( producto: Producto): string
  {
    return producto.casoclinico ? (producto.casoclinico.centromedico ? producto.casoclinico.centromedico.nomHospital : '') :'';    
  }
}
