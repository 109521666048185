import { Component, OnInit, Input } from '@angular/core';

import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Documento } from 'src/app/moddelo/documento';
import { TipoDocumento } from 'src/app/moddelo/tipo-documento';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';

@Component({
  selector: 'app-caso-clinico-documentos-supervisor',
  templateUrl: './caso-clinico-documentos-supervisor.component.html',
  styleUrls: ['./caso-clinico-documentos-supervisor.component.css']
})
export class CasoClinicoDocumentosSupervisorComponent implements OnInit {
  public tipoDocumentos: TipoDocumento[] = [];
 
  @Input() seccion: number;
  @Input() casoclinico: CasoClinico;
  
  constructor(
                private catalogosSrv: CatalogosService,
                public casosClinicosSrv: CasosClinicosService,
              ) { }

  get url(): string {    
    return window.location.origin.replace(':4200', '');
  }

  tdocumento(id: number): string {
    let tdoc: TipoDocumento = this.tipoDocumentos.find(t => t.id == id); 
    return  tdoc ? tdoc.nomTipoDocumento :  '';
  }
  
  ngOnInit(): void {
        this.catalogosSrv.getTiposDocumentos().subscribe(data => { this.tipoDocumentos = data; }, err => { console.log(err); });      
  }
  deleteFile(id: number): void{
    let conf: boolean = confirm("estas seguro de borrar los datos");

    if(conf){
      this.casosClinicosSrv.setBajar(id).subscribe(data => {
        console.log(data);
        let i: number = this.casoclinico.documentos.findIndex(d => d.id == id);
        this.casoclinico.documentos.splice(i, 1);
      }, err => { console.log(err) });
      
      console.log("Documento eliminado" );
    }
  }
  documentosSeccion(i:  number): Documento[] {
    return this.casoclinico==null? [] : this.casoclinico.documentos.filter(documento => Number(documento.seccion) === i);
  }

}
