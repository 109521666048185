import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { Usuario } from 'src/app/moddelo/usuario';

@Component({
    selector: 'dialogo-cambiar-usuario-correo',
    templateUrl: 'dialogo-cambiar-usuario-correo.html',
    styles: ['.textRed { color: red; }']    
})
export class DialogoCambiarUsuarioCorreo {

    public correoFrm: FormGroup = new FormGroup({
        correo_nuevo: new FormControl('', Validators.required)        
    });

    get correo_nuevoCtrl(){
        return this.correoFrm.get('correo_nuevo');
    }
   
   
    constructor(
        private usuariosSrv: UsuariosService,       
        private snackBar: MatSnackBar,
        public dialogo: MatDialogRef<DialogoCambiarUsuarioCorreo>, 
        @Inject(MAT_DIALOG_DATA) public data: Usuario
         ){}

    onSubmit(){
        let usuario: Usuario = this.data;
        usuario.usuario = this.correo_nuevoCtrl.value;

        this.usuariosSrv
        .setUsuarioCorreoUpdate(usuario.id, usuario)
        .subscribe(data => {

            if(data){
                        this.snackBar.open("Correo/Usuario actualizada", "Usuario", {
                                                                                    duration: 3000,
                                                                                    verticalPosition:'bottom',
                                                                                    horizontalPosition:'center'
                                                                                });
                        this.onClose();
            }
           
        }, err => { console.log(err); });
       
    }
    onClose(){
        this.dialogo.close(); 
    } 
}