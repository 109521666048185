import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, PageEvent, Sort } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';

import { Medico } from 'src/app/moddelo/medico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Producto } from 'src/app/moddelo/producto';
import { CasoClinico } from 'src/app/moddelo/caso-clinico'; 
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { DialogoAsignarMedicoProductoPuntos } from '../../dialogos/dialogo-asignar-medico-producto-puntos/dialogo-asignar-medico-producto-puntos';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';

interface ItbProductos {

  Fecha: string;
  NoCX: string;   
  Producto: string;
  Lote: string;
  Hospital: string;
  Institucion: string;
  Codigo: string;
  Puntos: number; 
  Enfermera: string; 
}
interface IpdfProductos{
       
        Fecha: string; 
        NoCX: string;       
        Producto: string;
        Lote: string;
        Hospital: string;
        Institucion: string;
        Codigo: string;
        Puntos: number; 
        Enfermera: string;       
}
@Component({
  selector: 'app-administrador-medico-r-puntos',
  templateUrl: './administrador-medico-r-puntos.component.html',
  styleUrls: ['./administrador-medico-r-puntos.component.css']
})
export class AdministradorMedicoRPuntosComponent implements OnInit {
 
  public productos: Producto[] = [];
  public productosLocal: Producto[] = [];
  public enfermeras: Enfermera[] = [];
  public filtroFrm: FormGroup;
  
  private filtroEnfermeras: Observable<String[]>;
  private nombresEnfermeras: String [] = [];
  @Input('medico') medico: Medico;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  get usuario(){
    return this.aut.getUsuario;
  }

  get offLink(){
    return  this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }

  constructor(
            private aut: AuthService, 
            private adminSrv: AdministradorService,
            private catalogoSrv: CatalogosService,
            private formBuilder: FormBuilder,  
            private dialogo: MatDialog,
            ) { }
  
  get fechaInicialCtrl(){
          return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl() {
          return this.filtroFrm.get('fechaFinal');
  }

  get codigoCtrl() {
          return this.filtroFrm.get('codigo');
  }

  get productoCtrl() {
          return this.filtroFrm.get('producto');
  }

  get enfermeraCtrl() {
          return this.filtroFrm.get('enfermera');
  }

  get hospitalCtrl() {
          return this.filtroFrm.get('hospital');
  }

  get folioCtrl() {
    return this.filtroFrm.get('folio');
  }

  ngOnInit() {
    

    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial:[''],
                                            fechaFinal:[''],
                                            folio:[''],
                                            codigo:[''],
                                            producto:[''],
                                            enfermera:[''],      
                                            hospital: ['']
                                            });
          
    this.enfermeras = this.adminSrv.enfermeras.sort( (a, b) => a.nombre.localeCompare(b.nombre) );

    this.nombresEnfermeras = this.enfermeras.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) );
    
    this.productosLocal = this.medico.productos.sort((a, b) => { let fechaa = new Date(a.fechaAplicacion); let fechab = new Date(b.fechaAplicacion); return fechab.getTime() - fechaa.getTime()});
    this.productos = this.productosLocal;
    
    this.filtroEnfermeras = this.enfermeraCtrl.valueChanges.pipe( startWith(''), map(value => this._filter(value)) )
  } 

  onSubmit(): void  {   
    this.productos = this.productosLocal.filter( producto => 
      this.filterCodigo(producto.codigo) && 
      this.filterProducto(producto.nomProducto) && 
      this.filterEnfermera(producto.fk_enfermera) && 
      this.filterHospital(producto.fk_casoClinico) && 
      this.filterFolio(producto.fk_casoClinico) && 
      this.filterFecha(producto.fechaAplicacion.toString()));

    this.page_number = 1;
  }

  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  getEnfermera(id: string): string {
    let enfermera: Enfermera;
    let nombre: string = "";
    if(id) {
      enfermera = this.adminSrv.enfermeras.find(e => e.id.toString() == id);
      nombre = enfermera ? (enfermera.nombre + ( enfermera.primerApellido ? ' ' + enfermera.primerApellido : '' ) + (enfermera.segundoApellido ? ' ' + enfermera.segundoApellido : '') ): '';
    }  
    return nombre;
  }
  
  getCasoClinico(fk_casoclinico: number): String {
    let folio: String = "";
    let casoClinico: CasoClinico;
    if(fk_casoclinico){
      casoClinico = this.medico.casosclinicos.find(cc => cc.id == fk_casoclinico);
      if(casoClinico){
        folio = casoClinico.folio;
      }
    }

    return folio;
  }

  setAsignarPuntos(producto: Producto){
    let medico = this.medico;
    this.dialogo.open(DialogoAsignarMedicoProductoPuntos, {width: '800px', data: {producto, medico}});
  }

  getCasoClinicoHospital(fk_casoclinico: number): String{
    let hospital: String = "";
    let casoClinico: CasoClinico;

    if(fk_casoclinico){
      casoClinico = this.medico.casosclinicos.find(cc => cc.id == fk_casoclinico);
      if(casoClinico){
        hospital = casoClinico.hospital.concat(' ').concat(casoClinico.cveInstitucion);
      }
    }

    return hospital;
  }
  
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

 filterCodigo(codigo: string): boolean {
    let condicion: boolean = true;
    let code: string = "";

    if(this.codigoCtrl.value != ""){
      code = this.codigoCtrl.value;
     condicion = ( codigo.toUpperCase().includes(code.toUpperCase()) );
    }
    
    return condicion;
  }

  filterProducto(nomProductos: string): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    
    if(this.productoCtrl.value != ""){
        nom = this.productoCtrl.value;
        if(nomProductos)
        condicion = (nomProductos.toUpperCase().includes(nom.toUpperCase()) );
    }
    
    return condicion;
  }

  filterEnfermera(fk_enfermera: number): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: String = "";

    if(this.enfermeraCtrl.value != ""){
        nom = this._normalizeValue(this.enfermeraCtrl.value);
        if(fk_enfermera){
          nomEnfermera = this.getEnfermera(fk_enfermera.toString());
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );
         // console.log(this._normalizeValue(nomEnfermera), '  <==>  ', this._normalizeValue(this.enfermeraCtrl.value));
        }
    }
    
    return condicion;
  }

  filterHospital(fk_casoclinico:  number){
    let condicion: boolean = true;
    let nom: string = "";
    let nomHospital: String = "";

    if(this.hospitalCtrl.value != ""){
        nom = this._normalizeValue(this.hospitalCtrl.value);
        if(fk_casoclinico){
          nomHospital = this.getCasoClinicoHospital(fk_casoclinico);
          condicion = ( this._normalizeValue(nomHospital).includes(nom) );
        }
    }
    
    return condicion;

  }
 
  filterFolio(fk_casoclinico: number): boolean{
    let condicion: boolean = true;
    let f: String = "";
    let folio: String = "";

    if(this.folioCtrl.value != ""){
        f = this._normalizeValue(this.folioCtrl.value);
        if(f){
          folio = this.getCasoClinico(fk_casoclinico);
          condicion = (folio.toLowerCase().includes(f.toLowerCase()) );
        }
    }
        
    return condicion;
  }
  
  _filter(nombre: String): String[] {
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresEnfermeras.filter(enfermera => this._normalizeValue(enfermera).includes(valorFiltro));

  }

  _normalizeValue(nom: String): string{  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }

  displayFn(enfermera: Enfermera): string {
    return enfermera && enfermera.nombre ? enfermera.nombre : '';
  }

  sortMedicos(sort: Sort): void {
    const datos: Producto[] = this.productos.slice();
    if(!sort.active || sort.direction === ''){
      this.productos = datos;
      return;
    }
    this.productos =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fecha':
          let a1: number = new Date(a.fechaAplicacion).getTime();
          let b1: number = new Date(b.fechaAplicacion).getTime();
          return this.compare(a1, b1, isAsc);
        case 'folio':
          return this.compare(a.casoclinico.folio, b.casoclinico.folio, isAsc);
        case 'producto':
          return this.compare(a.nomProducto, b.nomProducto, isAsc);
        case 'enfermera':
          return this.compare(a.enfermera ? a.enfermera.nombre : '', b.enfermera ? b.enfermera.nombre : '', isAsc);
        case 'hospital':
            return this.compare(a.casoclinico.hospital, b.casoclinico.hospital, isAsc);  
        case 'codigo':
          return this.compare(a.codigo, b.codigo, isAsc);
        case 'puntos':
          return this.compare(a.m_puntos, b.m_puntos, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  } 

  generateData(): any[] {
    let result: IpdfProductos[] = [];
    let i: number = 0;

    this.productos.forEach((p, i) => {
        result.push( {                        
                      Fecha: moment(p.fechaAplicacion).format('L'), 
                      NoCX: p.fk_casoClinico ? p.casoclinico.folio : '',
                      Producto: p.nomProducto,
                      Lote: p.lote,
                      Hospital: p.fk_casoClinico ? p.casoclinico.hospital: '',
                      Institucion: p.fk_casoClinico ? p.casoclinico.cveInstitucion: '',
                      Codigo: p.codigo,
                      Puntos: p.m_puntos,
                      Enfermera: p.fk_enfermera ? p.enfermera.nombre + ' ' + p.enfermera.primerApellido: ''                      
                    });
    });

  return result;
  }

  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let nombreMedico: string = this.medico.nombre + ' ' + this.medico.primerApellido + (this.medico.primerApellido ? ' ' + this.medico.primerApellido: ''); 
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;

     doc.setFontSize(18); 
     doc.text("PRODUCTOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombreMedico, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();

     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       columnStyles: { Batch: { halign: 'center' } },
       body: rows,
       columns: [         
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'No. CX', dataKey: 'NoCX' },        
         { header: 'Producto', dataKey: 'Producto' },
         { header: 'Lote', dataKey: 'Lote' }, 
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Institucion', dataKey: 'Institucion' },        
         { header: 'Codigo', dataKey: 'Codigo' },
         { header: 'Puntos', dataKey: 'Puntos' },
         { header: 'Enfermera', dataKey: 'Enfermera' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
         doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
     
     
     doc.save(`${new Date().toISOString()}_sdo.pdf`);
  }

  exportTablaExcel(): void {
    let nombreMedico = [{campo: "Medico", nomMedico: this.medico.nombre + ' ' + this.medico.primerApellido + (this.medico.primerApellido ? ' ' + this.medico.primerApellido: '')}];
    let tProductos: ItbProductos[] = this.productos.map((p, i) => {
      return <ItbProductos> { 
                             
                              Fecha: moment(p.fechaAplicacion).format('L'),
                              NoCX: p.fk_casoClinico ? p.casoclinico.folio : '',  
                              Producto: p.nomProducto,
                              Lote: p.lote,
                              Hospital: p.fk_casoClinico ? p.casoclinico.hospital: '', 
                              Institucion: p.fk_casoClinico ? p.casoclinico.cveInstitucion: '',
                              Codigo: p.codigo,
                              Puntos: p.m_puntos,
                              Enfermera: p.fk_enfermera ? p.enfermera.nombre + ' ' + p.enfermera.primerApellido: ''     };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreMedico, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tProductos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Medico_Productos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}m_productos.xlsx`);
  }

  
}
