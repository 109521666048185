import { CasoClinico } from "./caso-clinico";
import { Enfermera } from "./enfermera";
import { Producto } from "./producto";
import { Usuario } from "./usuario";

export class Supervisor {
    id: number;        
    nombre: string;
    primerApellido: string;
    segundoApellido: string;
    correo: string;
    telefonoMovil: string;        
    fk_usuario: number;    
    activo: boolean;
    created_at: Date;
    updated_at: Date;
    usuario: Usuario;
    enfermeras: Enfermera[];
    casosclinicos: CasoClinico[];
    productos: Producto[];      
}
