import { Component, OnInit, OnDestroy, Input  } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { from, Subscriber, Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { 
        MAT_MOMENT_DATE_FORMATS, 
        MomentDateAdapter, 
        MAT_MOMENT_DATE_ADAPTER_OPTIONS 
        } from '@angular/material-moment-adapter';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { SeguimientoPostoperatorio } from 'src/app/moddelo/seguimiento-postoperatorio';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { Fecha } from 'src/app/moddelo/fecha';

@Component({
  selector: 'app-casos-clinicos-postoperatorio',
  templateUrl: './casos-clinicos-postoperatorio.component.html',
  styleUrls: ['./casos-clinicos-postoperatorio.component.css'],
  providers: [
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'es-MX'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, 
      useValue: MAT_MOMENT_DATE_FORMATS
    }]
})
export class CasosClinicosPostoperatorioComponent implements OnInit {
  private seguimientoPostOperatorio: SeguimientoPostoperatorio;
  public fMax: Date = null;
  public fMin: Date = null;
  public fechaSeguimiento: Fecha = <Fecha>{};
  public postOperatorioFrm: FormGroup =new FormGroup({ 
                                                      id: new FormControl(''),
                                                      resultado: new FormControl('', Validators.required),
                                                      numero: new FormControl(''),
                                                      observacion: new FormControl('', Validators.required),
                                                      fecha: new FormControl(formatDate(new Date(),'dd-MM-yyyy','es-MX'), Validators.required),
                                                      fk_casoclinico: new FormControl(''),
                                                      valoracion: new FormControl(''),                                              
                                                      puntos: new FormControl(200),                                  
                                                      }); 

                                                      
get fechaCtrl(){
return this.postOperatorioFrm.get('fecha');
}
get observacionCtrl(){
return this.postOperatorioFrm.get('observacion');
}
get resultadoCtrl(){
return this.postOperatorioFrm.get('resultado');
} 

get Seguimientos(){
  return this.casoClinicoSrv.casoClinico.seguimientos;// seguimientosPostoperatorios;
}
get CasoClinico(){
  return this.casoClinicoSrv.casoClinico;
}
@Input() fecha: Fecha;
  
constructor(private casoClinicoSrv: CasosClinicosService) { }

  ngOnInit() {
              const {fechaHoy, fechaAplicacionProducto, fechaPrimerSeguimiento, dias, activo, fechaMin, fechaMax, fechaMaxFch, fechaMinFch} = this.fecha;

              this.fechaSeguimiento = { fechaHoy, fechaAplicacionProducto, fechaPrimerSeguimiento, dias, activo, fechaMin, fechaMax, fechaMaxFch, fechaMinFch };
              this.fMax = this.fechaSeguimiento.fechaHoy;

              if(this.Seguimientos.length==0){
                this.fMin = this.fechaSeguimiento.fechaPrimerSeguimiento;
              }else{
                this.fechaSeguimiento.fechaAplicacionProducto = this.Seguimientos[this.Seguimientos.length-1].fecha;  
                this.fechaSeguimiento.fechaPrimerSeguimiento = this.nextSeguimiento( this.fechaSeguimiento.fechaAplicacionProducto );
                this.fechaSeguimiento.activo = this.activo(this.fechaSeguimiento.fechaHoy,  this.fechaSeguimiento.fechaPrimerSeguimiento);
                this.fechaSeguimiento.dias = this.enDias(this.fechaSeguimiento.fechaHoy, this.fechaSeguimiento.fechaPrimerSeguimiento );
                this.fMin = this.fechaSeguimiento.fechaPrimerSeguimiento;                
              }
              
  }

  onSubmit(){
    let { resultado, numero, observacion, fecha, fk_casoclinico, valoracion, puntos }  = this.postOperatorioFrm.value;
    let seguimientoPostoperatorio = <SeguimientoPostoperatorio> { resultado, numero, observacion, fecha, fk_casoclinico, valoracion, puntos };
    seguimientoPostoperatorio.fk_casoclinico = this.CasoClinico.id;
    seguimientoPostoperatorio.numero = this.Seguimientos.length + 1;

    this.casoClinicoSrv
    .setSeguimientoPostOperatorio(seguimientoPostoperatorio)
    .subscribe(data => {
      if(data){

        this.Seguimientos.push(seguimientoPostoperatorio);

        this.fechaSeguimiento.fechaAplicacionProducto = seguimientoPostoperatorio.fecha;  
        this.fechaSeguimiento.fechaPrimerSeguimiento = this.nextSeguimiento( this.fechaSeguimiento.fechaAplicacionProducto );
        this.fechaSeguimiento.activo = this.activo(this.fechaSeguimiento.fechaHoy,  this.fechaSeguimiento.fechaPrimerSeguimiento);
        this.fechaSeguimiento.dias = this.enDias(this.fechaSeguimiento.fechaHoy, this.fechaSeguimiento.fechaPrimerSeguimiento );
        this.fMin = this.fechaSeguimiento.fechaPrimerSeguimiento; 

        this.postOperatorioFrm.reset({observacion: '  ', resultado: ' ', fecha: formatDate(new Date(),'dd-MM-yyyy','es-MX')});
    
      } 
    }, err => { console.log(err); });
    
  }

  activo(fechaHoy: Date, nextSeguimiento: Date): boolean{
    const hoy = new Date(fechaHoy);
    const seguimiento = new Date(nextSeguimiento);
    return seguimiento.getTime() > hoy.getTime();
  }

  enDias(fechaHoy: Date, nextSeguimiento: Date): number{
    const hoy = new Date(fechaHoy);
    const seguimiento = new Date(nextSeguimiento);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = seguimiento.getTime() - hoy.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays > 0 ? diffInDays : 0;
  }
  
  nextSeguimiento(aplicacionProducto: Date): Date{
    
    let fechaAplicacionProducto  = new Date(aplicacionProducto);
    return new Date(fechaAplicacionProducto.getTime() + (35 * ( (1000 * 60) * 60 * 24)) );
  }

  nextSeguimientoformat(): String{
    let f = "";
    if(this.fechaSeguimiento.fechaPrimerSeguimiento){
      f = formatDate(this.fechaSeguimiento.fechaPrimerSeguimiento,'dd/MM/yyyy','es-MX')
    }
    return f;
  }
}
