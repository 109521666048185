import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/servicios/auth.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { Medico } from 'src/app/moddelo/medico';
import { Producto } from 'src/app/moddelo/producto';

@Component({
  selector: 'app-medico-productos',
  templateUrl: './medico-productos.component.html',
  styleUrls: ['./medico-productos.component.css']
})
export class MedicoProductosComponent implements OnInit {
  public productos: Producto[] = [];
  public medico: Medico;
  public filtroFrm: FormGroup;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(private medicosSrv: MedicosService, private aut: AuthService,     ) { }
  

  ngOnInit() {
    
    this.medico = this.aut.getUsuario.medico;
    this.medicosSrv.card = 2;

    
    this.medicosSrv
    .getProductos(this.medico.id)
    .subscribe(data => {
      this.productos = data;
    }, err => { console.log(err); } );

  }
  
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

}
