import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import { RedimirEnfermeraReferencia } from 'src/app/moddelo/redimir-enfermera-referencia';
import descripcion from '../../../datos/status';

interface Response{
  code: number; 
  message: string;
  data: RedimirEnfermeraReferencia;
}
@Component({
  selector: 'app-administrador-enfermera-r-canje-detalle',
  templateUrl: './administrador-enfermera-r-canje-detalle.component.html',
  styleUrls: ['./administrador-enfermera-r-canje-detalle.component.css']
})
export class AdministradorEnfermeraRCanjeDetalleComponent implements OnInit {
  public observacion: FormControl = new FormControl('');
  @Input('enfermera') public enfermera: Enfermera;
  @Input('canje') public canje: RedimirEnfermera;
  @Output() cancelarCanje: EventEmitter<any> = new EventEmitter<any>();

  constructor(  private auth: AuthService,
                private enfermerasSrv: EnfermerasService,
                private adminSrv: AdministradorService, 
                private matSnackBar: MatSnackBar ) { }
  
  get url() {    
    return window.location.origin.replace(':4200', '');
  }
  
  ngOnInit() {
    this.observacion.setValue(this.canje.observacion);
    console.log(this.canje)
  }

  getDescripcion(): string{
    return descripcion.status[this.canje.status];
  }
  borrar(id: number): void{
    let conf: boolean = confirm("estas seguro de eliminar este registro");

    if(conf){
      this.enfermerasSrv.setRedimirDeleteRef(id).subscribe(res => {

        let r: Response = <Response> res;
        
        if(r.code){
          let i: number = this.canje.referencias.findIndex(doc => doc.id == id);
          this.canje.referencias.splice(i, 1);       
        }

        this.matSnackBar.open(r.message, 'Documento', {
          duration: 3000,
          verticalPosition:'bottom',
          horizontalPosition:'center'
        });

      }, err => { console.log(err) });

    }
  
  }
  actualizar(): void{
    console.log(this.canje, this.observacion.value);
    this.enfermerasSrv.setRedimirObservacionUpdate(this.canje.id, this.observacion.value).subscribe(data => {
      
      this.matSnackBar.open('Se actualizo la observacion', "CANJE",{
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });

      this.canje.observacion = this.observacion.value;

      this.canje.casosclinicos.forEach((value: CasoClinico, index: number, arr: CasoClinico[]) => {
        if( value.status_canje == 4 )
        {
          this.enfermera.casosclinicos.find(cc=>cc.id == value.id).status_canje = 0;
          value.status_canje=0;
        }
      });

    }, err => { console.log(err); });
  }
  cerrar(): void {
    this.cancelarCanje.emit();
  }
}
