import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { MedicosRoutingModule } from './medicos-routing.module';
import { MedicosMainComponent } from './medicos-main/medicos-main.component';
import { MedicoCasosClinicosComponent } from './medico-casos-clinicos/medico-casos-clinicos.component';
import { MedicoProductosComponent } from './medico-productos/medico-productos.component';
import { MedicoPuntosComponent } from './medico-puntos/medico-puntos.component';
import { MedicoSaldoComponent } from './medico-saldo/medico-saldo.component';
import { MedicoCanjeComponent } from './medico-canje/medico-canje.component';
import { MedicoCanjeAddComponent } from './medico-canje-add/medico-canje-add.component';
import { MedicoFichaComponent } from './medico-ficha/medico-ficha.component';
import { PaginacionModule } from 'src/app/pipes/paginacion.module';
import { MedicoCanjeDetalleComponent } from './medico-canje-detalle/medico-canje-detalle.component';
import { MedicoUpdateComponent } from './medico-update/medico-update.component';

 
@NgModule({
  declarations: [ MedicosMainComponent, MedicoCasosClinicosComponent, MedicoProductosComponent, MedicoPuntosComponent, MedicoSaldoComponent, MedicoCanjeComponent, MedicoCanjeAddComponent, MedicoFichaComponent, MedicoCanjeDetalleComponent, MedicoUpdateComponent ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MedicosRoutingModule,
    MaterialModule,
    PaginacionModule,
  ]
})
export class MedicosModule { }
