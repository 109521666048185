import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatSnackBar } from '@angular/material';

import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { SupervisoresService } from 'src/app/servicios/supervisores.service';
import { Enfermera } from "src/app/moddelo/enfermera";
import { Supervisor } from "src/app/moddelo/supervisor";
interface RemoveEnfermera{
    code: number;
    message: string;
    data: Enfermera;
  }
@Component({
    selector: 'dialogo-supervisor-enfermera-remove',
    templateUrl: 'dialogo-supervisor-enfermera-remove.html'
})
export class DialogoSupervisorEnfermeraRemove {
    public respuesta: RemoveEnfermera;

    get usuario(){
        return this.auth.getUsuario;
    }
    get offBoton(){
        return  (this.usuario.fk_nivel==0 || this.usuario.fk_nivel==3);
    }

    constructor( 
        public dialogo: MatDialogRef<DialogoSupervisorEnfermeraRemove>,
        @Inject(MAT_DIALOG_DATA) public data: {supervisor: Supervisor, enfermera: Enfermera},
        private adminSrv: AdministradorService,
        private supervisoresSrv: SupervisoresService,   
        private snackBar: MatSnackBar,
        private auth: AuthService){}

    remove(): void {
            if(confirm("¿Estas seguro?")){
        
                this.supervisoresSrv.setDesasociarEnfermera(this.data.supervisor.id, this.data.enfermera).subscribe(data => {
                                                                                                                                let i: number = 0;
                                                                                                                                let e: Enfermera[] = null;
                                                                                                                                this.respuesta = <RemoveEnfermera> data;
                                                                                                                                if(this.respuesta.code) {
                                                                                                                                    i = this.data.supervisor.enfermeras.findIndex(e => e.id == this.data.enfermera.id); 
                                                                                                                                    e = this.data.supervisor.enfermeras.splice(i, 1);
                                                                                                                                    this.snackBar.open(this.respuesta.message, 
                                                                                                                                    'Enfermera', 
                                                                                                                                    {
                                                                                                                                        duration: 3000,
                                                                                                                                        verticalPosition:'bottom',
                                                                                                                                        horizontalPosition:'center'
                                                                                                                                    });
                                                                                                                                    console.log("Removido: ", e[0].nombre, ' ' , e[0].primerApellido );
                                                                                                                                    this.onClose(); 
                                                                                                                                }
                                                                                                                                else {
                                                                                                                                        this.snackBar.open(this.respuesta.message, 
                                                                                                                                            'Medico', 
                                                                                                                                            {
                                                                                                                                            duration: 3000,
                                                                                                                                            verticalPosition:'bottom',
                                                                                                                                            horizontalPosition:'center'
                                                                                                                                            });
                                                                                                                                }
                                                                                                                            }, err => { console.log(err); });

            }
    }
    onClose(): void {
      this.dialogo.close();
    }          
}