import { MatPaginatorIntl } from "@angular/material";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

    constructor(){
        super();
    }
    itemsPerPageLabel = 'Registros por página:'; 
    nextPageLabel     = 'Página siguiente';
    previousPageLabel = 'Página anterior';
    firstPageLabel = "Primera página";
    lastPageLabel = "Última página";
  
    getRangeLabel = function (page: number, pageSize: number, length: number) {
      if (length === 0 || pageSize === 0) {
        return '0 od ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) :  startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }