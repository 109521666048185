import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './servicios/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(
              next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
              if(!this.authService.isLoggedIn) {
                this.router.navigate(['/login']);
              }      
    return this.authService.isLoggedIn;
  }
}

