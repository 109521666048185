import { Component, OnInit, Input } from '@angular/core';
import { Medico } from 'src/app/moddelo/medico';
import { Puesto } from 'src/app/moddelo/puesto';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

@Component({
  selector: 'app-usuario-medico-ficha',
  templateUrl: './usuario-medico-ficha.component.html',
  styleUrls: ['./usuario-medico-ficha.component.css']
})
export class UsuarioMedicoFichaComponent implements OnInit {
  private puestos: Puesto[] = [];
  
  get puesto(){
    let p: string = "";
    if(this.puestos.length>0){
      p = this.puestos.find(puesto => puesto.id == this.medico.fk_puesto).nomPuesto  
    }
    return p;
  }
  constructor(private catalogoSrv: CatalogosService) { }

  @Input() medico: Medico;
  
  ngOnInit() {    
    this.catalogoSrv.getPuestos().subscribe(data => {this.puestos = data;}, err => { console.log(err); });
  }

}
