import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ControlContainer } from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { Hospital } from 'src/app/moddelo/hospital'; 
import { Institucion } from 'src/app/moddelo/institucion';
import { Estado } from 'src/app/moddelo/estado';
import { ActivatedRoute } from '@angular/router';
import { AdministradorService } from 'src/app/servicios/administrador.service';
@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.css']
})
export class HospitalComponent implements OnInit {
  public id: number = 0;
  private hospital: Hospital;
  private instituciones: Institucion[] = [];
  private estados: Estado[] = []
  private hospitalFrm = new FormGroup({
                                         nomHospital: new FormControl('', Validators.required),
                                         direccion: new FormControl(''),
                                         telefono: new FormControl(''),
                                         fk_estado: new FormControl('', Validators.required),
                                         fk_institucion: new FormControl('', Validators.required)
                                       });

  get nomHospitalCtrl(){ return this.hospitalFrm.get('nomHospital');}
  get fk_institucionCtrl(){ return this.hospitalFrm.get('fk_institucion');} 
  get fk_estadoCtrl(){ return this.hospitalFrm.get('fk_estado');}
  get direccionCtrl(){ return this.hospitalFrm.get('direccion');}
  get telefonoCtrl(){ return this.hospitalFrm.get('telefono');}
  
  get offDelete(){

    let noCasosClinicos: number = 0;
     if(this.hospital){
        noCasosClinicos = this.hospital.casosclinicos == undefined ? 0 :  this.hospital.casosclinicos.length;
     }
    return ((this.id==0) || (noCasosClinicos>0)); 
  }
  get noCasosClinicos(){
    let noCasosClinicos: number = 0;
    if(this.hospital){
       noCasosClinicos = this.hospital.casosclinicos == undefined ? 0 :  this.hospital.casosclinicos.length;
    }
    return noCasosClinicos;
  }
  constructor(
              private activeR: ActivatedRoute, 
              private catalogosSrv: CatalogosService, 
              private adminSrv: AdministradorService,
              private snackBar: MatSnackBar, 
              private location: Location) { }

  ngOnInit() {
    this.id = Number(this.activeR.snapshot.paramMap.get("id"));
    
    if(this.id > 0){
      this.hospital = this.adminSrv.hospitales.find( h => h.id == this.id);      
      let {nomHospital, fk_institucion, fk_estado, telefono, direccion} = this.hospital;
      this.hospitalFrm.setValue({ nomHospital, fk_institucion, fk_estado, telefono, direccion});     
    }

    this.catalogosSrv.getEstados().subscribe(data => { this.estados = data;}, err => { console.log(err); });

    this.catalogosSrv.getInstituciones().subscribe(data => { this.instituciones = data;}, err => { console.log(err); } );

  }
  onSubmit(){

    if(this.id > 0)
    {
      this.actualizar();
    }
    else
    {      
      this.guadar();
    }        
    
  }
  actualizar(): void  {
  this.catalogosSrv
  .setHospitalUpdate(this.id, this.hospitalFrm.value)
  .subscribe(data => { 
                      
                      this.hospital.nomHospital = data.nomHospital;
                      this.hospital.direccion = data.direccion;
                      this.hospital.telefono = data.telefono;
                      this.hospital.fk_institucion = data.fk_institucion;
                      this.hospital.fk_estado = data.fk_estado;
                      this.hospital.estado = this.estados.find(edo => edo.id == data.fk_estado);
                      this.hospital.institucion = this.instituciones.find(i => i.id == data.fk_institucion);

                      this.snackBar.open('Hospital actualizado con exito', 'Hospital', {
                        duration: 2000,
                        verticalPosition:'bottom',
                      });

                      this.goBack();

  }, err => { console.log(err);});
  }
  guadar(): void{
    this.catalogosSrv
    .setHospital(this.hospitalFrm.value)
    .subscribe(data => { 
                          this.id = data.id;
                          this.hospital = data;
                          this.hospital.estado = this.estados.find(edo => edo.id == data.fk_estado);
                          this.hospital.institucion = this.instituciones.find(i => i.id == data.fk_institucion);
;                         this.adminSrv.hospitales.push(this.hospital);
                          this.snackBar.open('Hospital registrado con exito', 'Hospital', {
                            duration: 2000,
                            verticalPosition:'bottom',
                          });
                  
                          this.goBack();
                }, err => { 
                            this.snackBar.open('No se registro vuelve a intentarlos', 'Hospital', {
                              duration: 2000,
                              verticalPosition:'bottom',
                              horizontalPosition:'center'
                            });
                  
                });
  }
  eliminar(){
    let i: number = 0;
    let h: Hospital[] = [];
    if(confirm("Estas seguro de eliminar este hospital")){
      this.catalogosSrv.setHospitalDelete(this.id).subscribe(data => { 
            if(data){
              alert("se elimino con exito el hospital " + data.nomHospital);
             /// i = this.medico.enfermeras.findIndex(e => e.id == this.enfermera.id); 
               //             e = this.medico.enfermeras.splice(i, 1);
              i = this.adminSrv.hospitales.findIndex(h => h.id == data.id);
              h = this.adminSrv.hospitales.splice(i, 1);
              console.log("Removido: ", h[0].nomHospital );
              this.goBack();
            }
      }, err =>{});
    }
  }
  goBack(): void{
    this.location.back();
  }
}
