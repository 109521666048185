import { Component, OnInit,ViewChild } from '@angular/core';
import { MatAccordion, MatDialog } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { SupervisoresService } from 'src/app/servicios/supervisores.service';
import { AuthService} from 'src/app/servicios/auth.service';
import { Supervisor } from 'src/app/moddelo/supervisor';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Producto } from 'src/app/moddelo/producto';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Medico } from '../../../moddelo/medico';
import { DialogoAsignarEnfermeraCasoclinico } from '../../dialogos/dialogo-asignar-enfermera-casoclinico/dialogo-asignar-enfermera-casoclinico';

interface Resumen {
  casosClinicos: CasoClinico[],
  productos: Producto[]
}
@Component({
  selector: 'app-supervisores-main',
  templateUrl: './supervisores-main.component.html',
  styleUrls: ['./supervisores-main.component.css']
})
export class SupervisoresMainComponent implements OnInit {
  public id: number = 0;
  public supervisor: Supervisor = new Supervisor();
  public casoclinico: CasoClinico = new CasoClinico();
  public enfermera: Enfermera = new Enfermera();
  public medicos: Medico[] = [];
  public casosClinicos: CasoClinico[] = []; 
  public casosClinicosUnico: CasoClinico [] = [];
  public accesoCasoClinico: number = 0;
  public accesoEnfermera: number = 0;
  public loadingSupervisor: boolean = true;
  public resumen: Resumen = null;
  @ViewChild(MatAccordion, {static: true}) accordion: MatAccordion;

  constructor(
    private aut: AuthService, 
    private dialogo: MatDialog,  
    public supervisorSrv: SupervisoresService) { }

  ngOnInit() {
       this.inicial();
  }

  inicial() {
    this.id = this.aut.getUsuario.supervisor.id;
    this.supervisorSrv.getSupervisor(this.id)
    .pipe(finalize( ()=> this.loadingSupervisor = false))
    .subscribe(data => { 

          this.supervisorSrv.supervisor = data;
          this.supervisor = this.supervisorSrv.supervisor; 
          this.supervisorSrv.supervisor.enfermeras.map( (enfermera) => { this.medicos.push(...enfermera.medicos) });
          
          this.medicos.map((medico) => {
              this.casosClinicos.push(...medico.casosclinicos.filter( m => m.fk_enfermera == null ));
          });
          
          this.casosClinicosUnico = <CasoClinico[]> this.uniqueArray( this.casosClinicos, ["id"], true);
        
       
    }, err => { console.log(err)}); 
  }
  updateCasoClinico(cc: CasoClinico): void {
    this.accesoCasoClinico = 1;
    this.casoclinico = cc; 
  }
  
  updateEnfermera(enfermera: Enfermera): void {
    console.info("Main: ", enfermera )
    this.accesoEnfermera = 1;
    this.enfermera = enfermera; 
  }

  NotificacionesMedicoAsociaciones(): number{
    let notificaciones: number = 0;
    this.medicos.forEach(m => {
      notificaciones += m.casosclinicos.filter( m => m.fk_enfermera == null ).length;      
      
    });
    return notificaciones;
  }

  onCancelarFicha(e: any): void {
    this.accesoCasoClinico = 0;
    
  }
  onCancelarEnfermera(e: any): void {
    this.accesoEnfermera = 0;
   
  }
  setAsignarEnfermera (casoclinico: CasoClinico){
    console.log(casoclinico);
    const refDialog = this.dialogo.open(DialogoAsignarEnfermeraCasoclinico, { width: '800px', data: { casoclinico: casoclinico, medico: casoclinico.medico } });
    refDialog.afterClosed().subscribe(result => {
      
      if(casoclinico.fk_enfermera){
      //   this.supervisor.casosclinicos.push(casoclinico);
      ;
      }
    });
  }


  uniqueArray (objects, uniqueBy, keepFirst = true) {
    return Array.from(
        objects.reduce((map, e) => {
            let key = uniqueBy.map(key => [e[key], typeof e[key]]).flat().join('-')
            if (keepFirst && map.has(key)) return map
            return map.set(key, e)
        }, new Map()).values()
    )
  }


}
