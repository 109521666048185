import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Supervisor } from 'src/app/moddelo/supervisor';
import { DialogoEnfermeraMedicosAdd } from '../../dialogos/dialogo-enfermera-medicos-add/dialogo-enfermera-medicos-add';
import { DialogoSupervisorEnfermerasAdd } from '../../dialogos/dialogo-supervisor-enfermeras-add/dialogo-supervisor-enfermeras-add';
import { DialogoSupervisorEnfermerasRemove } from '../../dialogos/dialogo-supervisor-enfermeras-remove/dialogo-supervisor-enfermeras-remove';

@Component({
  selector: 'app-supervisor-enfermeras',
  templateUrl: './supervisor-enfermeras.component.html',
  styleUrls: ['./supervisor-enfermeras.component.css']
})
export class SupervisorEnfermerasComponent implements OnInit {
  public enfermeras: Enfermera[] = [];

  public filtroFrm: FormGroup = new FormGroup({
    nomEnfermera: new FormControl('')
  });

  @Input("Supervisor") supervisor: Supervisor;
  @Output() updateEnfermera: EventEmitter<Enfermera> = new EventEmitter();
  get enfermeraCtrl() {
    return this.filtroFrm.get('nomEnfermera');
  }

  constructor(private dialogo: MatDialog) { }

  ngOnInit() {
    this.enfermeras = this.supervisor.enfermeras;
  }
  onSubmit()
  {
    this.enfermeras = this.supervisor.enfermeras.filter(m => this.filterEnfermera(m.nombre.concat(m.primerApellido).concat(m.segundoApellido?m.segundoApellido:''))); 
   // this.page_number = 1;
  }
  filterEnfermera(nomEnfermera: string): boolean{
    let condicion: boolean = true;
    let enfermera: string;
    let e: string;
    let nombre: string; 

    if(this.enfermeraCtrl.value != ""){
      e = this.enfermeraCtrl.value;      
      enfermera = e.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomEnfermera.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      condicion = nombre.replace(/ +/g,'').includes(enfermera.replace(/ +/g,''));
    }  
   
    return condicion;
  }
  addMedico(enfermera: Enfermera){
    let dialogRef=this.dialogo.open(DialogoEnfermeraMedicosAdd, {width: '800px', data: enfermera });
    //dialogRef.afterClosed().subscribe(r => {
     // this.dataSourceMedicos.data = this.enfermera.medicos;        
    // })
  }
  addEnfermera(): void { 

    const dialogRef = this.dialogo.open( DialogoSupervisorEnfermerasAdd, { width: '800px', data: this.supervisor});
 
    dialogRef.afterClosed().subscribe(r => {
      this.enfermeras = this.supervisor.enfermeras;     
    });  
    
  } 
  removeEnfermera(enfermera: Enfermera): void { 
    const dialogRef = this.dialogo.open(DialogoSupervisorEnfermerasRemove, {width: '800px', data: {supervisor: this.supervisor, enfermera: enfermera }});
    
    dialogRef.afterClosed().subscribe(r => {
        this.enfermeras = this.supervisor.enfermeras;  
    }); 
  }
  setUpdateEnfermeraMedicos(enfermera: Enfermera): void {
    console.log("enfermera", enfermera)
    this.updateEnfermera.emit(enfermera);
  }
}
