import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatSnackBar } from '@angular/material';

import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { Medico } from 'src/app/moddelo/medico';
import { Enfermera } from "src/app/moddelo/enfermera";

interface RemoveMedico{
    code: number;
    message: string;
    data: Medico;
  }

@Component({
    selector: 'dialogo-enfermera-medico-remove',
    templateUrl: 'dialogo-enfermera-medico-remove.html'
})
export class DialogoEnfermeraMedicoRemove {
    private respuesta: RemoveMedico;

    get usuario(){
        return this.auth.getUsuario;
    }
    get offBoton(){
        return  (this.usuario.fk_nivel==0 || this.usuario.fk_nivel==3);
    }

    constructor( 
        public dialogo: MatDialogRef<DialogoEnfermeraMedicoRemove>,
        @Inject(MAT_DIALOG_DATA) public data: {enfermera: Enfermera, medico: Medico},
        private adminSrv: AdministradorService,
        private enfermerasSrv: EnfermerasService,     
        private snackBar: MatSnackBar,
        private auth: AuthService){}

    remove(): void {
            if(confirm("¿Estas seguro?")){
        
             this.enfermerasSrv
             .setDesasociarMedico(this.data.enfermera.id, this.data.medico)
             .subscribe(data => {
                                  let i: number = 0;
                                  let m: Medico[] = null;
                                  this.respuesta = <RemoveMedico> data;
                                  if(this.respuesta.code){
                                    i = this.data.enfermera.medicos.findIndex(m => m.id == this.data.medico.id); 
                                    m = this.data.enfermera.medicos.splice(i, 1);
                                    this.snackBar.open(this.respuesta.message,'Medico', 
                                      {
                                        duration: 3000,
                                        verticalPosition:'bottom',
                                        horizontalPosition:'center'
                                      });
                                    console.log("Removido: ", m[0].nombre, ' ' , m[0].primerApellido );
                                    this.onClose(); 
                                  }else{
                                          this.snackBar.open(this.respuesta.message,'Medico', 
                                            {
                                              duration: 3000,
                                              verticalPosition:'bottom',
                                              horizontalPosition:'center'
                                            });
                                  }
             }, err => { console.log(err); });
            }
    }
    onClose(): void {
      this.dialogo.close();
    }          
}