import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent, Sort } from '@angular/material';
import { Location } from '@angular/common';
import moment  from 'moment/moment';
import jsPDF from 'jspdf';
import XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { SaldoGeneral } from 'src/app/moddelo/saldo-general';
import { img } from 'src/app/datos/content-img';

@Component({
  selector: 'app-administrador-medicos-informes-saldos-generales',
  templateUrl: './administrador-medicos-informes-saldos-generales.component.html',
  styleUrls: ['./administrador-medicos-informes-saldos-generales.component.css']
})
export class AdministradorMedicosInformesSaldosGeneralesComponent implements OnInit {
  public medicos: SaldoGeneral[] = [];
  public medicosFilter: SaldoGeneral[] = [];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

 
  constructor(private location: Location, 
              private medicosSrv: MedicosService) { }

  ngOnInit() {
    this.medicosSrv
    .getSaldosGenerales()
    .subscribe(data => {
         this.medicos = data;
         this.medicosFilter = this.medicos;
         
    }, err => {console.log(err)});
  }

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

  onSubmit(){
  //  this.medicosFilter = this.medicos.filter(medico => this.filterIngreso(medico.created_at)).sort((a, b) => a.primerApellido.localeCompare(b.primerApellido));    

  }

  generateData(): any[] {
    let result = [];
    let i: number = 0;

    this.medicosFilter.forEach((m, i) => {
        
        result.push(Object.assign( {}, 
        { 
          No: m.fk_medico, 
          Medico: m.medico,
          TotalPuntos: m.total_puntos,
          TotalRedimir: m.total_redimir,
          Saldo: m.saldo
        }));

    });

  return result;
  }

  createHeaders() {
   let keys: string[] = [
                          "No",    
                          "Medico",
                          "TotalPuntos",
                          "TotalRedimir",
                          "Saldo"
                        ]
    let result: any[]= []; 
    result = <any[]> keys.map(key => (<any> {
      id: key,
      name: key,
      prompt: key,
      width: "65",
      align: "center",
      padding: "0"
    }));

    
    return result;
  }  
 
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let producto: string = "Saldos";  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("Medicos",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(producto, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
   
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [ 
                { header: 'NO.', dataKey: 'No' }, 
                { header: 'Medico', dataKey: 'Medico' },
                { header: 'Total de Puntos', dataKey: 'TotalPuntos' },
                { header: 'Total Canjes', dataKey: 'TotalRedimir' }, 
                { header: 'Saldo', dataKey: 'Saldo' },         
               ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
         doc.text(moment(Date.now()).format('DD-MM-YYYY'),  260, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }   
     
     doc.save(`${new Date().toISOString()}_Medicos_saldos.pdf`);
  }
  exportTablaExcel(): void {
    let nombreMedico = [{campo: "Medicos", productos: "Saldos" }];
    let tProductos: SaldoGeneral[] = this.medicosFilter; 
   

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreMedico, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tProductos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Medicos');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}medicos-saldo.xlsx`);
  }
  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  sortMedicos(sort: Sort): void {
    const datos: SaldoGeneral[] = this.medicosFilter.slice();
    if(!sort.active || sort.direction === ''){
      this.medicosFilter = datos;
      return;
    }
    this.medicosFilter =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'medico':
          return this.compare(a.medico, b.medico, isAsc);
        case 'puntos':
          return this.compare(a.total_puntos, b.total_puntos, isAsc);
        case 'canjes':
          return this.compare(a.total_redimir, b.total_redimir, isAsc);
        case 'saldos':
          return this.compare(a.saldo, b.saldo, isAsc);
        default:
          return 0;
      }
    });
  } 
  
  goBack(): void{
    this.location.back();
  }
}
